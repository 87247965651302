import { Inject } from "typescript-ioc";
import { FetchService } from "Async/Scripts/FetchService";
import { autobind } from "core-decorators";
import { EventBinder } from "Events/Scripts/EventBinder";
import { Utils } from "Helpers/Scripts/Utils";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Spinner } from "Spinner/Scripts/Spinner";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { FormCallbackBeforeSubmit } from "./FormCallbackBeforeSubmit";
import { FormCallbackOnData } from "./FormCallbackOnData";
import { FormCallbackOnResult } from "./FormCallbackOnResult";
import { FormSubmitMode } from "./FormSubmitMode";
import { IFormOptions } from "./IFormOptions";
import { IFormSubmitHandler } from "./IFormSubmitHandler";

export class Form extends UiComponent implements IFormOptions {
    protected readonly ATTR_ACTION: string = "action";
    protected readonly ATTR_METHOD: string = "method";
    protected readonly CLASS_SPINNER_LOADING: string = "k-loading";
    protected readonly DEFAULT_SUBMIT_METHOD: string = "POST";
    protected readonly DISABLED: string = "disabled";
    protected readonly SEL_SUBMIT_BUTTON: string = "button[type=submit]";
    protected readonly SEL_KENDO_WIDGET: string = ".k-widget";
    protected readonly SEL_INPUT: string = "input";
    protected readonly SEL_TEXT_INPUT: string = "input[role=textbox]";
    protected readonly ANALYTICS_DATA_ID: string = "analytics-provider-data";

    public static readonly OPTIONS_KEY: string = "form";

    /**
     * Indicates whether to apply spinner overlay to the whole page or form element for the duration of the request.
     * @default false
     */
    public applyOverlay: boolean;

    /**
     * Indicates whether to apply spinner overlay to the form element only.
     * Valid only when applyOverlay is true.
     * @default false
     */
    public applyOverlayToFormOnly: boolean;

    /**
     * Class(es) to apply to the submit button for the duration of the request. If empty, ignored.
     * @default undefined
     */
    public buttonApplyClass: string;

    /**
     * Indicates whether to apply spinner to the submit button for the duration of the request.
     * @default true
     */
    public buttonApplySpinner: boolean = true;

    /**
     * Indicates whether to disable the submit button for the duration of the request.
     * @default true
     */
    public buttonDisable: boolean = true;

    /**
     * Selector to find the submit button within the form; if empty, no button will be manipulated.
     * @default "button[type=submit]"
     */
    public buttonSelector: string = this.SEL_SUBMIT_BUTTON;

    /**
     * Selector of the text input within the form.
     * @default "input[role=textbox]"
     */
    public textInputSelector: string = this.SEL_TEXT_INPUT;

    /**
     * UI component's context element in the DOM.
     */
    public get context(): HTMLFormElement {
        return this.getContextAs(HTMLFormElement);
    }

    /**
     * Called always after AJAX/fetch API response was returned. Called after callbackSuccess or callbackError
     * if some is set.
     * @default undefined
     */
    public callbackAlways?: FormCallbackOnResult;

    /**
     * Called before initiating form submit request. If a falsy value (empty string, false, ...)
     * is returned, no request is executed. If true is returned, the default form action is requested.
     * If nonempty string is returned, it will be used as the request URL.
     * @default undefined
     */
    public callbackBefore?: FormCallbackBeforeSubmit;

    /**
     * Can be used to provide rather a custom data before submitting
     * the form than using form's own data. The callback takes the form
     * element reference as a parameter and should return an object
     * with data to be sent.
     * @default undefined
     */
    public callbackOnData?: FormCallbackOnData;

    /**
     * Called always after AJAX/fetch API response returned an error.
     * @default undefined
     */
    public callbackOnError?: FormCallbackOnResult;

    /**
     * Called always after AJAX/fetch API response returned success.
     * @default undefined
     */
    public callbackOnSuccess?: FormCallbackOnResult;

    /**
     * Indicates whether to disable all input fields on the form to prevent user changes during request.
     * @default false
     */
    public disableInputs: boolean;

    /**
     * Indicates whether the form input text its bound trim instance.
     * @default false
     */
    public trimFields: boolean;

    /**
     * Unique component key for logger etc.
     */
    public get key(): string {
        return "Form";
    }

    /**
     * @returns The component model if provided.
     */
    public get model(): IFormOptions {
        return this._model;
    }

    /**
     * Indication whether the form is currently being submitted / waiting
     * for the previous submit response.
     */
    public get submitIsInProgress(): boolean {
        return this._submitIsInProgress;
    }

    /**
     * Determines the way which the form's data is submitted.
     * @default FormSubmitMode.FetchApi
     */
    public submitMode: FormSubmitMode = FormSubmitMode.FetchApi;

    /**
     * Determines if the previous form request should be aborted when new request
     * is being sent
     */
    public isResubmittingAllowed: boolean = false;

    /**
     * Indicates whether the form wrapper should maintain its bound validator instance.
     * @default false
     */
    public get isValidatorUsed(): boolean {
        return this._isValidatorUsed;
    }

    public set isValidatorUsed(value: boolean) {
        this._isValidatorUsed = value;
        this.updateValidatorInstance();
    }

    /**
     * Overrides for the default validator options.
     * Applicable only when isValidatorUsed is true.
     * @default undefined
     */
    public validatorOptions: kendo.ui.ValidatorOptions = {};

    /**
     * @returns Instance of internal validator or undefined when not used.
     */
    public get validator(): kendo.ui.Validator | undefined {
        return this._validator;
    }

    /**
     * Binds change event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindChange(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindChange(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds change event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindChange(namespace?: string, handler?: GenericEventHandler): this {
        this._binder.unbindChange(namespace, handler);
        return this;
    }

    protected _submitIsInProgress: boolean = false;
    protected _fetchService: FetchService;
    protected _isValidatorUsed: boolean = false;
    protected _validator?: kendo.ui.Validator;
    protected _model: IFormOptions;
    /**
     * Have to rememeber submit handlers to prioritize optional validator's submit handler.
     * TODO: Better without re-binding?
     */
    protected submitHandlers: IFormSubmitHandler[] = [];
    /**
     * Abort controller of the latest form submit.
     */
    private _latestFetchAbortController?: AbortController | null;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject fetchService: FetchService
    ) {
        super(componentFactory, binder, loggerFactory);
        this._fetchService = fetchService;
    }

    /**
     * Last initialization step override.
     */
    public init(): void {
        this.setOptions(Utils.extend(true, {}, this.getOptions(Form.OPTIONS_KEY), this._model));
    }

    /**
     * Sets up current instance's options, creates or destroys internal validator instance
     * and (re)connects event handlers accordingly.
     */
    // eslint-disable-next-line complexity
    public setOptions(options: IFormOptions): this {
        this._logger.info(
            "Setting form options - form: %s, options: %o ...",
            Utils.describeElement(this.context),
            options
        );

        let o: IFormOptions = {};
        o = Utils.extend(true, o, options);

        if (typeof o.applyOverlay === "boolean") {
            this.applyOverlay = o.applyOverlay;
        }
        if (typeof o.applyOverlayToFormOnly === "boolean") {
            this.applyOverlayToFormOnly = o.applyOverlayToFormOnly;
        }
        if (typeof o.buttonSelector === "string") {
            this.buttonSelector = o.buttonSelector;
        }
        if (typeof o.textInputSelector === "string") {
            this.textInputSelector = o.textInputSelector;
        }
        if (typeof o.buttonApplyClass === "string") {
            this.buttonApplyClass = o.buttonApplyClass;
        }
        if (typeof o.buttonApplySpinner === "boolean") {
            this.buttonApplySpinner = o.buttonApplySpinner;
        }
        if (typeof o.buttonDisable === "boolean") {
            this.buttonDisable = o.buttonDisable;
        }
        if (typeof o.callbackAlways === "function") {
            this.callbackAlways = o.callbackAlways;
        }
        if (typeof o.callbackBefore === "function") {
            this.callbackBefore = o.callbackBefore;
        }
        if (typeof o.callbackOnData === "function") {
            this.callbackOnData = o.callbackOnData;
        }
        if (typeof o.callbackOnError === "function") {
            this.callbackOnError = o.callbackOnError;
        }
        if (typeof o.callbackOnSuccess === "function") {
            this.callbackOnSuccess = o.callbackOnSuccess;
        }
        if (typeof o.disableInputs === "boolean") {
            this.disableInputs = o.disableInputs;
        }
        if (typeof o.isValidatorUsed === "boolean") {
            this.isValidatorUsed = o.isValidatorUsed;
        }
        if (typeof o.submitMode === "number") {
            this.submitMode = o.submitMode;
        }
        if (typeof o.validatorOptions === "object") {
            this.validatorOptions = o.validatorOptions;
        }
        if (typeof o.trimFields === "boolean") {
            this.trimFields = o.trimFields;
        }
        if (typeof o.isResubmittingAllowed === "boolean") {
            this.isResubmittingAllowed = o.isResubmittingAllowed;
        }

        return this.updateValidatorInstance().bindEvents();
    }

    public submit(): void {
        this._binder.trigger("submit");
    }

    /**
     * Creates or destroys validator instance depending on current
     * value of isValidatorUsed property.
     */
    protected updateValidatorInstance(): this {
        if (this._isValidatorUsed) {
            if (!this._validator) {
                this._validator = kendo.createValidator(this.context, this.validatorOptions);
                // Re-binding submit event handlers is needed here to have
                // the Kendo validator event handler as the first one.
                this._logger.info(
                    "New validator instance created, re-binding submit (and analytics) event handlers ..."
                );
                this.rebindSubmitHandlers();
            } else {
                this._validator.setOptions(this.validatorOptions);
            }
        } else {
            if (this._validator) {
                this._validator.destroy();
                this._validator = undefined;
            }
        }

        if (this.trimFields) {
            this.bindFieldTrimming();
        }

        return this;
    }

    protected updateDesignBeforeRequest(): void {
        this._logger.info("Updating design before request ...");

        if (this.buttonSelector) {
            const button = this.findElement(this.buttonSelector);

            if (button) {
                if (this.buttonApplyClass) {
                    button.classList.add(this.buttonApplyClass);
                }
                if (this.buttonApplySpinner) {
                    Spinner.applyTo(button, {
                        cssClass: this.CLASS_SPINNER_LOADING,
                        halign: "right",
                    });
                }
                if (this.buttonDisable) {
                    Utils.setProp(button, this.DISABLED, true);
                }
            }
        }

        if (this.applyOverlay) {
            Spinner.applyOverlayTo(this.applyOverlayToFormOnly ? this.context : undefined);
        }
    }

    protected updateDesignAfterResponse(): void {
        this._logger.info("Updating design after response ...");

        if (this.applyOverlay) {
            Spinner.removeOverlayFrom(this.applyOverlayToFormOnly ? this.context : undefined);
        }
        if (this.disableInputs) {
            this.toggleInputWidgets(true);
        }

        if (this.buttonSelector) {
            const button = this.findElement(this.buttonSelector);

            if (button) {
                if (this.buttonApplyClass) {
                    button.classList.remove(this.buttonApplyClass);
                }
                if (this.buttonApplySpinner) {
                    Spinner.removeFrom(button, this.CLASS_SPINNER_LOADING);
                }
                if (this.buttonDisable) {
                    Utils.setProp(button, this.DISABLED, false);
                }
            }
        }
    }

    protected bindEvents(): this {
        this.submitHandlers.push({
            handler: this.mainSubmitHandler,
            namespace: this.key,
        });

        return this.rebindSubmitHandlers();
    }

    @autobind
    protected rebindSubmitHandlers(): this {
        this.submitHandlers.forEach((item) =>
            this._binder
                .unbindSubmit(item.namespace, item.handler)
                .bindSubmit(item.handler, item.namespace)
        );

        return this;
    }

    @autobind
    protected mainSubmitHandler(event: JQuery.Event): void {
        const stop = () => {
            event.preventDefault();
            event.stopImmediatePropagation();
        };

        // block submit if already in progress
        if (this._submitIsInProgress && !this.isResubmittingAllowed) {
            return stop();
        }

        this._logger.info("Submit was called.");

        // Form's action value can be changed in callbackBefore.
        // If the result is false, form submit will be prevented.
        // When result is true, the form element's action will be used.
        // When result is a string, that string will be used as form submit URL.
        let action = this.getAttr(this.ATTR_ACTION);
        if (typeof this.callbackBefore === "function") {
            const callbackResult = this.callbackBefore(this.context);
            if (!callbackResult) {
                this._logger.info(
                    "Before submit callback returned a falsy value. Submit was stopped."
                );

                return stop();
            } else if (typeof callbackResult === "string") {
                action = callbackResult;
            }
        }
        action = action || location.href;
        this._logger.info(`Form action URL is '${action}'.`);

        // OK, going to do submit
        this._submitIsInProgress = true;
        // overlays, progress indicators, etc.
        this.updateDesignBeforeRequest();

        // "normal" submit
        if (this.submitMode === FormSubmitMode.Normal) {
            this._logger.info("Submitting in normal mode ...");

            return;
        }

        // AJAX / fetchAPI submit
        event.preventDefault();

        // TODO: some generics for better typed data?
        // TODO: shouldn't the callbacks be rather events? or callbacks + events?
        const data: FormData =
            typeof this.callbackOnData === "function"
                ? this.callbackOnData(this.context)
                : new FormData(this.context);

        // Inputs may only be disabled after form serialization,
        // otherwise they will not get serialized.
        if (this.disableInputs) {
            this.toggleInputWidgets(false);
        }

        // form's method
        let method = this.getAttr(this.ATTR_METHOD);
        method = method ? method.toUpperCase() : this.DEFAULT_SUBMIT_METHOD;

        // send the request
        // TODO: so far AJAX is not implemented, just fetch API
        this.abort();

        this._latestFetchAbortController = new AbortController();
        const options: RequestInit = Utils.extend(
            true,
            {},
            FetchService.NO_CACHE_REQUEST_OPTIONS,
            {
                method,
            },
            {
                signal: this._latestFetchAbortController?.signal,
            }
        );

        if (method !== "GET") {
            options.body = data;
        }

        let success = true;
        let result: any;
        let isAborted = false;
        this._fetchService
            .fetch<any>(action, null, options)
            // done
            .then((response) => {
                result = response;
                if (typeof this.callbackOnSuccess === "function") {
                    this.callbackOnSuccess(this.context, { result, success });
                }

                return response;
            })
            // error
            .catch((error) => {
                isAborted = error.name === "AbortError";
                if (isAborted) {
                    this._logger.info("The request has been aborted.");
                } else {
                    this._logger.error("Error while trying to submit form data:", error);
                }
                success = false;
                result = error;
                if (typeof this.callbackOnError === "function" && !isAborted) {
                    this.callbackOnError(this.context, { result, success });
                }
            })
            // finally
            .then(() => {
                if (isAborted) {
                    return;
                }
                this.updateDesignAfterResponse();
                this._submitIsInProgress = false;
                if (typeof this.callbackAlways === "function") {
                    this.callbackAlways(this.context, { result, success });
                }
                if (this._isValidatorUsed && this._validator) {
                    this._validator.displayValidationResult(result as kendo.ui.IValidationResult);
                }
            });
    }

    /**
     * Enables or disables form input widgets.
     */
    public toggleInputWidgets(enable: boolean): this {
        this.find(this.SEL_KENDO_WIDGET).forEach((item) => {
            const widget = kendo.findWidgetInstance(item);
            const ENABLE: string = "enable";
            if (
                widget &&
                widget.hasOwnProperty(ENABLE) &&
                typeof widget[ENABLE as keyof kendo.ui.Widget] === "function"
            ) {
                widget[ENABLE as keyof kendo.ui.Widget](enable);
            } else {
                Utils.find(item, this.SEL_INPUT).forEach((input) =>
                    Utils.setProp(input, this.DISABLED, !enable)
                );
            }
        });

        return this;
    }

    /**
     * Binds submit event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * TODO: Not using here the one parameter as in other event helpers because of the re-binding
     * logic for submit handlers. When it is needed in future, this whole approach has to be redone.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     */
    public bindSubmit(handler: (event?: JQuery.Event) => any, namespace?: string): this {
        this.submitHandlers.push({ handler, namespace });
        this._binder.bindSubmit(handler, namespace);

        return this;
    }

    /**
     * Unbinds submit event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindSubmit(namespace?: string, handler?: (event?: JQuery.Event) => any): this {
        this._binder.unbindSubmit(namespace, handler);

        if (namespace && handler) {
            this.submitHandlers = this.submitHandlers.filter(
                (item) => item.namespace !== namespace || item.handler !== handler
            );
        } else if (namespace) {
            this.submitHandlers = this.submitHandlers.filter(
                (item) => item.namespace !== namespace
            );
        } else if (handler) {
            this.submitHandlers = this.submitHandlers.filter((item) => item.handler !== handler);
        }

        return this;
    }

    /**
     * Trim form text field before validation.
     */
    public bindFieldTrimming(): this {
        this.find(this.textInputSelector).forEach((input) => {
            this._binder.bindChange(() => Utils.trimElement(input), this.textInputSelector);
        });

        return this;
    }

    /**
     * Aborts request of a given abort controller
     */
    public abort(): void {
        if (this._latestFetchAbortController && !this._latestFetchAbortController.signal.aborted) {
            this._latestFetchAbortController.abort();
        }
    }
}
