import { Inject } from "typescript-ioc";
import { autobind } from "core-decorators";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { UiComponent } from "../../Ui/Scripts/UiComponent";
import { Utils } from "Helpers/Scripts/Utils";

import "../Styles/clipboard-copy-button.css";

export class ClipboardCopyButton extends UiComponent {
    private readonly SEL_COPY_BUTTON: string = ".js-copy-button";
    private readonly SEL_COPY_INPUT: string = ".js-copy-input";
    private readonly DATA_TOOLTIP_CONTENT: string = "tooltip";
    private readonly CHECKMARK_TIMEOUT: number = 3000;
    private readonly SAFE_MAX_LINK_LENGTH: number = 1023;

    private _copyButton: HTMLButtonElement;
    private _copyInput: HTMLInputElement;
    private _tooltip: kendo.ui.Tooltip;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    public init(): void {
        this._copyButton = this.findElementStrict(this.SEL_COPY_BUTTON) as HTMLButtonElement;
        this._copyInput = this.findElementStrict(this.SEL_COPY_INPUT) as HTMLInputElement;

        const tooltipContent = Utils.getData(this._copyButton, this.DATA_TOOLTIP_CONTENT);
        this._tooltip = kendo.createTooltip(this._copyButton, {
            content: tooltipContent,
            position: "bottom",
            offset: 10,
            callout: false,
            showOn: "click",
        } as kendo.ui.TooltipOptions);

        this._copyButton.addEventListener("click", this._copyButtonClick);
    }

    @autobind
    private _copyButtonClick(_event: Event): void {
        this._copyButton.disabled = true;

        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const oldEditable = this._copyInput.contentEditable;
            const range = document.createRange();
            const selection = window.getSelection();

            this._copyInput.contentEditable = "true";
            this._copyInput.readOnly = true;
            range.selectNodeContents(this._copyInput);
            selection?.removeAllRanges();
            selection?.addRange(range);
            this._copyInput.setSelectionRange(0, this.SAFE_MAX_LINK_LENGTH);
            this._copyInput.contentEditable = oldEditable;
            this._copyInput.readOnly = false;
        } else {
            this._copyInput.select();
        }
        try {
            document.execCommand("copy");
        } catch (err) {
            throw new Error("Copy to clipboard failed!");
        }

        window.setTimeout(this._enableButton, this.CHECKMARK_TIMEOUT);
    }

    @autobind
    private _enableButton(): void {
        this._tooltip.hide();
        this._copyButton.disabled = false;
    }
}
