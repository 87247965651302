import { IConverterDefaultValuesSettings } from "./IConverterDefaultValuesSettings";

export class Converter {
    public static toDate(value: any): Date | undefined {
        switch (typeof value) {
            case "string":
                try {
                    return new Date(Date.parse(value));
                } catch (error) {
                    // empty block
                }
                break;

            case "object":
                if (value instanceof Date) {
                    return value;
                }
                break;

            default:
                break;
        }

        return undefined;
    }

    public static getPropertyValue(
        instance: any,
        key: string,
        requestedType: string,
        defaultValue?: any
    ): any {
        if (!instance || typeof instance !== "object" || !instance.hasOwnProperty(key)) {
            return defaultValue;
        }

        const propertyValue: any = instance[key];
        const propertyType: string = typeof propertyValue;

        switch (requestedType) {
            case "boolean":
            case "number":
            case "string":
                return propertyType === requestedType ? propertyValue : defaultValue;

            case "Date":
                const value = this.toDate(propertyValue);
                return !(value instanceof Date) && defaultValue instanceof Date
                    ? defaultValue
                    : value;

            case "Array":
                return Array.isArray(propertyValue) ? propertyValue : defaultValue;

            default:
                return propertyValue;
        }
    }

    public static hasNonDefaultValue(instance: any, key: string, defaultValue: any): boolean {
        return instance && instance[key] !== defaultValue;
    }

    public static hasNonDefaultValues(
        instance: any,
        settings: IConverterDefaultValuesSettings[]
    ): boolean {
        let result = false;
        if (!instance || typeof instance !== "object") {
            return result;
        }

        settings.forEach(
            (item) =>
                (result =
                    result || Converter.hasNonDefaultValue(instance, item.key, item.defaultValue))
        );

        return result;
    }
}
