import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";

import { AddressEditor } from "./AddressEditor";
import { AddressLookupEditor } from "ECommerce/Shared/Scripts/AddressLookupEditor";

@OnlyInstantiableByContainer
@Singleton
export class AddressEditorFactory extends UiComponent {
    private readonly SEL_ADDRESS_EDITOR: string = ".js-address-editor";
    private readonly SEL_ADDRESS_LOOKUP_EDITOR: string = ".js-address-lookup-editor";

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    public init(): void {
        this._binder.bindCustomEvent("DOMRefresh", (e) => {
            this.createAll((e as JQuery.TriggeredEvent).target);
        });

        this.createAll(this.context);
    }

    public createAll(context: Element): void {
        const newEditors: NodeListOf<Element> = context.querySelectorAll(this.SEL_ADDRESS_EDITOR);
        if (newEditors.length) {
            this._componentFactory.createArray(context, AddressEditor, this.SEL_ADDRESS_EDITOR);
        }

        const newLookupEditors: NodeListOf<Element> = context.querySelectorAll(
            this.SEL_ADDRESS_LOOKUP_EDITOR
        );
        if (newLookupEditors.length) {
            this._componentFactory.createArray(
                context,
                AddressLookupEditor,
                this.SEL_ADDRESS_LOOKUP_EDITOR
            );
        }
    }
}
