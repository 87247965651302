import { Inject } from "typescript-ioc";
import { autobind } from "core-decorators";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Div } from "Ui/Scripts/Div";
import { Span } from "Ui/Scripts/Span";

import "../Styles/progress-bar.css";

export class ProgressBar extends UiComponent {
    public get key(): string {
        return "ProgressBar";
    }

    private readonly CLASS_PREFIX: string = "progress-bar";
    private readonly SUFFIX_LABEL: string = "label";
    private readonly SUFFIX_PROGRESS: string = "progress";
    private readonly SUFFIX_VALUE: string = "value";
    private readonly MAX_PERCENT_WIDTH: number = 100;
    private readonly DATA_VALUE: string = "value";
    private readonly DATA_MAX: string = "max";
    private readonly ATTR_DATA_MAX: string = "data-max";
    private readonly DATA_TEMPLATE_VALUE: string = "templateValue";
    private readonly DATA_TEMPLATE_MAX: string = "templateMax";

    private _value: number;
    private _max: number;
    private _isScheduledUpdate: boolean;
    private _templateValue: string;
    private _templateMax: string;
    private _labelContainer: Div;
    private _valueBar: Span;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    public init(): void {
        this._value = this.getData(this.DATA_VALUE) || 0;
        this._max = this.getData(this.DATA_MAX) || 0;
        this._templateValue = this.getData(this.DATA_TEMPLATE_VALUE) || "{0}";
        this._templateMax = this.getData(this.DATA_TEMPLATE_MAX) || "";

        const labelClassName = `${this.CLASS_PREFIX}__${this.SUFFIX_LABEL}`;
        const progressClassName = `${this.CLASS_PREFIX}__${this.SUFFIX_PROGRESS}`;
        const valueClassName = `${this.CLASS_PREFIX}__${this.SUFFIX_VALUE}`;

        let elmLabel = this.findElement(`.${labelClassName}`);
        if (!elmLabel) {
            elmLabel = document.createElement("div");
            elmLabel.setAttribute("class", labelClassName);
            if (this._templateMax) {
                elmLabel.setAttribute(this.ATTR_DATA_MAX, "");
            }
            this.context.appendChild(elmLabel);
        }
        this._labelContainer = this.createComponent(Div, `.${labelClassName}`);

        let elmValue = this.findElement(`.${valueClassName}`);
        if (!elmValue) {
            const elmProgress = document.createElement("div");
            elmProgress.setAttribute("class", progressClassName);
            elmValue = document.createElement("span");
            elmValue.setAttribute("class", valueClassName);
            elmProgress.appendChild(elmValue);
            this.context.appendChild(elmProgress);
        }
        this._valueBar = this.createComponent(Span, `.${valueClassName}`);

        this._update();
    }

    public get max(): number {
        return this._max;
    }

    public get value(): number {
        return this._value;
    }

    public set value(newValue: number) {
        this._value = this._constrainValue(newValue);
        this._scheduleUpdate();
    }

    public setProgress(value: number, max?: number): void {
        if (typeof max === "number") {
            this._max = Math.max(max, 0);
            this.toggle(this._max > 0);
        }
        this._value = this._constrainValue(value);
        this._scheduleUpdate();
    }

    public setLabelTemplate(labelTemplate: string, maxTemplate?: string): void {
        this._templateValue = labelTemplate;
        if (this._templateMax && !maxTemplate) {
            this._labelContainer.removeAttr(this.ATTR_DATA_MAX);
        }
        this._templateMax = maxTemplate ? maxTemplate : "";
        this._scheduleUpdate();
    }

    private _constrainValue(value: number): number {
        return Math.min(Math.max(value, 0), this._max);
    }

    private _scheduleUpdate(): void {
        if (this._isScheduledUpdate) {
            return;
        }
        window.requestAnimationFrame(this._update);
        this._isScheduledUpdate = true;
    }

    @autobind
    private _update(): void {
        this._updateLabels();
        this._updateProgressBar();
        this._isScheduledUpdate = false;
    }

    private _updateLabels(): void {
        const formattedValueText = this._templateValue
            .replace("{0}", this._value.toString())
            .replace("{1}", this._max.toString());
        this._labelContainer.setHtml(`${formattedValueText}`);
        if (this._templateMax) {
            const formattedMaxText = this._templateMax.replace("{0}", this._max.toString());
            this._labelContainer.setAttr(this.ATTR_DATA_MAX, formattedMaxText);
        }
    }

    private _updateProgressBar(): void {
        let progressBarPosition = 0;

        if (this._max > 0) {
            progressBarPosition = (this._value * this.MAX_PERCENT_WIDTH) / this._max;
        }

        this._valueBar.setCss("width", `${progressBarPosition}%`);
    }
}
