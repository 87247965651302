import { ILogAppender } from "./ILogAppender";
import { LogAppender } from "./LogAppender";
import { DebugOutputType } from "./DebugOutputType";

/**
 * Simple alerts logger.
 */
/* eslint-disable  */
export class AlertsLogAppender extends LogAppender implements ILogAppender {
    public readonly outputType: DebugOutputType = DebugOutputType.Alerts;

    public error(prefix: string, showCallStack: boolean, message?: any, ..._: any[]): this {
        alert(this.constructMessage(`ERROR\n${prefix}${message}`, showCallStack));

        return this;
    }

    public warning(prefix: string, showCallStack: boolean, message?: any, ..._: any[]): this {
        alert(this.constructMessage(`WARNING\n${prefix}${message}`, showCallStack));

        return this;
    }

    public info(prefix: string, showCallStack: boolean, message?: any, ..._: any[]): this {
        alert(this.constructMessage(`INFO\n${prefix}${message}`, showCallStack));

        return this;
    }

    public log(prefix: string, showCallStack: boolean, message?: any, ..._: any[]): this {
        alert(this.constructMessage(`LOG\n${prefix}${message}`, showCallStack));

        return this;
    }

    private constructMessage(message: string, showCallStack: boolean): string {
        return !showCallStack ? message : `${message}\n\n${this.getCallStack()}`;
    }
}
/* eslint-enable */
