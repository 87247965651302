import { Utils } from "Helpers/Scripts/Utils";

export abstract class EditorInput {
    public static readonly COMPONENT_DATA_KEY: string = "EditorInput";

    public get key(): string {
        return EditorInput.COMPONENT_DATA_KEY;
    }

    private readonly CLASS_FILTER: string = "js-filter";
    private readonly DATA_PROPERTY_NAME: string = "property-name";
    private readonly DATA_CLEAR_ORDER: string = "filter-clear-order";
    private _filterPropertyName: string;
    private _isFilter: boolean;
    private _filterClearOrder: number;

    public component: kendo.ui.Widget | Element;

    public ctor(component: Element): void {
        this._filterPropertyName =
            Utils.getData(component, this.DATA_PROPERTY_NAME) ||
            (Utils.getAttr(component, "name") ?? "").replace(/^.+\./, "");
        this._isFilter = Utils.hasClass(component, this.CLASS_FILTER);
        this._filterClearOrder = Utils.getData(component, this.DATA_CLEAR_ORDER) || -1;
        Utils.setData(component, EditorInput.COMPONENT_DATA_KEY, this);
    }

    public getPropertyName(): string {
        return this._filterPropertyName;
    }

    public isFilter(): boolean {
        return this._isFilter;
    }

    public getFilterClearOrder(): number {
        return this._filterClearOrder;
    }

    /* these will be implemented in inherited classes */
    public abstract getValue(): any;

    public abstract setValue(value: string | number | string[]): this;
}
