import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Device } from "Rwd/Scripts/Device";
import { ISwiperMenuOptions } from "./ISwiperMenuOptions";
import { IDeviceMediaEvent } from "Rwd/Scripts/IDeviceMediaEvent";
import { Swiper } from "swiper";

export class SwiperMenu extends UiComponent {
    public get key() {
        return "Swiper";
    }

    protected _model: Partial<ISwiperMenuOptions> | null = null;

    private readonly WRAPPER_CLASS = "menu-wrapper";
    private readonly SLIDE_CLASS = "menu-item";
    private readonly NO_SWIPING_CLASS = "swiper-no-swiping";
    private readonly SWIPER_BUTTON_NEXT_CLASS = ".swiper-button-next";
    private readonly SWIPER_BUTTON_PREV_CLASS = ".swiper-button-prev";
    private readonly FIRST_SLIDE_INDEX = 0;

    private _device: Device;
    private _swiper?: Swiper;
    private _config: ISwiperMenuOptions = {
        keepPosition: false,
        swiperSlideClass: this.SLIDE_CLASS,
        swiperWrapperClass: this.WRAPPER_CLASS,
    };

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject device: Device
    ) {
        super(componentFactory, binder, loggerFactory);
        this._device = device;
    }

    /**
     * Initialization
     */
    protected init(): void {
        this._config = { ...this._config, ...this.getData() };
        this.createSwiper();
        this._device.bindMedia(() => this.updateSwiper());
        if (!this._config.swiperBreakpoints) return;

        this.toggleSwiperActiveClass(this._device.activeBreakpoint.index);
        this._device.bindMedia((event: IDeviceMediaEvent) =>
            this.toggleSwiperActiveClass(event.breakpoint.index)
        );
    }

    // Create swiper
    protected createSwiper(): void {
        const { swiperWrapperClass: wrapperClass, swiperSlideClass: slideClass } = this._config;
        this._swiper = new Swiper(this.context as HTMLElement, {
            navigation: {
                nextEl: this.SWIPER_BUTTON_NEXT_CLASS,
                prevEl: this.SWIPER_BUTTON_PREV_CLASS,
            },
            noSwiping: true,
            noSwipingClass: this.NO_SWIPING_CLASS,
            observer: true,
            observeParents: true,
            slideClass,
            slidesPerView: "auto",
            wrapperClass,
            ...this._model,
        });
    }

    // Set swiper active class for swiping
    protected toggleSwiperActiveClass(index: number): void {
        const { swiperBreakpoints: breakpoints = [] } = this._config;
        const isExcludedOnBreakpoint = breakpoints.indexOf(index) === -1;
        this.context.classList.toggle(this.NO_SWIPING_CLASS, isExcludedOnBreakpoint);
    }

    // Swiper update
    public updateSwiper(): void {
        if (!this._swiper) return;
        const index = this._config.keepPosition ? this._swiper.activeIndex : this.FIRST_SLIDE_INDEX;
        this._swiper.update();
        this._swiper.slideTo(index);
    }
}
