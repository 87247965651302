import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { DebugLevel, DEFAULT_DEBUG_LEVEL } from "./DebugLevel";
import { DebugOutputType, DEFAULT_OUTPUT_TYPE, DEFAULT_OUTPUT_TYPES } from "./DebugOutputType";
import { DEFAULT_SHOW_CALL_STACK } from "./ShowCallStack";
import { DEFAULT_FULL_DATES } from "./FullDates";
import { ILoggerService } from "./ILoggerService";
import { ILoggerServiceItem } from "./ILoggerServiceItem";
import { IList } from "Helpers/Scripts/IList";
import { IStorageService } from "Storages/Scripts/IStorageService";
import { LocalStorageService } from "Storages/Scripts/LocalStorageService";
import { LogAppenderFactory } from "./LogAppenderFactory";

@OnlyInstantiableByContainer
@Singleton
export class LoggerService implements ILoggerService {
    private readonly LEVEL_FILTER: string = "levelFilter";
    private readonly OUTPUT_TYPES: string = "outputTypes";
    private readonly FULL_DATES: string = "fullDates";
    private readonly IS_ACTIVE: string = "isActive";

    private readonly _storage: IStorageService;
    private readonly _logAppenderFactory: LogAppenderFactory;

    public get key(): string {
        return "LoggerService";
    }

    public constructor(
        @Inject storage: LocalStorageService,
        @Inject logAppenderFactory: LogAppenderFactory
    ) {
        this._storage = storage;
        this._logAppenderFactory = logAppenderFactory;
        this.readSettings();
    }

    /**
     * Reads settings from storage and creates corresponding items.
     */
    private readSettings(): this {
        // raw data
        const data = this._storage.getItem(this.key) || "{}";
        // parse data to items; JSON.parse(undefined) throws so give there empty object
        this._items = JSON.parse(data, this.itemsReviver) || {};

        return this;
    }

    /**
     * Reviver can be passed as the second parameter to JSON.parse()
     * to further customize the default converted values for particular properties.
     */
    private itemsReviver(key: string, value: any): any {
        switch (key) {
            // whole object
            case "":
                if (!value || typeof value !== "object") {
                    return value;
                }
                for (const name in value) {
                    if (!value.hasOwnProperty(name)) {
                        continue;
                    }
                    const item = value[name] as ILoggerServiceItem;
                    if (!item) {
                        continue;
                    }
                    if (!item.levelFilter) {
                        item.levelFilter = DEFAULT_DEBUG_LEVEL;
                    }
                    if (!item.outputTypes) {
                        item.outputTypes = DEFAULT_OUTPUT_TYPES;
                    }
                    if (typeof (item as any).fullDates === "undefined") {
                        item.fullDates = DEFAULT_FULL_DATES;
                    }
                    item.isActive = false;
                }

                return value;
            // modified fields
            case this.LEVEL_FILTER:
                if (typeof value === "number") {
                    if (value < DebugLevel.Error) {
                        return DebugLevel.Error;
                    }
                    if (value > DebugLevel.Log) {
                        return DebugLevel.Log;
                    }
                    return value;
                } else {
                    return DebugLevel.Warning;
                }

            case this.OUTPUT_TYPES:
                if (!(value instanceof Array)) {
                    return DEFAULT_OUTPUT_TYPES;
                }

                return value;

            // other fields are not modified
            default:
                return value;
        }
    }

    /**
     * Saves current settings to storage. Only items with settings
     * other then defaults are saved to spare storage space.
     */
    public saveSettings(): this {
        // filter only for non-default settings items
        const result: IList<ILoggerServiceItem> = {};
        let count = 0;
        for (const key in this._items) {
            if (!this._items.hasOwnProperty(key)) {
                continue;
            }
            const item = this._items[key];
            if (
                item.fullDates === DEFAULT_FULL_DATES &&
                item.levelFilter === DEFAULT_DEBUG_LEVEL &&
                item.outputTypes === DEFAULT_OUTPUT_TYPES &&
                item.showCallStack === DEFAULT_SHOW_CALL_STACK
            ) {
                continue;
            }
            result[key] = item;
            count++;
        }
        // save into storage only when we have something to store
        if (count) {
            this._storage.setItem(this.key, JSON.stringify(result, this.itemsReplacer));
        } else {
            this._storage.removeItem(this.key);
        }

        return this;
    }

    private itemsReplacer(key: string, value: any): any {
        switch (key) {
            case this.FULL_DATES:
                if (value === DEFAULT_FULL_DATES) {
                    return;
                }
                break;
            case this.LEVEL_FILTER:
                if (value === DEFAULT_DEBUG_LEVEL) {
                    return;
                }
                break;
            case this.OUTPUT_TYPES:
                if (
                    Array.isArray(value) &&
                    value.length === 1 &&
                    value[0] === DEFAULT_OUTPUT_TYPE
                ) {
                    return;
                }
                break;
            case this.IS_ACTIVE:
                return;
            default:
                break;
        }

        return value;
    }

    private _items: IList<ILoggerServiceItem> = {};

    /**
     * Returns current logger service items configuration.
     * @param includeInactiveItems Tells whether to include also inactive items in the result.
     * Inactive items are those read from storage at start, but not yet used in current page.
     */
    public getItems(includeInactiveItems: boolean = false): IList<ILoggerServiceItem> {
        const result: IList<ILoggerServiceItem> = {};
        for (const key in this._items) {
            if (
                !this._items.hasOwnProperty(key) ||
                (!this._items[key].isActive && !includeInactiveItems)
            ) {
                continue;
            }
            result[key] = this._items[key];
        }

        return result;
    }

    private addItem(
        key: string,
        levelFilter: DebugLevel = DEFAULT_DEBUG_LEVEL,
        showCallStack: boolean = DEFAULT_SHOW_CALL_STACK,
        outputTypes: DebugOutputType[] = DEFAULT_OUTPUT_TYPES,
        fullDates: boolean = DEFAULT_FULL_DATES
    ): this {
        this._items[key] = {
            fullDates,
            isActive: true,
            levelFilter,
            outputTypes,
            showCallStack,
        };

        return this;
    }

    /**
     * Returns one logger item settings. Optionally creates
     * the item if it didn't exist before.
     * @param key Logger instance / item key.
     */
    private getItem(key: string = this.key): ILoggerServiceItem | null {
        if (!key) {
            return null;
        }

        if (!this._items[key]) {
            this.addItem(key);
        }

        const item = this._items[key];
        item.isActive = true;

        return item;
    }

    /**
     * Sets one logger item settings.
     * @param key Logger instance / item key.
     * @param levelFilter Maximum debug level filter.
     * @param outputType Log appender type to be used.
     * @param fullDates Display also date in logger messages in addition to time?
     */
    public setItem(
        key: string,
        levelFilter?: DebugLevel,
        showCallStack?: boolean,
        outputType?: DebugOutputType,
        fullDates?: boolean
    ): this {
        const item = this.getItem(key);
        if (!item) {
            return this;
        }

        if (typeof showCallStack !== "undefined") {
            item.showCallStack = showCallStack;
        }
        if (typeof levelFilter !== "undefined") {
            item.levelFilter = levelFilter;
        }
        if (typeof outputType !== "undefined") {
            item.outputTypes = [outputType];
        }
        if (typeof fullDates !== "undefined") {
            item.fullDates = fullDates;
        }

        return this;
    }

    /**
     * Resets debug level filters for all current items to the default debug level.
     */
    public resetDebugLevelFilters(): this {
        for (const key in this._items) {
            if (!this._items.hasOwnProperty(key)) {
                continue;
            }
            this._items[key].levelFilter = DEFAULT_DEBUG_LEVEL;
        }

        return this;
    }

    /**
     * Resets show call stack settings for all current items to the default.
     */
    public resetShowCallStacks(): this {
        for (const key in this._items) {
            if (!this._items.hasOwnProperty(key)) {
                continue;
            }
            this._items[key].showCallStack = DEFAULT_SHOW_CALL_STACK;
        }

        return this;
    }

    /**
     * Helper function to format date in following format :
     * [YYYY-MM-DD ]hh:mm:ss.xxx
     */
    private formatDate(d: Date, fullDate: boolean = false): string {
        let fd = "";

        /* eslint-disable no-magic-numbers */
        if (fullDate) {
            fd +=
                `${d.getFullYear()}` +
                "-" +
                `00${d.getMonth() + 1}`.slice(-2) +
                "-" +
                `00${d.getDate()}`.slice(-2) +
                " ";
        }

        fd +=
            `00${d.getHours()}`.slice(-2) +
            ":" +
            `00${d.getMinutes()}`.slice(-2) +
            ":" +
            `00${d.getSeconds()}`.slice(-2) +
            "." +
            `000${d.getMilliseconds()}`.slice(-3);
        /* eslint-enable no-magic-numbers */

        return fd;
    }

    /**
     * Log prefix for debug messages functionality.
     */
    private logPrefix(key: string, fullDates: boolean): string {
        return this.formatDate(new Date(), fullDates) + " " + (key ? key + " : " : "");
    }

    /**
     * Logs a message for a given key bound to particular class / object instance
     * if the current level filter set for this key allows it. Can log the message
     * into one or more appenders associated with given key's output types.
     * @param key "Logged" instance key.
     * @param level Debug level of the logged message.
     * @param message Message text.
     * @param optionalParams Optional parameters to be filled into message's placeholders.
     * This works the same way as in JS console in browser, TODO: and for now only for the console appender.
     */
    public logMessage(
        key: string,
        level: DebugLevel,
        _?: boolean,
        message?: any,
        ...optionalParams: any[]
    ): this {
        if (!key) {
            return this;
        }
        const item = this.getItem(key);
        if (!item) {
            return this;
        }
        // skip if debug level filter is set too low for given key
        if (level > item.levelFilter) {
            return this;
        }
        const prefix = this.logPrefix(key, item.fullDates);
        item.outputTypes.forEach((outType) => {
            const appender = this._logAppenderFactory.getAppender(outType);
            if (!appender) {
                return;
            }

            switch (level) {
                case DebugLevel.Error:
                    appender.error(prefix, item.showCallStack, message, ...optionalParams);
                    break;
                case DebugLevel.Warning:
                    appender.warning(prefix, item.showCallStack, message, ...optionalParams);
                    break;
                case DebugLevel.Info:
                    appender.info(prefix, item.showCallStack, message, ...optionalParams);
                    break;
                case DebugLevel.Log:
                    appender.log(prefix, item.showCallStack, message, ...optionalParams);
                    break;
                default:
                    break;
            }
        });

        return this;
    }
}
