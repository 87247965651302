import { Inject } from "typescript-ioc";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { ILogger } from "Logging/Scripts/ILogger";
import { PreloadableObject, IPreloader } from "./IPreloader";
import * as PreloaderEvent from "./PreloaderEvents";

/**
 * Base Class for Preloaders.
 */
export abstract class PreloaderBase implements IPreloader {
    private readonly ERR_EMPTY_PRELOADABLE: string = "Preloadable object is undefined.";
    protected abstract _key: string;

    protected _binder: EventBinder;
    protected _logger: ILogger;

    public get key(): string {
        return this._key;
    }

    /** object to be preload */
    protected _preloadableObject?: PreloadableObject;
    public set preloadableObject(value: PreloadableObject) {
        this._preloadableObject = value;
        this._binder.init(this._preloadableObject);
        this.initLogging();
    }

    constructor(@Inject binder: EventBinder, @Inject loggerFactory: LoggerFactory) {
        this._binder = binder;
        this._logger = loggerFactory.getLogger(this.key);
    }

    public abstract preload(): Promise<PreloadableObject>;

    /**
     * Bind load success event
     * @param handler
     * @param namespace
     * @param one
     */
    public bindSuccessEvent(
        handler: PreloaderEvent.PreloaderSuccessEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        if (!this._preloadableObject) {
            this._logger.error(this.ERR_EMPTY_PRELOADABLE);
            throw new Error(this.ERR_EMPTY_PRELOADABLE);
        }
        this._binder.bindCustomEvent(PreloaderEvent.LOAD_SUCCESS, handler, namespace, one);

        return this;
    }
    /**
     * Bind load error event
     * @param handler
     * @param namespace
     * @param one
     */
    public bindErrorEvent(
        handler: PreloaderEvent.PreloaderErrorEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        if (!this._preloadableObject) {
            this._logger.error(this.ERR_EMPTY_PRELOADABLE);
            throw new Error(this.ERR_EMPTY_PRELOADABLE);
        }
        this._binder.bindCustomEvent(PreloaderEvent.LOAD_ERROR, handler, namespace, one);

        return this;
    }
    /**
     * Bind load error event
     * @param handler
     * @param namespace
     * @param one
     */
    public bindCompleteEvent(
        handler: PreloaderEvent.PreloaderCompleteEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        if (!this._preloadableObject) {
            this._logger.error(this.ERR_EMPTY_PRELOADABLE);
            throw new Error(this.ERR_EMPTY_PRELOADABLE);
        }
        this._binder.bindCustomEvent(PreloaderEvent.LOAD_COMPLETE, handler, namespace, one);

        return this;
    }
    /**
     * Bind load init event
     * @param handler
     * @param namespace
     * @param one
     */
    public bindInitEvent(
        handler: PreloaderEvent.PreloaderInitEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        if (!this._preloadableObject) {
            this._logger.error(this.ERR_EMPTY_PRELOADABLE);
            throw new Error(this.ERR_EMPTY_PRELOADABLE);
        }
        this._binder.bindCustomEvent(PreloaderEvent.LOAD_INIT, handler, namespace, one);

        return this;
    }
    /**
     * Initialize default logging while loading images with preloader
     */
    private initLogging(): void {
        this.bindInitEvent(() => {
            this._logger.log("Loading started.");
        }, this.key);

        this.bindSuccessEvent((evt: JQuery.TriggeredEvent) => {
            this._logger.log("Loading finished. Object: ", evt.target);
        }, this.key);

        this.bindErrorEvent((evt: JQuery.Event) => {
            this._logger.error("Loading failed. Reason: ", evt);
        }, this.key);
    }
}
