import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";

import { IDeliveryComponent } from "./IDeliveryComponent";
import { ITooltipOptions } from "./ITooltipOptions";
import { Utils } from "Helpers/Scripts/Utils";

export abstract class DeliveryComponent extends UiComponent implements IDeliveryComponent {
    protected _model: object;

    protected readonly DELIVERY_CHANNEL_DATA_ATTRIBUTE: string = "data-delivery-channel";
    protected readonly OUT_OF_STOCK_TRIGGER_DATA_ATTRIBUTE: string = "data-out-of-stock-trigger";
    protected readonly UNSUPPORTED_ADDRESS_TRIGGER_DATA_ATTRIBUTE: string =
        "data-unsupported-address-trigger";

    private readonly OUT_OF_STOCK_TOOLTIP_TEMPLATE: string =
        "delivery-channel-out-of-stock-tooltip";
    private readonly UNSUPPORTED_ADDRESS_TOOLTIP_TEMPLATE: string =
        "delivery-channel-unsupported-address-tooltip";
    private readonly DELIVERY_CHANNEL_INFO_TEMPLATE: string = "delivery-channel-info-template";
    private readonly DELIVERY_ADDRESS_INFO_TEMPLATE: string = "delivery-address-info-template";

    protected readonly DELIVERY_NOTIFICATIONS_PHONES_SELECTOR: string =
        ".js-delivery-notifications-phones";
    private readonly DELIVERY_CHANNEL_LIST_SELECTOR: string = ".js-delivery-channels-list";

    protected readonly DELIVERY_ADDRESS_INFO_CLASS: string = "js-delivery-address-info";
    protected readonly DELIVERY_CHANNEL_INFO_CLASS: string = "js-delivery-channel-info";
    protected readonly DELIVERY_FEE_CLASS: string = "js-fee";
    protected readonly HOME_DELIVERY_CHANNEL_CLASS: string = "js-home-delivery-channel";

    public tooltipOptions: ITooltipOptions = {
        delay: 0,
        offset: 22,
        position: "top",
        show: (event: kendo.ui.TooltipEvent) => {
            if (event.sender.popup) {
                event.sender.popup.element.addClass("out-of-stock-tooltip");
            }
        },
    };

    public AddressTooltipOptions: ITooltipOptions = {
        delay: 0,
        offset: 22,
        position: "bottom",
        show: (event: kendo.ui.TooltipEvent) => {
            if (event.sender.popup) {
                event.sender.popup.element.addClass("unsupported-address-tooltip-box");
            }
        },
    };

    protected get _deliveryChannelInfoTemplate(): (data: any) => string {
        return Utils.getTemplate(this.DELIVERY_CHANNEL_INFO_TEMPLATE);
    }

    private get _outOfStockToolTipTemplate(): (data: any) => string {
        return Utils.getTemplate(this.OUT_OF_STOCK_TOOLTIP_TEMPLATE);
    }

    private get _unsupportedAddressToolTipTemplate(): (data: any) => string {
        return Utils.getTemplate(this.UNSUPPORTED_ADDRESS_TOOLTIP_TEMPLATE);
    }

    protected get _deliveryAddressInfoTemplate(): (data: any) => string {
        return Utils.getTemplate(this.DELIVERY_ADDRESS_INFO_TEMPLATE);
    }

    public index: number = 0;
    public abstract init(): void;
    public abstract update(nextState: IChannelsInfo, pageNumber?: number): void;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    /**
     * @returns The component model if provided
     */
    public get model(): object {
        return this._model;
    }

    public get key(): string {
        return "DeliveryComponent";
    }

    protected _handleOutOfStockTooltip(
        items: IOutOfStockItems[],
        deliveryChannelId: number,
        targetSelector?: string
    ): void {
        const triggerSelector = [];

        if (targetSelector) {
            triggerSelector.push(targetSelector);
        } else {
            triggerSelector.push(
                `[${this.DELIVERY_CHANNEL_DATA_ATTRIBUTE}="${deliveryChannelId}"]`
            );
        }

        triggerSelector.push(` [${this.OUT_OF_STOCK_TRIGGER_DATA_ATTRIBUTE}]`);

        const triggerSelectorString = triggerSelector.join("");
        this._applyOutOfStockTooltip(triggerSelectorString, items);
    }

    protected _applyOutOfStockTooltip(
        triggerElemenSelector: string,
        items: IOutOfStockItems[]
    ): void {
        const triggerElement = this.findElement(triggerElemenSelector);

        if (triggerElement) {
            const template = this._outOfStockToolTipTemplate;
            const markup = Utils.createDomElementFromMarkup(
                template({ OutOfStockItems: items })
            ) as kendo.ui.TooltipContent;
            const options = {
                ...this.tooltipOptions,
                content: markup,
            };

            kendo.applyTooltip(triggerElement, options);
        }
    }

    protected _handleUnsupportedAddressTooltip(
        deliveryChannelId: number,
        targetSelector?: string
    ): void {
        const triggerSelector = [];

        if (targetSelector) {
            triggerSelector.push(targetSelector);
        } else {
            triggerSelector.push(
                `[${this.DELIVERY_CHANNEL_DATA_ATTRIBUTE}="${deliveryChannelId}"]`
            );
        }

        triggerSelector.push(` [${this.UNSUPPORTED_ADDRESS_TRIGGER_DATA_ATTRIBUTE}]`);

        const triggerSelectorString = triggerSelector.join("");
        this._applyUnsupportedAddressTooltip(triggerSelectorString);
    }

    protected _applyUnsupportedAddressTooltip(triggerElemenSelector: string): void {
        const triggerElement = this.findElement(triggerElemenSelector);

        if (triggerElement) {
            const template = this._unsupportedAddressToolTipTemplate;
            const markup = Utils.createDomElementFromMarkup(
                template([])
            ) as kendo.ui.TooltipContent;
            const options = {
                ...this.AddressTooltipOptions,
                content: markup,
            };

            kendo.applyTooltip(triggerElement, options);
        }
    }

    protected _displayError(title?: string, msg?: string): void {
        let titleToShow = "Error";
        if (title) {
            titleToShow = title;
        }

        let msgToShow = "An error occured during request, please try again later.";
        if (msg) {
            msgToShow = msg;
        }

        ori.displayError(titleToShow, msgToShow);
    }

    protected _isEndCustomerFirstCheckout(): boolean {
        if (ori.shopping && ori.shopping.wizard) {
            const shoppingContext = ori.shopping.wizard.getShoppingContext();
            return shoppingContext.IsAnonymous;
        }
        return false;
    }

    protected _getDeliveryChannelSection(deliveryChannelId: number): Element | null {
        if (!deliveryChannelId) {
            return null;
        }
        const deliveryChannelSection = Utils.find(
            this.DELIVERY_CHANNEL_LIST_SELECTOR,
            `[${this.DELIVERY_CHANNEL_DATA_ATTRIBUTE}="${deliveryChannelId}"]`
        );

        /* in case of home delivery was selected in previous order
         * there will be 2 nodes with the same deliveryChannelId */
        const foundSection = deliveryChannelSection.find((element) =>
            element.classList.contains(this.HOME_DELIVERY_CHANNEL_CLASS)
        );
        return foundSection || null;
    }

    protected _wasOutOfStockItemsChanged(prev?: object, next?: object): boolean {
        if (prev && next) {
            return !Utils.deepEqual(prev, next);
        } else if ((prev && !next) || (!prev && next)) {
            return true;
        }
        return false;
    }

    protected _isChanged<T>(prev: T, next: T): boolean {
        return prev !== next;
    }

    protected _updateDeliveryFee(fee: number, deliveryChannelId: number): void {
        const deliveryFee = this.findElement(
            `[${this.DELIVERY_CHANNEL_DATA_ATTRIBUTE}="${deliveryChannelId}"] .${this.DELIVERY_FEE_CLASS}`
        );
        if (deliveryFee) {
            Utils.setHtml(deliveryFee, kendo.toString(fee, "oc"));
        }
    }

    protected _hidePhoneBoxes(prevSelectedDeliveryChannel: number | null): void {
        if (!prevSelectedDeliveryChannel) {
            return;
        }
        const deliveryChannelSection = this._getDeliveryChannelSection(prevSelectedDeliveryChannel);
        /* condition can be removed (or extended) if phone numbers
         * will be applied for more delivery channels in future
         */
        if (deliveryChannelSection) {
            const isHomeDelivery = Utils.hasClass(
                deliveryChannelSection,
                this.HOME_DELIVERY_CHANNEL_CLASS
            );
            if (isHomeDelivery) {
                const phoneBoxToHide = Utils.find(
                    deliveryChannelSection,
                    this.DELIVERY_NOTIFICATIONS_PHONES_SELECTOR
                );
                if (phoneBoxToHide.length) {
                    Utils.addClass(phoneBoxToHide[0], this.CLASS_HIDDEN);
                }
            }
        }
    }

    protected _shouldComponentUpdate(prev: object, next: object): boolean {
        // No functions/methods in the state allowed; only data should be stored
        // Keep the same order of the properties
        return !Utils.deepEqual(prev, next);
    }
}
