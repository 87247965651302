export const LOAD_SUCCESS = "load_success";
export const LOAD_ERROR = "load_error";
export const LOAD_INIT = "load_init";
export const LOAD_COMPLETE = "load_complete";

/**
 * Preloader success event handler
 */
export declare type PreloaderSuccessEventHandler = (event: JQuery.Event) => void;
/**
 * Preloader error event handler
 */
export declare type PreloaderErrorEventHandler = (event: JQuery.Event) => void;
/**
 * Preloader init event handler
 */
export declare type PreloaderInitEventHandler = (event: JQuery.Event) => void;
/**
 * Preloader complete event handler
 */
export declare type PreloaderCompleteEventHandler = (event: JQuery.Event) => void;
