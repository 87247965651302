import { Name as ClearCacheEventName } from "@ori-events/request-clear-shopping-cache";
import { Name as ShowErrorNotificationEventName } from "@ori-events/show-error-notification";

// When staying on current page & no immediate page redirect, clear provider cache with this.
export const dispatchClearCacheEvent = () =>
    window.dispatchEvent(new CustomEvent(ClearCacheEventName));

// When request error appears or request isn't performed at all, show error notification with generic message with it.
export const showErrorNotification = () =>
    window.dispatchEvent(new CustomEvent(ShowErrorNotificationEventName, { detail: {} }));

// When page reload happens, clear provider cache with this.
export const scheduleCacheCleanup = () =>
    localStorage.setItem("basket-provider cache cleanup", "1");
