/*
--------------------------------------------------------------------------------
Oriflame Global API data receiver functionality
Used to send requests to GlobalApi gateway by url
--------------------------------------------------------------------------------
*/
import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { Utils } from "Helpers/Scripts/Utils";
import { IStorageService } from "Storages/Scripts/IStorageService";
import { SessionStorageService } from "Storages/Scripts/SessionStorageService";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";

@OnlyInstantiableByContainer
@Singleton
export class GlobalApiStorageService {
    public get key(): string {
        return "GlobalApiStorageService";
    }

    private readonly STORAGE_PREFIX: string = "globalAPI-";
    protected readonly _logger: ILogger;
    private readonly _storage: IStorageService;
    private readonly _keyCache: Map<string, string> = new Map();

    constructor(
        @Inject loggerFactory: LoggerFactory,
        @Inject storageService: SessionStorageService
    ) {
        this._logger = loggerFactory.getLogger(this.key);
        this._storage = storageService;
    }

    /**
     * Generating meaningful name + hash
     * TODO: Implement some proper caching
     */

    public getApiName(url: string): string {
        const apiName = url.split("/").pop();
        if (apiName) {
            return apiName.replace(/\?|&|=/g, "-");
        }
        return "";
    }
    public generateApiStorageKey(url: string): string {
        const cachedKey = this._keyCache.get(url); // poor man's caching
        if (cachedKey) {
            return cachedKey;
        }

        let fake = "";
        if (/fake/i.test(url)) {
            fake = "Fake-";
        }

        const apiHash = Utils.javaHash(url);
        const apiName = this.getApiName(url);

        return `${this.STORAGE_PREFIX}${fake}${apiName}[${apiHash}]`;
    }

    /**
     * Store loaded data into session storage
     */
    public setStorageData(storageKey: string, data?: object): void {
        try {
            this._storage.setItem(storageKey, data);
        } catch (error) {
            this._logger.error(`Couldn't write data to storage: ${error}`);
        }
    }

    /**
     * Load data from session storage.
     */
    public getStoredData(storageKey: string): object | undefined {
        try {
            const value = this._storage.getItem(storageKey);
            if (value) {
                return JSON.parse(value);
            }
            this._logger.info(`No stored data for ${storageKey}`);
        } catch (error) {
            this._logger.error(`Couldn't read storage data: ${error}`);
        }
        return undefined;
    }

    /**
     * Returns list of session storage keys used by this module.
     * Used for cleanups while on logout.
     */
    public clearStorageItems(): void {
        try {
            this._storage.clearItems(this.STORAGE_PREFIX);
        } catch (error) {
            this._logger.error(`Couldn't clear data from storage: ${error}`);
        }
    }
}
