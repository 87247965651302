import { Inject } from "typescript-ioc";
import { UiComponent } from "./UiComponent";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";

export class Div extends UiComponent {
    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    /**
     * UI component's context element in the DOM.
     */
    public get context(): HTMLDivElement {
        return this.getContextAs(HTMLDivElement);
    }
}
