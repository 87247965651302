import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { Utils } from "Helpers/Scripts/Utils";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { IRSConfigOptions } from "./IRSConfigOptions";

export class SliderView extends UiComponent {
    public readonly RS: string = "royalSlider";
    public readonly LAST: string = "g-last";
    public readonly NO_NAVIGATION: string = "no-navigation";
    public readonly CONTROL_NAVIGATION_NONE: string = "none";
    public readonly CONTROL_NAVIGATION_BULLETS: string = "bullets";
    public readonly AUTOPLAY: string = "autoplay";
    public readonly SLIDE: string = ".slide";
    public readonly PROMO_SLIDER: string = ".ui-promo-slider";
    public readonly SLIDE_ARROW: string = ".rsArrow";
    public readonly ARROW_HIDE: string = "arrow-hide";
    public readonly TOTAL_NUM_OF_SLIDES: string = "total-num-of-slides";
    public readonly RS_BULLETS: string = ".rsBullets";
    public readonly UI_HERO: string = ".ui-hero";
    public readonly IMAGE_A1: string = ".image-A1";
    public readonly RS_SLIDE: string = ".rsSlide";
    public readonly THUMB_ARROW: string = ".rsThumbsArrow";
    public static readonly RS_THUMBNAIL: string = "rstmb";

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    /**
     * Creates new Royalslider instance based on passed config.
     * @param bpConfig IRSConfigOptions for specific breakpoint.
     * @returns New instance of Royalslider.
     */
    public createRsInstance(bpConfig: IRSConfigOptions): RoyalSlider.RoyalSlider | null {
        const $slider = $(this.context);
        const slides = this.find(this.SLIDE);
        const startSlide = bpConfig.startSlideId;

        if (bpConfig.numImagesToPreload === -1) {
            bpConfig.numImagesToPreload = slides.length - 1; // first slide is shown always
        }

        $slider.royalSlider(bpConfig).data(this.RS).goTo(startSlide);

        return this.getData(this.RS) || null;
    }

    /**
     * Destroys Royalslider instance and restores original markup.
     * @param sliderInstance Royalslider instance to destroy.
     * @param bpConfig IRSConfigOptions for specific breakpoint.
     * @param ignoreInstance Wheter to use instance to count slides or not
     */
    public destroyRsInstance(
        sliderInstance: RoyalSlider.RoyalSlider | null,
        bpConfig: IRSConfigOptions,
        ignoreInstance: boolean
    ): void {
        let slides: any[] = [];
        const items: any[] = [];
        const slideSize = bpConfig.itemsPerSlide;
        const sliderSlides = this.find(bpConfig.slideElementClass);
        const sliderInstanceUse: boolean = Boolean(
            !ignoreInstance && sliderInstance && sliderInstance.slides.length !== 0
        );

        let itemsCount = sliderSlides.length;
        if (sliderInstanceUse) {
            const totalNumOfSlides = this.getData(this.TOTAL_NUM_OF_SLIDES) as number;
            if (totalNumOfSlides) {
                itemsCount = totalNumOfSlides;
            }
        }
        const numOfSlides = Math.ceil(itemsCount / slideSize);

        if (!sliderInstance) {
            this.setData(this.TOTAL_NUM_OF_SLIDES, sliderSlides.length);
            if (bpConfig.noSlider) {
                return;
            }
        }

        slides = sliderInstanceUse && sliderInstance ? sliderInstance.slides : [sliderSlides];

        slides.forEach((slide) => {
            const slideItems = sliderInstanceUse
                ? slide.content?.find(bpConfig.slideElementClass).toArray() ?? []
                : slide;

            slideItems.forEach((slideItem: any) => {
                Utils.detach(slideItem);

                if (bpConfig.itemsClass) {
                    Utils.addClass(slideItem, bpConfig.itemsClass);
                }
                // if you need to preserve height of slides (e.g. in digital catalogue)
                Utils.setCssWithHandler(
                    slideItem,
                    "height",
                    () => Utils.getData(slideItem, "height") || ""
                );

                items.push(slideItem);
            });
        });
        try {
            if (sliderInstance) {
                sliderInstance.ev.off(".rsAutoHeight");
                sliderInstance.ev.off(".rsAfterSlideChange");
                sliderInstance.ev.off(".rsBeforeAnimStart");
                this.removeClass("rsAutoHeight rsHor rsVer rsFade");
                sliderInstance.destroy();
            }
        } catch (e) {
            document.getElementById("recommendations-slider")?.remove();
        }

        // clear old contents markup
        this.empty();

        // (re)create new slides and append to original wrapper (skip if noSlider property is true)
        if (!bpConfig.noSlider) {
            slides = Array.apply(null, Array(numOfSlides)).map(
                (_slide: Element | undefined, index: number) => {
                    const slide = document.createElement("div");
                    Utils.addClass(slide, "slide rsContent");

                    // if there is data attribute for thumb image, move it to the slide
                    if (items[index] && Utils.getData(items[index], SliderView.RS_THUMBNAIL)) {
                        Utils.setAttr(
                            slide,
                            "data-" + SliderView.RS_THUMBNAIL,
                            Utils.getData(items[index], SliderView.RS_THUMBNAIL)
                        );
                    }

                    this.append(slide);

                    return slide;
                }
            );
        }

        items.forEach((item, index) => {
            // This strange variable is here because of specific behavior of banner area on homepage
            const skipAddingGlast = bpConfig.noSlider && bpConfig.noSliderSkipAddingGlast;

            if ((index + 1) % slideSize === 0 && !skipAddingGlast) {
                Utils.addClass(item, this.LAST);
            }

            if (bpConfig.noSlider) {
                this.append(item);
            } else {
                Utils.append(slides[Math.floor(index / slideSize)], item);
            }
        });
    }
}
