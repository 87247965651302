import { Inject } from "typescript-ioc";
import { DebugLevel } from "./DebugLevel";
import { DEFAULT_SHOW_CALL_STACK } from "./ShowCallStack";
import { ILogger } from "./ILogger";
import { ILoggerService } from "./ILoggerService";
import { LoggerService } from "./LoggerService";

export class Logger implements ILogger {
    public key: string;
    public showCallStack: boolean = DEFAULT_SHOW_CALL_STACK;

    private readonly _loggerService: ILoggerService;

    constructor(@Inject loggerService: LoggerService) {
        this._loggerService = loggerService;
    }

    public error(message?: any, ...optionalParams: any[]): this {
        this._loggerService.logMessage(
            this.key,
            DebugLevel.Error,
            this.showCallStack,
            message,
            ...optionalParams
        );

        return this;
    }

    public warning(message?: any, ...optionalParams: any[]): this {
        this._loggerService.logMessage(
            this.key,
            DebugLevel.Warning,
            this.showCallStack,
            message,
            ...optionalParams
        );

        return this;
    }

    public info(message?: any, ...optionalParams: any[]): this {
        this._loggerService.logMessage(
            this.key,
            DebugLevel.Info,
            this.showCallStack,
            message,
            ...optionalParams
        );

        return this;
    }

    public log(message?: any, ...optionalParams: any[]): this {
        this._loggerService.logMessage(
            this.key,
            DebugLevel.Log,
            this.showCallStack,
            message,
            ...optionalParams
        );

        return this;
    }
}
