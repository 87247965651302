import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";

export abstract class FeedbackNotification extends UiComponent {
    private readonly FEEDBACK_SUCCESS: string = ".feedback-notification__success";
    private readonly FEEDBACK_FAIL: string = ".feedback-notification__fail";
    private readonly FEEDBACK_FAIL_CONTENT: string =
        ".feedback-notification__fail .feedback-notification__content";
    private readonly K_WINDOW_ACTIONS: string = ".k-window-actions";
    private readonly NOTIFICATION_FEEDBACK_WINDOW: string = "feedback-notification-window";

    protected _window: kendo.ui.Window;
    private _feedbackSuccess: Element;
    private _feedbackFail: Element;
    private _feedbackFailContent: Element;
    private _defaultFailMessage: string;

    public abstract get key(): string;

    protected abstract _init(): void;

    protected abstract _showSuccessMessage(): void;

    protected abstract _showFailMessage(): void;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    public init(): void {
        this._createComponents();
        this._init();
    }

    public getWindow(): kendo.ui.Window {
        return this._window;
    }

    public showSuccessMessage(): void {
        this._feedbackSuccess.classList.remove(this.CLASS_HIDDEN);
        this._feedbackFail.classList.add(this.CLASS_HIDDEN);

        this._showSuccessMessage();

        this._window.open();
    }

    public showFailMessage(errorMessage: string = this._defaultFailMessage): void {
        this._feedbackSuccess.classList.add(this.CLASS_HIDDEN);
        this._feedbackFail.classList.remove(this.CLASS_HIDDEN);

        this._showFailMessage();

        this._feedbackFailContent.innerHTML = errorMessage;

        this._window.open();
    }

    public close(): void {
        this._window.close();
    }

    public bindCloseAction(callback: () => any): void {
        this._window.unbindClose();
        this._window.bindClose(() => callback());
    }

    private _createComponents(): void {
        this._feedbackSuccess = this._findElement(this.FEEDBACK_SUCCESS);
        this._feedbackFail = this._findElement(this.FEEDBACK_FAIL);
        this._feedbackFailContent = this._findElement(this.FEEDBACK_FAIL_CONTENT);
        this._defaultFailMessage = this._feedbackFailContent.innerHTML;

        this._window = kendo.createWindow(this.context, {
            resizable: false,
            title: false,
            visible: false,
        });

        this._window.wrapper.addClass(this.NOTIFICATION_FEEDBACK_WINDOW);
    }

    protected _getFeedbackWindowActions(): JQuery {
        return this._window.wrapper.find(this.K_WINDOW_ACTIONS);
    }

    protected _findElement(selector: string): Element {
        const element = this.findElement(selector);

        if (!element) {
            throw new Error(`Cannot find the element: "${selector}"`);
        }

        return element;
    }
}
