import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { EndConsumerEditorComponentView } from "./EndConsumerEditorComponentView";
import { autobind } from "core-decorators";
import { DialogUtils } from "Shared/DialogUtils/Scripts/DialogUtils";
import { FetchService } from "Async/Scripts/FetchService";
import { Spinner } from "Spinner/Scripts/Spinner";

import "../Styles/end-consumer-editor.css";

export class EndConsumerEditorComponent extends UiComponent {
    public get key(): string {
        return "EndConsumerSelectorComponent";
    }

    private _view: EndConsumerEditorComponentView;

    private _dialogUtils: DialogUtils;
    private readonly _fetchService: FetchService;
    private _onChangeCallback: (customerId: number) => void;
    private _onDeleteCallback: (customerId: number, customerName: string) => void;
    private _onCancelCallback: () => void;

    private _hideCancelButton: boolean;
    private _hideDeleteButton: boolean;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject dialogUtils: DialogUtils,
        @Inject fetchService: FetchService
    ) {
        super(componentFactory, binder, loggerFactory);
        this._dialogUtils = dialogUtils;
        this._fetchService = fetchService;
    }

    @autobind
    public onChange(callback: (customerId: number) => void): void {
        this._onChangeCallback = callback;
    }

    @autobind
    public onDelete(callback: (customerId: number, customerName: string) => void): void {
        this._onDeleteCallback = callback;
    }

    @autobind
    public onCancel(callback: () => void): void {
        this._onCancelCallback = callback;
    }

    public hideCancelButton(): void {
        this._hideCancelButton = true;
        if (this._view.cancelButton) {
            this._view.cancelButton.addClass(this.CLASS_HIDDEN);
        }
    }

    public hideDeleteButton(): void {
        this._hideDeleteButton = true;
        if (this._view.deleteButton) {
            this._view.deleteButton.addClass(this.CLASS_HIDDEN);
        }
    }

    protected init(): void {
        this._view = this.createView(EndConsumerEditorComponentView);
        this.bindEvents();
        kendo.createValidator(this._view.editorForm.context);
        if (this._hideCancelButton && this._view.cancelButton) {
            this._view.cancelButton.addClass(this.CLASS_HIDDEN);
        }
        if (this._hideDeleteButton && this._view.deleteButton) {
            this._view.deleteButton.addClass(this.CLASS_HIDDEN);
        }
    }

    private bindEvents(): void {
        this._view.editorForm.unbindSubmit();
        this._view.editorForm.bindSubmit(this.submit, this.key);
        if (this._view.deleteButton) {
            this._view.deleteButton.bindClick(this.delete, this.key);
        }
        if (this._view.cancelButton) {
            this._view.cancelButton.bindClick(this.cancel, this.key);
        }
    }

    @autobind
    private submit(event?: JQuery.Event): void {
        if (!event) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        Spinner.applyOverlayTo(this._context);
        this._fetchService.postForm(this._view.editorForm.context).then((result: any) => {
            if (result.Success === true) {
                Spinner.removeOverlayFrom(this._context);
                if (this._onChangeCallback) {
                    this._onChangeCallback(result.CustomerId);
                }
            } else if (result.Success === false) {
                Spinner.removeOverlayFrom(this._context);
                this._dialogUtils.errorDialog(
                    this._view.errorModalMessage,
                    this._view.errorModalTitle,
                    this._view.errorModalCloseText
                );
            } else {
                this.setHtml(result.toString());
                this.init();
                Spinner.removeOverlayFrom(this._context);
            }
        });
    }

    @autobind
    private delete(): void {
        if (this._onDeleteCallback) {
            this._onDeleteCallback(this._view.customerId, this._view.customerName);
        }
    }

    @autobind
    private cancel(): void {
        if (this._onCancelCallback) {
            this._onCancelCallback();
        }
    }
}
