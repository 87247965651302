import { Container, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { format } from "util";
import { PreloaderBase } from "./PreloaderBase";
import { PreloadableObject, IPreloader } from "./IPreloader";
import { PreloaderImage } from "./PreloaderImage";

/**
 * Factory for Preloaders. It can be used when preloading more different objects at once.
 * Also this factory is used to map preloader to legacy JS implementation
 */
@OnlyInstantiableByContainer
@Singleton
export class PreloaderFactory {
    public static readonly ERR_PRELOADER_TYPE: string =
        "Preloader type '%s' is not defined or implementation is missing.";

    /**
     * Get preloader object
     * TODO: Implement video and script preloader
     *
     * @param PreloadObject
     */
    public create(object: PreloadableObject): IPreloader {
        let preloader: PreloaderBase;
        if (object instanceof HTMLImageElement) {
            preloader = Container.get(PreloaderImage);
        } else {
            const errMsg = format(PreloaderFactory.ERR_PRELOADER_TYPE, object);
            throw new Error(errMsg);
        }
        preloader.preloadableObject = object;

        return preloader;
    }
    /**
     * Create image preloader
     * @param object
     */
    public createImagePreloader(object: HTMLImageElement): PreloaderImage {
        const preloader = Container.get(PreloaderImage);
        preloader.preloadableObject = object;

        return preloader;
    }
}
