import { autobind } from "core-decorators";

import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { FetchService } from "Async/Scripts/FetchService";
import { Spinner } from "Spinner/Scripts/Spinner";
import { Utils } from "Helpers/Scripts/Utils";
import { IOfferItemToAdd } from "./IOfferItemToAdd";
import { IOfferProduct } from "./IOfferProduct";
import * as OffersEventType from "./OffersEventType";

export class OfferSummary extends UiComponent {
    private readonly ADD_BUTTON_CLASS: string = "js-add-offer";
    private readonly ADD_OFFER_ITEMS_URL_DATA_KEY: string = "addOfferItemsUrl";
    private readonly OFFER_SUMMARY_CONTENT_TEMPLATE: string = "offer-summary-content-template";
    private readonly OFFER_SUMMARY_CONTENT_CONTAINER_SELECTOR: string =
        ".js-offer-summary-content-container";
    private readonly AVAILABLE_POINTS_SELECTOR: string = ".js-available-points";

    private readonly _fetchService: FetchService;
    private _addOfferItemsUrl: string;
    private _selectedProducts: IOfferProduct[] = [];

    public static readonly templateId: string = "offer-summary-template";

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject fetchService: FetchService
    ) {
        super(componentFactory, binder, loggerFactory);

        this._fetchService = fetchService;
    }

    private get _offerSummaryContentTemplate(): (data: {}) => string {
        return Utils.getTemplate(this.OFFER_SUMMARY_CONTENT_TEMPLATE);
    }

    public get selectedProductCount(): number {
        return this._selectedProducts.length;
    }

    public init(): void {
        this._setOptions();
        this._bindEvents();
    }

    private _setOptions(): void {
        this._addOfferItemsUrl = Utils.getData(
            this.context,
            this.ADD_OFFER_ITEMS_URL_DATA_KEY
        ).addOfferItemsUrl;
    }

    private _bindEvents(): void {
        this.bindClick(this._handleClick);
    }

    @autobind
    private _handleClick(event: JQuery.TriggeredEvent): void {
        if (
            event.target.classList.contains(this.ADD_BUTTON_CLASS) &&
            this._selectedProducts.length
        ) {
            const postData = new FormData();
            const data: IOfferItemToAdd[] = this._selectedProducts.map((product) => ({
                ItemCode: product.ProductCode,
                Quantity: product.Quantity,
            }));

            this._fetchService.appendValue(postData, "Items", data);

            Spinner.applyOverlayTo(this._context);
            this._fetchService
                .post(this._addOfferItemsUrl, postData)
                .then((response: IShoppingContext) => {
                    Spinner.removeOverlayFrom(this._context);
                    const addOfferEvent = new CustomEvent(OffersEventType.ADD_OFFER_ITEMS_EVENT, {
                        bubbles: true,
                        detail: { shoppingContext: response },
                    });
                    this.context.dispatchEvent(addOfferEvent);
                });
        }
    }

    public update(selectedProduct: IOfferProduct, availablePoints?: number | null): void {
        const existingProduct = this._selectedProducts.find(
            (product) => product.ProductCode === selectedProduct.ProductCode
        );
        if (existingProduct) {
            if (selectedProduct.Quantity > 0) {
                existingProduct.Quantity = selectedProduct.Quantity;
            } else {
                this._selectedProducts = this._selectedProducts.filter(
                    (product) => product.ProductCode !== selectedProduct.ProductCode
                );
            }
        } else if (selectedProduct.Quantity > 0) {
            this._selectedProducts.push(selectedProduct);
        }
        this.updateTotals(false, availablePoints);
    }

    public getSelectedQuantity(itemCode: string): number {
        const existingProduct = this._selectedProducts.find(
            (product) => product.ProductCode === itemCode
        );
        if (existingProduct) {
            return existingProduct.Quantity;
        } else {
            return 0;
        }
    }

    public getRemainingPoints(availablePoints?: number | null): number {
        if (availablePoints === undefined || availablePoints === null) {
            return 0;
        }

        let totalPoints: number = 0;

        const pointsArray: number[] = [];

        this._selectedProducts.forEach((product) => {
            pointsArray.push(product.Quantity * product.Points);
        });

        if (pointsArray.length) {
            totalPoints = pointsArray.reduce(
                (sumOfPoints, currentPoints) => sumOfPoints + currentPoints,
                0
            );
        }

        return Math.round((availablePoints - totalPoints) * 100) / 100;
    }

    public updateTotals(resetTotals?: boolean, availablePoints?: number | null): void {
        if (resetTotals) {
            this._selectedProducts = [];
        }

        let totalPrice: number = 0;
        let totalBP: number = 0;
        const pricesArray: number[] = [];
        const bpArray: number[] = [];
        const pointsArray: number[] = [];

        this._selectedProducts.forEach((product) => {
            pricesArray.push(product.Quantity * product.ActualPriceUnformated);
            bpArray.push(product.Quantity * product.LoyaltyEarnPoints);
            pointsArray.push(product.Quantity * product.Points);
        });

        if (pricesArray.length) {
            totalPrice = pricesArray.reduce(
                (sumOfPrices, currentPrice) => sumOfPrices + currentPrice,
                0
            );
        }
        if (bpArray.length) {
            totalBP = bpArray.reduce((sumOfBP, currentBP) => sumOfBP + currentBP, 0);
        }
        const summaryContentContainer = this.findElement(
            this.OFFER_SUMMARY_CONTENT_CONTAINER_SELECTOR
        );
        const availablePointsElement = this.findElement(this.AVAILABLE_POINTS_SELECTOR);
        if (summaryContentContainer) {
            const data = {
                Bp: totalBP,
                TotalPrice: totalPrice,
            };

            Utils.setHtml(summaryContentContainer, this._offerSummaryContentTemplate(data));
        }
        if (availablePointsElement && availablePoints !== undefined && availablePoints !== null) {
            // formating aligned with updateAvailablePoints in offers.entry.js
            availablePointsElement.textContent = this.getRemainingPoints(availablePoints)
                .toString()
                .replace(".", ",");
        }
    }
}
