import { Inject } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { SwiperMenu } from "./SwiperMenu";
import { Utils } from "Helpers/Scripts/Utils";
import { ISwiperMenuOptions } from "./ISwiperMenuOptions";

export class SwiperFactory {
    public get key(): string {
        return "SwiperFactory";
    }

    public readonly SWIPER_SELECTOR: string = ".js-swiper";
    private _uiComponentFactory: UiComponentFactory;
    protected readonly _logger: ILogger;

    constructor(
        @Inject uiComponentFactory: UiComponentFactory,
        @Inject loggerFactory: LoggerFactory
    ) {
        this._logger = loggerFactory.getLogger(this.key);
        this._uiComponentFactory = uiComponentFactory;
    }

    /**
     * Creates new Swiper instance based on selector.
     * @param selector Selector string which will be the Swiper context.
     * @returns New instance of Swiper.
     */
    public create(
        selector: Element | string,
        swiperMenuOptions?: ISwiperMenuOptions
    ): SwiperMenu | null {
        if (!selector) {
            this._logger.error("No selector provided");

            return null;
        }

        return this._uiComponentFactory.createBase(SwiperMenu, selector, swiperMenuOptions);
    }

    /**
     * Creates Swiper instances automatically if there are some elements which match SWIPER selector in context
     * @param context Elemntent or selector string for context in which method will search for swipers.
     *                If no context passed, than document body will be the context.
     */
    public createAll(context: Element | string = "body"): void {
        const swipers = Utils.find(context, this.SWIPER_SELECTOR);

        swipers.forEach((swiper) => this.create(swiper));
    }
}
