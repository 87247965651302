import { ILogAppender } from "./ILogAppender";
import { DebugOutputType } from "./DebugOutputType";

export abstract class LogAppender implements ILogAppender {
    public readonly outputType: DebugOutputType;

    public abstract error(
        prefix: string,
        showCallStack: boolean,
        message?: any,
        ...optionalParameters: any[]
    ): this;

    public abstract warning(
        prefix: string,
        showCallStack: boolean,
        message?: any,
        ...optionalParameters: any[]
    ): this;

    public abstract info(
        prefix: string,
        showCallStack: boolean,
        message?: any,
        ...optionalParameters: any[]
    ): this;

    public abstract log(
        prefix: string,
        showCallStack: boolean,
        message?: any,
        ...optionalParameters: any[]
    ): this;

    protected getCallStack(): any {
        let result = "";
        try {
            new Function("throw new Error('')")();
        } catch (error) {
            result = error.stack;
            const marker = "Logger.";
            let pos = result.indexOf(marker);
            pos = result.indexOf("\n", pos > -1 ? pos : 0);
            if (pos > -1) {
                result = result.substring(pos);
            }
            if (result.substr(0, 1) === "\n") {
                result = result.substring(1);
            }
        }

        return result;
    }
}
