import { autobind } from "core-decorators";
import { Inject } from "typescript-ioc";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
type ButtonClickEventHandler = (event: kendo.ui.ButtonClickEvent) => void;
// Models
import { IFeedbackNotification } from "./IFeedbackNotification";
// Components
import { FeedbackNotification } from "./FeedbackNotification";

export class FeedbackNotificationWithActions extends FeedbackNotification {
    private readonly BTN_SUCCESS_PRIMARY_ACTION: string =
        ".feedback-notification__success .feedback-notification__primary-action";
    private readonly BTN_SUCCESS_ALTERNATIVE_ACTION: string =
        ".feedback-notification__success .feedback-notification__alternative-action";
    private readonly BTN_FAIL_PRIMARY_ACTION: string =
        ".feedback-notification__fail .feedback-notification__primary-action";
    private readonly BTN_FAIL_ALTERNATIVE_ACTION: string =
        ".feedback-notification__fail .feedback-notification__alternative-action";
    private readonly FEEDBACK_ACTIONS: string = ".feedback-notification__actions";

    private _feedbackActions: Element;
    private _viewModel: IFeedbackNotification;

    public get key(): string {
        return "FeedbackNotificationWithActions";
    }

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    protected _init(): void {
        this._viewModel = this.model || ({} as IFeedbackNotification);
        this._feedbackActions = this._findElement(this.FEEDBACK_ACTIONS);
        this._createButton(this.BTN_SUCCESS_PRIMARY_ACTION, this._onClickBtnSuccessPrimaryAction);
        this._createButton(
            this.BTN_SUCCESS_ALTERNATIVE_ACTION,
            this._onClickBtnSuccessAlternativeAction
        );
        this._createButton(this.BTN_FAIL_PRIMARY_ACTION, this._onClickBtnFailPrimaryAction);
        this._createButton(this.BTN_FAIL_ALTERNATIVE_ACTION, this._onClickBtnFailAlternativeAction);
    }

    protected _showSuccessMessage(): void {
        this._getFeedbackWindowActions().addClass(this.CLASS_HIDDEN);
        this._feedbackActions.classList.remove(this.CLASS_HIDDEN);
        this._window.unbindClose();
    }

    protected _showFailMessage(): void {
        this._getFeedbackWindowActions().addClass(this.CLASS_HIDDEN);
        this._feedbackActions.classList.add(this.CLASS_HIDDEN);
    }

    private _createButton(selector: string, fn: ButtonClickEventHandler): void {
        if (this.findElement(selector)) {
            kendo.createButton(selector, { click: fn });
        }
    }

    @autobind
    private _onClickBtnSuccessPrimaryAction(): void {
        this._closeWindowAndCallFunctionIfNotUndefined(
            this._viewModel.OnClickBtnSuccessPrimaryAction
        );
    }

    @autobind
    private _onClickBtnSuccessAlternativeAction(): void {
        this._closeWindowAndCallFunctionIfNotUndefined(
            this._viewModel.OnClickBtnSuccessAlternativeAction
        );
    }

    @autobind
    private _onClickBtnFailPrimaryAction(): void {
        this._closeWindowAndCallFunctionIfNotUndefined(this._viewModel.OnClickBtnFailPrimaryAction);
    }

    @autobind
    private _onClickBtnFailAlternativeAction(): void {
        this._closeWindowAndCallFunctionIfNotUndefined(
            this._viewModel.OnClickBtnFailAlternativeAction
        );
    }

    private _closeWindowAndCallFunctionIfNotUndefined(fn: Function | undefined): void {
        this.close();
        if (fn) {
            fn();
        }
    }
}
