import { Inject } from "typescript-ioc";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { FetchService } from "Async/Scripts/FetchService";
import { Utils } from "Helpers/Scripts/Utils";
import * as DeliveryChannelsEventType from "./DeliveryChannelsEventType";

import { IList } from "Helpers/Scripts/IList";
import { IPickupPointFiltersData, PickupPointUiFilterKeys } from "./IPickupPointFiltersData";
import { IPickupPointUrlLinks } from "./IPickupPointUrlLinks";
import { IFilter } from "./IFilter";
import { IPickupPointModel } from "./IPickupPointModel";
import { IPickupPointSearchData } from "./IPickupPointSearchData";
import { ShippingOffer } from "./ShippingOffer/ShippingOffer";
import { autobind } from "core-decorators";
import { DeliveryComponent } from "./DeliveryComponent";

export abstract class PickupPoint extends DeliveryComponent {
    protected _model: IPickupPointModel;
    protected _filters: IList<IFilter>;

    private readonly FULLSEARCH_FILTER_NO_DATA_TEMPLATE: string =
        "#pickup-point-filter-fullsearch-no-data-template";
    private readonly FULLSEARCH_FILTER_TEMPLATE: string =
        "#pickup-point-filter-fullsearch-template";
    protected readonly PICKUP_POINT_SEARCH_FILTER_VALIDATION_MESSAGE: string = "validation-message";
    private readonly KENDO_TEMPLATE_PICKUP_POINT_ROW: string = "kendo-template-pickup-point-row";
    private readonly KENDO_TEMPLATE_PICKUP_MESSAGE: string = "delivery-message-template";
    protected readonly PICKUP_POINTS_RESULTS: string = ".js-pickup-points-results";
    protected readonly PICKUP_POINTS_SEARCH_MANUAL_INPUT: string =
        ".js-pickup-point-filters-search-manual";
    protected readonly PICKUP_POINTS_SEARCH_BUTTON: string = "pickup-point-search-button";
    protected readonly PICKUP_POINTS_SEARCH_BUTTON_SELECTOR: string =
        "#" + this.PICKUP_POINTS_SEARCH_BUTTON;
    protected readonly PICKUP_POINTS_SEARCH_BUTTON_LABEL: string = "js-search-button-label";
    private readonly DELIVERY_CHANNEL_PICKUP_POINT: string = "deliveryChannelPickupPoint";
    protected readonly LOAD_MORE_BUTTON: string = ".js-pickup-points-loadmore";
    private readonly URLS: string = "urls";
    protected readonly MAP_WRAPPER: string = ".js-delivery-map-wrapper";
    protected readonly FILTER_DELIVERY_METHOD: PickupPointUiFilterKeys = "deliveryMethodKey";
    protected readonly FILTER_SEARCH_TERM: PickupPointUiFilterKeys = "searchTerm";
    protected readonly SEARCH_ICON_CLASS: string = "v-icon-search";
    protected readonly FILTER_SEARCH: string = "search";
    protected readonly FILTER_SEARCH_LISTBOX: string = "#pickup-point-filters-search_listbox";
    protected readonly ENTER_KEY_CODE: number = 13;

    protected readonly SLIDE_SPEED: number = 400;
    protected _submitActionFlag: boolean = false;

    private _shippingOfferComponent: ShippingOffer;

    protected _getPickupPointsUrl: string;
    protected _getCities: string;
    protected _getPickupPointsList: string;
    protected _getDeliveryChannelModels: string;
    protected _getPickupPointsByBounds: string;
    protected _getPickupPointDetails: string;
    protected _updateDeliveryMethodFilter: string;

    protected _intervalId: number; // used for mobile fix in Chinese laguage

    protected _fetchService: FetchService;
    protected _channelState: IPickupPointModel[] | null;

    protected _filtersCurrentData: IPickupPointFiltersData;
    public isMapShown: boolean = false;

    public get key(): string {
        return "PickupPoint";
    }

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject fetchService: FetchService,
        @Inject shippingOfferComponent: ShippingOffer
    ) {
        super(componentFactory, binder, loggerFactory);

        this._fetchService = fetchService;
        this._shippingOfferComponent = shippingOfferComponent;
        this._filters = {
            search: {
                selector: "#pickup-point-filters-search",
                widget: null,
            },
            type: {
                selector: "#pickup-point-filters-type",
                widget: null,
            },
        };

        this._filtersCurrentData = {
            deliveryChannelId: null,
            deliveryMethodKey: "",
            page: 0,
            pageSize: null,
            requestId: 0,
            searchTerm: "",
        };
    }

    protected _initSelectedPickupPoint(): void {
        if (!this._model) {
            this._logger.info("Cannot assign pick up point");
            return;
        }

        if (this._model.IsSelected) {
            this._channelState = [this._model];
            this._appendAndShowResults(false);
        }
    }

    protected _setUrlLinks(): void {
        const urls = this.getData(this.URLS) as IPickupPointUrlLinks;
        this._logger.info("Setting delivery options : %o ...", urls);

        if (!urls) {
            return;
        }

        const {
            getPickupPoints = "",
            getCities = "",
            getPickupPointsList = "",
            getDeliveryChannelModels = "",
            getPickupPointsByBounds = "",
            getPickupPointDetails = "",
            updateDeliveryMethodFilter = "",
        }: IPickupPointUrlLinks = urls;

        this._getPickupPointsUrl = getPickupPoints;
        this._getCities = getCities;
        this._getPickupPointsList = getPickupPointsList;
        this._getDeliveryChannelModels = getDeliveryChannelModels;
        this._getPickupPointsByBounds = getPickupPointsByBounds;
        this._getPickupPointDetails = getPickupPointDetails;
        this._updateDeliveryMethodFilter = updateDeliveryMethodFilter;
    }

    protected _setFilterType(
        deliveryMethods: IDeliveryMethods[] | undefined,
        handleFilterTypeChange: (event: kendo.ui.DropDownListChangeEvent) => void
    ): void {
        const data = {
            change: handleFilterTypeChange,
            dataSource: deliveryMethods,
            dataTextField: "Name",
            dataValueField: "DeliveryMethodId",
        };
        this._filters.type.widget = kendo.createDropDownList(this._filters.type.selector, data);
        this._filters.type.widget.select(
            (dataItem: IDeliveryMethods) =>
                dataItem.DeliveryMethodId === this._filtersCurrentData.deliveryMethodKey
        );
    }

    public getFilterTypeValue(): string {
        return this._filtersCurrentData.deliveryMethodKey;
    }

    public setTextSearchValue(value: string = ""): void {
        /* current agreement with markets:
         ** fulltext search should be cleared
         ** after switching layout
         */
        if (this._filters.search.widget) {
            this._filters.search.widget.value(value);
        }
    }

    public setFilterTypeValue(index: string): void {
        if (this._filters.type.widget) {
            this._filters.type.widget.value(index);
            if (this._filters.type.widget instanceof kendo.ui.DropDownList) {
                this._enableFilterType(this._filters.type.widget);
            }
            this._filtersCurrentData.deliveryMethodKey = index;
        }
    }

    private _enableFilterType(kendoWidget: kendo.ui.DropDownList): void {
        kendoWidget.enable(true);
    }

    protected _setFilterSearch(
        handleFilterSearchChange: (event: kendo.ui.AutoCompleteEvent) => void
    ): void {
        const autocompleteInput = this.findElement(this._filters[this.FILTER_SEARCH].selector);
        if (!autocompleteInput) {
            return;
        }

        const data = {
            change: handleFilterSearchChange.bind(this),
            dataBound: this._renderNiceScroll,
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: {
                        data: () => ({
                            deliveryMethodKey: this._filtersCurrentData.deliveryMethodKey,
                            searchTerm: this._filtersCurrentData.searchTerm,
                        }),
                        url: this._getPickupPointsList,
                    },
                    schema: {
                        model: {
                            fields: {
                                ChannelId: { type: "number" },
                                Name: { type: "string" },
                            },
                        },
                    },
                },
            },
            dataTextField: "Name",
            filtering: (event: kendo.ui.AutoCompleteFilteringEvent) => {
                this._filtersCurrentData.searchTerm = event.sender.value();
            },
            highlightFirst: false,
            noDataTemplate: Utils.getHtml(this.FULLSEARCH_FILTER_NO_DATA_TEMPLATE),
            template: (dataItem: IPickupPointSearchData) => {
                dataItem.searchTerm = this._filtersCurrentData.searchTerm;
                return kendo.template(Utils.getHtml(this.FULLSEARCH_FILTER_TEMPLATE))(dataItem);
            },
        };

        const widget = (this._filters[this.FILTER_SEARCH].widget = kendo.createAutoComplete(
            autocompleteInput,
            data
        ));
        widget.wrapper.addClass(this.SEARCH_ICON_CLASS);
        const searchElement = widget.element[0];
        kendo.createValidator(searchElement, {
            messages: {
                custom: () =>
                    Utils.getData(
                        searchElement || "",
                        this.PICKUP_POINT_SEARCH_FILTER_VALIDATION_MESSAGE
                    ),
            },
            rules: {
                custom: () =>
                    !Utils.getData(
                        searchElement || "",
                        this.PICKUP_POINT_SEARCH_FILTER_VALIDATION_MESSAGE
                    ),
            },
        });
        Utils.setData(searchElement, "val", true);
    }

    private _getPickupPointTemplate(): (data: any) => string {
        return Utils.getTemplate(this.KENDO_TEMPLATE_PICKUP_POINT_ROW);
    }

    private _getPickupPointMessageTemplate(): (data: any) => string {
        return Utils.getTemplate(this.KENDO_TEMPLATE_PICKUP_MESSAGE);
    }

    protected _updateFiltersCurrentData(value: string, key: PickupPointUiFilterKeys): void {
        if (this._filtersCurrentData[key] === value) {
            return;
        }

        this._filtersCurrentData[key] = value;
        this._filtersCurrentData.requestId += 1;
    }

    protected _swapShownChannels(isNextPageAvailable?: boolean): void {
        const pickupPointResults = this.findElement(this.PICKUP_POINTS_RESULTS);
        if (pickupPointResults && pickupPointResults.children.length > 0) {
            this._hideAndRemoveResults(() => {
                this._appendAndShowResults(isNextPageAvailable);
            });
        } else {
            this._appendAndShowResults(isNextPageAvailable);
        }
    }

    protected _hideAndRemoveResults(callback?: Function): void {
        const pickupPointResults = this.findElement(this.PICKUP_POINTS_RESULTS);
        if (pickupPointResults) {
            Utils.slideUp(pickupPointResults, this.SLIDE_SPEED, () => {
                this._forceCheckoutDisable();
                this._showMoreButtonToggle(true);
                if (this._shippingOfferComponent) {
                    this._shippingOfferComponent.getAndStoreShippingOfferBar();
                }
                Utils.empty(pickupPointResults);
                if (callback) {
                    callback();
                }
            });
        }
    }

    protected _showMoreButtonToggle(isShown: boolean): void {
        Utils.toggleClass(this.LOAD_MORE_BUTTON, this.CLASS_HIDDEN, isShown);
    }

    protected _appendResults(results: IPickupPointModel[]): void {
        const pickupPointResults = this.findElement(this.PICKUP_POINTS_RESULTS);
        if (pickupPointResults) {
            const template = this._getPickupPointTemplate();
            results.forEach((item: IPickupPointModel) => {
                item.Message = this._model.Message;
                Utils.append(pickupPointResults, Utils.createDomElementFromMarkup(template(item)));
                if (
                    item &&
                    item.OutOfStockItems &&
                    item.OutOfStockItems.length > 0 &&
                    item.DeliveryChannelId
                ) {
                    this._handleOutOfStockTooltip(item.OutOfStockItems, item.DeliveryChannelId);
                }

                if (item.Message) {
                    const messageTemplate = this._getPickupPointMessageTemplate();

                    const pickupPointDescription = this.findElement(
                        ".js-pickup-point-row .w-radio-item"
                    );

                    const messageElement = Utils.createDomElementFromMarkup(messageTemplate(item));

                    if (pickupPointDescription && messageElement) {
                        pickupPointDescription.appendChild(messageElement);
                    }
                }
            });
        }
    }

    protected _appendAndShowResults(IsNextPageAvailable?: boolean): void {
        const pickupPointResults = this.findElement(this.PICKUP_POINTS_RESULTS);

        if (this._channelState && this._channelState.length) {
            this._appendResults(this._channelState);

            const pickupPoint = this._channelState[0];
            if (pickupPoint && pickupPoint) {
                this._selectFirstChannel(pickupPoint.DeliveryChannelId);
            }
        }

        // show the results
        if (pickupPointResults) {
            Utils.slideDown(pickupPointResults, this.SLIDE_SPEED, () => {
                this._showMoreButtonToggle(IsNextPageAvailable ? false : true);
            });
        }
    }

    protected _isClickOnPickupChannel(event: JQuery.TriggeredEvent): Boolean {
        return (<HTMLInputElement>event.target).name === this.DELIVERY_CHANNEL_PICKUP_POINT;
    }

    protected _isClickOnSearchButton(event: JQuery.TriggeredEvent): Boolean {
        return (
            (<HTMLInputElement>event.target).name === this.PICKUP_POINTS_SEARCH_BUTTON ||
            Utils.hasClass(<HTMLSpanElement>event.target, this.PICKUP_POINTS_SEARCH_BUTTON_LABEL)
        );
    }

    protected _forceCheckoutDisable(): void {
        this._binder.trigger(DeliveryChannelsEventType.PICKUP_POINT_CHANNEL_UPDATE, {
            deliveryChannel: null,
            delveryAddress: null,
        });
    }

    protected _sendChannelUpdate(deliveryChannelId: number): void {
        this._binder.trigger(DeliveryChannelsEventType.PICKUP_POINT_CHANNEL_UPDATE, {
            deliveryChannel: deliveryChannelId,
        });
    }

    private _selectFirstChannel(deliveryChannelId?: number): void {
        if (deliveryChannelId) {
            const radioInput = this.findElement(
                `[${this.DELIVERY_CHANNEL_DATA_ATTRIBUTE}="${deliveryChannelId}"] input[type="radio"]`
            );
            if (radioInput) {
                const radioButton = kendo.getRadioButton(radioInput);
                if (radioButton) {
                    radioButton.value(true);
                }
            }

            this._sendChannelUpdate(deliveryChannelId);
        }
    }

    protected _toggleAllFiltersButSearch(isEnabled: boolean): void {
        for (const key in this._filters) {
            if (key === this.FILTER_SEARCH) {
                continue;
            }

            const widget = this._filters[key].widget;
            if (widget) {
                // not possible to call enable() method directly due to incompatible call signatures; TODO: fix signatures
                widget.enable.call(widget, isEnabled);
            }
        }
    }

    public toggleMapWrapper(): void {
        const mapWrapper = this.findElement(this.MAP_WRAPPER);
        if (mapWrapper !== null) {
            Utils.toggleClass(mapWrapper, this.CLASS_HIDDEN, this.isMapShown);
        }
    }

    public toggleSearchButton(): void {
        const searchButton = this.findElement(this.PICKUP_POINTS_SEARCH_BUTTON_SELECTOR);
        if (searchButton) {
            Utils.toggleClass(searchButton, this.CLASS_HIDDEN, !this.isMapShown);
        }
    }

    private _unbindSubmitAction(): void {
        this._binder.unbindKeyDown(this._filters.search.selector);
    }

    public destroyPickupPointComponent(): void {
        this._destroyAllFilters();
        this._hideAndRemoveResults();
    }

    protected _destroyAllFilters(): void {
        this._unbindSubmitAction();
        for (const key of Object.keys(this._filters)) {
            const widget = this._filters[key].widget;
            if (widget) {
                const clearIcon = widget.element[0].nextElementSibling;
                let titleIcon;
                if (clearIcon) {
                    titleIcon = clearIcon.nextElementSibling;
                }
                // not possible to call destroy() method directly due to incompatible call signatures; TODO: fix signatures
                widget.destroy.call(widget);
                // workaround - Kendo doesn't remove clear icon and title icon in the destroy() method
                if (clearIcon) {
                    const clearIconParent = clearIcon.parentNode;
                    if (clearIconParent) {
                        clearIconParent.removeChild(clearIcon);
                    }
                }
                if (titleIcon) {
                    const titleIconParent = titleIcon.parentNode;
                    if (titleIconParent) {
                        titleIconParent.removeChild(titleIcon);
                    }
                }
            }
        }
        if (this._intervalId) {
            this._destroyAutoFiltering();
        }
    }

    // START: Safari + iOS mobile fix (for Chinese)
    private _destroyAutoFiltering(): void {
        window.clearInterval(this._intervalId);
        this._intervalId = 0;
        this.unbindClick(this._filters.search.selector);
    }
    // END: Safari + iOS mobile fix (for Chinese)

    protected _handleFilterSearchChange(
        event: kendo.ui.AutoCompleteEvent,
        onNonEmptyAutoComplete?: Function,
        onEmptyAutoComplete?: Function,
        onPickupPointsHidden?: Function
    ): void {
        if (!this._filters[this.FILTER_SEARCH].widget) {
            return;
        }

        const widget = this._filters[this.FILTER_SEARCH].widget as kendo.ui.AutoComplete;
        const autoCompleteData = widget.dataItem();

        // if autoCompleteData is not undefined then we can fetch data and update
        if (autoCompleteData !== undefined) {
            this._filtersCurrentData.deliveryChannelId = autoCompleteData.ChannelId;
            this._toggleAllFiltersButSearch(false);
            this._updateFiltersCurrentData(event.sender.value(), this.FILTER_SEARCH_TERM);

            if (onNonEmptyAutoComplete) {
                onNonEmptyAutoComplete();
            }
        } else if (!event.sender.value()) {
            this._toggleAllFiltersButSearch(true);
            this._filtersCurrentData.deliveryChannelId = null;

            if (onEmptyAutoComplete) {
                onEmptyAutoComplete();
            }

            this._forceCheckoutDisable();
            Utils.slideUp(this.PICKUP_POINTS_RESULTS, this.SLIDE_SPEED, () => {
                if (this._shippingOfferComponent) {
                    this._shippingOfferComponent.getAndStoreShippingOfferBar();
                }
                Utils.empty(this.PICKUP_POINTS_RESULTS);
                if (onPickupPointsHidden) {
                    onPickupPointsHidden();
                }
                this._showMoreButtonToggle(true);
            });
        }
    }

    protected _isOptionHighlighted(): boolean {
        if (
            this._filters &&
            this._filters.search &&
            this._filters.search.widget &&
            (this._filters.search.widget as kendo.ui.AutoComplete).list
        ) {
            return Boolean(
                (this._filters.search.widget as kendo.ui.AutoComplete).list.find(".k-state-focused")
                    .length
            );
        }
        return false;
    }

    @autobind
    private _renderNiceScroll(): void {
        const searchList = $(this.FILTER_SEARCH_LISTBOX);
        if (searchList) {
            const data = {
                autohidemode: false,
                cursorcolor: "var(--grey-300)",
                cursorwidth: "3px",
            };

            const niceScrollObject = searchList.getNiceScroll(0);
            if (!niceScrollObject) {
                searchList.niceScroll(data);
            }
        }
    }

    protected _updateFromDeliveryModel(
        prevState: IPickupPointModel,
        nextState: IPickupPointModel,
        forceUpdate: boolean
    ): boolean {
        if (!nextState || (!forceUpdate && !this._shouldComponentUpdate(prevState, nextState))) {
            return false;
        }

        if (this._isChanged(prevState.IsAllowed, nextState.IsAllowed) || forceUpdate) {
            this._toggleChannelSelectability(nextState);
        }

        if (
            this._wasOutOfStockItemsChanged(prevState.OutOfStockItems, nextState.OutOfStockItems) ||
            this._isChanged(prevState.DeliveryDate, nextState.DeliveryDate) ||
            (this._isEndCustomerFirstCheckout() &&
                this._isChanged(prevState.Address, nextState.Address))
        ) {
            this._updateChannelInfo(nextState);
        }

        if (
            nextState.DeliveryChannelId &&
            this._isChanged(prevState.DeliveryFee, nextState.DeliveryFee)
        ) {
            this._updateDeliveryFee(nextState.DeliveryFee, nextState.DeliveryChannelId);
        }

        if (this._isChanged(prevState.SelectedDeliveryChannel, nextState.SelectedDeliveryChannel)) {
            this._hidePhoneBoxes(prevState.SelectedDeliveryChannel);
        }

        return true;
    }

    protected _updateAddressInfo(data: IPickupPointModel): void {
        if (data.DeliveryChannelId) {
            const addressInfo = this.findElement(
                `[${this.DELIVERY_CHANNEL_DATA_ATTRIBUTE}="${data.DeliveryChannelId}"] .${this.DELIVERY_ADDRESS_INFO_CLASS}`
            );

            if (addressInfo) {
                Utils.setHtml(addressInfo, this._deliveryAddressInfoTemplate(data));
            }
        }
    }

    private _updateChannelInfo(data: IPickupPointModel): void {
        if (data.DeliveryChannelId && data.OutOfStockItems) {
            const channelInfo = this.findElement(
                `[${this.DELIVERY_CHANNEL_DATA_ATTRIBUTE}="${data.DeliveryChannelId}"] .${this.DELIVERY_CHANNEL_INFO_CLASS}`
            );
            if (channelInfo) {
                Utils.setHtml(channelInfo, this._deliveryChannelInfoTemplate(data));
                this._handleOutOfStockTooltip(data.OutOfStockItems, data.DeliveryChannelId);
            }
        }
    }

    /**
     * Delivery channel can be disabled for a customer (Smartbox: ordered products doesn't match the box)
     */
    protected _toggleChannelSelectability(data: IPickupPointModel): void {
        const input = this.findElement("input:radio") as Element;
        if (input) {
            const radioButton = kendo.getRadioButton(input);
            if (radioButton) {
                radioButton.enable(data.IsAllowed);
            }
        }
    }
}
