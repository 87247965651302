/**
 * Level of module debug messages.
 */
export enum DebugLevel {
    None = 0,
    Error,
    Warning,
    Info,
    Log,
}

export const DEFAULT_DEBUG_LEVEL = DebugLevel.Warning;
