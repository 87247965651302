// @ts-ignore
import justThrottle from "just-throttle";
import { ISize } from "./ISize";
import { IScrollPosition } from "./IScrollPosition";
import $ from "jquery";
import { IList } from "Helpers/Scripts/IList";
import { ILogger } from "Logging/Scripts/ILogger";

/**
 * Helper functions so far written in a static way for (maybe) easier utilization.
 * Re-wrapping the element reference via jQuery is maybe expensive, but probably not so much as traversing the DOM.
 * TODO: In many places uses jQuery. Drop once in the future when jQuery is removed (maybe ...).
 */
export class Utils {
    public static readonly DISABLED: string = "disabled";
    public static readonly NO_COUNT_LIMIT: number = -1;
    private static readonly OPTIONS_SCRIPT_TYPE: string = "application/json";
    private static readonly TEST: string = "test";
    private static _touchEventsEnabled?: boolean;
    public static sessionStorageIsAvailable?: boolean;
    public static localStorageAvailable?: boolean;
    /**
     * Classic check for local storage availability.
     * @returns True when localStorage is supported in current browser.
     */
    public static get isLocalStorageAvailable(): boolean {
        if (Utils.localStorageAvailable !== undefined) {
            return Utils.localStorageAvailable; // if available, use cached value
        }

        try {
            localStorage.setItem(this.TEST, this.TEST);
            localStorage.removeItem(this.TEST);
            return (Utils.localStorageAvailable = true);
        } catch (error) {
            return (Utils.localStorageAvailable = false);
        }
    }

    /**
     * Classic check for session storage availability.
     * @returns {} True when sessionStorage is supported in current browser.
     */
    public static get isSessionStorageAvailable(): boolean {
        if (Utils.sessionStorageIsAvailable !== undefined) {
            return Utils.sessionStorageIsAvailable; // if available, use cached value
        }

        try {
            sessionStorage.setItem(this.TEST, this.TEST);
            sessionStorage.removeItem(this.TEST);
            return (Utils.sessionStorageIsAvailable = true);
        } catch (error) {
            return (Utils.sessionStorageIsAvailable = false);
        }
    }

    private static guidHelper(dashes?: boolean): string {
        const p = (Math.random().toString(16) + "000000000").substr(2, 8); // eslint-disable-line no-magic-numbers

        return dashes ? `-${p.substr(0, 4)}-${p.substr(4, 4)}` : p; // eslint-disable-line no-magic-numbers
    }

    /**
     * Generates a GUID string.
     * @link http://slavik.meltser.info/?p=142
     */
    public static guid(): string {
        return (
            this.guidHelper() + this.guidHelper(true) + this.guidHelper(true) + this.guidHelper()
        );
    }

    // /**
    //  * Generates RFC4122 version 4 compliant GUID
    //  * @link https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    //  */
    // public static guid(): string {
    //     return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c: string): string => {
    //         const r = Math.random() * 16 | 0;
    //         const v = (c === 'x') ? r : (r & 0x3 | 0x8);
    //         return v.toString(16);
    //     });
    // }

    public static getWindowLocation(): Location {
        return window.location;
    }

    public static get touchEventsEnabled(): boolean {
        if (Utils._touchEventsEnabled === undefined) {
            try {
                document.createEvent("TouchEvent");
                Utils._touchEventsEnabled = true;
            } catch (error) {
                Utils._touchEventsEnabled = false;
            }
        }

        return Utils._touchEventsEnabled;
    }

    /**
     * @returns Value of common redirect URL parameter from current page location.
     */
    public static getRedirectUrlPath(): string {
        const PARAM_NAME = "returnUrl";
        const regExp = new RegExp("^([^=&]+=[^&]*&)*" + PARAM_NAME + "=([^&]+)", "g");
        const regExpGroup = 2;
        const searchString = this.getWindowLocation().search;
        let redirectUrlPath = "";

        const match = regExp.exec(searchString);

        if (match) {
            redirectUrlPath = decodeURIComponent(match[regExpGroup]);
        }

        return redirectUrlPath;
    }

    /**
     * Redirects the page to specified url.
     * @param url URL path to navigate to
     * @param params Optional URL parameteres specification.
     * @param useReplace When true, rather location.replace will be used instead of location.href.
     */
    public static redirectTo(url: string, params?: IList<string>, useReplace?: boolean): void {
        let href: string = url;
        if (params) {
            const query = Object.keys(params)
                .map(
                    (key: string) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
                )
                .join("&");
            if (query) {
                href += "?" + query;
            }
        }
        if (useReplace) {
            window.location.replace(href);
        } else {
            window.location.href = href;
        }
    }

    /**
     * Adds a new parameter to the provided URL querystring
     * Modifies the value of a given parameter in the provided URL querystring
     * Adds or modifies a parameter in the provided URL querystring
     * Removes a parameter from the provided URL querystring
     * Replaces the querystring in the current browser's URL with the provided new one
     * TODO: these functions are currently present in pbsCookieUrl.js and should be moved here when rewriting that piece to TS
     * use URLSearchParams if possible - https://caniuse.com/#search=URLSearchParams
     */

    /**
     * Returns the value of a querystring parameter
     */
    public static getParamValue(queryString: string, param: string): string | null {
        const pattern = new RegExp("([?&]" + param + ")=([^&#?;,=]+)", "i");

        const match = queryString.match(pattern);
        if (match && match.length > 2) {
            // eslint-disable-line no-magic-numbers
            return match[2]; // eslint-disable-line no-magic-numbers
        }
        return null;
    }

    /**
     * Removes specified parameter in provided URL and returns the resulting string.
     * TODO Rewrite the tests for this function. The original code did not work properly for 1st parameter (the ?-delimited one),
     * TODO it also made a mess if found a substring-matching parameter; and removed #hash part. But passed the tests :D
     * TODO use URLSearchParams if possible - https://caniuse.com/#search=URLSearchParams
     */
    public static removeUrlParam(url: string, parameter: string): string {
        const regex = new RegExp(`([?&])${parameter}\=[^&#?;,=]+([&#]|$)`, "i");
        const match = url ? url.match(regex) : null;

        if (!match || match.length < 3) {
            // eslint-disable-line no-magic-numbers
            return url;
        }
        let delimiter = match[2]; // eslint-disable-line no-magic-numbers
        if (match[1] === "?" && delimiter === "&") {
            delimiter = "?";
        }
        return url.replace(regex, delimiter);
    }

    /**
     * Helper method for checking whether the script runs inside an iframe.
     */
    public static get isIframe(): boolean {
        let result = false;
        try {
            result = window.self !== window.top;
        } catch (error) {
            // suppose exception due to crossdomain, than this has to be iframe
            result = true;
        }

        return result;
    }

    /**
     * "Hyphenates" the input string, e.g. input "myLongString" returns "my-long-string".
     */
    public static hyphenate(value: string) {
        return value.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
    }

    /**
     * @param element Selector or element instance.
     * @param name Name of the HTML attribute.
     * @returns Value of attribute with given name on specified element.
     */
    public static getAttr(element: JQuery | Element | string, name: string) {
        return $(element as Element).attr(name);
    }

    /**
     * @param element Selector.
     * @param selector Selector which should be matched.
     * @returns matched Jquery object.
     */
    public static siblings(element: JQuery | Element | string, selector?: string) {
        return $(element as Element).siblings(selector);
    }

    /**
     * Get the ancestors of element
     * @param element Element instance.
     * @param filter Selector expression to match elements against
     * @returns matched Jquery object.
     */
    public static parents(element: JQuery | Element | string, filter?: string) {
        return $(element as Element).parents(filter);
    }

    /**
     * Get the ancestors of element up to (but not including) the element matched
     * @param element Element instance.
     * @param stopElement Element instance on which search should be stopped.
     * @param filter Selector expression to match elements against
     * @returns matched Jquery object.
     */
    public static parentsUntil(element: Element, stopElement: Element, filter?: string) {
        return $(element).parentsUntil(stopElement, filter);
    }

    /**
     * Checks whether input element was selected
     * @param element Selector.
     */
    public static isChecked(element: JQuery | Element | string) {
        return $(element as Element).is(":checked");
    }

    /**
     * Sets attribute with given name and value on specific element.
     * @param element Selector or element instance.
     * @param name Name of the HTML attribute.
     * @param value Value to be set.
     */
    public static setAttr(element: JQuery | Element | string, name: string, value: string) {
        $(element as Element).attr(name, value);
    }

    /**
     * Toggle attribute with given name and value on specific element.
     * @param element Selector or element instance.
     * @param name Name of the HTML attribute.
     * @param value Value to be set.
     */
    public static toggleAttr(element: Element, name: string, value: string) {
        element.setAttribute(name, element.getAttribute(name) === value ? "" : value);
    }

    /**
     * Removes attribute with given name from specific element.
     * @param element Selector or element instance.
     * @param name Name of the HTML attribute.
     */
    public static removeAttr(element: Element | JQuery | string, name: string) {
        $(element as Element).removeAttr(name);
    }

    /**
     * Checks whether the element matches the given selector.
     */
    public static is(element: Element | JQuery | string, selector: any) {
        return $(element as Element).is(selector);
    }

    /**
     * Checks whether the element is visible.
     */
    public static isVisible(element: Element | JQuery | string) {
        return this.is(element, ":visible");
    }

    /**
     * Checks whether given element has a provided class name in its CSS class list.
     * @param element Selector or element instance.
     * @param className The class to be checked.
     */
    public static hasClass(element: Element | JQuery | string, className: string) {
        return $(element as Element).hasClass(className);
    }

    /**
     * Adds the provided class name in element's CSS class list.
     * @param element Selector or element instance.
     * @param className The class to be added.
     */
    public static addClass(element: Element | JQuery | string, className: string) {
        $(element as Element).addClass(className);
    }

    /**
     * Removes passed class/es from given element
     * @param element Selector or element instance.
     * @param className The class to be removed. When not provided, all classes will be removed.
     */
    public static removeClass(element: Element | JQuery | string, className?: string) {
        $(element as Element).removeClass(className);
    }

    /**
     * Removes passed class/es from given element via passed handler.
     * @param element Selector or element instance.
     * @param func Function which returns class to be removed.
     */
    public static removeClassWithHandler(
        element: Element | JQuery | string,
        func: (index: number, className: string) => string
    ) {
        $(element as Element).removeClass(func);
    }

    /**
     * Switches the specified class on or off on the provided element.
     * @param element Selector or element instance.
     * @param className The class to be switched.
     * @param switchOn When tru, the class will be set. When false, the class will be removed.
     * When not specified, the class will be toggled depending on its current presence.
     */
    public static toggleClass(
        element: Element | JQuery | string,
        className: string,
        switchOn?: boolean
    ) {
        $(element as Element).toggleClass(className, switchOn);
    }

    /**
     * @param element Selector or element instance.
     * @param propertyName CSS property name.
     * @returns Current value of CSS styling property on the specified element.
     */
    public static getCss(element: Element | JQuery | string, propertyName: string) {
        return $(element as Element).css(propertyName);
    }

    /**
     * Sets CSS styling on the element.
     * @param element Selector or element instance.
     * @param propertyName CSS property name.
     * @param value Value to be set for the selected CSS property.
     */
    public static setCss(
        element: Element | JQuery | string,
        propertyName: string,
        value: string | number
    ) {
        $(element as Element).css(propertyName, value);
    }

    /**
     * Sets CSS styling on the element via passed handler
     * @param element Selector or element instance.
     * @param propertyName CSS property name.
     * @param value Function which returns new value.
     */
    public static setCssWithHandler(
        element: Element | JQuery | string,
        propertyName: string,
        value: (index: number, value: string) => string | number
    ) {
        $(element as Element).css(propertyName, value);
    }

    /**
     * Tries to describe the specified element for better identification.
     * @element Selector or element instance.
     */
    public static describeElement(element: Element | JQuery | string) {
        const $tested = $(element as Element);
        if (!$tested[0]) {
            return "***element not found***";
        }

        const tag = $tested[0].nodeName.toLowerCase();
        let type = $tested.attr("type") || "";

        let value = "";

        if (type) {
            value = type === "checkbox" ? $tested.prop("checked") : $tested.val();
        }

        let id = $tested.attr("id") || "";
        let name = $tested.attr("name") || "";
        let cssClass = $tested.attr("class") || "";

        if (id === name) {
            name = "";
        }

        if (type) {
            type = `[${type}]`;
        }

        if (id) {
            id = `#${id}`;
        }

        if (name) {
            name = `[${name}]`;
        }

        if (cssClass) {
            cssClass = `.${cssClass.split(" ").join(".")}`;
        }

        if (value) {
            value = `(value=${value})`;
        }

        return tag + type + name + id + cssClass + value;
    }

    /**
     * @param element Selector or element instance to start the search on.
     * @param selector Selector for matching found element.
     * @returns Closest element matching the given selector starting with the original provided one.
     */
    public static closest(element: Element | JQuery | string, selector: string) {
        const $result = $(element as Element).closest(selector);

        return $result[0];
    }

    /**
     * Wrap Element
     * @param element
     * @param wrapper
     */
    public static wrap(element: Element | JQuery | string, wrapper: Element | JQuery | string) {
        return $(element as Element).wrap(wrapper)[0];
    }

    /**
     * @param element Checked object selector or reference.
     * @returns Current scroll position of the specified object.
     */
    public static getScrollPosition(element: Element | Window | JQuery | string): IScrollPosition {
        const $element = $(element as Element);

        return { left: $element.scrollLeft() ?? 0, top: $element.scrollTop() ?? 0 };
    }

    /**
     * @param element Checked object selector or reference.
     * @param outerSize When true, rather outerWidth() and outerHeight() will be used internally.
     * @returns Dimensions of the specified object.
     */
    public static getSize(element: Element | Window | JQuery | string, outerSize?: boolean): ISize {
        const $element = $(element as Element);
        return outerSize
            ? { width: $element.outerWidth() ?? NaN, height: $element.outerHeight() ?? NaN }
            : { width: $element.width() ?? NaN, height: $element.height() ?? NaN };
    }

    /**
     * @param outerSize When true, rather outerWidth() will be used internally.
     * @returns Width of specified element.
     */
    public static getWidth(element: Window | Element | JQuery | string, outerSize?: boolean) {
        const $element = $(element as Element);
        return outerSize ? $element.outerWidth() ?? NaN : $element.width() ?? NaN;
    }

    /**
     * @param outerSize When true, rather outerHeight() will be used internally.
     * @returns Height of specified element.
     */
    public static getHeight(element: Window | Element | JQuery | string, outerSize?: boolean) {
        const $element = $(element as Element);
        return outerSize ? $element.outerHeight() ?? NaN : $element.height() ?? NaN;
    }

    /**
     * @param element Selector or element instance.
     * @returns Specified element's coordinates.
     */
    public static getOffset(element: Element | JQuery | string) {
        return $(element as Element).offset();
    }

    /**
     * Returns specified data stored on specified element.
     * @param element Selector or element instance.
     * @param key Optional data key specification. When not provided, all data will be returned.
     */
    public static getData(element: Element | JQuery | string, key?: string) {
        return $(element as Element).data(key);
    }

    /**
     * Sets specified data on provided element.
     * @param element Selector or element instance.
     * @param key Data key under which the data value will be stored.
     * @param value Data to store.
     */
    public static setData(element: Element | JQuery | string, key: string, value: unknown) {
        $(element as Element).data(key, typeof value === "undefined" ? null : value);
    }

    /**
     * Removes specified data from specified element.
     * @param element Selector or element instance.
     * @param key Optional data key specification. When not provided, all data will be removed.
     */
    public static removeData(element: Element | JQuery | string, key?: string) {
        $(element as Element).removeData(key);
    }

    /**
     * Returns specified property value of given element.
     * @param element Selector or element instance.
     * @param key Property key specification.
     */
    public static getProp(element: Element | JQuery | string, key: string) {
        return $(element as Element).prop(key);
    }

    /**
     * Sets specified property on provided element.
     * @param element Selector or element instance.
     * @param key Key of property for which the value will be set.
     * @param value Property value.
     */
    public static setProp(element: Element | JQuery | string, key: string, value: any) {
        $(element as Element).prop(key, value);
    }

    /**
     * Appends a content into specified element.
     * @param element Selector or element instance into which to append the content.
     * @param content Appended content.
     */
    public static append(
        element: Element | JQuery | string,
        content: string | any[] | JQuery | Element | DocumentFragment
    ) {
        $(element as Element).append(content);
    }

    /**
     * Prepends a content into specified element.
     * @param element Selector or element instance into which to prepend the content.
     * @param content Prepended content.
     */
    public static prepend(
        element: Element | JQuery | string,
        content: string | any[] | JQuery | Element | DocumentFragment
    ) {
        $(element as Element).prepend(content);
    }

    /**
     * Places the content after target element.
     * @param placementTarget Selector or element after which to place the content.
     * @param movedElement Content to be placed / moved after the target element.
     */
    public static after(
        placementTarget: Element | JQuery | string,
        movedElement: string | any[] | JQuery | Element | DocumentFragment
    ) {
        $(placementTarget as Element).after(movedElement);
    }

    /**
     * Places the content before target element.
     * @param placementTarget Selector or element before which to place the content.
     * @param movedElement Content to be placed / moved before the target element.
     */
    public static before(
        placementTarget: Element | JQuery | string,
        movedElement: string | any[] | JQuery | Element | DocumentFragment
    ) {
        $(placementTarget as Element).before(movedElement);
    }

    /**
     * Empties the content of passed element.
     * @param element Selector or element which should be emptied.
     */
    public static empty(element: string | any[] | JQuery | Element | DocumentFragment): void {
        $(element).empty();
    }

    /**
     * Replaces element with passed content.
     * @param placementTarget Selector or element which should be replaced.
     * @param newContent Content to be placed instead of the target element.
     * @return Element(s) replaced by the new content.
     */
    public static replaceWith(
        placementTarget: Element | JQuery | string,
        newContent: string | any[] | JQuery | Element | Text
    ) {
        return $(placementTarget as Element).replaceWith(newContent);
    }

    /**
     * Detaches element from DOM.
     * @param element Selector or element to detach.
     */
    public static detach(element: string | any[] | JQuery | Element | DocumentFragment): void {
        $(element).detach();
    }

    /**
     * Checks whether givem element is detached from DOM
     * @param element Element to be checked
     * @return boolean whether element is out of DOM
     */
    public static isDetached(element: Element): boolean {
        return document.documentElement ? !document.documentElement.contains(element) : false;
    }

    /**
     * Finds a set of subelements matching specified selector.
     * @param selector jQuery selector.
     * @param elementCountLimit Maximum count of returned element items.
     * @returns Array with selected subelements.
     */
    public static find(
        element: Element | JQuery | string,
        selector: string,
        elementCountLimit: number = this.NO_COUNT_LIMIT
    ): Element[] {
        const result = $(element as Element)
            .find(selector)
            .toArray();
        return elementCountLimit === this.NO_COUNT_LIMIT
            ? result
            : result.slice(0, elementCountLimit);
    }

    /**
     * @param element Selector or element instance.
     * @returns Text content of the specified element.
     */
    public static getText(element: Element | JQuery | string) {
        return $(element as Element).text();
    }

    /**
     * Sets new text content on the specified element.
     * @param element Selector or element instance.
     * @param content Text content to be set.
     */
    public static setText(element: Element | JQuery | string, content: string) {
        $(element as Element).text(content);
    }

    /**
     * Creates deep clone of element
     */
    public static clone(element: Element) {
        return $(element).clone().toArray()[0];
    }

    /**
     * Sets new html content on the specified element.
     * @param element Selector or element instance.
     * @param content Text content to be set.
     */
    public static setHtml(element: Element | JQuery | string, content: string) {
        $(element as Element).html(content);
    }

    /**
     * Get  html content on the specified element.
     * @param element Selector or element instance.
     */
    public static getHtml(element: Element | JQuery | string) {
        return $(element as Element).html();
    }

    /**
     * @param element Selector or element instance.
     * @returns Value of the specified element.
     */
    public static getValue(element: Element | JQuery | string) {
        return $(element as Element).val();
    }

    /**
     * Sets new value on the specified element.
     * @param element Selector or element instance.
     * @param value Value to be set.
     */
    public static setValue(element: Element | JQuery | string, value: string | number | string[]) {
        $(element as Element).val(value);
    }

    /**
     * Helper to get deserialized options data according to given key.
     * Tries to search for an element with the key used in its id attribute or,
     * as a fallback, element with HTML data attribute with specific name.
     * When the found element is script of application/json type, its inner HTML
     * is considered to be the serialized data. Otherwise, the fallback solution is checked,
     * which was used earlier, i.e. HTML data attribute with specific name
     * in "data-{key}-options" format. This is less efficient storage, as it requires
     * HTML encoding, and is also less readable in the markup.
     *
     * @param key Options key. ID of script element or name of HTML data attribute.
     * For the HTML data attribute fallback, the real searched name is "data-{key}-options".
     * @param element Optional element on which to search the data. If not provided,
     * the data is searched on the <body> element in current page as a fallback solution.
     * Same could be done on <html> element, but long data attributes can cause some HTML
     * validation problems with charset definition not within first like 1024 characters in the page.
     * @returns deserialized options data according to given key, or empty object when not found.
     */
    public static getOptions(key: string, elementSelector?: Element | JQuery | string): any {
        if (!key) {
            return {};
        }

        let element = elementSelector;
        if (!element) {
            element =
                document.getElementById(key) ||
                document.querySelector(`[data-${key}-options]`) ||
                document.body;
        }

        if (Utils.getAttr(element, "type") === Utils.OPTIONS_SCRIPT_TYPE) {
            return JSON.parse(Utils.getHtml(element)) || {};
        }

        const dataKey: string = `${key}Options`;

        return (
            Utils.getData(element, dataKey) ||
            Utils.getData(element, Utils.hyphenate(dataKey)) ||
            {}
        );
    }

    /**
     * Merge the objects (last wins) and return new instance of object
     * @param object1
     * @param objectN
     */
    public static extend<T extends Object>(deep: boolean | T, object1: T, ...objectN: T[]): T {
        let result: T;

        if (deep === true) {
            result = $.extend(true, {}, object1, ...objectN) as T;
        } else {
            result = $.extend({}, deep, object1, ...objectN) as T;
        }

        return result;
    }

    /**
     * Function that returns kendo template per given id of the template
     * @param id of the template
     * @returns kendo template as function
     */
    public static getTemplate(id: string): (data: any) => string {
        let templateString = "";
        const templateElement = document.getElementById(id);
        if (templateElement) {
            templateString = templateElement.innerHTML;
        }

        return kendo.template(templateString, { useWithBlock: false });
    }

    /**
     * Function that creates markup from given string
     * @param markup string to be converted to markup
     * @returns created element
     */
    public static createDomElementFromMarkup(markup: string): Element {
        return $(markup)[0];
    }

    public static DEFAULT_SPEED = 400;

    /**
     * Slide down animation
     * @param selector of the element to be slided down
     * @param duration
     * @param callback function
     */
    public static slideDown(
        selector: string | Element,
        duration?: number,
        callback?: (this: Element) => void
    ): void {
        $(selector as Element).slideDown(
            duration !== undefined ? duration : this.DEFAULT_SPEED,
            callback as (this: Element) => void
        );
    }

    /**
     * Slide up animation
     * @param selector of the element to be slided up
     * @param duration
     * @param callback function
     */
    public static slideUp(
        selector: string | Element,
        duration?: number,
        callback?: (this: Element) => void
    ): void {
        $(selector as Element).slideUp(
            duration ? duration : this.DEFAULT_SPEED,
            callback as (this: Element) => void
        );
    }

    /**
     * Function compares two objects by turning them into JSON strings.
     * No functions/methods in the objects allowed; only pure data should be stored
     * Keep the same order of the properties so the comparison is correct
     * @returns boolean if the two objects are equal
     */
    public static deepEqual(a: object, b: object): boolean {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    /**
     * @param element Element to check.
     * @returns Zero-based index of the provided element in its parent's DOM (if any).
     */
    public static getElementIndex(element: Element): number {
        let current: Element | null = element;
        let result: number = 0;
        while (current) {
            current = current.previousElementSibling;
            if (current) {
                result++;
            }
        }

        return result;
    }

    /**
     * @param element Object to be evaluated to element reference.
     * @returns element reference or undefined when not found by selector or provided
     * JQuery object doesn't contain any elements.
     */
    public static toElement(element: string | Element | JQuery): Element | undefined {
        if (!element) {
            return undefined;
        }
        if (typeof element === "string") {
            return document.querySelector(element) || undefined;
        }
        if (element instanceof Element) {
            return element;
        }
        return element.length ? element[0] : undefined;
    }

    /**
     * Get DOM element
     * @param context DOM element as parent for search
     * @param selector DOM element string selector
     * @param logger Provide logger instance
     * @param throwError Do we need stop script execution?
     */
    public static getHTMLElement<T extends Element = HTMLElement>(
        context: Document | Element | null,
        selector: string,
        logger?: ILogger,
        throwError?: boolean
    ): T | null {
        if (!context) {
            return null;
        }
        const element = context.querySelector<T>(selector);
        if (!element) {
            const errorMessage = `Element [${selector}] not found!`;
            if (logger) {
                logger.error(errorMessage);
            }
            if (throwError) {
                throw new Error(errorMessage);
            }
        }
        return element;
    }

    /**
     * Get DOM elements and return array
     * @param context DOM element as parent for search
     * @param selector DOM element string selector
     * @param logger Provide logger instance
     * @param throwError Do we need stop script execution?
     */
    public static getHTMLElementsArray<T extends Element = HTMLElement>(
        context: Document | Element | null,
        selector: string,
        logger?: ILogger,
        throwError?: boolean
    ): T[] {
        if (!context) {
            return [];
        }
        const elementsArray = Array.prototype.slice.call(context.querySelectorAll(selector));
        if (!elementsArray.length) {
            const errorMessage = `Elements [${selector}] not found!`;
            if (logger) {
                logger.error(errorMessage);
            }
            if (throwError) {
                throw new Error(errorMessage);
            }
        }
        return elementsArray;
    }
    /**
     * Enables element by switching the disabled property off.
     * @param element Element to enable.
     */
    public static enable(element: string | Element | JQuery): void {
        Utils.setProp(element, Utils.DISABLED, false);
    }

    /**
     * Disables element by switching the disabled property on.
     * @param element Element to disable.
     */
    public static disable(element: string | Element | JQuery): void {
        Utils.setProp(element, Utils.DISABLED, true);
    }

    /**
     * Simple hash from Java world https://stackoverflow.com/a/33647870
     * @param value String to hash
     */
    public static javaHash(value: string): number {
        const positiveInt = 2147483647;
        let hash = 0;
        let i;
        let chr;
        if (value.length === 0) {
            return hash;
        }
        for (i = 0; i < value.length; i++) {
            chr = value.charCodeAt(i);
            // eslint-disable-next-line
            hash = (hash << 5) - hash + chr;
            // eslint-disable-next-line
            hash = hash & hash; // Convert to 32bit integer
            hash = hash + positiveInt + 1; // only positive values
        }
        return hash;
    }

    /**
     * Trim element field validation.
     * @param element Element to trim.
     */
    public static trimElement(element: Element | JQuery | string) {
        const value = element && Utils.getValue(element);
        if (typeof value !== "string" && typeof value === "number") {
            return;
        }
        Utils.setValue(element, String(value).trim());
    }

    /**
     * Runs function not often than every x sec
     * @param fn Function to be thorttled
     * @param delay Delay between function runs.
     * Example:
     *   const _doSomethingThrottled = Utils.throttle(() => this._doSomething(args), 200) as EventListener;
     *   window.addEventListener("resize", _doSomethingThrottled);
     *   window.removeEventListener("resize", _doSomethingThrottled);
     */
    public static throttle(fn: () => void, delay: number): void | EventListener {
        return justThrottle(fn, delay);
    }
}
