import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Utils } from "Helpers/Scripts/Utils";
import { DocumentItemComponent } from "Documents/Scripts/DocumentItemComponent";
import { Div } from "Ui/Scripts/Div";
import { DocumentItem } from "Documents/Scripts/DocumentItem";
import { Hidden } from "Ui/Scripts/Hidden";
import { Span } from "Ui/Scripts/Span";

export class DocumentComponentView extends UiComponent {
    public uploader: kendo.ui.Upload;
    public validationMaxSize: number;
    public validationMaxSizeMessage: string;
    public documentItems: DocumentItemComponent[] = [];
    public submitButton: Element;
    public documentTypeId: number;

    private _documentPopup: Div;
    private _documentSection: Div;
    private _documentApprovedSection: Div;
    private _documentExpirationSection: Div;
    private _documentExpirationApprovedSection: Div;
    private _thumbnailUrl: string;
    private _imageUrl: string;
    private _downloadUrl: string;
    private _approvalStatePending: Span;
    private _approvalStateApproved: Span;
    private _documentIdsInput: Hidden;
    private _itemTemplate: (data: DocumentItem) => string;
    private _expirationDropDownDatePicker: kendo.ui.DropDownDatePicker | undefined;

    private _documentIdParamName: string = "js-replace-id";
    private _documentItemSelector: string = ".js-document-item";
    private _loadingClass: string = "loading";

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    protected init(): void {
        this._downloadUrl = this.getData("url-download");
        this._thumbnailUrl = this.getData("url-thumbnail");
        this._imageUrl = this.getData("url-image");
        this.documentTypeId = this.getData("document-type-id");
        this.validationMaxSize = this.getData("validation-max-size");
        this.validationMaxSizeMessage = this.getData("validation-max-size-message");
        this._documentIdsInput = this.createComponent(Hidden, ".js-document-ids");
        this._documentSection = this.createComponent(Div, ".js-document-items");
        this._documentPopup = this.createComponent(Div, ".js-document-popup");
        this._documentApprovedSection = this.createComponent(Div, ".js-document-items-approved");

        const expirationClass: string = ".js-document-expiration";
        if (Utils.find(this._context, expirationClass).length > 0) {
            this._documentExpirationSection = this.createComponent(Div, expirationClass);

            const expirationInput: Element[] = this._documentExpirationSection.find(
                "input.js-document-expiration-input"
            );
            if (expirationInput && expirationInput.length > 0) {
                this._expirationDropDownDatePicker = kendo.getDropDownDatePicker(
                    expirationInput[0]
                );
            }
        }
        const expirationApprovedClass: string = ".js-document-expiration-approved";
        if (Utils.find(this._context, expirationApprovedClass).length > 0) {
            this._documentExpirationApprovedSection = this.createComponent(
                Div,
                expirationApprovedClass
            );
        }

        const approvalStatePendingSelector: string = ".js-document-approval-state-pending";
        if (Utils.find(this._context, approvalStatePendingSelector).length > 0) {
            this._approvalStatePending = this.createComponent(Span, approvalStatePendingSelector);
        }
        const approvalStateApprovedSelector: string = ".js-document-approval-state-approved";
        if (Utils.find(this._context, approvalStateApprovedSelector).length > 0) {
            this._approvalStateApproved = this.createComponent(Span, approvalStateApprovedSelector);
        }

        this.initTooltip();
        this.initValidationError();

        if (Utils.find(this._context, this._documentItemSelector).length > 0) {
            this.documentItems = this.createComponentArray(
                DocumentItemComponent,
                this._documentItemSelector
            );
        }

        const templateHtml: Element[] = Utils.find(this.context, ".js-document-item-template");
        if (templateHtml.length > 0) {
            this._itemTemplate = kendo.template(templateHtml[0].innerHTML, { useWithBlock: true });
        }

        const uploader: Element[] = Utils.find(this.context, ".js-document-input");
        if (uploader.length > 0) {
            this.uploader = kendo.createUpload(uploader[0]);
        }

        this.initSubmitButton();
    }

    private initSubmitButton(): void {
        const form: Element | undefined = this.closest("form");
        if (form) {
            const buttons: Element[] = Utils.find(form, ":submit");
            if (buttons.length > 0) {
                this.submitButton = buttons[0];
            }
        }
    }

    public toggleLoading(isLoading: boolean): void {
        this.toggleClass(this._loadingClass, isLoading);
    }

    public getDownloadUrl(documentId: string): string {
        return this._downloadUrl.replace(this._documentIdParamName, documentId);
    }

    public getThumbnailUrl(documentId: string): string {
        return this._thumbnailUrl.replace(this._documentIdParamName, documentId);
    }

    public getImagePopup(documentId: string): Element {
        const originalPopup: Div = this._documentPopup;

        const popup: Element = Utils.clone(originalPopup.context);
        const image: Element = Utils.find(popup, ".js-document-popup-image")[0];

        image.setAttribute("src", this.getImageUrl(documentId));
        Utils.toggleClass(popup, "hidden", false);

        return popup;
    }

    /**
     * Appends new item into html and initialize component
     */
    public appendItem(data: DocumentItem): DocumentItemComponent {
        const itemString: string = this.renderItem(data);

        this._documentSection.append(itemString);

        return this.initItem(data);
    }

    /**
     * Checks whether there is some non-approved item
     */
    public containsNewItems(): boolean {
        const newItems: string = this._documentSection.getHtml().trim();

        return Boolean(newItems);
    }

    /**
     * Removes item from html and children items
     */
    public removeItem(component: DocumentItemComponent): void {
        this.documentItems = this.documentItems.filter((item) => item.itemId !== component.itemId);
        component.context.remove();
    }

    public toggleApprovedItems(show: boolean): void {
        this._documentApprovedSection.toggle(show);
        if (this._documentExpirationSection && show && this._expirationDropDownDatePicker) {
            this._expirationDropDownDatePicker.value("");
        }
        if (this._documentExpirationApprovedSection) {
            this._documentExpirationApprovedSection.toggle(show);
        }

        if (this._approvalStateApproved) {
            const showApproved: boolean = show && this.documentItems.length > 0;
            this._approvalStateApproved.toggle(showApproved);
        }
        if (this._approvalStatePending) {
            const showPending: boolean = !show && this.documentItems.length > 0;
            this._approvalStatePending.toggle(showPending);
        }
    }

    public addDocumentId(data: DocumentItem): void {
        if (data.DocumentId) {
            const documentIds: string[] = this.getDocumentIds();
            documentIds.push(data.DocumentId);
            this._documentIdsInput.setValue(documentIds.toString());
        }
    }

    public removeDocumentId(component: DocumentItemComponent): void {
        let documentIds: string[] = this.getDocumentIds();
        documentIds = documentIds.filter((id) => id !== component.documentId);
        this._documentIdsInput.setValue(documentIds.toString());
    }

    public cancelUpload(component: DocumentItemComponent): void {
        if (this.uploader) {
            const selector: string = `ul.k-upload-files .k-file-progress[data-uid='${component.itemId}']`;
            const fileProgress: Element[] = Utils.find(this.uploader.wrapper, selector);
            if (fileProgress.length > 0) {
                const cancelButton: Element[] = Utils.find(fileProgress[0], ".k-i-cancel");
                if (cancelButton.length > 0) {
                    (cancelButton[0] as HTMLButtonElement).click();
                }
            }
        }
    }

    private getImageUrl(documentId: string): string {
        return this._imageUrl.replace(this._documentIdParamName, documentId);
    }

    private renderItem(data: DocumentItem): string {
        // eslint-disable-next-line
        return kendo.render(this._itemTemplate, [data]);
    }

    private initItem(data: DocumentItem): DocumentItemComponent {
        const newItemSelector: string = `${this._documentItemSelector}[data-item-id='${data.ItemId}']:last`;
        const newItem: DocumentItemComponent = this.createComponent(
            DocumentItemComponent,
            newItemSelector
        );

        this.documentItems.push(newItem);

        return newItem;
    }

    private getDocumentIds(): string[] {
        const stringValue = this._documentIdsInput.getValue();

        return stringValue ? stringValue.split(",") : [];
    }

    private initValidationError(): void {
        const tooltipSelector: string = ".js-document-error";
        if (this.find(tooltipSelector).length > 0) {
            const tooltipMessage: Span = this.createComponent(Span, tooltipSelector);
            const dodumentWrapper: Div = this.createComponent(Div, ".js-document-wrapper");

            if (tooltipMessage && tooltipMessage.find(".field-validation-error").length) {
                const message: string = tooltipMessage
                    .find("span")
                    .map((error) => error.textContent)
                    .join("<br>");
                if (message) {
                    kendo.applyTooltip(tooltipMessage.context, { content: message });
                    tooltipMessage.removeClass(this.CLASS_HIDDEN);
                    dodumentWrapper.addClass("document-upload__wrapper--error");
                }
            }
        }
    }

    private initTooltip(): void {
        const tooltipSelector: string = ".js-document-tooltip";
        if (this.find(tooltipSelector).length > 0) {
            const tooltipMessage: Span = this.createComponent(Span, tooltipSelector);

            if (tooltipMessage) {
                const message: string = tooltipMessage.getData("tooltip-message");
                if (message) {
                    kendo.applyTooltip(tooltipMessage.context, { content: message });
                }
            }
        }
    }
}
