import { Container, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { ILogger } from "./ILogger";
import { Logger } from "./Logger";
import { IList } from "Helpers/Scripts/IList";

@OnlyInstantiableByContainer
@Singleton
export class LoggerFactory {
    private _loggers: IList<ILogger> = {};

    /**
     * Creates a new logger instance or returns existing one for a given key.
     * Key represents a particular "logged" object (class), and we assume usage
     * of one logger instance with same settings for 1 or more class instances of same type.
     * @param key Logger key.
     */
    public getLogger(key: string): ILogger {
        if (!this._loggers[key]) {
            const logger = Container.get(Logger);
            logger.key = key;
            this._loggers[key] = logger;
        }

        return this._loggers[key];
    }
}
