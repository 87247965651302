export class FetchServiceResponseError extends Error {
    response: Response;
    constructor(response: Response) {
        super(`${response.status} ${response.statusText}`);

        // this have to stay first after super
        // it is needed to restore prototype chain
        // require TypeScript 2.2 or greater
        const actualProto = new.target.prototype;
        Object.setPrototypeOf(this, actualProto);

        this.name = "FetchServiceResponseError";
        this.response = response;
    }
}
