import { Utils } from "Helpers/Scripts/Utils";

/**
 * Provides operations with submit button from multiple related components
 */
export class SubmitButtonManager {
    private _button: Element;
    private _disabledDataName: string = "disabled-for";

    constructor(button: Element) {
        this._button = button;
    }

    /**
     * disables submit button and stores invoking sender
     */
    public disableFor(sender: any): void {
        Utils.disable(this._button);

        const disabledFor: any[] = Utils.getData(this._button, this._disabledDataName) || [];
        disabledFor.push(sender);
        Utils.setData(this._button, this._disabledDataName, disabledFor);
    }

    /**
     * removes sender and enables button if it was the last one
     */
    public enableFor(sender: any): void {
        let disabledFor: any[] = Utils.getData(this._button, this._disabledDataName) || [];
        disabledFor = disabledFor.filter((id) => id !== sender);
        Utils.setData(this._button, this._disabledDataName, disabledFor);

        if (disabledFor.length === 0) {
            Utils.enable(this._button);
        }
    }
}
