import { autobind } from "core-decorators";
import { Inject } from "typescript-ioc";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { CookieService } from "Cookies/Scripts/CookieService";
import { dispatchClearCacheEvent, scheduleCacheCleanup } from "Shopping/Scripts/utils";

export class LangPopupSwitcher extends UiComponent {
    public get key(): string {
        return "LangPopupSwitcher";
    }

    private readonly _cookieService: CookieService;
    private readonly SEL_LANGSWITCHER_LINK: string = ".langswitcher-link";
    private readonly MIN_LANGUAGES_COUNT: number = 2;
    private readonly COOKIE_EXPIRES_IN: number = 365;
    private readonly DATA_POPUP_LANGUAGE_COOKIE: string = "popupLanguageCookie";
    private readonly DATA_LANGUAGE_COOKIE: string = "languageCookie";
    private _popupLangWindow: kendo.ui.Window;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject cookieService: CookieService
    ) {
        super(componentFactory, binder, loggerFactory);
        this._cookieService = cookieService;
    }

    public init(): void {
        this._cookieService.setRaw(true);
        this._initLangPopupSwitcher();
    }

    private _initLangPopupSwitcher(): void {
        const links = this.find(this.SEL_LANGSWITCHER_LINK);
        if (links.length < this.MIN_LANGUAGES_COUNT) {
            return;
        }

        links.forEach((link) => {
            link.addEventListener("click", this._onLangClick);
        });

        const popupLanguageCookie = this.getData(this.DATA_POPUP_LANGUAGE_COOKIE);
        const popupLangFromCookie = this._cookieService.getCookie(popupLanguageCookie);
        if (popupLangFromCookie === "undefined" || popupLangFromCookie === "") {
            this._popupLangWindow = kendo
                .createWindow(this.context, {
                    draggable: false,
                    modal: true,
                    resizable: false,
                    title: false,
                    visible: false,
                })
                .open();
        }
    }

    @autobind
    _onLangClick(event: Event): void {
        const el = event.target as HTMLElement;

        const languageCookie = this.getData(this.DATA_LANGUAGE_COOKIE);
        const popupLanguageCookie = this.getData(this.DATA_POPUP_LANGUAGE_COOKIE);
        const clickedLangflag = el.dataset.languageCode;
        const reloadPage = this._cookieService.getCookie(languageCookie) !== clickedLangflag;

        this._cookieService.setCookie(languageCookie, clickedLangflag, this.COOKIE_EXPIRES_IN);
        this._cookieService.setCookie(popupLanguageCookie, clickedLangflag, this.COOKIE_EXPIRES_IN);

        this._popupLangWindow.close();

        if (reloadPage) {
            scheduleCacheCleanup();
            window.location.reload();
        } else {
            dispatchClearCacheEvent();
        }
    }
}
