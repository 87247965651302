import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { ISliderConfiguration } from "./ISliderConfiguration";
import { Slider } from "./Slider";
import { Utils } from "Helpers/Scripts/Utils";

declare global {
    interface IOri {
        ytplayer?: {
            init: (scope?: Element) => void;
        };
    }
}

@OnlyInstantiableByContainer
@Singleton
export class SliderFactory {
    public get key(): string {
        return "SliderFactory";
    }

    public readonly SLIDER: string = ".js-slider";

    private _uiComponentFactory: UiComponentFactory;
    protected readonly _logger: ILogger;

    constructor(
        @Inject uiComponentFactory: UiComponentFactory,
        @Inject loggerFactory: LoggerFactory
    ) {
        this._logger = loggerFactory.getLogger(this.key);
        this._uiComponentFactory = uiComponentFactory;
    }

    /**
     * Creates new Slider instance based on selector.
     * @param selector Selector string which will be the slider context.
     * @returns New instance of Slider.
     */
    public create(
        selector: Element | string,
        configOverwrite?: ISliderConfiguration
    ): Slider | null {
        if (!selector) {
            this._logger.error("No selector or element provided");

            return null;
        }

        return this._uiComponentFactory.createBase(Slider, selector, configOverwrite);
    }

    /**
     * Creates Slider instances automatically if there are some elements which match SLIDER selector in context
     * @param context Elemntent or selector string for context in which method will search for sliders.
     *                If no context passed, than document body will be the context.
     */
    public createAll(context: Element | string = "body"): void {
        const sliders = Utils.find(context, this.SLIDER);

        sliders.forEach((sliderRoot) => {
            const slider = this.create(sliderRoot);

            // hook our yt utility into slider, so we can reactivate placeholders when slider create DOM element
            if (window.ori.ytplayer !== null && slider?.rsInstance !== null) {
                slider?.rsInstance.ev.on("rsAfterContentSet", (_, slide) => {
                    window.ori.ytplayer?.init(slide.content);
                });
            }
        });
    }
}
