import { DebugOutputType } from "./DebugOutputType";
import { ILogAppender } from "./ILogAppender";

export class NullLogAppender implements ILogAppender {
    public readonly outputType: DebugOutputType = DebugOutputType.None;

    public error(): this {
        return this;
    }

    public warning(): this {
        return this;
    }

    public info(): this {
        return this;
    }

    public log(): this {
        return this;
    }
}
