/*
--------------------------------------------------------------------------------
Oriflame Global API data receiver functionality
Used to send requests to GlobalApi gateway by url
--------------------------------------------------------------------------------
*/
import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { Utils } from "Helpers/Scripts/Utils";
import {
    IGlobalApiOptions,
    IGlobalLoadData,
    ITokenData,
    IApiSettings,
    IApiCache,
    IGlobalLoadBlob,
} from "./IGlobalApiModule";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { TokenService } from "Token/Scripts/TokenService";
import { GlobalApiStorageService } from "./GlobalApiStorageService";

@OnlyInstantiableByContainer
@Singleton
export class GlobalApi {
    public get key(): string {
        return "GlobalApi";
    }

    private static readonly GLOBAL_API_KEY: string = "global-api";
    private static readonly MILISECONDS_IN_SECOND: number = 1000;
    private _tenant: string;
    private _tokenService: TokenService;
    private _fetchCachingPromise?: Promise<any>;

    protected readonly _logger: ILogger;
    protected readonly _storage: GlobalApiStorageService;

    constructor(
        @Inject loggerFactory: LoggerFactory,
        @Inject tokenService: TokenService,
        @Inject storageService: GlobalApiStorageService
    ) {
        this._logger = loggerFactory.getLogger(this.key);
        this._storage = storageService;
        this._tokenService = tokenService;
    }

    public init(): this {
        // options setup also (re)connects default event handlers
        const optionsElement = document.getElementById("global-api-options");

        if (!optionsElement) {
            const errorMessage = "Element containing API options was not found.";
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        const options = Utils.getOptions(GlobalApi.GLOBAL_API_KEY, optionsElement);
        if (!options) {
            return this;
        }

        this._setOptions(options);

        return this;
    }

    /**
     * Sets up current instance's options and (re)connects event handlers accordingly.
     */
    private _setOptions({ tenant = "" }: IGlobalApiOptions): this {
        this._tenant = tenant;
        return this;
    }

    /**
     * load cache setting from url or cache
     */
    private async _fetchApiCacheConfig(url: string): Promise<any> {
        const urlKey = this._storage.generateApiStorageKey(url);
        const storedData = this._storage.getStoredData(urlKey);
        if (storedData) {
            return storedData;
        }

        const requestOptions = await this.getRequestOptions();
        const request = new Request(url, requestOptions);
        return fetch(request)
            .then(this._handleResponse)
            .then((jsonData: any) => {
                this._storage.setStorageData(urlKey, jsonData);
                return jsonData;
            })
            .catch((error) => this._logAjaxError(url, error));
    }

    /**
     * verifies if url is cacheable
     */
    private _isCacheable(url: string, cacheConfig?: any, useCachedValues?: boolean): boolean {
        if (useCachedValues) {
            return true;
        }

        if (cacheConfig) {
            const apiRecord: IApiSettings = cacheConfig.find((item: IApiSettings) =>
                this._compareUrls(item, url)
            );
            if (apiRecord && apiRecord.IsEnabledStoreInBrowserCache) {
                return true;
            }
        }

        return false;
    }

    /**
     * gets cache item from storage for url
     */
    private _loadCacheForUrl(url: string): IApiCache | undefined {
        const urlKey = this._storage.generateApiStorageKey(url);
        const cache = this._storage.getStoredData(urlKey);
        if (cache) {
            return cache as IApiCache;
        }
        return;
    }

    /**
     * compares url with url from API cache settings
     * on localhost, uses fake API
     */
    private _compareUrls(settings: IApiSettings, url: string): boolean {
        if (location.hostname === "www.local.oriflame.cc") {
            const [fakeUrl]: string[] = url.split(/[?#]/);
            return settings.FakeUrl === fakeUrl;
        }

        const settingsUrlArray = settings.EndpointUrl.split("/");
        const currentUrlArray = url.split("/");

        if (settingsUrlArray.length !== currentUrlArray.length) {
            return false;
        }

        return settingsUrlArray.every((item, index) => {
            if (item.startsWith("{")) {
                return true;
            }
            return currentUrlArray[index] === item;
        });
    }

    /**
     * returns cached data - if cache is founnd and valid
     */
    private _loadFromCache(
        url: string,
        cacheConfig?: any,
        useCachedValues?: boolean
    ): IApiCache | undefined {
        if (useCachedValues) {
            return this._loadCacheForUrl(url);
        }

        if (cacheConfig) {
            const apiRecord: IApiSettings = cacheConfig.find((item: IApiSettings) =>
                this._compareUrls(item, url)
            );
            if (apiRecord && apiRecord.IsEnabledStoreInBrowserCache) {
                const cache = this._loadCacheForUrl(url);
                if (!cache) {
                    return;
                }
                if (!apiRecord.TimeToLive) {
                    return cache;
                }
                const now = Date.now();
                if (
                    (now - cache.created) / GlobalApi.MILISECONDS_IN_SECOND <=
                    apiRecord.TimeToLive
                ) {
                    return cache;
                }
            }
        }

        return;
    }

    /**
     * Load data (from API or from browser cache)
     */
    public async loadData({
        cacheSettingsUrl,
        url,
        onError,
        onSuccess,
        useCachedValues,
        customHeaders,
        usePublicToken,
        suppressErrorLog,
    }: IGlobalLoadData): Promise<any> {
        let cacheConfig;
        if (cacheSettingsUrl) {
            if (!this._fetchCachingPromise) {
                this._fetchCachingPromise = this._fetchApiCacheConfig(cacheSettingsUrl);
            }
            cacheConfig = await this._fetchCachingPromise;
        }

        const cachedData = this._loadFromCache(url, cacheConfig, useCachedValues);
        if (cachedData) {
            return new Promise<any>((resolve) => {
                this._logger.info(this._formatLogMessage(url, "loaded data from cache"));
                resolve(cachedData.cache);
            }).then(onSuccess);
        }

        const asyncCacheConfig = cacheConfig;
        const urlKey = this._storage.generateApiStorageKey(url);

        const requestOptions = await this.getRequestOptions(customHeaders, usePublicToken);
        const request = new Request(url, requestOptions);

        return fetch(request)
            .then(this._handleResponse)
            .then((jsonData: any) => {
                const isCacheabble = this._isCacheable(url, asyncCacheConfig, useCachedValues);
                if (isCacheabble) {
                    this._storage.setStorageData(urlKey, {
                        cache: jsonData,
                        created: Date.now(),
                    } as IApiCache);
                }
                this._logger.info(this._formatLogMessage(url, "loaded data via AJAX"));
                return jsonData;
            })
            .then(onSuccess)
            .catch((error) => this._logAjaxError(url, error, suppressErrorLog))
            .catch(onError);
    }

    /**
     * Load and returns blob and filename
     */
    public async loadBlob({
        url,
        onError,
        onSuccess,
        customHeaders,
        usePublicToken,
        suppressErrorLog,
    }: IGlobalLoadBlob): Promise<any> {
        const requestOptions = await this.getRequestOptions(customHeaders, usePublicToken);
        const request = new Request(url, requestOptions);

        let filename = "";
        return fetch(request)
            .then(async (response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                this._logAjaxSuccess(response.url);

                filename = this._getFilenameFromResponse(response);

                return await response.blob();
            })
            .then((blob) => onSuccess(blob, filename))
            .catch((error) => this._logAjaxError(url, error, suppressErrorLog))
            .catch(onError);
    }

    // helper functions

    /**
     * Process response from `fetch()`, returning reply as JSON
     */
    private _handleResponse = async (response: Response): Promise<any> => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        this._logAjaxSuccess(response.url);
        return response.json();
    };

    /**
     * Process response from `fetch()`, returning reply as JSON
     */
    private _getFilenameFromResponse = (response: Response): string => {
        let filename = "";

        const header = response.headers.get("Content-Disposition");
        if (header && header.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(header);
            if (matches !== null && matches[1]) {
                filename = matches[1].replace(/['"]/g, "");
            }
        }

        return filename;
    };

    /**
     * Create request options for `fetch()`
     */
    public async getRequestOptions(
        headers?: HeadersInit,
        usePublicToken: boolean = false
    ): Promise<RequestInit> {
        const tokenFunc = usePublicToken
            ? this._tokenService.getPublicToken()
            : this._tokenService.getToken();
        const token = await tokenFunc.then((tokenData: ITokenData) =>
            tokenData ? tokenData.Token : ""
        );

        const options = {
            headers: {
                ...headers,
                Authorization: token,
                "x-tenant-context": this._tenant,
            },
            mode: "cors",
        } as RequestInit;

        return options;
    }

    private _logAjaxSuccess(url: string): void {
        this._logger.info(this._formatLogMessage(url, "request call is successfull"));
    }

    private _logAjaxError(url: string, error: any, suppressErrorLog?: boolean): void {
        if (!suppressErrorLog) {
            this._logger.error(this._formatLogMessage(url, `data load failed. Error: ${error}`));
        }
        throw new Error(error);
    }

    private _formatLogMessage(url: string, message: string): string {
        return `Global API [${url}]: ${message}`;
    }

    // end of helper functions

    // expose for logout.ts
    public clearStorageItems(): void {
        this._storage.clearStorageItems();
    }
}
