import { GenericEventHandler } from "./GenericEventHandler";
import $ from "jquery";

/**
 * Class for binding native DOM and custom events on provided context objects.
 * TODO: uses jQuery.Event currently, refactoring to native Event and CustomEvent objects later.
 */
export class EventBinder {
    public get key(): string {
        return "EventBinder";
    }

    private _context: Object;
    private _$context: JQuery<unknown>;

    public init(context: Object): void {
        this._context = context;
        this._$context = $(this._context);
    }

    protected updateNs(namespace?: string): string {
        return (namespace || "").trim().replace(/^(\.)/, "");
    }

    /**
     * Binds generic event handler of a given type on the context element.
     * @param eventType Textual type of the event as used in addEventListener() or jQuery.on().
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    private bind(
        eventType: string,
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        const bindMethod = one ? "one" : "on";
        const eventName = this.formatEventName(eventType, namespace);
        (this._$context as any)[bindMethod](eventName, handler);

        return this;
    }

    /**
     * Binds generic delegated event handler of a given type with the context
     * as a delegating wrapper.
     * @param childSelector Selector filter for child elements to which this handler should apply.
     * @param eventType Textual type of the event as used in addEventListener() or jQuery.on().
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    private bindDelegated(
        childSelector: string,
        eventType: string,
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        const bindMethod = one ? "one" : "on";
        const eventName = this.formatEventName(eventType, namespace);
        (this._$context as any)[bindMethod](eventName, childSelector, handler);

        return this;
    }

    /**
     * Unbinds generic event handler of a given type from the context element.
     * @param eventType Textual type of the event as used in addEventListener() or jQuery.on().
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    private unbind(eventType: string, handler?: GenericEventHandler, namespace?: string): this {
        const eventName = this.formatEventName(eventType, namespace);
        if (handler) {
            this._$context.off(eventName, handler);
        } else {
            this._$context.off(eventName);
        }

        return this;
    }

    /**
     * Unbinds generic delegated event handler of a given type from the context delegating wrapper.
     * @param childSelector Selector filter for child elements from which this handler should be removed.
     * @param eventType Textual type of the event as used in addEventListener() or jQuery.on().
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     */
    private unbindDelegated(
        childSelector: string,
        eventType: string,
        handler?: GenericEventHandler,
        namespace?: string
    ): this {
        const eventName = this.formatEventName(eventType, namespace);
        this._$context.off(eventName, childSelector, handler ?? false);

        return this;
    }

    private formatEventName(eventType: string, namespace?: string): string {
        const ns = this.updateNs(namespace);

        return eventType + (ns ? `.${ns}` : "");
    }

    // #region standard DOM events

    /**
     * Binds before unload event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindBeforeUnload(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("beforeunload", handler, namespace, one);
    }

    /**
     * Binds blur event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindBlur(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("blur", handler, namespace, one);
    }

    /**
     * Binds change event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindChange(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("change", handler, namespace, one);
    }

    /**
     * Binds click event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindClick(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("click", handler, namespace, one);
    }

    /**
     * Binds context menu event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindContextMenu(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("contextMenu", handler, namespace, one);
    }

    /**
     * Binds double click event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindDblClick(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("dblclick", handler, namespace, one);
    }

    /**
     * Binds error event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindError(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("error", handler, namespace, one);
    }

    /**
     * Binds focus event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindFocus(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("focus", handler, namespace, one);
    }

    /**
     * Binds focus in event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindFocusIn(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("focusin", handler, namespace, one);
    }

    /**
     * Binds focus out event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindFocusOut(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("focusout", handler, namespace, one);
    }

    /**
     * Binds key down event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindKeyDown(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("keydown", handler, namespace, one);
    }

    /**
     * Binds key press event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindKeyPress(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("keypress", handler, namespace, one);
    }

    /**
     * Binds key up event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindKeyUp(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("keyup", handler, namespace, one);
    }

    /**
     * Binds load event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindLoad(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("load", handler, namespace, one);
    }

    /**
     * Binds mouse down handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindMouseDown(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("mousedown", handler, namespace, one);
    }

    /**
     * Binds mouse enter handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindMouseEnter(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("mouseenter", handler, namespace, one);
    }

    /**
     * Binds mouse leave handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindMouseLeave(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("mouseleave", handler, namespace, one);
    }

    /**
     * Binds mouse move handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindMouseMove(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("mousemove", handler, namespace, one);
    }

    /**
     * Binds mouse out handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindMouseOut(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("mouseout", handler, namespace, one);
    }

    /**
     * Binds mouse over handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindMouseOver(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("mouseover", handler, namespace, one);
    }

    /**
     * Binds mouse up handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindMouseUp(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("mouseup", handler, namespace, one);
    }

    /**
     * Binds orientation change handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindOrientationChange(
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        return this.bind("orientationchange", handler, namespace, one);
    }

    /**
     * Binds resize handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindResize(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("resize", handler, namespace, one);
    }

    /**
     * Binds scroll handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindScroll(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("scroll", handler, namespace, one);
    }

    /**
     * Binds select handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindSelect(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("select", handler, namespace, one);
    }

    /**
     * Binds submit handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindSubmit(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("submit", handler, namespace, one);
    }

    /**
     * Binds touch end handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindTouchEnd(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("touchend", handler, namespace, one);
    }

    /**
     * Binds touch move handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindTouchMove(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("touchmove", handler, namespace, one);
    }

    /**
     * Binds touch start handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindTouchStart(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("touchstart", handler, namespace, one);
    }

    /**
     * Binds CSS transition end handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindTransitionEnd(
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        return this.bind("transitionend", handler, namespace, one);
    }

    /**
     * Binds unload handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindUnload(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        return this.bind("unload", handler, namespace, one);
    }

    // #endregion standard DOM events

    // #region standard DOM events delegation

    /**
     * Binds click event delegating handler. Context is used as delegating wrapper.
     * Multiple calls will bind multiple event handlers which will be executed in the order they were added.
     * @param childSelector Selector filter for child elements to which this handler should apply.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindDelegatedClick(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        return this.bindDelegated(childSelector, "click", handler, namespace, one);
    }

    /**
     * Binds change event delegating handler. Context is used as delegating wrapper.
     * Multiple calls will bind multiple event handlers which will be executed in the order they were added.
     * @param childSelector Selector filter for child elements to which this handler should apply.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindDelegatedChange(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        return this.bindDelegated(childSelector, "change", handler, namespace, one);
    }

    /**
     * Binds mouse leave event delegating handler. Context is used as delegating wrapper.
     * Multiple calls will bind multiple event handlers which will be executed in the order they were added.
     * @param childSelector Selector filter for child elements to which this handler should apply.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindDelegatedMouseLeave(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        return this.bindDelegated(childSelector, "mouseleave", handler, namespace, one);
    }

    /**
     * Binds mouse enter event delegating handler. Context is used as delegating wrapper.
     * Multiple calls will bind multiple event handlers which will be executed in the order they were added.
     * @param childSelector Selector filter for child elements to which this handler should apply.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindDelegatedMouseEnter(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        return this.bindDelegated(childSelector, "mouseenter", handler, namespace, one);
    }

    // #endregion standard DOM events delegation

    // #region custom events

    /**
     * Binds generic custom event handler of a given type on the context element.
     * This should not be used to bind native DOM events, and should be wrapped with
     * other more specific wrapper method for binding a specific custom event type.
     * @param eventType Textual type of the event as used in addEventListener() or jQuery.on().
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindCustomEvent(
        eventType: string,
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        return this.bind(eventType, handler, namespace, one);
    }

    /**
     * Disconnects generic custom event handler of a given type on the context element.
     * This should not be used to unbind native DOM events, and should be wrapped with
     * other more specific wrapper method for unbinding a specific custom event type.
     * @param eventType Textual type of the event as used in addEventListener() or jQuery.on().
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param handler Event handler method reference.
     */
    public unbindCustomEvent(
        eventType: string,
        namespace?: string,
        handler?: GenericEventHandler
    ): this {
        return this.unbind(eventType, handler, namespace);
    }

    /**
     * Triggers custom event. If only event type (string) is provided as the first parameter,
     * a corresponding event object will be created first.
     * TODO: uses jQuery - later this should be refactored probably to CustomEvent instead of $.Event.
     * TODO: namespacing the event needed? or the string is enough?
     * @param event Event type (string) or complete event object.
     * @param eventData Optional additional data for the event.
     * This data will become part of the may vary with each triggering of the event.
     * The eventData is used only when first parameter is a string.
     */
    public trigger(event: JQuery.Event | string, eventData?: any[] | Object): void {
        if (!event) {
            return;
        }

        let currentEvent: JQuery.Event;
        if (typeof event === "string") {
            const eventName = this.formatEventName(event);

            currentEvent = new $.Event(
                eventName,
                $.extend(true, {}, eventData, { type: eventName })
            );
        } else {
            currentEvent = event;
        }

        this._$context.trigger(currentEvent);
    }

    // #endregion custom events

    // #region disconnecting events

    /**
     * Unbinds before unload event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindBeforeUnload(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("beforeunload", handler, namespace);
    }

    /**
     * Unbinds blur event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindBlur(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("blur", handler, namespace);
    }

    /**
     * Unbinds change event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindChange(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("change", handler, namespace);
    }

    /**
     * Unbinds click event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindClick(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("click", handler, namespace);
    }

    /**
     * Unbinds context menu event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindContextMenu(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("contextmenu", handler, namespace);
    }

    /**
     * Unbinds double click event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindDblClick(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("dblclick", handler, namespace);
    }

    /**
     * Unbinds error event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindError(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("error", handler, namespace);
    }

    /**
     * Unbinds focus event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindFocus(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("focus", handler, namespace);
    }

    /**
     * Unbinds focus in event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindFocusIn(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("focusin", handler, namespace);
    }

    /**
     * Unbinds focus out event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindFocusOut(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("focusout", handler, namespace);
    }

    /**
     * Unbinds key down event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindKeyDown(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("keydown", handler, namespace);
    }

    /**
     * Unbinds key press event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindKeyPress(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("keypress", handler, namespace);
    }

    /**
     * Unbinds key up event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindKeyUp(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("keyup", handler, namespace);
    }

    /**
     * Unbinds load event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindLoad(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("load", handler, namespace);
    }

    /**
     * Unbinds mouse down event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindMouseDown(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("mousedown", handler, namespace);
    }

    /**
     * Unbinds mouse enter event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindMouseEnter(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("mouseenter", handler, namespace);
    }

    /**
     * Unbinds mouse leave event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindMouseLeave(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("mouseleave", handler, namespace);
    }

    /**
     * Unbinds mouse move event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindMouseMove(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("mousemove", handler, namespace);
    }

    /**
     * Unbinds mouse out event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindMouseOut(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("mouseout", handler, namespace);
    }

    /**
     * Unbinds mouse over event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindMouseOver(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("mouseover", handler, namespace);
    }

    /**
     * Unbinds mouse up event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindMouseUp(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("mouseup", handler, namespace);
    }

    /**
     * Unbinds orientation change event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindOrientationChange(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("orientationchange", handler, namespace);
    }

    /**
     * Unbinds resize event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindResize(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("resize", handler, namespace);
    }

    /**
     * Unbinds scroll event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindScroll(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("scroll", handler, namespace);
    }

    /**
     * Unbinds select event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindSelect(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("select", handler, namespace);
    }

    /**
     * Unbinds submit event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindSubmit(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("submit", handler, namespace);
    }

    /**
     * Unbinds touch end event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindTouchEnd(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("touchend", handler, namespace);
    }

    /**
     * Unbinds touch move event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindTouchMove(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("touchmove", handler, namespace);
    }

    /**
     * Unbinds touch start event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindTouchStart(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("touchstart", handler, namespace);
    }

    /**
     * Unbinds CSS transition end event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindTransitionEnd(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("transitionend", handler, namespace);
    }

    /**
     * Unbinds unload event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindUnload(namespace?: string, handler?: GenericEventHandler): this {
        return this.unbind("unload", handler, namespace);
    }

    // #endregion disconnecting events

    // #region disconnecting delegated events

    /**
     * Unbinds click event delegating handler.
     * @param childSelector Selector filter for child elements to which this handler was applied.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     */
    public unbindDelegatedClick(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string
    ): this {
        return this.unbindDelegated(childSelector, "click", handler, namespace);
    }

    /**
     * Unbinds change event delegating handler.
     * @param childSelector Selector filter for child elements to which this handler was applied.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     */
    public unbindDelegatedChange(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string
    ): this {
        return this.unbindDelegated(childSelector, "change", handler, namespace);
    }

    /**
     * Unbinds mouse leave event delegating handler.
     * @param childSelector Selector filter for child elements to which this handler was applied.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     */
    public unbindDelegatedMouseLeave(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string
    ): this {
        return this.unbindDelegated(childSelector, "mouseleave", handler, namespace);
    }

    /**
     * Unbinds mouse enter event delegating handler.
     * @param childSelector Selector filter for child elements to which this handler was applied.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     */
    public unbindDelegatedMouseEnter(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string
    ): this {
        return this.unbindDelegated(childSelector, "mouseenter", handler, namespace);
    }

    // #endregion disconnecting delegated events
}
