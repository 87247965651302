import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { GlobalApi } from "GlobalApi/Scripts/GlobalApi";
import { EditorInputAutoComplete } from "ECommerce/Shared/Scripts/EditorInputAutoComplete";
import { EditorInputFactory } from "ECommerce/Shared/Scripts/EditorInputFactory";
import { autobind } from "core-decorators";
import { IAddressSuggestionsDto } from "./AddressLookup/IAddressSuggestionsDto";
import { IAddressSuggestionDto } from "ECommerce/Shared/Scripts/AddressLookup/IAddressSuggestionDto";
import { IAddressNodeDto } from "ECommerce/Shared/Scripts/AddressLookup/IAddressNodeDto";
import { EditorInputHidden } from "ECommerce/Shared/Scripts/EditorInputHidden";
import { Span } from "Ui/Scripts/Span";
import { AddressCountrySelect } from "./AddressCountrySelect";
import { Utils } from "Helpers/Scripts/Utils";

import "../Styles/address-editor.css";

export class AddressLookupEditor extends UiComponent {
    private readonly _onlineOriginHeader: string = "OnlineForm";
    private readonly _editorInputFactory: EditorInputFactory;
    private readonly _globalApi: GlobalApi;

    private _errorContainer: Span;

    private _countrySelect: AddressCountrySelect;
    private _autoCompleteInput: EditorInputAutoComplete;

    private _streetInput: EditorInputHidden;
    private _cityInput: EditorInputHidden;
    private _zipCodeInput: EditorInputHidden;
    private _deliveryInput: EditorInputHidden;
    private _areaInput: EditorInputHidden;
    private _districtInput: EditorInputHidden;
    private _provinceInput: EditorInputHidden;
    private _regionInput: EditorInputHidden;
    private _localityInput: EditorInputHidden;

    private _linkedFieldInputs: EditorInputHidden[];

    private _maxSearchResults: number = 10;
    private _useMockResponse: boolean = true;
    private _gwApi: string;

    private readonly _minSearchLength: number = 2;
    private readonly _searchTimerDelay: number = 300;
    private _searchTimer: number = 0;

    // do not close autocomplete on non-final address to provide selection journey
    private _isPathSelected: boolean = false;

    // prevent overriding the most recent search by some previous slower request
    private _lastSearchedPath: string = "";

    // prevent double click on item selection
    private _isItemClicked: boolean = false;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject editorInputFactory: EditorInputFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject globalApi: GlobalApi
    ) {
        super(componentFactory, binder, loggerFactory);

        this._globalApi = globalApi;
        this._editorInputFactory = editorInputFactory;
    }

    public init(): void {
        const autoCompleteClass: string = "input.js-lookup-autocomplete";
        if (Utils.find(this._context, autoCompleteClass).length > 0) {
            this._autoCompleteInput = this._editorInputFactory.create(
                this.context,
                EditorInputAutoComplete,
                autoCompleteClass
            );

            this._autoCompleteInput.component.bindFiltering(this.onAutoCompleteFiltering);
            this._autoCompleteInput.component.bindSelect(this.onAutoCompleteSelect);
            this._autoCompleteInput.component.bindClose(this.onAutoCompleteClose);
            this._autoCompleteInput.component.element.bindFocus(this.onAutoCompleteFocus);
            // fix display of saved addresses for chrome
            this._autoCompleteInput.component.element.attr("autocomplete", "no-fill");
            this._autoCompleteInput.preventPageScroll();
            this._autoCompleteInput.component.options.ignoreCase = false;

            this._autoCompleteInput.component.options.delay = 0;
        }

        const errorContainerClass: string = ".js-error-container";
        if (Utils.find(this._context, errorContainerClass).length > 0) {
            this._errorContainer = this.createComponent(Span, errorContainerClass);
        }

        const countrySelectClass: string = ".js-address-country-select";
        if (Utils.find(this._context, countrySelectClass).length > 0) {
            this._countrySelect = this.createComponent(AddressCountrySelect, countrySelectClass);
            this._countrySelect.onChange = this.onCountryChange;
        }

        this._maxSearchResults = this.getData("max-search-results");
        this._useMockResponse = this.getData("use-mock-response");
        this._gwApi = this.getData("api-url");
        this._streetInput = this._editorInputFactory.create(
            this.context,
            EditorInputHidden,
            "input.js-street"
        );
        this._cityInput = this._editorInputFactory.create(
            this.context,
            EditorInputHidden,
            "input.js-city"
        );
        this._zipCodeInput = this._editorInputFactory.create(
            this.context,
            EditorInputHidden,
            "input.js-zipCode"
        );
        this._deliveryInput = this._editorInputFactory.create(
            this.context,
            EditorInputHidden,
            "input.js-delivery"
        );
        this._areaInput = this._editorInputFactory.create(
            this.context,
            EditorInputHidden,
            "input.js-area"
        );
        this._districtInput = this._editorInputFactory.create(
            this.context,
            EditorInputHidden,
            "input.js-district"
        );
        this._provinceInput = this._editorInputFactory.create(
            this.context,
            EditorInputHidden,
            "input.js-province"
        );
        this._regionInput = this._editorInputFactory.create(
            this.context,
            EditorInputHidden,
            "input.js-region"
        );
        this._localityInput = this._editorInputFactory.create(
            this.context,
            EditorInputHidden,
            "input.js-locality"
        );
        this._linkedFieldInputs = this._editorInputFactory.createArray(
            this.context,
            EditorInputHidden,
            ".js-linked-address-lookup-field input"
        );
    }

    @autobind
    private onCountryChange(): void {
        if (this._autoCompleteInput) {
            this._autoCompleteInput.setValue("");
        }
    }

    @autobind
    private async onAutoCompleteClose(
        e: kendo.ui.AutoCompleteCloseEvent | undefined
    ): Promise<void> {
        if (e && this._isPathSelected) {
            e.preventDefault();
        }

        this._isPathSelected = false;
    }

    @autobind
    private async onAutoCompleteSelect(
        e: kendo.ui.AutoCompleteSelectEvent | undefined
    ): Promise<void> {
        if (typeof e === "undefined") {
            return;
        }

        try {
            e.preventDefault();
            this._isPathSelected = false;

            const autoComplete: kendo.ui.AutoComplete | undefined = kendo.getAutoComplete(
                e.sender.element
            );
            if (!autoComplete) {
                return;
            }

            const selectedItem: IAddressSuggestionDto = e.dataItem;
            if (!selectedItem) {
                return;
            }

            this._isPathSelected = !selectedItem.AddressNodeId;
            if (this._isItemClicked) {
                return;
            }
            this._isItemClicked = true;

            if (selectedItem.AddressNodeId) {
                await this.get(selectedItem.AddressNodeId);
            } else {
                await this.search(autoComplete.value(), selectedItem.PathKey);
            }
        } finally {
            this._isItemClicked = false;
        }
    }

    @autobind
    private async onAutoCompleteFiltering(
        e: kendo.ui.AutoCompleteFilteringEvent | undefined
    ): Promise<void> {
        if (typeof e === "undefined") {
            return;
        }

        this._isItemClicked = false;
        e.preventDefault();

        if (e.filter.value.length > this._minSearchLength) {
            this.debounceSearch(e.filter.value);
        }
    }

    @autobind
    private debounceSearch(text: string): void {
        if (this._searchTimer) {
            window.clearTimeout(this._searchTimer);
        }

        this._searchTimer = window.setTimeout(
            async () => await this.search(text),
            this._searchTimerDelay
        );
    }

    @autobind
    private async search(value: string, pathKey?: string): Promise<void> {
        // prettier-ignore
        const path: string =
            `/addressSuggestions?SearchText=${value}&Top=${this._maxSearchResults}&Country=${this.getCountryValue()}`;
        let suggestionUrl: string = this._gwApi + path;

        if (pathKey) {
            suggestionUrl = suggestionUrl + "&PathKey=" + pathKey;
        }

        const headers: HeadersInit = {
            "x-origin": this._onlineOriginHeader,
        };

        this._lastSearchedPath = path;
        await this._globalApi.loadData({
            customHeaders: headers,
            onError: (e) => this.onSearchError(e, path),
            onSuccess: (d) => this.onSuggestionSuccess(d, path),
            url: suggestionUrl,
            useCachedValues: false,
            usePublicToken: true,
        });
    }

    @autobind
    private async get(id: string): Promise<void> {
        const getUrl: string =
            this._gwApi + `/addressNodes/${id}?Country=${this.getCountryValue()}`;

        const headers: HeadersInit = {
            "x-origin": this._onlineOriginHeader,
            "x-mock-response": this._useMockResponse.toString(),
        };

        await this._globalApi.loadData({
            customHeaders: headers,
            onError: this.onGetError,
            onSuccess: this.onGetSuccess,
            url: getUrl,
            useCachedValues: false,
            usePublicToken: true,
        });
    }

    @autobind
    private getCountryValue(): string {
        if (!this._countrySelect) {
            return "";
        }

        return this._countrySelect.getValue();
    }

    @autobind
    private onAutoCompleteFocus(e: JQuery.Event | undefined): void {
        if (typeof e === "undefined") {
            return;
        }

        if (!this._autoCompleteInput) {
            return;
        }

        const value: string = this._autoCompleteInput.getValue();

        if (value.length > this._minSearchLength) {
            this._autoCompleteInput.component.search(value);
        }
    }

    @autobind
    private onGetSuccess(result: IAddressNodeDto): void {
        this.toggleError(false);

        this._streetInput.setValue(result.Street);
        this._cityInput.setValue(result.City);
        this._zipCodeInput.setValue(result.ZipCode);
        this._deliveryInput.setValue(result.BuildingDetails);
        this._areaInput.setValue(result.Area);
        this._districtInput.setValue(result.District);
        this._provinceInput.setValue(result.Province);
        this._regionInput.setValue(result.Region);
        this._localityInput.setValue(result.Locality);

        this._linkedFieldInputs.forEach((input) => {
            const textField = Utils.siblings(
                input.component,
                ".js-linked-address-lookup-field-value"
            ) as JQuery<HTMLElement>;
            const parents = Utils.parents(input.component, ".w-control");
            Utils.setText(textField, input.getValue());
            Utils.removeClass(parents, "hidden");
        });

        if (this._autoCompleteInput) {
            this._autoCompleteInput.setValue("");

            this._autoCompleteInput.component.close();
        }
    }

    @autobind
    private onSuggestionSuccess(result: IAddressSuggestionsDto, path: string): void {
        if (path !== this._lastSearchedPath) {
            return;
        }

        this.toggleError(false);

        const dataSource: kendo.data.DataSource = new kendo.data.DataSource({
            data: result.AddressSuggestionsCollection,
        });

        if (this._autoCompleteInput) {
            this._autoCompleteInput.component.setDataSource(dataSource);
            this._autoCompleteInput.component.dataSource.read();
        }
    }

    @autobind
    private onSearchError(e: any, path: string): void {
        if (path !== this._lastSearchedPath) {
            return;
        }

        this.toggleError(true);

        const errorMessage: string = `Calling API failed.${e}`;
        this._logger.error(errorMessage);
    }

    @autobind
    private onGetError(e: any): void {
        this.toggleError(true);

        const errorMessage: string = `Calling API failed.${e}`;
        this._logger.error(errorMessage);
    }

    @autobind
    private toggleError(show: boolean): void {
        if (this._errorContainer) {
            this._errorContainer.toggleClass("hidden", !show);
        }
    }
}
