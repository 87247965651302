import { autobind } from "core-decorators";
import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { IList } from "Helpers/Scripts/IList";
import { Utils } from "Helpers/Scripts/Utils";
import { Spinner } from "Spinner/Scripts/Spinner";

@OnlyInstantiableByContainer
@Singleton
export class LoadingUtils {
    private readonly CLASS_LOADING: string = "k-loading";
    private readonly CLASS_LOADING_LEFT: string = "left";
    private readonly DATA_LOADING_CLASSES_SET: string = "loadingClasses";
    private readonly SEL_DEFAULT_ONCHANGE: string = "form";
    private readonly SEL_FORM_CONTENT: string = ".js-form-content";
    private readonly SEL_SUBMIT_CONTROL: string = `[type="submit"]`;

    private readonly _logger: ILogger;
    private readonly _windowBinder: EventBinder;

    /**
     * Reference to the last active form to which overlay could be applied,
     * and which needs to get its state restored when page reload is cancelled by the user.
     * The reference gets set with the showFormSubmitted() method (rather in legacy code),
     * or directly on demand (TS code using the overlay functionality in Form.ts).
     *
     * TODO: Maybe we could put the _onBeforeUnload() handler implementation directly
     * into Form.ts and apply it once the form uses overlays. With this, we could get rid of the
     * lastActiveForm property & bound code here (or use it exclusively for the legacy stuff
     * if still needed).
     */
    public lastActiveForm: Element | undefined;

    constructor(@Inject loggerFactory: LoggerFactory, @Inject windowBinder: EventBinder) {
        this._logger = loggerFactory.getLogger(this.key);
        this._windowBinder = windowBinder;
        // setup window helper event binder with context pointing to window
        this._windowBinder.init(window);
        this._bindEvents();
        this._logger.info("Backoffice loading utils instance created.");
    }

    public get key(): string {
        return "LoadingUtils";
    }

    public shouldNotClose: boolean;

    private _bindEvents(): this {
        this._windowBinder.bindBeforeUnload(this._onBeforeUnload);

        return this;
    }

    /**
     * Prevent window close "setter" for legacy code.
     * @param isPrevented When true, a dialog will be shown when exiting the page.
     */
    @autobind
    public setPreventWindowClose(isPrevented: boolean): this {
        this.shouldNotClose = isPrevented;

        return this;
    }

    @autobind
    private _onBeforeUnload(): string | undefined {
        if (!this.shouldNotClose) {
            // no dialogue will be shown
            return undefined;
        }

        // Hack for capturing cancel button event http://stackoverflow.com/a/4651049/1208684
        window.setTimeout(() => {
            window.setTimeout(this._restoreFormAction);
            this._logger.info("Scheduled second timeout.");
        });
        this._logger.info("Scheduled first timeout.");

        // show dialogue with this text (some browsers show only standard predefined text instead)
        return "Do you want to leave without saving changes?";
    }

    /**
     * Shows loading overlay over form. Please note, that for proper
     * overlay display without additional markup wrappers or similar,
     * the position: relative; is needed on the form element for kendo/Spinner
     * overlay to be sized correctly.
     * @deprecated For legacy code only. Form.ts UI component has this built-in.
     */
    @autobind
    public showFormSubmitted(form: string | Element | JQuery): this {
        this.lastActiveForm = Utils.toElement(form);
        Spinner.applyOverlayTo(form);

        return this;
    }

    /**
     * Restores last active form and its submit button(s) to normal state.
     */
    @autobind
    private _restoreFormAction(): this {
        if (!this.lastActiveForm) {
            return this;
        }

        this.restoreFormSubmitted(this.lastActiveForm);
        Utils.find(this.lastActiveForm, this.SEL_SUBMIT_CONTROL).forEach((button) =>
            this.restoreButton(button)
        );
        this.lastActiveForm = undefined;
        this._logger.info(`Restored form state for ${this.lastActiveForm}.`);

        return this;
    }

    /**
     * Removes loading overlay over form.
     * @deprecated For legacy code only. Form.ts UI component has this built-in.
     * @param form Form element or selector from which to clear the overlay.
     */
    @autobind
    public restoreFormSubmitted(form: string | Element | JQuery): this {
        Spinner.removeOverlayFrom(form);

        return this;
    }

    /**
     * Restores button so it does not show loading icon and is not disabled.
     * @param button Button element or selector to be restored to normal state.
     */
    @autobind
    public restoreButton(button: string | Element | JQuery): this {
        return this.hideButtonLoading(button).enableButton(button);
    }

    /**
     * Tells whether a loading spinner is applied on button.
     * @param button Button to be checked for loading state.
     * TODO: Should probably be part of Spinner itself.
     */
    @autobind
    public isButtonLoading(button: string | Element | JQuery): boolean {
        return Utils.hasClass(button, this.CLASS_LOADING);
    }

    /**
     * Shows loading spinner on button.
     * @param button Botton to have the loading icon applied to.
     * @param hAlignClass Optional horizontal alignment class for the loading state.
     * When ommited, "left" will be used as a default.
     * TODO: Probably should be part of Spinner itself.
     */
    @autobind
    public showButtonLoading(button: string | Element | JQuery, hAlignClass?: string): this {
        Utils.addClass(button, this.CLASS_LOADING);
        const loadingAlignClass: string = hAlignClass || this.CLASS_LOADING_LEFT;
        Utils.addClass(button, loadingAlignClass);
        Utils.setData(button, this.DATA_LOADING_CLASSES_SET, loadingAlignClass);

        return this;
    }

    /**
     * Hides loading spinner on button.
     * @param button Button to have the loading icon removed.
     * TODO: Probably should be part of Spinner itself.
     */
    @autobind
    public hideButtonLoading(button: string | Element | JQuery): this {
        Utils.removeClass(button, this.CLASS_LOADING);
        const loadingAlignClass: string =
            Utils.getData(button, this.DATA_LOADING_CLASSES_SET) || "";
        if (loadingAlignClass) {
            Utils.removeClass(button, loadingAlignClass);
        }
        Utils.removeData(button, this.DATA_LOADING_CLASSES_SET);

        return this;
    }

    /**
     * Disables a button.
     * @param button Button to be disabled.
     * @deprecated For legacy code only. Use Utils.disable() in new TS code.
     */
    @autobind
    public disableButton(button: string | Element | JQuery): this {
        Utils.disable(button);

        return this;
    }

    /**
     * Enables a button.
     * @param button Button to be enabled.
     * @deprecated For legacy code only. Use Utils.enable() in new TS code.
     */
    @autobind
    public enableButton(button: string | Element | JQuery): this {
        Utils.enable(button);

        return this;
    }

    /**
     * Disables button and show loading icon.
     * @param button The button to be disabled.
     * @param hAlignClass Optional horizontal alignment class for loading state.
     * When ommited, "left" will be applied.
     */
    @autobind
    public setButtonLoadingAndDisabled(
        button: string | Element | JQuery,
        hAlignClass?: string
    ): this {
        return this.showButtonLoading(button, hAlignClass).disableButton(button);
    }

    /**
     * Redirects the page to specified url.
     * @param url URL path to navigate to
     * @param params Optional URL parameteres specification.
     * @param useReplace When true, rather location.replace will be used instead of location.href.
     * @deprecated For legacy code only. Use Utils.redirectTo() in new TS code.
     */
    @autobind
    public redirToUrl(url: string, params?: IList<string>, useReplace?: boolean): void {
        Utils.redirectTo(url, params, useReplace);
    }

    /**
     * For specified selector adds onChange detection, so it can be used to detect
     * changes in forms and users can be warned before canceling.
     * Callback should be function, that sets some local hasChagnes = true.
     * Selector specifies on which elements it should be applied.
     * TODO: binding for more widgets: grid?
     * TODO: For forms there should be standard "dirty" functionality implemented with
     * no additional work in particular cases needed to achieve similar effect.
     * @param callback Event handler to call after some widget had changed.
     * @param selector Optional container element type selector inside of which
     * particular widgets will get applied the on-change handler.
     */
    @autobind
    public addOnChangeHandlers(callback: Function, selector?: string): this {
        let sel: string = selector || this.SEL_DEFAULT_ONCHANGE;
        sel = `${sel} input, ${sel} select, ${sel} textarea`;
        Utils.find(document.documentElement, sel).forEach((item) =>
            item.addEventListener("change", (event) => callback(event))
        );

        return this;
    }

    /**
     * Shows loading status overlay on form and its submit button.
     * TODO: better typing of the event?
     * TODO: The form content wrapper can be probably removed everywhere in code once all form
     * elements hav position: relative; (or similar) in CSS. The code here can be
     * also updated then, but it is for legacy code only anyway.
     * @deprecated For legacy code only. Use Utils.redirectTo() in new TS code.
     */
    @autobind
    public onFormValidationDone(
        event: kendo.ui.ValidatorValidateEvent,
        includeButton?: boolean
    ): void {
        if (!event.valid) {
            return;
        }
        const form: Element | undefined = Utils.toElement(
            (event as any).target ? (event as any).target : event.sender.element
        );
        if (!form) {
            return;
        }
        const content: Element | undefined = Utils.find(form, this.SEL_FORM_CONTENT, 1)[0];
        if (!content) {
            return;
        }

        if (typeof includeButton === "undefined" || includeButton === true) {
            Utils.find(form, this.SEL_SUBMIT_CONTROL).forEach((button) =>
                this.setButtonLoadingAndDisabled(button)
            );
        }
        this.showFormSubmitted(content);
    }
}
