import { OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { IStorageService } from "./IStorageService";
import { Utils } from "Helpers/Scripts/Utils";

@OnlyInstantiableByContainer
@Singleton
export class LocalStorageService implements IStorageService {
    /**
     * Returns requested data when found.
     * @param key The key under which the actual data is stored.
     */
    public getItem(key: string): any {
        return key && Utils.isLocalStorageAvailable ? localStorage.getItem(key) : undefined;
    }

    /**
     * Stores provided data.
     * @param key Key under which to store the provided data in storage.
     * @param value Data to store.
     */
    public setItem(key: string, value: any): this {
        if (key && Utils.isLocalStorageAvailable) {
            localStorage.setItem(key, typeof value === "string" ? value : JSON.stringify(value));
        }

        return this;
    }

    /**
     * Removes the item with given key from storage when found.
     * @param key Key of the item to be removed.
     */
    public removeItem(key: string): this {
        if (key && Utils.isLocalStorageAvailable) {
            localStorage.removeItem(key);
        }

        return this;
    }

    /**
     * Clear keys with provided prefix from storage.
     * @param prefix Prefix of the items to be removed.
     */
    public clearItems(prefix: string): void {
        if (prefix && Utils.isLocalStorageAvailable) {
            Object.keys(localStorage)
                .filter((keyName) => keyName.indexOf(prefix, 0) === 0)
                .forEach((key) => localStorage.removeItem(key));
        }
    }
}
