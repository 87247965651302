import { Container, Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { UiComponent } from "./UiComponent";
import { Utils } from "Helpers/Scripts/Utils";

@OnlyInstantiableByContainer
@Singleton
export class UiComponentFactory {
    public get key(): string {
        return "UiComponentFactory";
    }

    protected readonly _logger: ILogger;
    private static _document: UiComponent;

    constructor(@Inject loggerFactory: LoggerFactory) {
        this._logger = loggerFactory.getLogger(this.key);
    }

    /**
     * Creates top-level UI component.
     * @param componentType Type of component (type / constructor reference).
     * @param selector Selector used to find corresponding context element in document.
     */
    public createBase<T extends UiComponent>(
        componentType: new (...args: any[]) => T,
        selector: Element | string,
        model?: object
    ): T {
        const element = typeof selector === "string" ? document.querySelector(selector) : selector;

        if (!element) {
            const errorMessage = "Base component not found";
            this._logger.error(errorMessage, selector);
            throw new Error(errorMessage);
        }

        return this._createComponent(element, componentType, model);
    }

    /**
     * Creates new UI component instance based on already known context element instance.
     * @param context Context element reference.
     * @param componentType Component's type / constructor reference.
     * @param selector When provided, context element will be first searched for starting
     * at the element specified in context parameter.
     * @param component model
     * @returns New instance of UI component of a given type.
     */
    public create<T extends UiComponent>(
        context: Element,
        componentType: new (...args: any[]) => T,
        selector?: string,
        model?: object
    ): T {
        return selector
            ? this._createSubComponent(context, componentType, selector, model)
            : this._createComponent(context, componentType, model);
    }

    /**
     * Creates new array of UI components instance based on already known context element instance.
     * @param context Context element reference.
     * @param componentType Component's type / constructor reference.
     * @param selector used for all instances in given context
     * @returns New instance of UI component of a given type.
     */
    public createArray<T extends UiComponent>(
        context: Element,
        componentType: new (...args: any[]) => T,
        selector: string
    ): T[] {
        const elements = Utils.find(context, selector);
        if (!elements || !elements.length) {
            const errorMessage = `Context element for UI component not found. Context: ${context}, selector: '${selector}'`;
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        const result = elements.map((e) => this._createComponent(e, componentType));

        return result;
    }

    public getComponentReference<T extends UiComponent>(context: Element): T | undefined {
        const result = Utils.getData(context, UiComponent.COMPONENT_DATA_KEY);
        return result ? (result as T) : undefined;
    }

    public getDocument(): UiComponent {
        if (!UiComponentFactory._document) {
            const doc = document.documentElement;
            if (!doc) {
                const errorMessage =
                    "Document element not found when setting up document UiComponent wrapper.";
                this._logger.error(errorMessage);
                throw new Error(errorMessage);
            }
            UiComponentFactory._document = this._createComponent(doc, UiComponent);
        }

        return UiComponentFactory._document;
    }

    private _createSubComponent<T extends UiComponent>(
        context: Element,
        componentType: new (...args: any[]) => T,
        selector: string,
        model?: object
    ): T {
        const countLimit = 1;
        const element = Utils.find(context, selector, countLimit)[0];
        if (!element) {
            const errorMessage = `Context element for UI component not found. Context: ${context}, selector: '${selector}'`;
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        return this._createComponent(element, componentType, model);
    }

    /**
     * This is the actual component instantiation. It happens in 3 steps:
     * 1. constructor() - using IoC injection, same for all IoC driven classes
     * 2. ctor() - initialization and checking of context element
     * 3. init() - called in ctor(), custom initialization override in subclasses
     * @param context Context element for the UI component.
     * @param componentType Component's type / constructor reference.
     * @param component model
     * @returns New instance of UI component of a given type.
     */
    private _createComponent<T extends UiComponent>(
        context: Element,
        componentType: new (...args: any[]) => T,
        model?: object
    ): T {
        const component = Container.get(componentType) as T;
        component.ctor(context, model);

        return component;
    }

    /**
     * This is the actual component instantiation when template it used.
     * Public setter called "setTmplateData" is used to pass the template data to the instance.
     * It is done before calling ctor, so the data is available in the init method from the public setter
     * 1. constructor() - using IoC injection, same for all IoC driven classes
     * 2. ctor() - initialization and checking of context element
     * 3. init() - called in ctor(), custom initialization override in subclasses
     * @param context Context element for the UI component.
     * @param componentType Component's type / constructor reference.
     * @returns New instance of UI component of a given type.
     */
    private _createTemplateComponent<T extends UiComponent>(
        context: Element,
        componentType: new (...args: any[]) => T,
        templateData: object
    ): T {
        return this._createComponent(context, componentType, templateData);
    }

    /**
     * This is the actual component instantiation
     * @param componentType Component's type / constructor reference.
     * @param template ready-to-use template method pointer
     * @param templateData object to be passed to the template
     * @param target element to which to append the new element, if not provided then it is appened to the context
     */
    public createComponentFromTemplate<T extends UiComponent>(
        componentType: new (...args: any[]) => T,
        template: (data: object) => string,
        templateData: object,
        target?: Element
    ): T {
        const markup = template(templateData);
        const context = Utils.createDomElementFromMarkup(markup);

        if (!context) {
            const errorMessage = `Context element from template does not exist. Context: ${context}, template: '${template}'`;
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        if (target) {
            Utils.append(target, context);
        }

        return this._createTemplateComponent(context, componentType, templateData);
    }

    /**
     * This is the actual component instantiation
     * @param componentType Component's type / constructor reference.
     * @param templateId ID of the template to be used
     * @param templateData object to be passed to the template
     * @param target element to which to append the new element, if not provided then it is appened to the context
     */
    public createComponentFromTemplateId<T extends UiComponent>(
        componentType: new (...args: any[]) => T,
        templateId: string,
        templateData: object,
        target?: Element
    ): T {
        const template = Utils.getTemplate(templateId);
        if (!template) {
            const errorMessage = `Template was not found. Template id: '${templateId}'`;
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        return this.createComponentFromTemplate(componentType, template, templateData, target);
    }
}
