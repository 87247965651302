import { Container, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { EditorInput } from "./EditorInput";
import { Utils } from "Helpers/Scripts/Utils";

@OnlyInstantiableByContainer
@Singleton
export class EditorInputFactory {
    /**
     * Creates new EditorInput instance based on already known context element instance.
     * @param context Context element reference.
     * @param componentType Component's type / constructor reference.
     * @param selector When provided, context element will be first searched for starting
     * at the element specified in context parameter.
     * @returns New instance of EditorInput of a given type.
     */
    public create<T extends EditorInput>(
        context: Element,
        componentType: new (...args: any[]) => T,
        selector?: string
    ): T {
        return selector
            ? this._createSubComponent(context, componentType, selector)
            : this._createComponent(context, componentType);
    }

    /**
     * Creates new array of EditorInput instances based on already known context element instance.
     * @param context Context element reference.
     * @param componentType Component's type / constructor reference.
     * @param selector used to find all instances in the given context
     * @returns New array of instances of EditorInput of a given type.
     */
    public createArray<T extends EditorInput>(
        context: Element,
        componentType: new (...args: any[]) => T,
        selector: string
    ): T[] {
        const elements: Element[] = Utils.find(context, selector);

        let result: T[] = [];
        if (elements && elements.length) {
            result = elements.map((e) => this._createComponent(e, componentType));
        }

        return result;
    }

    public getComponentReference<T extends EditorInput>(context: Element): T | undefined {
        const result = Utils.getData(context, EditorInput.COMPONENT_DATA_KEY);
        return result ? (result as T) : undefined;
    }

    private _createSubComponent<T extends EditorInput>(
        context: Element,
        componentType: new (...args: any[]) => T,
        selector: string
    ): T {
        const countLimit = 1;
        const element = Utils.find(context, selector, countLimit)[0];
        if (!element) {
            const errorMessage = `Context element for EditorInput not found. Context: ${context}, selector: '${selector}'`;
            throw new Error(errorMessage);
        }

        return this._createComponent(element, componentType);
    }

    /**
     * This is the actual component instantiation. It works similarly to UiComponentFactory:
     * 1. constructor() - using IoC injection, same for all IoC driven classes
     * 2. ctor() - initialization and checking of context element
     * @param context Context element for the EditorInput.
     * @param componentType Component's type / constructor reference.
     * @returns New instance of EditorInput of a given type.
     */
    private _createComponent<T extends EditorInput>(
        context: Element,
        componentType: new (...args: any[]) => T
    ): T {
        const component = Container.get(componentType) as T;
        component.ctor(context);

        return component;
    }
}
