import { Utils } from "Helpers/Scripts/Utils";
import { IOverlayOptions } from "./IOverlayOptions";
import { ISpinnerOptions } from "./ISpinnerOptions";
import $ from "jquery";

const PROGRESS_WRAP = ".progress-wrap";
const LOADING_IMAGE_CLASS = "k-loading-image";
const LOADING_MASK_CLASS = "k-loading-mask";
const IFRAME_PARENT = ".w-iframe";
const Z_INDEX = "z-index";
const $defaultOverlay = $("body");

export class Spinner {
    protected static getOverlay(element?: Element | JQuery | string) {
        if (element) {
            const $element = $<HTMLElement>(element as HTMLElement);
            if ($element.length) {
                return $element;
            }
        }

        return $defaultOverlay.length ? $defaultOverlay : null;
    }

    protected static getElement(element: Element | JQuery | string) {
        const $element = $(element as HTMLElement);

        return $element.length ? $element : null;
    }

    /**
     * Applies a spinner markup as a child element of specified selector.
     */
    public static applyTo(element: Element | JQuery | string, options?: ISpinnerOptions) {
        const $element = this.getElement(element);
        if (!$element) {
            return;
        }
        let cssClass = LOADING_IMAGE_CLASS;
        if ($element.find(`.${cssClass}`).length) {
            return;
        }
        let cssClassAlign = "";
        if (options) {
            if (options.cssClass) {
                cssClass = options.cssClass; // TODO: problem if custom class without k-loading-image
            }
            if (options.halign !== undefined) {
                cssClassAlign = options.halign;
            }
        }
        $element.append(
            `<span class="${cssClass}${cssClassAlign ? ` ${cssClassAlign}` : ""}"></span>`
        );
    }

    /**
     * Removes a spinner markup from objects with specified selector.
     */
    public static removeFrom(element: Element | JQuery | string, loadingImageClass?: string): void {
        const $element = this.getElement(element);
        if (!$element) {
            return;
        }

        const $wrap = $element.closest(PROGRESS_WRAP);
        const cssClass = loadingImageClass || LOADING_IMAGE_CLASS;
        const duration = Utils.DEFAULT_SPEED;
        const onComplete = function (this: Element): void {
            this.remove();
        };
        if ($wrap.length) {
            $wrap.find(`.${cssClass}`).fadeOut(duration, onComplete);
            $element.unwrap();
        } else {
            $element.find(`.${cssClass}`).fadeOut(duration, onComplete);
        }
    }

    /**
     * Applies loading overlay on top of selected block item. Uses dynamically
     * generated spinner in it to indicate in-progress state.
     */
    public static applyOverlayTo(
        element?: Element | JQuery | string,
        options?: IOverlayOptions
    ): void {
        const $overlay = this.getOverlay(element);
        if (!$overlay || !$overlay.length) {
            return;
        }
        kendo.ui.progress($overlay, true);
        if (!options) {
            return;
        }

        if (options.overlay && !isNaN(options.overlay.zIndex)) {
            $(`.${LOADING_MASK_CLASS}`).css({ [Z_INDEX]: options.overlay.zIndex });
        }

        // apply spinner settings
        if (options.spinner) {
            const $loadingMask = $(`.${LOADING_MASK_CLASS} .${LOADING_IMAGE_CLASS}`);
            if (options.spinner.display === "none") {
                $loadingMask.addClass("hidden");
            }
            // for fixed spinner position use value "fixed"
            if (options.spinner.position === "fixed") {
                $loadingMask.addClass(options.spinner.position);
            }
        }

        // change parent z-index if element is iframe
        const $frame = $(options.iframe as string);
        if ($frame.is("iframe")) {
            $frame
                .closest(IFRAME_PARENT)
                .css({ [Z_INDEX]: (options.overlay ? options.overlay.zIndex : 0) + 1 });
        }
    }

    /**
     * Hides loading overlay and removes it from DOM
     */
    public static removeOverlayFrom(element?: Element | JQuery | string): void {
        const $overlay = this.getOverlay(element);
        if (!$overlay || !$overlay.length) {
            return;
        }
        if ($overlay.closest(PROGRESS_WRAP).length > 0) {
            kendo.ui.progress($overlay.closest(PROGRESS_WRAP), false);
            $overlay.unwrap();
        } else {
            kendo.ui.progress($overlay, false);
        }
    }

    public static runWith<T>(
        promise: Promise<T> | Promise<T>[],
        element?: Element | JQuery | string
    ): void {
        this.applyOverlayTo(element);
        let promises: any[] = [];
        const callback = () => this.removeOverlayFrom(element);
        promises = promises.concat(promise);
        Promise.all(promises).then(callback).catch(callback);
    }
}
