import { EditorInput } from "./EditorInput";

export abstract class EditorInputKendo extends EditorInput {
    public component: kendo.ui.AutoComplete | kendo.ui.DropDownList;

    public ctor(component: Element): void {
        super.ctor(component);
    }

    public getSelectedItem(): any {
        return this.component.dataItem();
    }

    /* EditorInput methods implementation */
    public getValue(): any {
        return this.component.value();
    }

    public setValue(value: string | number | string[]): this {
        const stringValue: string = value ? value.toString() : "";

        this.component.value(stringValue);
        return this;
    }
}
