import { Inject } from "typescript-ioc";
import { autobind } from "core-decorators";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { IPreloader, PreloadableObject } from "./IPreloader";
import { PreloaderBase } from "./PreloaderBase";
import { LOAD_INIT, LOAD_SUCCESS, LOAD_ERROR, LOAD_COMPLETE } from "./PreloaderEvents";

/**
 * Preloader for images.
 */
export class PreloaderImage extends PreloaderBase implements IPreloader {
    private readonly ERR_UNDEFINED_OBJECT: string =
        "Preloadable object was not initialized properly.";

    protected _key: string = "PreloaderImage";

    private reject?: Function;
    private resolve?: Function;

    constructor(@Inject binder: EventBinder, @Inject loggerFactory: LoggerFactory) {
        super(binder, loggerFactory);
    }

    /**
     * Preload images using promises
     */
    public async preload(): Promise<PreloadableObject> {
        return new Promise<PreloadableObject>((resolve: Function, reject: Function) => {
            // check whether object is loadable by preloaderImage class
            if (
                !this._preloadableObject ||
                !(this._preloadableObject instanceof HTMLImageElement)
            ) {
                this._logger.error(this.ERR_UNDEFINED_OBJECT);
                reject(this.ERR_UNDEFINED_OBJECT);

                return;
            }
            // if image is cached in browser then resolve it automatically
            if (this._preloadableObject.complete) {
                this._logger.log("Image is cached. No loading required.");
                resolve(this._preloadableObject);
                return;
            }

            // trigger load init event from here
            this._binder.trigger(LOAD_INIT, [this._preloadableObject]);

            // assign reject and resolve functions to be discoverable in other metod
            this.resolve = resolve;
            this.reject = reject;

            // add listeners to image
            this._preloadableObject.addEventListener("load", this._onLoad);
            this._preloadableObject.addEventListener("error", this._onError);
        });
    }

    /**
     * On load success
     */
    @autobind
    private _onLoad(): void {
        if (!this._preloadableObject || !this.resolve) {
            return;
        }
        this.resolve(this._preloadableObject);

        this._binder.trigger(LOAD_SUCCESS, [this._preloadableObject]);
        this._binder.trigger(LOAD_COMPLETE);

        this._unbindPreloadableObjectListeners();
    }
    /**
     * On Load error
     * @param err
     */
    @autobind
    private _onError(err: ErrorEvent): void {
        if (this.reject) {
            this.reject(err.message);
        }
        this._binder.trigger(LOAD_ERROR, [err]);
        this._binder.trigger(LOAD_COMPLETE);

        this._unbindPreloadableObjectListeners();
    }
    /**
     * Unbind object event listeners
     */
    private _unbindPreloadableObjectListeners(): void {
        if (!this._preloadableObject) {
            return;
        }
        this._preloadableObject.removeEventListener("error", this._onError);
        this._preloadableObject.removeEventListener("load", this._onLoad);
    }
}
