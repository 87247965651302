import { Utils } from "Helpers/Scripts/Utils";
import { EditorInput } from "./EditorInput";

export class EditorInputHidden extends EditorInput {
    public component: HTMLInputElement;

    public ctor(component: Element): void {
        super.ctor(component);
        this.component = component as HTMLInputElement;
    }

    public getValue(): any {
        return Utils.getValue(this.component);
    }

    public setValue(value: string | number | string[]): this {
        const stringValue: string = value ? value.toString() : "";

        Utils.setValue(this.component, stringValue);
        return this;
    }
}
