/*
--------------------------------------------------------------------------------
Oriflame Consultant API data receiver functionality
Sends request to GlobalApi gateway if url exists in settings, otherwise sends request to eshop
Api is defined by it's name
--------------------------------------------------------------------------------
*/
import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { Utils } from "Helpers/Scripts/Utils";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { GlobalApi } from "GlobalApi/Scripts/GlobalApi";
import { IConsultantsApiSettings } from "./IConsultantsApiSettings";
import { IApiLoadDataParams } from "./IApiLoadDataParams";

@OnlyInstantiableByContainer
@Singleton
export class ConsultantsApi {
    public get key(): string {
        return "ConsultantsApi";
    }

    private static readonly CONSULTANTS_API_KEY: string = "consultants-api";

    protected readonly _logger: ILogger;
    protected readonly _globalApi: GlobalApi;

    private _globalApiKpiUrls: { [apiName: string]: string } = {};

    constructor(@Inject loggerFactory: LoggerFactory, @Inject globalApi: GlobalApi) {
        this._logger = loggerFactory.getLogger(this.key);
        this._globalApi = globalApi;
    }

    public init(): this {
        // options setup also (re)connects default event handlers
        // TODO: read options with one string :)
        const optionsElement = document.getElementById("consultants-api-options");
        if (!optionsElement) {
            const errorMessage = "Element containing API options was not found.";
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }
        const options = Utils.getOptions(ConsultantsApi.CONSULTANTS_API_KEY, optionsElement);
        if (options) {
            this._initSettings(options);
        }
        return this;
    }

    /**
     * Load data (from API or from browser cache)
     */
    public loadData(params: IApiLoadDataParams): void {
        const apiName: string | undefined = params.apiName;
        if (!apiName) {
            this._onError(params.onError);
            return;
        }

        const globalApiUrl = this._globalApiKpiUrls[apiName];
        if (globalApiUrl) {
            this._globalApi.loadData({
                cacheSettingsUrl: "/api/v1/system/kpiApiconfiguration",
                onError: params.onError,
                onSuccess: params.onSuccess,
                url: globalApiUrl,
            });
        } else if (ori.webApi) {
            ori.webApi.loadData({
                action: params.apiName,
                alwaysFetchNewData: !params.useCachedValues,
                onError: params.onError,
                onSuccess: params.onSuccess,
                requestData: params.requestData,
            });
        }
    }

    /**
     * Sets up current instance's options and (re)connects event handlers accordingly.
     */
    private _initSettings(options: IConsultantsApiSettings): void {
        this._globalApiKpiUrls = options.globalApiKpiUrls;
    }

    private _onError(onErrorCallback: () => void): void {
        if (onErrorCallback) {
            onErrorCallback();
        }
    }
}
