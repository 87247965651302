import { Inject } from "typescript-ioc";
import { UiComponent } from "./UiComponent";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";

export class Anchor extends UiComponent {
    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    /**
     * UI component's context element in the DOM.
     */
    public get context(): HTMLAnchorElement {
        return this.getContextAs(HTMLAnchorElement);
    }

    /**
     * @returns Current value of the href property / attribute of the context element.
     */
    public getHref(): string {
        return this.context.href;
    }

    /**
     * Sets a new value of the href property / attribute on context element.
     * @value href property / attribute value to be set.
     */
    public setHref(value: string): this {
        this.context.href = value;

        return this;
    }
}
