import { Inject } from "typescript-ioc";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
// Components
import { FeedbackNotification } from "./FeedbackNotification";

export class FeedbackNotificationWithNoActions extends FeedbackNotification {
    private readonly CLOSE_TIME: number = 2000;
    private readonly FEEDBACK_ACTIONS: string = ".feedback-notification__actions";

    public get key(): string {
        return "FeedbackNotificationWithNoActions";
    }

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    protected _init(): void {
        this.find(this.FEEDBACK_ACTIONS).forEach((feedbackAction) =>
            feedbackAction.classList.add(this.CLASS_HIDDEN)
        );
    }

    protected _showSuccessMessage(): void {
        this._getFeedbackWindowActions().addClass(this.CLASS_HIDDEN);
        window.setTimeout(() => this.close(), this.CLOSE_TIME);
        this.bindCloseAction(() => location.reload());
    }

    protected _showFailMessage(): void {
        this._getFeedbackWindowActions().removeClass(this.CLASS_HIDDEN);
    }
}
