/* ORDER REVIEW BOX */

import { autobind } from "core-decorators";
import { UiComponent } from "Ui/Scripts/UiComponent";

// Utils
import { Utils } from "Helpers/Scripts/Utils";

export class OrderReviewBox {
    private readonly _parentComponent: UiComponent;

    private readonly OPENED: string = "opened";
    private readonly ORDER_REVIEW_BOX: string = ".js-order-review-box";
    private readonly ORDER_REVIEW_BOX_EXPANDER: string = ".js-order-review-box-expander";

    constructor(parentComponent: UiComponent) {
        this._parentComponent = parentComponent;
    }

    /**
     * Inits tooltips in order tracking popup
     */
    public init(): void {
        this._bindEvents();
    }

    private _bindEvents(): void {
        this._parentComponent.bindDelegatedClick(
            this.ORDER_REVIEW_BOX_EXPANDER,
            this._toggleOrderReviewBox
        );
    }

    /**
     * Toggles visibility of review box content
     */
    @autobind
    private _toggleOrderReviewBox(event: JQuery.TriggeredEvent): void {
        const button = event.target as HTMLInputElement;
        const orderReviewBox = Utils.closest(button, this.ORDER_REVIEW_BOX);

        if (orderReviewBox) {
            Utils.toggleClass(orderReviewBox, this.OPENED);
        }
    }
}
