/**
 * Order Status Dictionary
 */

import { OrderStatus } from "./OrderStatus";
import { OrderStatusInfo } from "./OrderStatusInfo";

export class OrderStatusDictionary {
    [id: number]: OrderStatusInfo;

    constructor() {
        this[OrderStatus.Paid] = new OrderStatusInfo("paid", "v-icon-check-round");
        this[OrderStatus.Unpaid] = new OrderStatusInfo("unpaid", "v-icon-round-exclam");
        this[OrderStatus.CreditNote] = new OrderStatusInfo(
            "credit-note",
            "v-icon-document-outlined"
        );
        this[OrderStatus.PreOrder] = new OrderStatusInfo("preorder", "v-icon-calendar-outlined");
        this[OrderStatus.PrePreOrder] = new OrderStatusInfo(
            "pre-preorder",
            "v-icon-calendar-outlined"
        );
        this[OrderStatus.Pending] = new OrderStatusInfo("pending", "v-icon-clock-outlined");
        this[OrderStatus.Waiting] = new OrderStatusInfo("waiting", "v-icon-clock-outlined");
        this[OrderStatus.Blocked] = new OrderStatusInfo("blocked", "v-icon-cancel");
        this[OrderStatus.Canceled] = new OrderStatusInfo("canceled", "v-icon-remove-circle");
    }
}
