import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { IRequestTokenData, ITokenData } from "GlobalApi/Scripts/IGlobalApiModule";
import { IStorageService } from "Storages/Scripts/IStorageService";
import { SessionStorageService } from "Storages/Scripts/SessionStorageService";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { FetchService } from "Async/Scripts/FetchService";
import { EventBinder } from "Events/Scripts/EventBinder";
import { ITokenUpdateEvent } from "./ITokenUpdateEvent";
import { TokenUpdateEventHandler } from "./TokenUpdateEventHandler";
import $ from "jquery";
import { ITokenConfig } from "./ITokenConfig";
import { IStoredTokenData, ITokenStorage, ITokenStorageItem } from "./ITokenStorage";
import { tokenTypes } from "./tokenTypes";
import { ICookieService } from "Cookies/Scripts/ICookieService";
import { CookieService } from "Cookies/Scripts/CookieService";

@OnlyInstantiableByContainer
@Singleton
export class TokenService {
    public get key(): string {
        return "TokenService";
    }

    private readonly _fetchService: FetchService;
    protected readonly _logger: ILogger;
    private readonly _storage: IStorageService;
    private readonly _eventBinder: EventBinder;
    private readonly _cookieService: ICookieService;

    private readonly userCookieName = "OriLoggedUser";
    private readonly TOKEN_REQUEST_INTERVAL: number = 30000;
    private readonly TOKEN_CONFIG: ITokenConfig = {
        publicToken: {
            apiUrl: "/system/ajax/ApiGateway/GetSpaTokenAsync",
            storageKey: "PublicToken",
            updatedEvent: "publicTokenUpdated",
        },
        userToken: {
            apiUrl: "/system/ajax/ApiGateway/GetUserAccessTokenAsync",
            storageKey: "AuthToken",
            updatedEvent: "tokenUpdated",
        },
    };

    private _tokenCache: ITokenStorage = {
        publicToken: {} as ITokenStorageItem,
        userToken: {} as ITokenStorageItem,
    };

    constructor(
        @Inject loggerFactory: LoggerFactory,
        @Inject storageService: SessionStorageService,
        @Inject fetchService: FetchService,
        @Inject eventBinder: EventBinder,
        @Inject cookieService: CookieService
    ) {
        this._logger = loggerFactory.getLogger(this.key);
        this._storage = storageService;
        this._fetchService = fetchService;
        this._eventBinder = eventBinder;
        this._cookieService = cookieService;
        this.init();
    }

    public init(): this {
        // setup event binder with context pointing to this instance
        this._eventBinder.init(this);

        return this;
    }

    /**
     * Binds custom token updated event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindTokenUpdated(
        tokenType: tokenTypes,
        handler: TokenUpdateEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        this._eventBinder.bindCustomEvent(
            this.TOKEN_CONFIG[tokenType].updatedEvent,
            handler,
            namespace,
            one
        );

        return this;
    }

    /**
     * Disconnects custom token updated event handler or all ready event handlers
     * when no particular handler reference was provided.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param handler Optional event handler method reference.
     */
    public unbindTokenUpdated(
        tokenType: tokenTypes,
        namespace?: string,
        handler?: TokenUpdateEventHandler
    ): this {
        this._eventBinder.unbindCustomEvent(
            this.TOKEN_CONFIG[tokenType].updatedEvent,
            namespace,
            handler
        );

        return this;
    }

    /**
     * Dispatches custom token update event.
     */
    private _dispatchUpdateEvent(tokenType: tokenTypes): void {
        const tokenData = this._tokenCache[tokenType].tokenData;
        const event = $.Event(this.TOKEN_CONFIG[tokenType].updatedEvent, {
            tokenData,
        }) as ITokenUpdateEvent;
        this._logger.info("Triggering token update event: %o ...", event);
        this._eventBinder.trigger(event);
    }

    public async getToken(): Promise<ITokenData | null> {
        const isInitToken = await this.initToken(tokenTypes.userToken);
        return isInitToken ? this._tokenCache[tokenTypes.userToken].tokenData : null;
    }

    public async getPublicToken(): Promise<ITokenData | null> {
        const isInitToken = await this.initToken(tokenTypes.publicToken);
        return isInitToken ? this._tokenCache[tokenTypes.publicToken].tokenData : null;
    }

    public async initToken(tokenType: tokenTypes): Promise<boolean> {
        const token = this._tokenCache[tokenType];
        if (!token.tokenData) {
            token.tokenData = this._getTokenFromStorage(tokenType);
        }

        const cookieUser = this._getUserFromCookie(tokenType);

        const isUserSame = cookieUser === token.tokenData?.User;
        const isTokenValid = token.tokenData && !this.hasTokenExpired(tokenType) && isUserSame;

        if (!isTokenValid) {
            if (token.tokenData) {
                this._removeTokenFromStorage(tokenType);
            }

            try {
                const expirationDate = new Date();
                const response = await this._requestToken(tokenType);
                expirationDate.setSeconds(expirationDate.getSeconds() + response.ExpirationSeconds);
                token.tokenData = {
                    ExpirationDate: expirationDate.toJSON(),
                    Token: response.Token,
                    User: cookieUser,
                };
                this._saveTokenToStorage(tokenType);
                this._dispatchUpdateEvent(tokenType);
            } catch (error) {
                const errorMessage = `Couldn't request "${tokenType}" token from API: ${error}`;
                this._logger.error(errorMessage);
                throw new Error(errorMessage);
            } finally {
                if (!isUserSame) {
                    token.tokenRequest = null;
                }
            }
        }

        this._setRefreshTimer(tokenType);
        return true;
    }

    private async _requestToken(tokenType: tokenTypes): Promise<IRequestTokenData> {
        const token = this._tokenCache[tokenType];
        try {
            let result = await token.tokenRequest;
            if (!result) {
                const request = this._fetchService.post<IRequestTokenData>(
                    this.TOKEN_CONFIG[tokenType].apiUrl
                );

                token.tokenRequest = request;
                result = await request;
            }

            return result;
        } catch (error) {
            const errorMessage = `Failed: request call for token is Failed ${error}`;
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }
    }

    private _getUserFromCookie(tokenType: tokenTypes): string | undefined {
        if (tokenType === tokenTypes.publicToken) {
            return "anonymous";
        }

        const cookieValue = this._cookieService.getCookie(this.userCookieName);
        if (cookieValue) {
            return cookieValue;
        }

        return undefined;
    }

    private _removeTokenFromStorage(tokenType: tokenTypes): void {
        try {
            this._storage.removeItem(this.TOKEN_CONFIG[tokenType].storageKey);
            this._tokenCache[tokenType].tokenData = null;
        } catch (error) {
            const errorMessage = `Couldn't remove data from storage: ${error}`;
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }
    }

    private _saveTokenToStorage(tokenType: tokenTypes): void {
        try {
            this._storage.setItem(
                this.TOKEN_CONFIG[tokenType].storageKey,
                this._tokenCache[tokenType].tokenData
            );
        } catch (error) {
            const errorMessage = `Couldn't write data to storage: ${error}`;
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }
    }

    private _getTokenFromStorage(tokenType: tokenTypes): IStoredTokenData {
        const data = this._storage.getItem(this.TOKEN_CONFIG[tokenType].storageKey);
        return data ? JSON.parse(data) : null;
    }

    private _setRefreshTimer(tokenType: tokenTypes): void {
        const token = this._tokenCache[tokenType];
        if (token.timerId) {
            return;
        }

        const timeToRefresh = this._getTimeTokenRefresh(tokenType);

        token.timerId = window.setTimeout(() => {
            token.timerId = null;
            token.tokenRequest = null;
            this.initToken(tokenType);
        }, timeToRefresh);
    }

    private _getCurrentDateMilliseconds(): number {
        const currentDate: number = new Date().getTime();

        return currentDate;
    }

    private _getTimeTokenRefresh(tokenType: tokenTypes): number {
        const tokenData = this._tokenCache[tokenType].tokenData;
        if (!tokenData) {
            return this.TOKEN_REQUEST_INTERVAL;
        }
        const expirationTokenDate = tokenData.ExpirationDate;
        const expirationDate: number = Date.parse(expirationTokenDate);
        const currentDate: number = this._getCurrentDateMilliseconds();
        const diffDate = expirationDate - currentDate;
        const timeToRefresh = diffDate > 0 ? diffDate : this.TOKEN_REQUEST_INTERVAL;

        return timeToRefresh;
    }

    public hasTokenExpired(tokenType: tokenTypes): boolean {
        const tokenData = this._tokenCache[tokenType].tokenData;
        if (!tokenData) {
            return true;
        }
        const expirationTokenDate = tokenData.ExpirationDate;
        const expirationDate: number = Date.parse(expirationTokenDate);

        return expirationDate <= this._getCurrentDateMilliseconds();
    }
}
