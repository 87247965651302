import { ILogAppender } from "./ILogAppender";
import { LogAppender } from "./LogAppender";
import { DebugOutputType } from "./DebugOutputType";

/**
 * Simple console logger.
 */
/* eslint-disable no-console */
export class ConsoleLogAppender extends LogAppender implements ILogAppender {
    public readonly outputType: DebugOutputType = DebugOutputType.Console;

    public error(
        prefix: string,
        showCallStack: boolean,
        message?: any,
        ...optionalParameters: any[]
    ): this {
        if (window.console && typeof console.error === "function") {
            // eslint-disable-line
            console.error(`${prefix}${message}`, ...optionalParameters);
            if (showCallStack) {
                console.log(this.getCallStack());
            }
        }

        return this;
    }

    public warning(
        prefix: string,
        showCallStack: boolean,
        message?: any,
        ...optionalParameters: any[]
    ): this {
        if (window.console && typeof console.warn === "function") {
            // eslint-disable-line
            console.warn(`${prefix}${message}`, ...optionalParameters);
            if (showCallStack) {
                console.log(this.getCallStack());
            }
        }

        return this;
    }

    public info(
        prefix: string,
        showCallStack: boolean,
        message?: any,
        ...optionalParameters: any[]
    ): this {
        if (window.console && typeof console.info === "function") {
            // eslint-disable-line
            console.info(`${prefix}${message}`, ...optionalParameters);
            if (showCallStack) {
                console.log(this.getCallStack());
            }
        }

        return this;
    }

    public log(
        prefix: string,
        showCallStack: boolean,
        message?: any,
        ...optionalParameters: any[]
    ): this {
        if (window.console && typeof console.log === "function") {
            // eslint-disable-line
            console.log(`${prefix}${message}`, ...optionalParameters);
            if (showCallStack) {
                console.log(this.getCallStack());
            }
        }

        return this;
    }
}
/* eslint-enable no-console */
