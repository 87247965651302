import { autobind } from "core-decorators";
import { EditorInputKendo } from "./EditorInputKendo";

export class EditorInputAutoComplete extends EditorInputKendo {
    public component: kendo.ui.AutoComplete;

    public ctor(component: Element): void {
        super.ctor(component);
        this.component = kendo.createAutoComplete(component);
    }

    /**
     * Prevents scrolling the whole page when the first/last item is reached during scrolling in dropdown
     */
    @autobind
    public preventPageScroll(): void {
        this.component.ul.on("wheel", (e: JQuery.TriggeredEvent) => {
            const container = e.currentTarget as HTMLElement;
            const scrollEvent = e.originalEvent as WheelEvent;
            if (!container || !scrollEvent) {
                return;
            }

            if (
                (container.scrollTop === 0 && scrollEvent.deltaY < 0) ||
                (container.scrollTop === container.scrollHeight - container.offsetHeight &&
                    scrollEvent.deltaY > 0)
            ) {
                e.preventDefault();
                e.stopPropagation();
            }
        });
    }
}
