import { autobind } from "core-decorators";
import { Container, Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { Utils } from "Helpers/Scripts/Utils";
import { EventBinder } from "Events/Scripts/EventBinder";
import { ICookieService } from "Cookies/Scripts/ICookieService";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { CookieService } from "Cookies/Scripts/CookieService";
import { IBreakpointDto } from "./IBreakpointDto";
import { IBreakpoint } from "./IBreakpoint";
import { Breakpoint } from "./Breakpoint";
import { IDeviceOptions } from "./IDeviceOptions";
import { IScrollPosition } from "Helpers/Scripts/IScrollPosition";
import { IDeviceUpdateEvent } from "./IDeviceUpdateEvent";
import { IDeviceMediaEvent } from "./IDeviceMediaEvent";
import { IDeviceReadyEvent } from "./IDeviceReadyEvent";
import { IDeviceResizeEvent } from "./IDeviceResizeEvent";
import { IDeviceScrollEvent } from "./IDeviceScrollEvent";
import { READY, MEDIA, RESIZE, SCROLL, ORIENTATION } from "./DeviceUpdateEventType";
import {
    RWD_PLACEMENT_AFTER,
    RWD_PLACEMENT_BEFORE,
    RWD_PLACEMENT_APPEND,
    RWD_PLACEMENT_PREPEND,
} from "./RwdPlacementType";
import { ICookieOptions } from "./ICookieOptions";
import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import { DeviceMediaEventHandler } from "./DeviceMediaEventHandler";
import { DeviceOrientationEventHandler } from "./DeviceOrientationEventHandler";
import { DeviceReadyEventHandler } from "./DeviceReadyEventHandler";
import { DeviceResizeEventHandler } from "./DeviceResizeEventHandler";
import { DeviceScrollEventHandler } from "./DeviceScrollEventHandler";
import { DeviceType } from "./DeviceType";
import { OSName } from "./OSName";
import * as Bowser from "bowser";
import $ from "jquery";

/**
 * Client-side RWD routines & similar.
 */
@OnlyInstantiableByContainer
@Singleton
export class Device {
    public get key(): string {
        return "Device";
    }

    private readonly CHANGE: string = "change";
    private static readonly RWD_KEY: string = "rwd";
    private readonly DATA_RWD: string = `[data-${Device.RWD_KEY}]`;
    private readonly CLASS_KEY: string = "cssClass";
    private readonly SIZE_RATIO_KEY: string = "sizeRatio";
    private readonly PLACEMENT_KEY: string = "placement";
    private readonly TEXT_KEY: string = "text";
    private readonly SLIDER_KEY: string = "slider";
    private readonly DEFAULT_SIZE_INDEX: number = -1;
    private readonly SIZE_5_INDEX: number = 4;
    private readonly NO_PREFIX_ATTRIBUTES: string[] = ["colspan"];

    // wrapper on which to apply some functionality of this module
    private _elements: NodeListOf<Element>;
    // options for a cookie which propagates detected client capabilities to server
    // TODO: ICookieOptions - is it generic enough to move under cookies folder & reuse?
    private _deviceInfoCookie: ICookieOptions = {
        expires: 365,
        name: "clientCapabilities",
        path: "/",
    };

    private DID_USER_TOUCH: boolean = false;

    // basic settings for layout & other breakpoints
    private _breakpoints: IBreakpoint[] = [];
    // scroll position from when it was last checked
    private _lastScroll: IScrollPosition = this.scrollPosition;
    // last size breakpoint stored for media events with previous check
    private _lastBreakpointIndex: number;
    // indication of connected event handlers
    private _eventsConnected: boolean;

    private readonly _readyHelper: ReadyHelper;
    private readonly _binder: EventBinder;
    private readonly _windowBinder: EventBinder;
    private readonly _logger: ILogger;
    private readonly _cookieService: ICookieService;
    private readonly _parser: Bowser.Parser.Parser = Bowser.getParser(window.navigator.userAgent);

    constructor(
        @Inject readyHelper: ReadyHelper,
        @Inject binder: EventBinder,
        @Inject windowBinder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject cookieService: CookieService
    ) {
        this._readyHelper = readyHelper;
        this._binder = binder;
        this._windowBinder = windowBinder;
        this._logger = loggerFactory.getLogger(this.key);
        this._cookieService = cookieService;
        // setup event binder with context pointing to this instance
        this._binder.init(this);
        // setup window helper event binder with context pointing to window
        this._windowBinder.init(window);
    }

    public init(): this {
        // options setup also (re)connects default event handlers
        const options = Utils.getOptions(Device.RWD_KEY);
        this.setOptions(options);
        this._windowBinder.bindTouchStart(this.onFirstTouch, "", true);

        return this;
    }

    /**
     * Sets up current instance's options and (re)connects event handlers accordingly.
     */
    public setOptions(options: IDeviceOptions): this {
        this._logger.info("Setting device module options : %o ...", options);
        if (!options) {
            return this;
        }
        const {
            monitorOrientationChangeEvents = true,
            monitorResizeEvents = true,
            monitorScrollEvents = true,
            monitorLeavingMediaEvents = false,
            breakpoints = [],
        }: IDeviceOptions = options;
        this.monitorOrientationChangeEvents = monitorOrientationChangeEvents;
        this.monitorResizeEvents = monitorResizeEvents;
        this.monitorScrollEvents = monitorScrollEvents;
        this.monitorLeavingMediaEvents = monitorLeavingMediaEvents;
        breakpoints.forEach((breakpoint) => this.addBreakpoint(breakpoint));
        this._logger.info("Current breakpoints : %o", this._breakpoints);
        // apply default media queries & resizing logic
        this.applyRwd();

        return this;
    }

    /**
     * (Re)applies responsive behavior regarding dispatching events for matched
     * media queries, orientation and resize events. For now the "behavior"
     * means dispatching device update events in some generalized manner.
     * Orientantion change events should work only for some devices
     * but probably generally not needed if we solve all via media queries
     * so this is actually a fallback & can be switched on dynamically
     * for some devices via monitorOrientationChangeEvents property in setOptions().
     */
    private applyRwd(): void {
        this.unbindOptionalInternalEvents();
        this._logger.info("Connecting device event handlers ...");
        // startup ready event and media query events are connected only the first time this is executed
        if (!this._breakpoints || !this._breakpoints.length) {
            this._logger.warning(
                "No breakpoints defined while applying RWD behavior, this._breakpoints : %o.",
                this._breakpoints
            );
        } else if (!this._eventsConnected) {
            this.bindInternalMediaEvents().bindInternalReadyEvents();
            this._eventsConnected = true;
        }
        // re-connect optional events according to options
        this.bindInternalResizeEvents().bindInternalScrollEvents().bindInternalOrientationEvents();
        // dispatch 1st ready update event when DOM is ready
        this._readyHelper.bindDomReady(() => this.dispatchUpdateEvent());
    }

    /**
     * Disconnects all event handlers except ready & media events
     * (i.e. handlers dependent on optional settings).
     */
    private unbindOptionalInternalEvents(): this {
        if (!this._eventsConnected) {
            return this;
        }
        this._logger.info(
            "Disconnecting old handlers for resize, scroll & orientation change events ..."
        );
        this._windowBinder
            .unbindResize(this.key)
            .unbindScroll(this.key)
            .unbindOrientationChange(this.key);
        this._binder
            .unbindCustomEvent(RESIZE, this.key, this.defaultUpdateHandler)
            .unbindCustomEvent(SCROLL, this.key, this.defaultUpdateHandler)
            .unbindCustomEvent(ORIENTATION, this.key, this.defaultUpdateHandler);

        return this;
    }

    private bindInternalMediaEvents(): this {
        if (!this._eventsConnected) {
            this.bindMedia(this.defaultUpdateHandler, this.key);
            // connect breakpoint events when the DOM is ready
            this._breakpoints.forEach((breakpoint) =>
                breakpoint.bindEvents(this.dispatchUpdateEvent)
            );
        }

        return this;
    }

    private bindInternalReadyEvents(): this {
        if (!this._eventsConnected) {
            this.bindReady(this.defaultUpdateHandler, this.key);
        }

        return this;
    }

    private bindInternalResizeEvents(): this {
        if (this.monitorResizeEvents) {
            this.bindResize(this.defaultUpdateHandler, this.key);
            this._windowBinder.bindResize(this.dispatchUpdateEvent, this.key);
        }

        return this;
    }

    private bindInternalScrollEvents(): this {
        if (this.monitorScrollEvents) {
            this.bindScroll(this.defaultUpdateHandler, this.key);
            this._windowBinder.bindScroll(this.dispatchUpdateEvent, this.key);
        }

        return this;
    }

    private bindInternalOrientationEvents(): this {
        if (this.monitorOrientationChangeEvents && this.isDualOrientation) {
            this.bindOrientationChange(this.defaultUpdateHandler, this.key);
            this._windowBinder.bindOrientationChange(this.dispatchUpdateEvent, this.key);
        }

        return this;
    }

    /**
     * Returns current device pixel ratio (count of device pixels per 1 "virtual" CSS pixel).
     */
    public get devicePixelRatio(): number {
        return window.devicePixelRatio || 1;
    }

    /**
     * Specifies whether to monitor device double orientation events.
     */
    public monitorOrientationChangeEvents: boolean;

    /**
     * Specifies whether to monitor viewport resize events.
     */
    public monitorResizeEvents: boolean;

    /**
     * Specifies whether to monitor viewport scroll events.
     */
    public monitorScrollEvents: boolean;

    /**
     * Specifies whether to monitor also leaving media queries. This means
     * the media query event is in this case generated when the size breakpoint
     * is being left & another breakpoint starts matching (which triggers own event).
     * This could be useful in some special cases for cleanups etc.
     */
    public monitorLeavingMediaEvents: boolean;

    /**
     * @returns Array of currently set breakpoints.
     * Currently only size breakpoints are used.
     */
    public get breakpoints(): IBreakpoint[] {
        return this._breakpoints;
    }

    /**
     * Adds one media query / breakpoint object into options.breakpoints
     * configuration and immediatelly connects its events to update event
     * trigger.
     */
    public addBreakpoint(data: IBreakpointDto): this {
        const breakpoint = Container.get(Breakpoint);
        breakpoint.init(data);
        if (breakpoint.mq) {
            this._breakpoints.push(breakpoint);
        }

        return this;
    }

    /**
     * On touchstart handler
     */
    private onFirstTouch(): void {
        this.DID_USER_TOUCH = true;
    }

    /**
     * Returns true when touch input is supposed to work on current device.
     * Warning: There is no really reliable way how to detect touch currently in browsers.
     */
    public get isTouch(): boolean {
        return this.DID_USER_TOUCH || "ontouchstart" in window || navigator.maxTouchPoints > 0;
    }

    /**
     * Returns true when mobile device detected.
     */
    public get isMobile(): boolean {
        return this.isDeviceType(DeviceType.Mobile);
    }

    /**
     * Returns true when tablet device detected.
     */
    public get isTablet(): boolean {
        return this.isDeviceType(DeviceType.Tablet);
    }

    /**
     * Returns true when desktop device detected.
     */
    public get isDesktop(): boolean {
        return this.isDeviceType(DeviceType.Desktop);
    }

    /**
     * Check device type.
     */
    public isDeviceType(deviceType: string): boolean {
        return this._parser.getPlatformType() === deviceType;
    }

    /**
     * Returns true when iOS device detected.
     */
    public get isIOS(): boolean {
        return this.isOSName(OSName.IOS);
    }

    /**
     * Check OS name.
     */
    public isOSName(osName: string): boolean {
        return this._parser.getOSName() === osName;
    }

    /**
     * Returns true when double orientation events are supposed to work on current device.
     */
    public get isDualOrientation(): boolean {
        return "orientation" in window && "onorientationchange" in window;
    }

    /**
     * Returns current scroll position in browser's window.
     */
    public get scrollPosition(): IScrollPosition {
        return Utils.getScrollPosition(window);
    }

    /**
     * @returns Currently active (matching) breakpoint object or undefined.
     */
    public get activeBreakpoint(): IBreakpoint {
        const result = this._breakpoints.filter((item) => item.isActive);

        if (!result.length) {
            const errorMessage = "Active breakpoint was not found or set properly.";
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        } else if (result.length > 1) {
            // mitigation of FF behaviour - on resize down, this always occurs with result[0] being the currently valid one
            if (result.length === 2) {
                // eslint-disable-line no-magic-numbers
                return result[0];
            }
            const errorMessage = "Too many active breakpoints found.";
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        return result[0];
    }

    /**
     * Convenience property for checking 5th breakpoint.
     * @returns true when the 5th breakpoint is active.
     */
    public get isFifthBreakpoint(): boolean {
        return this.activeBreakpoint.index === this.SIZE_5_INDEX;
    }

    /**
     * @returns Currently available viewport width in CSS pixels.
     */
    public get availableWidthPixels(): number {
        return Utils.getWidth(window);
    }

    /**
     * @returns Currently available viewport height in CSS pixels.
     */
    public get availableHeightPixels(): number {
        return Utils.getHeight(window);
    }

    /**
     * Detects client-side capabilities and overrides the default properties
     * detected on server. Sets the capabilites cookie to be sent to server
     * with next request ( normally only when not in iframe ).
     */
    private writeCookie(): void {
        if (Utils.isIframe) {
            this._logger.warning(
                "Currently in iframe mode, will not save capabilities cookie. URL : %s",
                location.href
            );
            return;
        }
        const cookie = this._deviceInfoCookie;
        // create cookie value
        cookie.value =
            `${this.devicePixelRatio}|${this.isTouch ? "1" : "0"}|` +
            `${this.activeBreakpoint ? this.activeBreakpoint.index : this.SIZE_5_INDEX}`;
        // write capabilities cookie
        this._cookieService.setCookie(cookie.name, cookie.value, cookie.expires);
        this._logger.info(`Saved device capabilities cookie : ${cookie.value}`);
    }

    /**
     * Dispatches custom device update event.
     * The original event is stored in originalObject property and can be undefined.
     * When undefined, ready update type will be triggered.
     */
    @autobind
    public dispatchUpdateEvent(originalObject?: any): void {
        let eventType = READY;
        if (originalObject) {
            eventType = originalObject.type ? originalObject.type : MEDIA;
        }
        // Chrome 39 seems to dispatch rather change event instead of media
        if (eventType === this.CHANGE) {
            eventType = MEDIA;
        }

        const event = $.Event(eventType, { originalObject }) as IDeviceUpdateEvent;

        switch (eventType) {
            case READY:
                this.setupReadyEvent(event);
                this.writeCookie();
                break;
            case MEDIA:
                const mediaEvent = this.setupMediaEvent(event, originalObject);
                this.writeCookie();
                // don't trigger leaving-old-breakpoint media event if we don't want it
                if (!mediaEvent.matches && !this.monitorLeavingMediaEvents) {
                    return;
                }
                break;
            case RESIZE:
                this.setupResizeEvent(event);
                break;
            case SCROLL:
                this.setupScrollEvent(event);
                break;
            default:
                this._logger.info(
                    `Unknown EventType '${eventType}' was set. Don't know how to handle.`
                );
                break;
        }
        this._logger.info("Triggering device update event (%s): %o ...", event.type, event);
        this._binder.trigger(event);
    }

    /**
     * Updates ready event object to be triggered with additional properties.
     * Also updates the internally stored _lastBreakpointIndex according to active breakpoint.
     * @param event Original update event.
     * @returns Updated event object cast as proper event type.
     */
    private setupReadyEvent(event: IDeviceUpdateEvent): IDeviceReadyEvent {
        const result = event as IDeviceReadyEvent;
        this._lastBreakpointIndex = this.activeBreakpoint.index;
        result.lastBreakpointIndex = this.activeBreakpoint.index;

        return result;
    }

    /**
     * Updates media query event object to be triggered with additional properties.
     * Also updates the internally stored _lastBreakpointIndex if the event's mql currently matches.
     * @param event Original update event.
     * @returns Updated event object cast as proper event type.
     */
    private setupMediaEvent(event: IDeviceUpdateEvent, originalObject?: any): IDeviceMediaEvent {
        const result = event as IDeviceMediaEvent;
        const mql =
            originalObject && originalObject.target ? originalObject.target : originalObject;
        const breakpoint = this.findBreakpointWithMql(mql);
        let matches = false;

        if (breakpoint) {
            matches = breakpoint.isActive;
            result.breakpoint = breakpoint;

            if (matches) {
                this._lastBreakpointIndex = breakpoint.index;
            }
        }

        result.matches = matches;
        result.lastBreakpointIndex = this._lastBreakpointIndex;

        return result;
    }

    /**
     * Returns a breakpoint with specified media query list object, or null when not found.
     */
    private findBreakpointWithMql(mql: MediaQueryList): IBreakpoint | null {
        if (!mql) {
            return null;
        }
        const filtered = this._breakpoints.filter((breakpoint) => breakpoint.hasMql(mql));

        return filtered[0] || null;
    }

    /**
     * Updates resize event object to be triggered with additional properties.
     * @param event Original update event.
     * @returns Updated event object cast as proper event type.
     */
    private setupResizeEvent(event: IDeviceUpdateEvent): IDeviceResizeEvent {
        const result = event as IDeviceResizeEvent;
        const size = Utils.getSize(window);
        result.width = size.width;
        result.height = size.height;

        return result;
    }

    /**
     * Updates scroll event object to be triggered with additional properties.
     * Also updates the internally stored _lastScroll position.
     * @param event Original update event.
     * @returns Updated event object cast as proper event type.
     */
    private setupScrollEvent(event: IDeviceUpdateEvent): IDeviceScrollEvent {
        const result = event as IDeviceScrollEvent;
        const scroll = this.scrollPosition;
        result.scrollTop = scroll.top;
        result.scrollLeft = scroll.left;
        result.scrollDeltaTop = scroll.top - this._lastScroll.top;
        result.scrollDeltaLeft = scroll.left - this._lastScroll.left;
        this._lastScroll = scroll;

        return result;
    }

    /**
     * @returns List of currently targetted elements with RWD data.
     * The list is updated on both ready and media query events
     * so that it doesn't have to be re-targetted in each handler.
     */
    public get elements(): NodeListOf<Element> {
        return this._elements;
    }

    /**
     * Default update event logic handler for functionality implemented in
     * device module itself. Other handlers can be bound later as needed.
     */
    public defaultUpdateHandler = (event: IDeviceUpdateEvent): void => {
        const isMainEvent = event.type === READY || event.type === MEDIA;
        const isScrollEvent = event.type === SCROLL;

        // select and cache RWD elements only on main events
        if (isMainEvent || !this._elements) {
            this._elements = document.querySelectorAll(this.DATA_RWD);
        }

        // iterate through elements and do RWD updates
        this.elements.forEach((element) => {
            const data = Utils.getData(element, Device.RWD_KEY);

            Object.keys(data).forEach((key) => {
                const value = data[key];
                switch (key) {
                    case this.CLASS_KEY:
                        if (isMainEvent) {
                            this.updateCssClass(element, value);
                        }
                        break;
                    case this.SIZE_RATIO_KEY:
                        if (!isScrollEvent) {
                            this.updateSizeRatio(element, this.valueForCurrentBreakpoint(value));
                        }
                        break;
                    case this.PLACEMENT_KEY:
                        if (isMainEvent) {
                            this.updatePosition(element, this.valueForCurrentBreakpoint(value));
                        }
                        break;
                    case this.TEXT_KEY:
                        if (isMainEvent) {
                            this.updateText(element, this.valueForCurrentBreakpoint(value));
                        }
                        break;
                    case this.SLIDER_KEY:
                        // we dont need any data attribute for sliders
                        break;
                    default:
                        if (isMainEvent) {
                            let attrKey = Utils.hyphenate(key);
                            if (this.NO_PREFIX_ATTRIBUTES.indexOf(key) === -1) {
                                attrKey = `data-${attrKey}`;
                            }
                            this.updateRwdAttribute(element, Utils.hyphenate(attrKey), value);
                        }
                }
            });
        });
    };

    /**
     * @returns value from specified data object for matching breakpoint key.
     */
    public valueForCurrentBreakpoint(data: any): any {
        let key = this.activeBreakpoint.index;
        let value = data && data.hasOwnProperty(key) ? data[key] : undefined;
        if (typeof value === "undefined") {
            key = this.DEFAULT_SIZE_INDEX;
            value = data && data.hasOwnProperty(key) ? data[key] : undefined;
        }

        return value;
    }

    /**
     * Updates selected RWD attribute value on element according to active breakpoint.
     * If no value is found for current breakpoint, the attribute will be removed.
     * @param element Element to update.
     * @param attribute Attribute name.
     * @param data Data where to find a value for current breakpoint.
     */
    private updateRwdAttribute(element: Element, attribute: string, data: Object): void {
        if (!attribute || !data) {
            return;
        }

        const value = this.valueForCurrentBreakpoint(data);
        const description = Utils.describeElement(element);

        if (value) {
            element.setAttribute(attribute, value);
            this._logger.info(
                `Attribute '${attribute}' for element '${description}' was set to '${value}'.`
            );
        } else if (element.hasAttribute(attribute)) {
            element.removeAttribute(attribute);
            this._logger.info(
                `Attribute '${attribute}' was removed from element '${description}'.`
            );
        }
    }

    /**
     * Updates CSS class on element according to active breakpoint.
     * @param element Element to update.
     * @param data Data where to find a values for CSS classes per breakpoints.
     */
    private updateCssClass(element: Element, data: Object): void {
        const newClass = this.valueForCurrentBreakpoint(data);
        let description = Utils.describeElement(element);
        // remove old RWD classes
        Object.keys(data).forEach((key) => {
            const oldClass = (data as any)[key];
            if (Utils.hasClass(element, oldClass) && oldClass !== newClass) {
                Utils.removeClass(element, oldClass);
                description = description.replace(`.${oldClass}`, "");
                this._logger.info(`Removed class ${oldClass} from element '${description}'.`);
            }
        });
        if (!newClass) {
            return;
        }
        // apply new classes for active breakpoint
        Utils.addClass(element, newClass);
        this._logger.info(`Added class ${newClass} to element '${description}'.`);
    }

    /**
     * Generic placement helper method. So far logic is the same for all placement types.
     * @param element The element being moved.
     * @param placement The placement method for the selected element.
     * @param data Whole placement data found on given element.
     */
    private updatePosition(element: Element, data: any[]): void {
        const placementIndex = 0;
        const targetSelectorIndex = 1;

        const placement = data && data[placementIndex];
        const targetSelector = data && data[targetSelectorIndex];

        if (!element || !placement || !targetSelector) {
            return;
        }

        // Could be done via generic Utils[placement.toString()](value, element);
        // but this way we have more type checking
        const description = Utils.describeElement(element);
        switch (placement) {
            case RWD_PLACEMENT_AFTER:
                Utils.after(targetSelector, element);
                this._logger.info(
                    `Moved element '${description}' after element with selector '${targetSelector}'.`
                );
                break;
            case RWD_PLACEMENT_BEFORE:
                Utils.before(targetSelector, element);
                this._logger.info(
                    `Moved element '${description}' before element with selector '${targetSelector}'.`
                );
                break;
            case RWD_PLACEMENT_PREPEND:
                Utils.prepend(targetSelector, element);
                this._logger.info(
                    `Prepended element '${description}' into element with selector '${targetSelector}'.`
                );
                break;
            case RWD_PLACEMENT_APPEND:
                Utils.append(targetSelector, element);
                this._logger.info(
                    `Appended element '${description}' into element with selector '${targetSelector}'.`
                );
                break;
            default:
                this._logger.info(`Placement was set with incorrect value of '${placement}'`);
                break;
        }
    }

    /**
     * Updates size ratio on specified element according to this formula: height = width / ratio.
     * @param element Element to be updated.
     * @param ratio Numeric positive and non-zero value used for sizing the element.
     */
    private updateSizeRatio(element: Element, ratio: number): void {
        if (!element) {
            return;
        }
        const description = Utils.describeElement(element);
        if (isNaN(ratio) || ratio === 0) {
            this._logger.warning(
                `Size ratio specified on element '${description}' is not a number or is equal to zero.`
            );

            return;
        }
        const height = Math.round(Utils.getWidth(element, true) / ratio);
        Utils.setCss(element, "height", `${height}px`);
        this._logger.info(`Height on element '${description}' was set to ${height}px.`);
    }

    /**
     * Updates text content of specific element.
     * @param element Element to be updated.
     * @param text New text content for the element.
     */
    private updateText(element: Element, text: string): void {
        const description = Utils.describeElement(element);
        Utils.setText(element, text || "");
        this._logger.info(`Updated text of element '${description}' to '${text}'.`);
    }

    /**
     * Binds custom startup ready event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * TODO: promise in device for this?
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindReady(handler: DeviceReadyEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindCustomEvent(READY, handler, namespace, one);

        return this;
    }

    /**
     * Binds custom resize event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindResize(handler: DeviceResizeEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindCustomEvent(RESIZE, handler, namespace, one);

        return this;
    }

    /**
     * Binds custom media query event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindMedia(handler: DeviceMediaEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindCustomEvent(MEDIA, handler, namespace, one);

        return this;
    }

    /**
     * Binds custom scroll event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindScroll(handler: DeviceScrollEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindCustomEvent(SCROLL, handler, namespace, one);

        return this;
    }

    /**
     * Binds custom orientation change event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindOrientationChange(
        handler: DeviceOrientationEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        this._binder.bindCustomEvent(ORIENTATION, handler, namespace, one);

        return this;
    }

    /**
     * Triggers a new custom device event using the event binder helper class.
     * @param event Event object or type of the event as a string. If only string is provided,
     * a corresponding event object will be created first.
     * @param eventData Optional additional event data which will be merged into the event.
     */
    public trigger(event: IDeviceUpdateEvent | string, eventData?: any[] | Object): this {
        this._binder.trigger(event, eventData);

        return this;
    }

    /**
     * Disconnects custom startup ready event handler or all ready event handlers
     * when no particular handler reference was provided.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param handler Optional event handler method reference.
     */
    public unbindReady(namespace?: string, handler?: DeviceReadyEventHandler): this {
        this._binder.unbindCustomEvent(READY, namespace, handler);

        return this;
    }

    /**
     * Disconnects custom resize event handler or all resize event handlers
     * when no particular handler reference was provided.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param handler Optional event handler method reference.
     */
    public unbindResize(namespace?: string, handler?: DeviceResizeEventHandler): this {
        this._binder.unbindCustomEvent(RESIZE, namespace, handler);

        return this;
    }

    /**
     * Disconnects custom media query event handler or all media query event handlers
     * when no particular handler reference was provided.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param handler Optional event handler method reference.
     */
    public unbindMedia(namespace?: string, handler?: DeviceMediaEventHandler): this {
        this._binder.unbindCustomEvent(MEDIA, namespace, handler);

        return this;
    }

    /**
     * Disconnects custom scroll event handler or all scroll event handlers
     * when no particular handler reference was provided.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param handler Optional event handler method reference.
     */
    public unbindScroll(namespace?: string, handler?: DeviceResizeEventHandler): this {
        this._binder.unbindCustomEvent(SCROLL, namespace, handler);

        return this;
    }

    /**
     * Disconnects custom orientation change event handler or all orientation change event handlers
     * when no particular handler reference was provided.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param handler Optional event handler method reference.
     */
    public unbindOrientationChange(namespace?: string, handler?: DeviceResizeEventHandler): this {
        this._binder.unbindCustomEvent(ORIENTATION, namespace, handler);

        return this;
    }

    /**
     * Shortcut for unbinding all custom events optionally filtered by namespace.
     * @param namespace Filter for unbinding only event handlers with specific namespace.
     */
    public unbind(namespace?: string): this {
        this.unbindReady(namespace);
        this.unbindResize(namespace);
        this.unbindMedia(namespace);
        this.unbindScroll(namespace);
        this.unbindOrientationChange(namespace);

        return this;
    }
}
