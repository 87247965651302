import { OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { DebugOutputType, DEFAULT_OUTPUT_TYPE } from "./DebugOutputType";
import { ILogAppender } from "./ILogAppender";
import { NullLogAppender } from "./NullLogAppender";
import { AlertsLogAppender } from "./AlertsLogAppender";
import { ConsoleLogAppender } from "./ConsoleLogAppender";
import { IList } from "Helpers/Scripts/IList";

@OnlyInstantiableByContainer
@Singleton
export class LogAppenderFactory {
    private _appenders: IList<ILogAppender> = {};

    /**
     * Creates a new appender instance or returns existing one for a given output type.
     * @param outputType Required type of appender output.
     */
    public getAppender(outputType: DebugOutputType = DEFAULT_OUTPUT_TYPE): ILogAppender {
        if (!this._appenders[outputType]) {
            this._appenders[outputType] = this.getAppenderInstance(outputType);
        }

        return this._appenders[outputType];
    }

    private getAppenderInstance(outputType: DebugOutputType = DEFAULT_OUTPUT_TYPE): ILogAppender {
        let appender: ILogAppender;
        switch (outputType) {
            case DebugOutputType.None:
                appender = new NullLogAppender();
                break;
            case DebugOutputType.Alerts:
                appender = new AlertsLogAppender();
                break;
            default:
                appender = new ConsoleLogAppender();
        }

        return appender;
    }
}
