import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { FetchService } from "Async/Scripts/FetchService";
import { Utils } from "Helpers/Scripts/Utils";
import { IContactItem } from "./IContactItem";
import { IContactsData } from "./IContactsData";
import { autobind } from "core-decorators";
import DropDownListChangeEvent = kendo.ui.DropDownListChangeEvent;

@OnlyInstantiableByContainer
@Singleton
export class NameBank extends UiComponent {
    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject fetchService: FetchService
    ) {
        super(componentFactory, binder, loggerFactory);
        this._fetchService = fetchService;
    }

    public get key(): string {
        return "NameBank";
    }

    private readonly _fetchService: FetchService;
    private readonly DATA_URL_ATTRIBUTE: string = "data-contacts-api-url";
    private readonly TAB_SELECTOR: string = ".tab";
    private readonly TAB_LINK_SELECTOR: string = ".tab-heading";
    private readonly TAB_SELECT_DROPDOWN: string = ".name-bank-tab-dropdown";

    private readonly CONTACT_ITEM_TEMPLATE_SELECTOR: string =
        "kendo-template-name-bank-contact-item";
    private readonly ALL_CONTACTS_SELECTOR: string = ".name-bank-tab--all-contacts";
    private readonly CONTACTED_SELECTOR: string = ".name-bank-tab--contacted";
    private readonly NOT_CONTACTED_SELECTOR: string = ".name-bank-tab--not-contacted";

    public init(): void {
        if (ori.tabs) {
            ori.tabs.init(this.TAB_SELECTOR, this.TAB_LINK_SELECTOR);
        }

        if (this.findElement(this.TAB_SELECT_DROPDOWN)) {
            const tabsDropdown = kendo.createDropDownList(this.TAB_SELECT_DROPDOWN);
            tabsDropdown.bindChange(this.onTabsDropdownChange);
            Utils.removeClass(this.TAB_SELECT_DROPDOWN, this.CLASS_HIDDEN);
        }

        this.showNameBankTabs();
    }

    @autobind
    private onTabsDropdownChange(event: DropDownListChangeEvent): void {
        if (ori.tabs) {
            const tabIndexToShow = event.sender.value();
            ori.tabs.showTab(this.TAB_SELECTOR, Number(tabIndexToShow));
        }
    }

    public async showNameBankTabs(): Promise<void> {
        const contactsApiUrl = this.getAttr(this.DATA_URL_ATTRIBUTE);
        if (!contactsApiUrl) {
            return;
        }
        const contactsJSON = await this._getContactsData(contactsApiUrl);
        if (contactsJSON) {
            const contacts: IContactItem[] = contactsJSON.ContactsCollection;
            this._renderNameBankTabs(contacts);
        }
    }

    private async _getContactsData(contactsApiUrl: string): Promise<IContactsData | null> {
        let contactsData: IContactsData | null = null;
        try {
            contactsData = await this._fetchService.get<IContactsData>(contactsApiUrl);
        } catch (error) {
            const errorMessage = `Api call to ${contactsApiUrl} get user contacts failed - ${error}`;
            this._logger.error(errorMessage);
        }
        return contactsData;
    }

    private _renderNameBankTabs(contacts: IContactItem[]): void {
        const contactItemTemplate = Utils.getTemplate(this.CONTACT_ITEM_TEMPLATE_SELECTOR);
        const contactedList: IContactItem[] = contacts.filter(
            (contact: IContactItem) => contact.IsContacted
        );
        const notContactedList: IContactItem[] = contacts.filter(
            (contact: IContactItem) => !contact.IsContacted
        );

        this._renderTabItem(this.ALL_CONTACTS_SELECTOR, contacts, contactItemTemplate);
        this._renderTabItem(this.CONTACTED_SELECTOR, contactedList, contactItemTemplate);
        this._renderTabItem(this.NOT_CONTACTED_SELECTOR, notContactedList, contactItemTemplate);
    }

    private _renderTabItem(
        selector: string,
        content: IContactItem[],
        contactTemplate: Function
    ): void {
        const tabElement = this.findElement(selector, true) as HTMLElement;
        tabElement.innerHTML = contactTemplate(content).trim();
    }
}
