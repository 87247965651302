/**
 * Order Status Info
 */

import { OrderStatus } from "ECommerce/Orders/OrderListing/Scripts/OrderStatus";

export class OrderStatusInfo {
    CurrentStatus: OrderStatus;
    OrderStatusClass: string;
    OrderStatusIcon: string;

    constructor(orderStatusClass: string, orderStatusIcon: string) {
        this.OrderStatusClass = orderStatusClass;
        this.OrderStatusIcon = orderStatusIcon;
    }
}
