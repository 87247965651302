import { Inject } from "typescript-ioc";
import { autobind } from "core-decorators";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { Utils } from "Helpers/Scripts/Utils";

export class TextAreaWithCounter extends UiComponent {
    private readonly DATA_LABEL_INSIDE: string = "label-inside";
    private readonly DATA_COUNTER_SUFFIX: string = "counter-suffix";
    private readonly DATA_MAX_LENGTH: string = "max-length";
    private readonly ATTR_COUNTER_VALUE: string = "data-counter-value";
    private readonly CLASS_COUNTER: string = "counter";
    private readonly CLASS_ERROR: string = "error";

    private _counterElement: HTMLDivElement;
    private _maxLength: number;
    private _isValid: boolean;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    public get context(): HTMLTextAreaElement {
        return this.getContextAs(HTMLTextAreaElement);
    }

    public init(): void {
        kendo.createTextArea(this.context);
        const kendoWrapper = this.context.parentElement;
        if (!kendoWrapper) {
            return;
        }
        const parent = kendoWrapper.parentElement as HTMLElement;

        const labelInside = Boolean(this.getData(this.DATA_LABEL_INSIDE));
        const labelElement = parent.querySelector(`label[for="${this.getAttr("name")}"]`);
        if (labelInside && labelElement) {
            Utils.prepend(kendoWrapper, labelElement);
        }

        this._maxLength = this.getData(this.DATA_MAX_LENGTH) || 0;
        this._isValid = true;

        this._counterElement = document.createElement("div");
        this._counterElement.classList.add(this.CLASS_COUNTER);
        if (this._maxLength) {
            this._counterElement.innerHTML = `/${this._maxLength}`;
        }
        const suffix = this.getData(this.DATA_COUNTER_SUFFIX);
        if (suffix) {
            this._counterElement.setAttribute(`data-${this.DATA_COUNTER_SUFFIX}`, ` ${suffix}`);
        }
        Utils.append(kendoWrapper, this._counterElement);

        this.context.addEventListener("input", this._onTextareaInput);
        this.context.addEventListener("propertychange", this._onTextareaInput);

        this._onTextareaInput();
    }

    public isValid(): boolean {
        return this._isValid;
    }

    public getValue(): string {
        return this.context.value;
    }

    public setValue(value: string | number | string[]): this {
        Utils.setValue(this._context, value);
        this._onTextareaInput();
        return this;
    }

    public destroy(): void {
        this.context.removeEventListener("input", this._onTextareaInput);
        this.context.removeEventListener("propertychange", this._onTextareaInput);
    }

    @autobind
    private _onTextareaInput(_event?: Event | undefined): void {
        const length = this.context.value.replace(/\r\n?|\n/g, "\n").length;
        this._counterElement.setAttribute(this.ATTR_COUNTER_VALUE, length.toString());

        if (!this._maxLength) {
            return;
        }

        this._isValid = length <= this._maxLength;
        this._counterElement.classList.toggle(this.CLASS_ERROR, !this._isValid);
    }
}
