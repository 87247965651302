import { Inject } from "typescript-ioc";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EndConsumerEditorComponent } from "./EndConsumerEditorComponent";

export class EndConsumerEditorComponentFactory {
    public get key(): string {
        return "EndConsumerEditorComponentFactory";
    }

    private _uiComponentFactory: UiComponentFactory;

    constructor(@Inject uiComponentFactory: UiComponentFactory) {
        this._uiComponentFactory = uiComponentFactory;
    }

    /**
     * Creates new End Consumer Editor Component instance based on selector.
     * @param selector Element or selector string for context.
     * @returns New instance of End Consumer Editor.
     */
    public create(selector: Element | string): EndConsumerEditorComponent {
        if (!selector) {
            throw new Error("No selector provided");
        }

        return this._uiComponentFactory.createBase(EndConsumerEditorComponent, selector);
    }
}
