import { Inject } from "typescript-ioc";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { DocumentComponent } from "./DocumentComponent";
import { Utils } from "Helpers/Scripts/Utils";

export class DocumentComponentFactory {
    private _uiComponentFactory: UiComponentFactory;

    public readonly COMPONENT_SELECTOR: string = ".js-document-upload-wrapper";

    constructor(@Inject uiComponentFactory: UiComponentFactory) {
        this._uiComponentFactory = uiComponentFactory;
    }

    public create(selector: Element | string): DocumentComponent {
        if (!selector) {
            throw new Error("No selector provided");
        }

        return this._uiComponentFactory.createBase(DocumentComponent, selector);
    }

    public createAll(context: Element | string = "body"): DocumentComponent[] {
        const componentElements: Element[] = Utils.find(context, this.COMPONENT_SELECTOR);
        const components: DocumentComponent[] = componentElements.map((component) =>
            this.create(component)
        );

        return components;
    }
}
