import { DocumentItemType } from "Documents/Scripts/DocumentItemType";

// this is referenced from .cs
// property cannot be optional because kendo template cannot handle missing property
export class DocumentItem {
    // ReSharper disable InconsistentNaming
    public DocumentId: string;
    public DownloadUrl: string;
    public ErrorMessage: string;
    public FileName: string;
    public ItemId: string;
    public ItemType: DocumentItemType;
    public ThumbnailUrl: string;
    public FileSize: string;
    public IsApproved: boolean;
    // ReSharper restore InconsistentNaming

    constructor(itemId: string, fileName: string, fileSize: string) {
        this.DocumentId = "";
        this.DownloadUrl = "";
        this.ErrorMessage = "";
        this.FileName = fileName;
        this.ItemId = itemId;
        this.ItemType = DocumentItemType.None;
        this.ThumbnailUrl = "";
        this.FileSize = fileSize;
        this.IsApproved = false;
    }
}
