import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Form } from "Ui/Scripts/Form";
import { Button } from "Ui/Scripts/Button";
import { Utils } from "Helpers/Scripts/Utils";
import { AddressEditorFactory } from "ECommerce/Shared/Scripts/AddressEditorFactory";

export class EndConsumerEditorComponentView extends UiComponent {
    public customerId: number;
    public customerName: string;

    public editorForm: Form;
    public deleteButton: Button;
    public cancelButton: Button;

    public errorModalTitle: string;
    public errorModalMessage: string;
    public errorModalCloseText: string;

    private readonly DATA_CUSTOMER_ID: string = "customer-id";
    private readonly DATA_CUSTOMER_NAME: string = "customer-name";

    private readonly DATA_ERROR_MODAL_TITLE: string = "error-modal-title";
    private readonly DATA_ERROR_MODAL_MESSAGE: string = "error-modal-message";
    private readonly DATA_ERROR_MODAL_CLOSE_TEXT: string = "error-modal-close-text";

    private readonly EDITOR_FORM_SELECTOR: string = ".js-end-consumer-editor-form";
    private readonly DELETE_BUTTON_SELECTOR: string = ".js-delete-end-consumer";
    private readonly CANCEL_BUTTON_SELECTOR: string = ".js-cancel-end-consumer";

    private _addressEditorFactory: AddressEditorFactory;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject addressEditorFactory: AddressEditorFactory
    ) {
        super(componentFactory, binder, loggerFactory);

        this._addressEditorFactory = addressEditorFactory;
    }

    protected init(): void {
        this.customerId = this.getData(this.DATA_CUSTOMER_ID);
        this.customerName = this.getData(this.DATA_CUSTOMER_NAME);

        const config = {
            applyOverlay: true,
            trimFields: true,
        };

        this.editorForm = this.createComponent(Form, this.EDITOR_FORM_SELECTOR, config);

        this._addressEditorFactory.createAll(this.context);
        if (Utils.find(this._context, this.DELETE_BUTTON_SELECTOR).length > 0) {
            this.deleteButton = this.createComponent(Button, this.DELETE_BUTTON_SELECTOR);
        }
        if (Utils.find(this._context, this.CANCEL_BUTTON_SELECTOR).length > 0) {
            this.cancelButton = this.createComponent(Button, this.CANCEL_BUTTON_SELECTOR);
        }
        this.errorModalTitle = this.getData(this.DATA_ERROR_MODAL_TITLE);
        this.errorModalMessage = this.getData(this.DATA_ERROR_MODAL_MESSAGE);
        this.errorModalCloseText = this.getData(this.DATA_ERROR_MODAL_CLOSE_TEXT);
    }
}
