import { Inject } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { GenericEventHandler } from "Events/Scripts/GenericEventHandler";
import { EventBinder } from "Events/Scripts/EventBinder";
import { UiComponentFactory } from "./UiComponentFactory";
import { Utils } from "Helpers/Scripts/Utils";
import { IScrollPosition } from "Helpers/Scripts/IScrollPosition";
import { ISize } from "Helpers/Scripts/ISize";

export class UiComponent {
    protected readonly CLASS_HIDDEN: string = "hidden";
    public static readonly COMPONENT_DATA_KEY: string = "UiComponent";

    public get key(): string {
        return UiComponent.COMPONENT_DATA_KEY;
    }

    protected _context: Element;
    protected _model: object | null = null;
    protected _contextChecked: boolean;
    protected _logger: ILogger;
    protected _binder: EventBinder;
    protected _componentFactory: UiComponentFactory;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        this._componentFactory = componentFactory;
        this._binder = binder;
        this._logger = loggerFactory.getLogger(this.key);
    }

    /**
     * Initialization of element context. Override only when really needed.
     * Use rather init() for custom initialization in subclasses if possible.
     * @param element Element to be used as UI component's DOM context.
     * @param model Optional object used as component's model.
     */
    public ctor(element: Element, model?: object): void {
        this._context = element;

        // the getter should check for proper context
        if (!this.context) {
            throw new Error(`Context element for component ${this.key} is not defined.`);
        }

        if (typeof model === "object") {
            this._model = model;
        }
        this._binder.init(this.context);
        this.setData(UiComponent.COMPONENT_DATA_KEY, this);
        this.init();
    }

    /**
     * @returns Element used as UI component's DOM context.
     */
    public get context(): Element {
        return this._context;
    }

    /**
     * @returns The component model if provided
     */
    public get model(): object | null {
        return this._model;
    }

    /**
     * Custom finalization of the component instance setup. Override as needed in subclasses.
     */
    protected init(): void {
        // empty
    }

    /**
     * Creates a sub-component using UI component factory.
     * @param componentType
     * @param selector
     * @param component model
     */
    public createComponent<T extends UiComponent>(
        componentType: new (...args: any[]) => T,
        selector?: string,
        model?: object
    ): T {
        return this._componentFactory.create(this._context, componentType, selector, model);
    }

    /**
     * Creates a sub-component from template using UI component factory.
     * @param componentType reference to the class of a new component
     * @param template      ready-to-use template method pointer
     * @param templateData  data object to be passed to the template
     * @param target        element to which to append the new element, if not provided then it is appened to the context
     */
    protected createComponentFromTemplate<T extends UiComponent>(
        componentType: new (...args: any[]) => T,
        template: (data: object) => string,
        templateData: object,
        target?: Element
    ): T {
        const context = target ? target : this.context;
        return this._componentFactory.createComponentFromTemplate(
            componentType,
            template,
            templateData,
            context
        );
    }

    /**
     * Creates a sub-component from template using UI component factory.
     * @param componentType reference to the class of a new component
     * @param templateId    ID of the template to be used
     * @param templateData  data object to be passed to the template
     * @param target        element to which to append the new element, if not provided then it is appened to the context
     */
    public createComponentFromTemplateId<T extends UiComponent>(
        componentType: new (...args: any[]) => T,
        templateId: string,
        templateData: object,
        target?: Element
    ): T {
        return this._componentFactory.createComponentFromTemplateId(
            componentType,
            templateId,
            templateData,
            target
        );
    }

    /**
     * Creates an array of sub-components using UI component factory.
     * @param componentType
     * @param selector
     */
    protected createComponentArray<T extends UiComponent>(
        componentType: new (...args: any[]) => T,
        selector: string
    ): T[] {
        return this._componentFactory.createArray(this._context, componentType, selector);
    }

    protected createView<T extends UiComponent>(componentType: new (...args: any[]) => T): T {
        return this._componentFactory.create(this._context, componentType);
    }

    /**
     * Does run-time check of a proper type of context element.
     * @param outputType          Constructor / type reference of required output type.
     * @param detailedElementType Optional detailed info about the required result.
     * For outputType HTMLElement set this to the element name. Use this for elements like
     * <section> etc. which don't have own special HTML element type defined.
     * For outputType HTMLInputElement set this to required type HTML attribute value of the input.
     */
    protected getContextAs<T extends Element>(
        outputType: {
            new (...args: any[]): T;
            type?: string;
            name?: string;
        },
        detailedElementType?: string
    ): T {
        if (this._contextChecked) {
            return this._context as any as T;
        }
        const constructorName = this._getConstructorName(outputType);
        // basic checking of instance type
        if (!(this._context instanceof outputType)) {
            const errorMessage = "Context is not set to element of required type: %o.";
            this._logger.error(errorMessage, constructorName);
            throw new Error(errorMessage);
        }

        // advanced element checking based on detailed element type
        // this will not work in IE in some cases and will be skipped
        if (constructorName && detailedElementType) {
            switch (constructorName) {
                // some elements don't have special element type and simply are of type HTMLElement, like <section>
                case "HTMLElement":
                    if (!Utils.is(this._context, detailedElementType)) {
                        const errorMessage =
                            "Context is not set to element of required type: %o, type: %s.";
                        this._logger.error(
                            errorMessage,
                            outputType.name ? outputType.name : outputType,
                            detailedElementType
                        );
                        throw new Error(errorMessage);
                    }
                    break;

                // HTMLInputElement currently covers more types of inputs
                case "HTMLInputElement":
                    const attrType = Utils.getAttr(this._context, "type");
                    if (!attrType || attrType !== detailedElementType) {
                        const errorMessage =
                            "Context is not set to input of required type: %o, type: %s.";
                        this._logger.error(
                            errorMessage,
                            outputType.name ? outputType.name : outputType,
                            detailedElementType
                        );
                        throw new Error(errorMessage);
                    }
                    break;

                case "Object":
                    // workaround for this due to problems in PhantomJS
                    break;

                default:
                    this._logger.warning(
                        "Context is not of type HTMLElement or HTMLInputElement, but detailed element type is provided."
                    );
            }
        }
        this._contextChecked = true;

        return this._context as any as T;
    }

    /**
     * Tries to get constructor name (type name) from constructor function reference.
     * @param source Constructor function reference to be checked.
     */
    private _getConstructorName(source: any): string {
        if (source.name) {
            return source.name;
        }
        try {
            // This is for IE as it doesn't have name property on constructor functions.
            const functionNameRegExp = /function (\w*)/;
            const functionNameRegExpMatchGroup = 1;

            const constructorDefinition = source.prototype.constructor.toString();
            const constructorName =
                constructorDefinition.match(functionNameRegExp)[functionNameRegExpMatchGroup];

            if (constructorName && constructorName !== "Function") {
                return constructorName;
            }
        } catch (error) {
            //
        }

        return "";
    }

    /**
     * Toggles visible and invisible state of the UI component.
     */
    public toggle(show?: boolean): this {
        let forceShow = show;
        if (typeof forceShow === "undefined") {
            forceShow = this.hasClass(this.CLASS_HIDDEN);
        }
        this.toggleClass(this.CLASS_HIDDEN, !forceShow);

        return this;
    }

    /**
     * @param name Name of the HTML attribute.
     * @returns Value of attribute with given name on context element.
     */
    public getAttr(name: string) {
        return Utils.getAttr(this.context, name);
    }

    /**
     * Sets attribute with given name and value on context element.
     * @param name Name of the HTML attribute.
     * @param value Value to be set.
     */
    public setAttr(name: string, value: string): this {
        Utils.setAttr(this.context, name, value);

        return this;
    }

    /**
     * Removes attribute with given name from context element.
     * @param name Name of the HTML attribute.
     */
    public removeAttr(name: string): this {
        Utils.removeAttr(this.context, name);

        return this;
    }

    /**
     * Checks whether context element matches the given selector.
     */
    public is(selector: any): boolean {
        return Utils.is(this.context, selector);
    }

    /**
     * Checks whether context element has a provided class name in its CSS class list.
     * @param className The class to be checked.
     */
    public hasClass(className: string): boolean {
        return Utils.hasClass(this.context, className);
    }

    /**
     * Adds the provided class name in context element's CSS class list.
     * @param className The class to be added.
     */
    public addClass(className: string): this {
        Utils.addClass(this.context, className);

        return this;
    }

    /**
     * Checks whether context element has a provided class name in its CSS class list.
     * @param className The class to be removed. When not provided, all classes will be removed.
     */
    public removeClass(className?: string): this {
        Utils.removeClass(this.context, className);

        return this;
    }

    /**
     * Switches the specified class on or off on context element.
     * @param className The class to be switched.
     * @param switchOn When tru, the class will be set. When false, the class will be removed.
     * When not specified, the class will be toggled depending on its current presence.
     */
    public toggleClass(className: string, switchOn?: boolean): this {
        Utils.toggleClass(this.context, className, switchOn);

        return this;
    }

    /**
     * @param propertyName CSS property name.
     * @returns Current value of CSS styling property on context element.
     */
    public getCss(propertyName: string): string {
        return Utils.getCss(this.context, propertyName);
    }

    /**
     * Sets CSS styling on context element.
     * @param propertyName CSS property name.
     * @param value Value to be set for the selected CSS property.
     */
    public setCss(propertyName: string, value: string | number): this {
        Utils.setCss(this.context, propertyName, value);

        return this;
    }

    /**
     * Tries to describe the context element for better identification.
     */
    public describeElement(): string {
        return Utils.describeElement(this.context);
    }

    /**
     * @param selector Selector for matching found element.
     * @returns Closest element matching the given selector starting with the context element.
     */
    public closest(selector: string): Element | undefined {
        return Utils.closest(this.context, selector);
    }

    /**
     * @returns Current scroll position of context element.
     */
    public getScrollPosition(): IScrollPosition {
        return Utils.getScrollPosition(this.context);
    }

    /**
     * @param outerSize When true, rather outerWidth() and outerHeight() will be used internally.
     * @returns Dimensions of context element.
     */
    public getSize(outerSize?: boolean): ISize {
        return Utils.getSize(this.context, outerSize);
    }

    /**
     * @param outerSize When true, rather outerWidth() will be used internally.
     * @returns Width of context element.
     */
    public getWidth(outerSize?: boolean): number {
        return Utils.getWidth(this.context, outerSize);
    }

    /**
     * @param outerSize When true, rather outerHeight() will be used internally.
     * @returns Height of context element.
     */
    public getHeight(outerSize?: boolean): number {
        return Utils.getHeight(this.context, outerSize);
    }

    /**
     * Retruns specified data stored on context element.
     * @param key Optional data key specification. When not provided, all data will be returned.
     */
    public getData(key?: string): any {
        return Utils.getData(this.context, key);
    }

    /**
     * Sets specified data on context element.
     * @param key Data key under which the data value will be stored.
     * @param value Data to store.
     */
    public setData(key: string, value: any): this {
        Utils.setData(this.context, key, value);

        return this;
    }

    /**
     * Removes specified data from context element.
     * @param key Optional data key specification. When not provided, all data will be removed.
     */
    public removeData(key?: string): this {
        Utils.removeData(this.context, key);

        return this;
    }

    /**
     * Appends a content into context element.
     * @param content Appended content.
     */
    public append(content: string | any[] | JQuery | Element | DocumentFragment): this {
        Utils.append(this.context, content);
        return this;
    }

    /**
     * Prepends a content into context element.
     * @param content Prepended content.
     */
    public prepend(content: string | any[] | JQuery | Element | DocumentFragment): this {
        Utils.prepend(this.context, content);
        return this;
    }

    /**
     * Places context element after target element in DOM.
     * @param placementTarget Selector or element after which to place the context.
     */
    public after(placementTarget: Element | string): this {
        Utils.after(placementTarget, this.context);
        return this;
    }

    /**
     * Places context element before target element in DOM.
     * @param placementTarget Selector or element before which to place the context.
     */
    public before(placementTarget: Element | string): this {
        Utils.before(placementTarget, this.context);
        return this;
    }

    /**
     * Empties the content of passed element.
     */
    public empty(): this {
        Utils.empty(this.context);
        return this;
    }

    /**
     * Finds a set of subelements matching specified selector.
     * @param selector jQuery selector.
     * @param elementCountLimit Maximum count of returned element items.
     * @returns Array with selected subelements.
     */
    public find(selector: string, elementCountLimit: number = Utils.NO_COUNT_LIMIT): Element[] {
        return Utils.find(this.context, selector, elementCountLimit);
    }

    /**
     * Finds first subelement matching specified selector.
     * @param selector jQuery selector.
     * @returns Found element object or undefined.
     */
    public findElement(selector: string, throwWhenNotFound?: boolean): Element | null {
        const countLimit = 1;
        const results = this.find(selector, countLimit);

        if (throwWhenNotFound && !results.length) {
            throw new Error(`Element with selector "${selector}" was not found.`);
        }

        return results.length ? results[0] : null;
    }

    /**
     * Finds first subelement matching specified selector.
     * @param selector jQuery selector.
     * @returns Found element object or raises error.
     */
    public findElementStrict(selector: string): Element {
        return this.findElement(selector, true) as Element;
    }

    /**
     * @returns Text content of context element.
     */
    public getText(): string {
        return Utils.getText(this.context);
    }

    /**
     * Sets new text content on context element.
     * @param value Text content to be set.
     */
    public setText(value: string): this {
        Utils.setText(this.context, value);
        return this;
    }

    /**
     * Sets new html content on context element.
     * @param value Html content to be set.
     */
    public setHtml(value: string): this {
        Utils.setHtml(this.context, value);
        return this;
    }

    /**
     *  Get html content on context element.
     * @param value Html content to be set.
     */
    public getHtml(): string {
        return Utils.getHtml(this.context);
    }

    /**
     * @param key Options key. The real searched data attribute is "data-{key}-options".
     * @returns Deserialized options data according to given key, or empty object when not found.
     */
    public getOptions(key: string): any {
        if (!key) {
            return {};
        }

        return Utils.getData(this.context, `${key}Options`) || {};
    }

    // #region binding events

    /**
     * Binds click event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindClick(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindClick(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds click event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindClick(namespace?: string, handler?: GenericEventHandler): this {
        this._binder.unbindClick(namespace, handler);
        return this;
    }

    /**
     * Binds click event delegating handler. Context is used as delegating wrapper.
     * Multiple calls will bind multiple event handlers which will be executed in the order they were added.
     * @param childSelector Selector filter for child elements to which this handler should apply.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindDelegatedClick(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        this._binder.bindDelegatedClick(childSelector, handler, namespace, one);
        return this;
    }

    /**
     * Unbinds click event delegating handler.
     * @param childSelector Selector filter for child elements to which this handler was applied.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     */
    public unbindDelegatedClick(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string
    ): this {
        this._binder.unbindDelegatedClick(childSelector, handler, namespace);
        return this;
    }

    /**
     * Binds key down event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindKeyDown(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindKeyDown(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds key down event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindKeyDown(namespace?: string, handler?: GenericEventHandler): this {
        this._binder.unbindKeyDown(namespace, handler);
        return this;
    }

    /**
     * Binds key up event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindKeyUp(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindKeyUp(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds key up event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindKeyUp(namespace?: string, handler?: GenericEventHandler): this {
        this._binder.unbindKeyUp(namespace, handler);
        return this;
    }

    /**
     * Binds mouse enter event delegating handler. Context is used as delegating wrapper.
     * Multiple calls will bind multiple event handlers which will be executed in the order they were added.
     * @param childSelector Selector filter for child elements to which this handler should apply.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindDelegatedMouseEnter(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        this._binder.bindDelegatedMouseEnter(childSelector, handler, namespace, one);
        return this;
    }

    /**
     * Binds mouse leave event delegating handler. Context is used as delegating wrapper.
     * Multiple calls will bind multiple event handlers which will be executed in the order they were added.
     * @param childSelector Selector filter for child elements to which this handler should apply.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindDelegatedMouseLeave(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        this._binder.bindDelegatedMouseLeave(childSelector, handler, namespace, one);
        return this;
    }

    /**
     * Unbinds mouse enter event delegating handler.
     * @param childSelector Selector filter for child elements to which this handler was applied.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     */
    public unbindDelegatedMouseEnter(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string
    ): this {
        this._binder.unbindDelegatedClick(childSelector, handler, namespace);
        return this;
    }

    /**
     * Unbinds mouse leave event delegating handler.
     * @param childSelector Selector filter for child elements to which this handler was applied.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     */
    public unbindDelegatedMouseLeave(
        childSelector: string,
        handler: GenericEventHandler,
        namespace?: string
    ): this {
        this._binder.unbindDelegatedMouseLeave(childSelector, handler, namespace);
        return this;
    }

    /**
     * Binds mouse down event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindMouseDown(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindMouseDown(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds mouse down event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindMouseDown(namespace?: string, handler?: GenericEventHandler): this {
        this._binder.unbindMouseDown(namespace, handler);
        return this;
    }

    /**
     * Binds mouse move event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindMouseMove(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindMouseMove(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds mouse move event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindMouseMove(namespace?: string, handler?: GenericEventHandler): this {
        this._binder.unbindMouseMove(namespace, handler);
        return this;
    }

    /**
     * Binds mouse up event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindMouseUp(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindMouseUp(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds mouse up event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindMouseUp(namespace?: string, handler?: GenericEventHandler): this {
        this._binder.unbindMouseUp(namespace, handler);
        return this;
    }

    /**
     * Binds touch start event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindTouchStart(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindTouchStart(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds touch start event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindTouchStart(namespace?: string, handler?: GenericEventHandler): this {
        this._binder.unbindTouchStart(namespace, handler);
        return this;
    }

    /**
     * Binds touch move event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindTouchMove(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindTouchMove(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds touch move event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindTouchMove(namespace?: string, handler?: GenericEventHandler): this {
        this._binder.unbindTouchMove(namespace, handler);
        return this;
    }

    /**
     * Binds touch end event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindTouchEnd(handler: GenericEventHandler, namespace?: string, one?: boolean): this {
        this._binder.bindTouchEnd(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds touch end event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindTouchEnd(namespace?: string, handler?: GenericEventHandler): this {
        this._binder.unbindTouchEnd(namespace, handler);
        return this;
    }

    /**
     * Binds CSS transition end event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindTransitionEnd(
        handler: GenericEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        this._binder.bindTransitionEnd(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds CSS transition end event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindTransitionEnd(namespace?: string, handler?: GenericEventHandler): this {
        this._binder.unbindTransitionEnd(namespace, handler);
        return this;
    }

    // #endregion binding events
}
