import { autobind } from "core-decorators";
import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Device } from "Rwd/Scripts/Device";
import { IDeviceMediaEvent } from "Rwd/Scripts/IDeviceMediaEvent";
import { IList } from "Helpers/Scripts/IList";
import { Utils } from "Helpers/Scripts/Utils";
import { ISliderConfiguration } from "./ISliderConfiguration";
import { IRSConfigOptions } from "./IRSConfigOptions";
import { SliderView } from "./SliderView";
import { SliderChangeEventHandler, SliderRebuildEventHandler } from "./SliderEvents";

declare global {
    interface IOri {
        cutShort: any;
    }

    interface Window {
        // eslint-disable-line @typescript-eslint/naming-convention
        ori: IOri;
    }
}

export class Slider extends UiComponent {
    public get key(): string {
        return "Slider";
    }

    public rsInstance: RoyalSlider.RoyalSlider | null = null;

    /**
     * Royal Slider events
     * These are triggered on the "rsInstance" object defined above and have to be re-triggered
     * on EventBinder object in order to be handle-able by functions bound by EventBinder.
     */
    private readonly RS_AFTER_SLIDE_CHANGE_EVENT: string = "rsAfterSlideChange";
    private readonly RS_BEFORE_ANIM_START_EVENT: string = "rsBeforeAnimStart";
    private readonly RS_BEFORE_MOVE_EVENT: string = "rsBeforeMove";

    // custom events triggered by this module
    private readonly SLIDER_REBUILD_EVENT: string = "rsrebuild";
    private readonly SLIDE_CHANGE_EVENT: string = "rsslidechange";

    private readonly SLIDER: string = "slider";
    private readonly MAX_THUMB_COUNT_WITHOUT_ARROWS: number = 5;
    private readonly SLIDER_NAV_HEIGHT: number = 25;
    private readonly RESIZE_TIMEOUT: number = 200;
    private readonly BP_SIZE_3: number = 2;
    private readonly RWD_KEY: string = "rwd";
    private readonly HEIGHT_HOLDER_CLASS: string = "initial-height";
    private _view: SliderView;
    private _device: Device;
    private _config: ISliderConfiguration;
    private _globalConfig: IList<any> = {
        extends: "default",
    };
    private readonly _presets: IList<ISliderConfiguration> = {
        default: {
            "-1": {
                arrowsNavAutoHide: false,
                autoHeight: true,
                autoScaleSlider: false,
                controlNavigation: "none",
                controlsInside: false,
                itemsPerSlide: 1,
                navigateByClick: false,
                slideElementClass: "slide",
                slidesSpacing: 0,
                startSlideId: 0,
            },
        },
    };

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject device: Device
    ) {
        super(componentFactory, binder, loggerFactory);

        this._device = device;
    }

    public init(): void {
        this._view = this.createView(SliderView);
        const rs: RoyalSlider.RoyalSlider = this.getData(this._view.RS) || null;
        const rwdData = this.getData(this.RWD_KEY);
        const customConfig = rwdData && rwdData.slider ? rwdData.slider : {};

        this._globalConfig = this.getData(this.SLIDER)
            ? Utils.extend(this._globalConfig, this.getData(this.SLIDER))
            : this._globalConfig;

        this._logger.info("Slider global config: ", this._globalConfig);

        this._mergeConfig(customConfig);

        this._logger.info("Slider config: ", this._config);

        if (rs) {
            this.rsInstance = rs;
        }

        this.rebuild();
        this._positionSliderNavBullets();

        if (!this._globalConfig.noRebuild) {
            this._device.bindMedia(this._onMediaChange, this.key);
        }

        const activeConfig = this._getActiveConfig();
        if (activeConfig && activeConfig.autoplay) {
            const arrows = this.find(this._view.SLIDE_ARROW);

            arrows.forEach((arrow) => {
                Utils.addClass(arrow, this._view.ARROW_HIDE);
            });
            this.addClass(this._view.AUTOPLAY);
            this._binder.bindMouseEnter(() => {
                arrows.forEach((arrow) => {
                    Utils.removeClass(arrow, this._view.ARROW_HIDE);
                });
            });
            this._binder.bindMouseLeave(() => {
                arrows.forEach((arrow) => {
                    Utils.addClass(arrow, this._view.ARROW_HIDE);
                });
            });
        }

        if (this.hasClass(this.HEIGHT_HOLDER_CLASS)) {
            this.removeClass(this.HEIGHT_HOLDER_CLASS);
        }

        this.observeProductBoxContainer();
    }

    /**
     * Rebuilds the slider after BP change and uses configuration for proper breakpoint
     */
    public rebuild(): void {
        const bpConfig = this._getActiveConfig();
        const items = this.find(bpConfig.slideElementClass);

        if (items.length === 0) {
            this._logger.warning(
                "No slides %s found for slider %s ...",
                bpConfig.slideElementClass,
                this.describeElement()
            );

            return;
        }

        this._logger.info(
            "Rebuilding slider %s. slideSize: %s ...",
            this.describeElement(),
            bpConfig.itemsPerSlide
        );

        // init slider on currently selected slide
        if (this.rsInstance) {
            bpConfig.startSlideId = this.rsInstance.currSlideId;
        }

        this.destroy();

        // if there should not be slider on BP, than exit and do not create new instance
        if (bpConfig.noSlider) {
            return;
        }

        this.rsInstance = this._view.createRsInstance(bpConfig);
        this._updateSliderOnResize();
        if (this.rsInstance) {
            const rsEvents = this.rsInstance.ev;
            rsEvents.on(this.RS_AFTER_SLIDE_CHANGE_EVENT, () => this._handleSlideChange());
            rsEvents.on(this.RS_BEFORE_ANIM_START_EVENT, (event) => this._binder.trigger(event));
            rsEvents.on(this.RS_BEFORE_MOVE_EVENT, (event, args) =>
                this._binder.trigger(event, args)
            );
        }

        this._binder.trigger(this.SLIDER_REBUILD_EVENT, { slider: this });
        this._hideThumbArrows(items.length);
    }

    /**
     * Destroys RS instance if exists and rebuilds structure
     */
    public destroy(unbindEvents?: boolean): void {
        const bpConfig = this._getActiveConfig();

        if (unbindEvents) {
            this._device.unbindMedia(this.key, this._onMediaChange);
            this._binder.unbindCustomEvent(this.SLIDER_REBUILD_EVENT);
            this._binder.unbindCustomEvent(this.RS_BEFORE_ANIM_START_EVENT);
            this._binder.unbindCustomEvent(this.RS_BEFORE_MOVE_EVENT);
            this._binder.unbindCustomEvent(this.SLIDE_CHANGE_EVENT);
        }

        this._view.destroyRsInstance(this.rsInstance, bpConfig, this._globalConfig.ignoreInstance);
        this.rsInstance = null;
    }

    // public event handlers
    public bindSliderRebuild(
        handler: SliderRebuildEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        this._binder.bindCustomEvent(this.SLIDER_REBUILD_EVENT, handler, namespace, one);
        return this;
    }

    public bindBeforeAnimationStart(
        handler: (event?: JQuery.Event) => any,
        namespace?: string,
        one?: boolean
    ): this {
        this._binder.bindCustomEvent(this.RS_BEFORE_ANIM_START_EVENT, handler, namespace, one);
        return this;
    }

    public bindBeforeMove(
        handler: (event: JQuery.Event, extraParameters?: any[] | Object) => any,
        namespace?: string,
        one?: boolean
    ): this {
        this._binder.bindCustomEvent(this.RS_BEFORE_MOVE_EVENT, handler, namespace, one);
        return this;
    }

    public bindSlideChange(
        handler: SliderChangeEventHandler,
        namespace?: string,
        one?: boolean
    ): this {
        this._binder.bindCustomEvent(this.SLIDE_CHANGE_EVENT, handler, namespace, one);
        return this;
    }

    /**
     * Handles which slides have to be preloaded if visibleNearby parameter is enabled.
     * Also triggers custom "rsslidechange" event.
     */
    private _handleSlideChange(): void {
        if (!this.rsInstance) {
            return;
        }

        const { slides, currSlideId, st }: RoyalSlider.RoyalSlider = this.rsInstance;
        const slidesToPreload = [slides[currSlideId]];

        // add previous and next slide if visibleNearby is enabled
        if (st.visibleNearby) {
            const nearbyIndexDiff = 1;
            if (slides[currSlideId - nearbyIndexDiff]) {
                slidesToPreload.push(slides[currSlideId - nearbyIndexDiff]);
            }
            if (slides[currSlideId + nearbyIndexDiff]) {
                slidesToPreload.push(slides[currSlideId + nearbyIndexDiff]);
            }
        }

        if (ori.cutShort) {
            ori.cutShort.init({ context: this.rsInstance.currSlide.content });
        }

        // trigger change event, so images can be loaded if visibleNearby is not enabled
        this._binder.trigger(this.SLIDE_CHANGE_EVENT, { slidesToPreload });
    }

    /**
     * Merges config with default configuration
     * @param config ISliderConfiguration to merge.
     */
    private _mergeConfig(config: ISliderConfiguration): void {
        const configOverwrite = (this.model as ISliderConfiguration) || {};

        // merge whole config if there is extends param
        if (this._presets.hasOwnProperty(this._globalConfig.extends)) {
            const preset = this._presets[this._globalConfig.extends];

            this._config = Utils.extend(true, preset, config, configOverwrite);
        } else {
            this._config = Utils.extend(true, config, configOverwrite);
        }

        // merge configs for each BP
        Object.keys(this._config).forEach((key) => {
            const RSconfig = this._config[key as keyof ISliderConfiguration];

            if (RSconfig && RSconfig.extends && this._config.hasOwnProperty(RSconfig.extends)) {
                this._config[key as keyof ISliderConfiguration] = Utils.extend(
                    true,
                    this._config[
                        RSconfig.extends as keyof ISliderConfiguration
                    ] as IRSConfigOptions,
                    RSconfig
                );
            }
        });
    }

    /**
     * Internal RS method updateSliderSize for slider recalculations
     */
    private _updateSlider(): void {
        const activeConfig = this._getActiveConfig();

        if (!activeConfig.noSlider && this.rsInstance) {
            this.rsInstance.updateSliderSize();
            this._positionSliderNavBullets();
        }
    }

    /**
     *  Calls _updateSlider on resize, so the slider has proper dimensions after resize
     */
    private _updateSliderOnResize(): void {
        const $window = $(window);
        const updateSliderThrottled = Utils.throttle(
            () => this._updateSlider(),
            this.RESIZE_TIMEOUT
        ) as unknown as GenericEventHandler;

        // window resize handler modification
        $window
            .unbindResize(this.SLIDER)
            .unbindUnload(this.SLIDER)
            .bindResize(updateSliderThrottled, this.SLIDER)
            .bindUnload(() => {
                $window.unbindResize(this.SLIDER);
            }, this.SLIDER);
    }

    /**
     * Handles position of slider bullets in hero slider.
     */
    private _positionSliderNavBullets(): void {
        const navBullets = this.find(this._view.RS_BULLETS);
        const heroSlider = this.find(this._view.UI_HERO);

        // return if the slider is not hero slider
        if (!(navBullets.length && heroSlider.length)) {
            return;
        }

        // remove top inline style in case breakpoint is bigger than Size 2
        if (
            this._device.activeBreakpoint &&
            this._device.activeBreakpoint.index >= this.BP_SIZE_3
        ) {
            Utils.setCss(navBullets[0], "top", "");
        } else {
            const navTop = this._getSliderNavPosition();
            Utils.setCss(navBullets[0], "top", `${navTop}px`);
        }
    }
    /**
     * Get slider position by getting the height of the first image.
     *
     * In case the first image is hidden then it is not that easy to get its height.
     * We have to get its parent elements visible first.
     */
    private _getSliderNavPosition(): number {
        const heroImage = this.find(this._view.IMAGE_A1)[0];
        const slide = Utils.closest(heroImage, this._view.RS_SLIDE) as HTMLElement;
        const previousCss = Utils.getAttr(slide, "style");

        Utils.setCss(slide, "position", "absolute");
        Utils.setCss(slide, "visibility", "hidden");
        Utils.setCss(slide, "display", "block");

        const height = Utils.getHeight(heroImage);
        Utils.setAttr(slide, "style", previousCss ? previousCss : "");

        return height - this.SLIDER_NAV_HEIGHT;
    }

    private _getActiveConfig(): IRSConfigOptions {
        let activeConfigIndex = "-1";

        if (this._device.activeBreakpoint) {
            const activeBreakpointIndexString = `${this._device.activeBreakpoint.index}`;
            if (this._config[activeBreakpointIndexString as keyof ISliderConfiguration]) {
                activeConfigIndex = activeBreakpointIndexString;
            }
        }

        return this._config[activeConfigIndex as keyof ISliderConfiguration] as IRSConfigOptions;
    }

    private _hideThumbArrows(slides: number): void {
        const arrows = this._view.THUMB_ARROW;

        if (slides <= this.MAX_THUMB_COUNT_WITHOUT_ARROWS) {
            Utils.setCss(arrows, "visibility", "hidden");
        }
    }

    private observeProductBoxContainer(): void {
        const productBoxRoot = document.querySelector(".product-box-root");

        if (!productBoxRoot) {
            return;
        }

        const resizeSlider = (mutationsList: MutationRecord[]) => {
            for (const mutation of mutationsList) {
                if (mutation.addedNodes.length && this.rsInstance) {
                    this.rsInstance.updateSliderSize(true);
                }
            }
        };
        const observer = new MutationObserver(resizeSlider);

        observer.observe(productBoxRoot, { childList: true });
    }

    @autobind
    private _onMediaChange(event: IDeviceMediaEvent): void {
        if (event && event.breakpoint.isActive) {
            this.rebuild();
        }
    }
}
