import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { DocumentComponentView } from "./DocumentComponentView";
import { autobind } from "core-decorators";
import { DocumentItem } from "Documents/Scripts/DocumentItem";
import { DocumentItemComponent } from "Documents/Scripts/DocumentItemComponent";
import { DocumentItemType } from "Documents/Scripts/DocumentItemType";
import { IDocumentItemResponse } from "Documents/Scripts/IDocumentItemResponse";
import { SubmitButtonManager } from "Helpers/Scripts/SubmitButtonManager";
import { DocumentItemAddedEvent } from "Documents/Scripts/DocumentItemAddedEvent";
import { DocumentItemRemovedEvent } from "Documents/Scripts/DocumentItemRemovedEvent";
import UploadSuccessEvent = kendo.ui.UploadSuccessEvent;
import UploadProgressEvent = kendo.ui.UploadProgressEvent;
import UploadUploadEvent = kendo.ui.UploadUploadEvent;
import UploadErrorEvent = kendo.ui.UploadErrorEvent;

export class DocumentComponent extends UiComponent {
    public get key(): string {
        return "DocumentComponent";
    }

    public uploadComplete: () => void;

    private _view: DocumentComponentView;
    private _submitButtonManager: SubmitButtonManager;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    @autobind
    protected init(): void {
        this._view = this.createView(DocumentComponentView);
        if (this._view.submitButton) {
            this._submitButtonManager = new SubmitButtonManager(this._view.submitButton);
        }

        if (this._view.uploader) {
            this._view.uploader.bindSelect(this.uploaderSelect);
            this._view.uploader.bindUpload(this.uploaderUpload);
            this._view.uploader.bindProgress(this.uploaderProgress);
            this._view.uploader.bindComplete(this.uploaderComplete);
            this._view.uploader.bindSuccess(this.uploaderSuccess);
            this._view.uploader.bindError(this.uploaderError);
        }

        if (this._view.documentItems) {
            this._view.documentItems.forEach(this.initItemEvents);
        }
    }

    @autobind
    private uploaderError(event: UploadErrorEvent): void {
        this._logger.log(`Problem with document upload${event?.XMLHttpRequest?.response}`);
    }

    @autobind
    private uploaderSelect(): void {
        const errors: DocumentItemComponent[] = this._view.documentItems.filter(
            (item) => item.itemType === DocumentItemType.Error
        );
        errors.forEach(this.removeItem);
    }

    @autobind
    private uploaderUpload(event: UploadUploadEvent): void {
        if (event.files) {
            event.files.forEach((file) => {
                const item: DocumentItem = new DocumentItem(
                    file.uid,
                    file.name,
                    this.formatFileSize(file)
                );

                if (file.size > this._view.validationMaxSize) {
                    event.preventDefault();

                    item.ItemType = DocumentItemType.Error;
                    item.ErrorMessage = this._view.validationMaxSizeMessage;
                } else {
                    item.ItemType = DocumentItemType.Loading;
                    this.toggleLoading(true);
                }

                this.addItem(item);
            });
        }
    }

    @autobind
    private initItemEvents(component: DocumentItemComponent): void {
        component.onDeleteClick = this.removeItem;
        component.onImageClick = this.showImagePopup;
    }

    @autobind
    private showImagePopup(component: DocumentItemComponent): void {
        const popup: Element = this._view.getImagePopup(component.documentId);

        const windowOptions: kendo.ui.WindowOptions = {
            close: this.popupClose,
        };

        const window: kendo.ui.Window = kendo.createWindow(popup, windowOptions);

        window.center().open();
    }

    @autobind
    private popupClose(event: kendo.ui.WindowCloseEvent): void {
        event.sender.destroy();
    }

    @autobind
    private uploaderProgress(event: UploadProgressEvent): void {
        if (event.files) {
            event.files.forEach((file) => {
                const item: DocumentItemComponent | undefined = this.getItem(file.uid);
                if (item && event.percentComplete) {
                    item.setProgress(event.percentComplete);
                }
            });
        }
    }

    @autobind
    private uploaderComplete(): void {
        this.toggleLoading(false);

        if (this.uploadComplete) {
            this.uploadComplete();
        }
    }

    @autobind
    private uploaderSuccess(event: UploadSuccessEvent): void {
        if (event.files && event.response && event.response.documents) {
            const document: IDocumentItemResponse = event.response
                .documents[0] as IDocumentItemResponse;
            const file: any = event.files[0];

            const documentItem: DocumentItem = {
                ...document,
                DownloadUrl: this._view.getDownloadUrl(document.DocumentId),
                IsApproved: false,
                ItemId: file.uid,
                ThumbnailUrl: this._view.getThumbnailUrl(document.DocumentId),
            };

            const loadingItem: DocumentItemComponent | undefined = this.getItem(file.uid);
            if (loadingItem) {
                this.removeItem(loadingItem);
            }
            this.addItem(documentItem);
        }
    }

    @autobind
    private removeItem(component: DocumentItemComponent): void {
        if (!component.isEditable) {
            return;
        }

        this._view.cancelUpload(component);
        this._view.removeDocumentId(component);
        this._view.removeItem(component);

        if (this.isUploadedItemType(component.itemType)) {
            const containsNewItems: boolean = this._view.documentItems.some(
                (x) => !x.isApproved && this.isUploadedItemType(x.itemType)
            );
            if (!containsNewItems) {
                this._view.toggleApprovedItems(true);
            }
            this.dispatchDocumentItemRemovedEvent(this._view.documentTypeId, containsNewItems);
        }
    }

    private getItem(itemId: string): DocumentItemComponent | undefined {
        return this._view.documentItems.find((item) => item.itemId === itemId);
    }

    private addItem(item: DocumentItem): void {
        this._view.addDocumentId(item);

        if (!this._view.uploader.options.multiple) {
            this._view.documentItems.forEach(this.removeItem);
        }

        const component: DocumentItemComponent = this._view.appendItem(item);
        if (this.isUploadedItemType(item.ItemType)) {
            this._view.toggleApprovedItems(false);
            this.dispatchDocumentItemAddedEvent(this._view.documentTypeId);
        }

        this.initItemEvents(component);
    }

    private toggleLoading(isLoading: boolean): void {
        this._view.toggleLoading(isLoading);

        if (!this._submitButtonManager) {
            return;
        }

        if (isLoading) {
            this._submitButtonManager.disableFor(this);
        } else {
            this._submitButtonManager.enableFor(this);
        }
    }

    private formatFileSize(file: any): string {
        /* eslint-disable no-magic-numbers */
        const megaBytes: number = file.size / 1024 / 1024;

        return megaBytes.toFixed(2) + " MB";
    }

    private isUploadedItemType(itemType: DocumentItemType): boolean {
        return itemType === DocumentItemType.File || itemType === DocumentItemType.Image;
    }

    private dispatchDocumentItemRemovedEvent(
        documentTypeId: number,
        containsNewItems: boolean
    ): void {
        const detail: DocumentItemRemovedEvent = new DocumentItemRemovedEvent(
            documentTypeId,
            containsNewItems
        );
        const event: CustomEvent<DocumentItemRemovedEvent> = new CustomEvent(
            "documentItemRemoved",
            { bubbles: true, detail }
        );
        this.context.dispatchEvent(event);
    }

    private dispatchDocumentItemAddedEvent(documentTypeId: number): void {
        const detail: DocumentItemAddedEvent = new DocumentItemAddedEvent(documentTypeId);
        const event: CustomEvent<DocumentItemAddedEvent> = new CustomEvent("documentItemAdded", {
            bubbles: true,
            detail,
        });
        this.context.dispatchEvent(event);
    }
}
