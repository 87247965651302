import { autobind } from "core-decorators";

import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { FetchService } from "Async/Scripts/FetchService";
import { Spinner } from "Spinner/Scripts/Spinner";
import { Utils } from "Helpers/Scripts/Utils";
import { SliderFactory } from "Sliders/Scripts/SliderFactory";
import { Slider } from "Sliders/Scripts/Slider";

import { IShippingOfferOptions } from "./IShippingOfferOptions";
import { IShippingOfferLocalizations } from "./IShippingOfferLocalizations";
import { IProductBox } from "ProductBox/Scripts/IProductBox";
import { IAjaxError } from "ECommerce/Shopping/Delivery/Scripts/IAjaxError";
import { OfferSummary } from "./OfferSummary";
import * as OffersEventType from "./OffersEventType";
import { IOfferProduct } from "./IOfferProduct";

import camelCaseObjectDeep from "camelcase-object-deep";

@OnlyInstantiableByContainer
@Singleton
export class ShippingOffer extends UiComponent {
    private readonly SEL_SHIPPING_OFFER: string = ".js-shipping-offer";
    private readonly SEL_SHIPPING_TEXT: string = ".js-shipping-offer-text";
    private readonly SHIPPING_OFFER_WINDOW_ID: string = "ShippingOfferWindow";
    private readonly SHIPPING_OFFER_WINDOW: string = "#" + this.SHIPPING_OFFER_WINDOW_ID;
    private readonly SHIPPING_OFFER_WINDOW_CLASS: string = "shipping-offer-popup";
    private readonly SHIPPING_OFFER_TEMPLATE_ID: string = "kendo-template-shipping-offer";
    private readonly SHIPPING_OFFER_TEMPLATE_HEADING_ID: string =
        "kendo-template-shipping-offer-heading";
    private readonly SEL_SHIPPING_OFFER_HEADING_PLACEHOLDER: string =
        ".js-shipping-offer-heading-placeholder";
    private readonly SEL_SHIPPING_OFFER_SLIDER_PLACEHOLDER: string = "#shipping-offer-slider";
    private readonly PRODUCT_BOX_TEMPLATE_ID: string = "offer-product-box-template";
    private readonly PRODUCT_SLIDER_TEMPLATE_ID: string = "kendo-template-product-slider";
    private readonly SEL_CLOSE_SHIPPING_OFFER_DIALOG: string = ".js-close-shipping-offer-dialog";
    private readonly SEL_SLIDER_WRAPPER: string = ".js-slider-wrapper";
    private readonly SHIPPING_PRODUCT_ITEM_CLASS: string = "js-shipping-product-item";
    private readonly SEL_OFFER_BODY: string = ".js-offer-body";
    private readonly HIDE_SLIDER_CLASS: string = "hide-slider";
    private readonly SEL_WINDOW: string = ".k-window";
    private readonly SEL_WINDOW_CLOSE_BUTTON: string = ".k-window-action";
    private readonly SELECTED: string = ".selected";
    private readonly DATA_DELIVERY_CHANNEL: string = "data-delivery-channel";
    private readonly DATA_ADDRESS: string = "data-address";
    private readonly SELECTED_ADDRESS_ID_DATA_KEY: string = "selectedAddressId";
    private readonly QUANTITY_LIMIT_DATA_KEY: string = "quantityLimit";
    private readonly PRODUCT_CODE_DATA_KEY: string = "productCode";
    private readonly BAR_SLIDE_SPEED: number = 200;
    private readonly MAX_ALLOWED_QUANTITY: number = 99;

    private readonly EVENT_WINDOW_OPEN: string = "open";
    private readonly EVENT_WINDOW_DEACTIVATE: string = "deactivate";
    private readonly NO_DELIVERY_CHANNEL_AVAILABLE: string = ".js-no-delivery-channel-available";
    private readonly DEFAULT_CHANNEL: string = ".js-default-channel";
    private readonly QUANTITY_PICKER_SELECTOR: string = ".js-quantity-picker";
    private readonly PLUS_BUTTON_SELECTOR: string = ".js-plus-button";
    private readonly OFFER_SUMMARY_CONTAINER: string = ".js-offer-summary-container";
    private readonly PRODUCT_CODE_DATA_ATTRIBUTE: string = "data-product-code";

    protected _model: IChannelsInfo | null;
    private _shippingOfferBar: Element | null;
    private _shippingFee: string;
    private _lastSelectedChannel: Element | null;
    private readonly _fetchService: FetchService;
    private readonly _sliderFactory: SliderFactory;
    private _slider: Slider | null;

    private _resetFreeShippingSelectionUrl: string;
    private _addFreeShippingItemUrl: string;
    private _productBoxLocalizationItem: string;
    private _addToBagButtonLabel: string;
    private _sliderId: string;
    private _ajaxErrorTitle: string;
    private _ajaxErrorMessage: string;
    private _isOfferWindowInited: boolean;
    private _offerWindow: kendo.ui.Window;
    private _offerPanelText: string;
    private _offerWindowTitle: string;
    private _headingTemplate: (data: any) => string;
    private _isQuantitySelectable: boolean = false;
    private _offerSummary: OfferSummary;

    public get key(): string {
        return "ShippingOffer";
    }

    /**
     * @returns The component model if provided
     */
    public get model(): IChannelsInfo | null {
        return this._model;
    }

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject fetchService: FetchService,
        @Inject sliderFactory: SliderFactory
    ) {
        super(componentFactory, binder, loggerFactory);

        this._fetchService = fetchService;
        this._sliderFactory = sliderFactory;
        this._isOfferWindowInited = false;
    }

    public init(): void {
        this._setOptions();
        this._shippingOfferBar = this.findElement(this.SEL_SHIPPING_OFFER);
        this.update(this._model);
        this._bindEvents();
    }

    private _setOptions(): this {
        this._logger.info("Setting delivery options from data attributes.");

        const { resetFreeShippingSelection = "", addFreeShippingItem = "" }: IShippingOfferOptions =
            this.getData("shipping-url");
        this._resetFreeShippingSelectionUrl = resetFreeShippingSelection;
        this._addFreeShippingItemUrl = addFreeShippingItem;

        const {
            productBoxLocalizationItem = "",
            addToBag = "Add to bag",
        }: IShippingOfferLocalizations = this.getData("shipping-tr");
        this._productBoxLocalizationItem = productBoxLocalizationItem;
        this._addToBagButtonLabel = addToBag;

        const {
            ajaxErrorTitle = "Error",
            ajaxErrorMessage = "An error occured during request, please try again later.",
        }: IAjaxError = this.getData("ajax-error");
        this._ajaxErrorTitle = ajaxErrorTitle;
        this._ajaxErrorMessage = ajaxErrorMessage;

        this._offerPanelText = this.getData("shipping-offer-text");
        this._offerWindowTitle = this.getData("shipping-window-title");

        this._sliderId = this.getData("shipping-slider-id");

        if (
            this._model &&
            this._model.ShippingOffer &&
            this._model.ShippingOffer.IsQuantitySelectable
        ) {
            this._isQuantitySelectable = true;
        } else {
            this._isQuantitySelectable = false;
        }

        return this;
    }

    @autobind
    public getAndStoreShippingOfferBar(): void {
        /* jquery call is a hot fix,
         * because this.context is undefined
         * and this.findElement(this.SEL_SHIPPING_OFFER) returns null
         */
        const shippingOfferNode = $(this.SEL_SHIPPING_OFFER);
        if (shippingOfferNode.length === 1) {
            Utils.after(this.NO_DELIVERY_CHANNEL_AVAILABLE, shippingOfferNode);
        }
    }

    private _updateShippingOfferBar(): void {
        if (
            !this._model ||
            !this._model.ShippingOffer?.ShippingOfferExists ||
            !this._lastSelectedChannel
        ) {
            this.hideShippingOfferBar();
            this._logger.warning(
                "Delivery channel is not selected or shipping offers are not allowed."
            );
            return;
        }

        if (this._shippingOfferBar) {
            Utils.slideUp(
                this._shippingOfferBar,
                this.BAR_SLIDE_SPEED,
                this._handleShippingOfferBarDisplay
            );
        }
    }

    @autobind
    private _handleShippingOfferBarDisplay(): void {
        if (!this._model) {
            return;
        }
        this._formatShippingFee();
        this._formatShippingOfferPanelText();
        if (this._shippingOfferBar) {
            Utils.append(this._lastSelectedChannel as Element, this._shippingOfferBar);
            Utils.slideDown(this._shippingOfferBar, this.BAR_SLIDE_SPEED, () => {
                if (this._shippingOfferBar) {
                    Utils.setAttr(this._shippingOfferBar, "style", "");
                }
            });
        }
    }

    private _formatShippingFee(): void {
        if (this._model) {
            this._shippingFee = kendo.toString(this._model.DeliveryFee, "oc");
        }
    }

    private _formatShippingOfferPanelText(): void {
        if (!this._shippingFee) {
            this._logger.warning("shipping offer fee is not provided");
        }
        const offerText = kendo.format(this._offerPanelText, this._shippingFee);
        const offerTextElement = this.findElement(this.SEL_SHIPPING_TEXT);
        if (offerTextElement) {
            Utils.setHtml(offerTextElement, offerText);
        }
    }

    private _bindEvents(): void {
        this._bindShippingOfferBarClick();
    }

    private _bindShippingOfferBarClick(): void {
        this.bindDelegatedClick(this.SEL_SHIPPING_OFFER, () => {
            this._createOfferWindow();
            this._openKendoWindow();
        });
    }

    @autobind
    private _createOfferWindow(): void {
        if (this._isOfferWindowInited) {
            return;
        }

        const windowElement = document.createElement("div");
        windowElement.setAttribute("id", this.SHIPPING_OFFER_WINDOW_ID);
        this._offerWindow = kendo.createWindow(windowElement, {});
        const template = Utils.getTemplate(this.SHIPPING_OFFER_TEMPLATE_ID);
        const data = {
            IsQuantitySelectable: this._isQuantitySelectable,
            AvailablePoints: this._model?.ShippingOffer?.AvailablePoints,
            LabelAvailablePoints: this._model?.ShippingOffer?.LabelAvailablePoints,
        };
        this._offerWindow.title(this._offerWindowTitle).content(template(data));
        this._setWindowHeadingContent();
        this._setWindowSliderContent(windowElement);

        const offerWindowElement = Utils.closest(windowElement, this.SEL_WINDOW);
        if (offerWindowElement) {
            Utils.addClass(offerWindowElement, this.SHIPPING_OFFER_WINDOW_CLASS);
            if (this._isQuantitySelectable) {
                const orderSummaryContainer = Utils.find(
                    windowElement,
                    this.OFFER_SUMMARY_CONTAINER
                );
                if (orderSummaryContainer.length) {
                    const summaryData = {
                        Bp: 0,
                        TotalPrice: 0,
                        AvailablePoints: this._model?.ShippingOffer?.AvailablePoints,
                        LabelAvailablePoints: this._model?.ShippingOffer?.LabelAvailablePoints,
                    };

                    this._offerSummary = this.createComponentFromTemplateId(
                        OfferSummary,
                        OfferSummary.templateId,
                        summaryData,
                        orderSummaryContainer[0]
                    );

                    this._initQuantityPickers(offerWindowElement);
                    this._bindPlusButtonClick(offerWindowElement);
                    this._offerSummary.context.addEventListener(
                        OffersEventType.ADD_OFFER_ITEMS_EVENT,
                        (event: CustomEventInit) => {
                            if (event.detail.shoppingContext) {
                                this._handleAddOfferItems(event.detail.shoppingContext);
                                this._offerSummary.updateTotals(true);
                                this._showOfferSummary(false);
                            }
                        }
                    );
                }
            }
        }

        this._offerWindow.one("activate", () => {
            this._offerWindow.center();
            this._bindKendoWindowEvents();
        });

        this._isOfferWindowInited = true;
    }

    private _setWindowHeadingContent(): void {
        if (!this._model) {
            return;
        }

        if (!this._headingTemplate) {
            this._headingTemplate = Utils.getTemplate(this.SHIPPING_OFFER_TEMPLATE_HEADING_ID);
        }
        const markup = this._headingTemplate(this._model.DeliveryChannelId);
        Utils.setHtml(this.SEL_SHIPPING_OFFER_HEADING_PLACEHOLDER, markup);
    }

    private _setWindowSliderContent(windowElement: HTMLElement): boolean {
        if (!this._model) {
            return false;
        }

        const productTemplate = Utils.getTemplate(this.PRODUCT_BOX_TEMPLATE_ID);
        const fragment = document.createDocumentFragment();

        this._model.ShippingOffer?.SliderModel.Products.forEach((item) => {
            fragment.appendChild(
                Utils.createDomElementFromMarkup(productTemplate(this._getProductBoxModel(item)))
            );
        });

        const sliderTemplate = Utils.getTemplate(this.PRODUCT_SLIDER_TEMPLATE_ID);
        const sliderMarkup = sliderTemplate({
            SliderId: this._sliderId,
            SliderConfig: this._sliderId,
        });
        const slider = Utils.createDomElementFromMarkup(sliderMarkup);

        slider.appendChild(fragment);

        if (this._slider) {
            this._slider.destroy();
            this._slider = null;
        }

        Utils.setHtml(this.SEL_SHIPPING_OFFER_SLIDER_PLACEHOLDER, slider.innerHTML);

        // hide slider items until slider is inited in order to avoid jumping of the window and product items
        // once the slider is inited then the class below is removed
        const sliderWrapper = Utils.find(windowElement, this.SEL_SLIDER_WRAPPER);
        if (sliderWrapper.length > 0) {
            Utils.addClass(sliderWrapper[0], this.HIDE_SLIDER_CLASS);
        }

        this._offerWindow.one("activate", () => {
            this._initShippingOfferSlider(windowElement);
        });
        return true;
    }

    private _getProductBoxModel(item: IProduct): IProductBox {
        return {
            Brand: item.BrandShortTitle || "",
            Code: item.ProductCode,
            CurrentPrice: item.ActualPriceUnformated,
            ImageUrl: item.ProductImageUrl ? item.ProductImageUrl.concat("&w=234&h=234") : "", // TODO: remove fixed dimensions
            IsQuantitySelectable: this._isQuantitySelectable,
            Name: item.ConceptName,
            OriginalPrice: item.IsActionPrice ? item.StandardPriceUnformated : null,
            Points: item.Points,
            ShowPoints: item.Points > 0,
            ProductBoxLocalizationItem: JSON.parse(this._productBoxLocalizationItem),
            QuantityLimit:
                item.QuantityLimit && item.QuantityLimit > 0
                    ? item.QuantityLimit
                    : this.MAX_ALLOWED_QUANTITY,
            Rating: item.ConceptRating,
            SelectButton: {
                Content: this._addToBagButtonLabel,
                HtmlAttributes: {
                    class: `k-button secondary small ${this.SHIPPING_PRODUCT_ITEM_CLASS}`,
                    ["data-item-code"]: item.ProductCode,
                },
            },
            ShadeName: item.ShadeName,
        };
    }

    private _initShippingOfferSlider(windowElement: HTMLElement): void {
        const offerBody = Utils.find(windowElement, this.SEL_OFFER_BODY);
        if (offerBody.length > 0) {
            Spinner.applyOverlayTo(offerBody[0]);
        }

        this._slider = this._sliderFactory.create(this.SEL_SHIPPING_OFFER_SLIDER_PLACEHOLDER);

        const WAIT_TIME_TO_POSITION = 150;
        window.setTimeout(() => {
            Utils.removeClass(this.SEL_SLIDER_WRAPPER, this.HIDE_SLIDER_CLASS);
            Spinner.removeOverlayFrom(offerBody[0]);
        }, WAIT_TIME_TO_POSITION);
    }

    private _bindKendoWindowEvents(): void {
        this._binder.init(this.SHIPPING_OFFER_WINDOW);

        // Close and remove shipping offer
        this._binder.bindDelegatedClick(this.SEL_CLOSE_SHIPPING_OFFER_DIALOG, () => {
            this._closeKendoWindow();
            this._resetFreeShippingSelection();
        });

        // Add shipping offer and close window
        this._binder.bindDelegatedClick(
            `.${this.SHIPPING_PRODUCT_ITEM_CLASS}`,
            (event: JQuery.TriggeredEvent) => {
                const postData = new FormData();
                postData.append("itemCode", Utils.getData(event.target, "itemCode"));
                this._fetchService
                    .post(this._addFreeShippingItemUrl, postData)
                    .then((data: IShoppingContext) => {
                        this._updateShoppingContext(data);
                        this._closeKendoWindow();
                    }, this._errorShoppingOfferFetch);
            }
        );
    }

    private _resetFreeShippingSelection(): void {
        if (!(this._model && this._model.ShippingOffer?.ShippingOfferApplied)) {
            this._logger.info("No shipping offer applied...");
            return;
        }

        this._logger.info("Reseting free shipping...");
        this._fetchService
            .get(this._resetFreeShippingSelectionUrl)
            .then(this._updateShoppingContext, this._errorShoppingOfferFetch);
    }

    private _updateShoppingContext(data: IShoppingContext): void {
        this._logger.info("resetFreeShippingSelection call successful.");
        if (ori.shopping && ori.shopping.wizard) {
            ori.shopping.wizard.updateShoppingContext(data, ori.shopping.wizard.deliveryPageId);
            const event = new CustomEvent("basket-updated", { detail: camelCaseObjectDeep(data) });
            window.dispatchEvent(event);
        }
    }

    private _errorShoppingOfferFetch(error: string): void {
        this._logger.error("resetFreeShippingSelection call failed - %s", error);
        ori.displayError(this._ajaxErrorTitle, this._ajaxErrorMessage);
    }

    private _openKendoWindow(): void {
        this._offerWindow.open();
    }

    private _closeKendoWindow(): void {
        this._offerWindow.close();
    }

    public update(data?: IChannelsInfo | null): void {
        // we will update only if correct data was set
        if (!data) {
            return;
        }

        // Check if offer was applied - if true then hide the shipping offer bar
        if (data.ShippingOffer?.ShippingOfferApplied) {
            this.hideShippingOfferBar();
            return;
        }

        if (!data) {
            this._logger.info(
                "Shipping Offer was not added because a delivery channel was not selected."
            );
            return;
        }

        const channelSelector = this._makeChannelSelector(data);
        const channel = this.findElement(channelSelector);

        if (
            channel &&
            (!Utils.deepEqual(this._model as object, data) || channel !== this._lastSelectedChannel)
        ) {
            this._lastSelectedChannel = channel;
            this._model = data;
            this._updateShippingOfferBar();

            if (this._isOfferWindowInited) {
                const windowElement = document.getElementById(this.SHIPPING_OFFER_WINDOW_ID);
                if (windowElement) {
                    this._setWindowHeadingContent();
                    const isContentReset = this._setWindowSliderContent(windowElement);
                    if (this._isQuantitySelectable && isContentReset) {
                        this._initQuantityPickers(windowElement);
                        this._bindPlusButtonClick(windowElement);
                        this._offerSummary.updateTotals(
                            true,
                            this._model.ShippingOffer?.AvailablePoints
                        );
                        this._showOfferSummary(false);
                    }
                }
            }
        }
    }

    private _makeChannelSelector(selectedDeliveryChannel: IChannelsInfo): string {
        const channelSelectorArray = [
            `[${this.DATA_DELIVERY_CHANNEL}="${selectedDeliveryChannel.DeliveryChannelId}"]`,
        ];

        let isDefaultChannelSelected: boolean = false;
        const radioButton = kendo.getRadioButton(this.DEFAULT_CHANNEL);
        if (radioButton) {
            isDefaultChannelSelected = radioButton.value();
        }

        if (selectedDeliveryChannel.IsHomeDelivery && !isDefaultChannelSelected) {
            const selectedAddressId = this.getData(this.SELECTED_ADDRESS_ID_DATA_KEY);
            if (selectedAddressId) {
                channelSelectorArray.push(`[${this.DATA_ADDRESS}="${selectedAddressId}"]`);
            }
        } else {
            channelSelectorArray.push(" > ");
        }

        channelSelectorArray.push(this.SELECTED);
        return channelSelectorArray.join("");
    }

    public hideShippingOfferBar(): void {
        if (this._shippingOfferBar) {
            Utils.slideUp(this._shippingOfferBar, this.BAR_SLIDE_SPEED);
        }
        // reset the state so it takes the next update
        this._model = null;
    }

    public shippingOfferBeforeCheckout(): void {
        this._createOfferWindow();

        this._offerWindow.one(this.EVENT_WINDOW_OPEN, (event: kendo.ui.WindowEvent) => {
            const closeButton = Utils.find(event.sender.wrapper[0], this.SEL_WINDOW_CLOSE_BUTTON);
            if (closeButton.length > 0) {
                Utils.addClass(closeButton[0], this.CLASS_HIDDEN);
            }
        });

        this._offerWindow.one(this.EVENT_WINDOW_DEACTIVATE, (event: kendo.ui.WindowEvent) => {
            const closeButton = Utils.find(event.sender.wrapper[0], this.SEL_WINDOW_CLOSE_BUTTON);
            if (closeButton.length > 0) {
                Utils.removeClass(closeButton[0], this.CLASS_HIDDEN);
                if (ori.shopping && ori.shopping.wizard) {
                    ori.shopping.wizard.navigateNext();
                }
            }
        });

        this._openKendoWindow();
    }

    private _initQuantityPickers(windowElement: Element): void {
        const quantityPickers = Utils.find(windowElement, this.QUANTITY_PICKER_SELECTOR);
        if (quantityPickers.length) {
            const quantityPickerOptions: IOriNumericTextBoxOptions = {
                decimals: 0,
                format: "#",
                min: 0,
                theme: "roundArrows",
                value: 0,
            };

            quantityPickers.forEach((picker) => {
                quantityPickerOptions.max = Utils.getData(picker, this.QUANTITY_LIMIT_DATA_KEY);
                const quantityPicker = kendo.createNumericTextBox(picker, quantityPickerOptions);
                quantityPicker.bindSpin((event: kendo.ui.NumericTextBoxSpinEvent) =>
                    event.sender.element.blur()
                );
                quantityPicker.bindChange((event: kendo.ui.NumericTextBoxChangeEvent) =>
                    this._onQuantityPickerChange(event.sender)
                );
            });
        }
    }

    private _bindPlusButtonClick(windowElement: Element): void {
        const plusButtons = Utils.find(windowElement, this.PLUS_BUTTON_SELECTOR);
        if (plusButtons.length) {
            plusButtons.forEach((button) => {
                const kendoButton = kendo.createButton(button);
                kendoButton.bindClick((event: kendo.ui.ButtonClickEvent) =>
                    this._onPlusButtonClick(event.sender.element)
                );
            });
        }
    }

    @autobind
    private _changeProductQuantity(element: JQuery, quantity: number): void {
        if (!this._model) {
            return;
        }
        const clickedItemCode = Utils.getData(element, this.PRODUCT_CODE_DATA_KEY);
        if (clickedItemCode) {
            const clickedProduct = this._model.ShippingOffer?.SliderModel.Products.find(
                (product) => product.ProductCode === clickedItemCode.toString()
            );
            if (clickedProduct) {
                const selectedQuantity = this._offerSummary.getSelectedQuantity(
                    clickedItemCode.toString()
                );
                if (
                    quantity > selectedQuantity &&
                    clickedProduct.Points >
                        this._offerSummary.getRemainingPoints(
                            this._model.ShippingOffer?.AvailablePoints
                        )
                ) {
                    // update to original quantity
                    this._logger.info("Not enough points");
                    const quantityPicker = kendo.getNumericTextBox(
                        `${this.QUANTITY_PICKER_SELECTOR}[${this.PRODUCT_CODE_DATA_ATTRIBUTE}="${clickedItemCode}"]`
                    );
                    if (quantityPicker) {
                        quantityPicker.value(selectedQuantity);
                    }
                    return;
                }
                const selectedProduct: IOfferProduct = { ...clickedProduct, Quantity: quantity };
                this._offerSummary.update(
                    selectedProduct,
                    this._model.ShippingOffer?.AvailablePoints
                );
                this._showOfferSummary(this._offerSummary.selectedProductCount > 0);
            }
        }
    }

    private _showOfferSummary(isShown: boolean): void {
        Utils.toggleClass(this.OFFER_SUMMARY_CONTAINER, this.CLASS_HIDDEN, !isShown);
        Utils.toggleClass(this.SEL_CLOSE_SHIPPING_OFFER_DIALOG, this.CLASS_HIDDEN, isShown);
    }

    @autobind
    private _handleAddOfferItems(shoppingContext: IShoppingContext): void {
        this._updateShoppingContext(shoppingContext);
        this._closeKendoWindow();
    }

    private _onQuantityPickerChange(clickedQuantityPicker: kendo.ui.NumericTextBox): void {
        if (clickedQuantityPicker.value() === 0) {
            const clickedProductCode = Utils.getData(
                clickedQuantityPicker.element,
                this.PRODUCT_CODE_DATA_KEY
            );
            if (clickedProductCode) {
                const plusButton = kendo.getButton(
                    `${this.PLUS_BUTTON_SELECTOR}[${this.PRODUCT_CODE_DATA_ATTRIBUTE}="${clickedProductCode}"]`
                );
                if (plusButton) {
                    Utils.addClass(clickedQuantityPicker.wrapper, this.CLASS_HIDDEN);
                    Utils.removeClass(plusButton.element, this.CLASS_HIDDEN);
                }
            }
        }
        this._changeProductQuantity(clickedQuantityPicker.element, clickedQuantityPicker.value());
    }

    private _onPlusButtonClick(plusButton: JQuery): void {
        const clickedProductCode = Utils.getData(plusButton, this.PRODUCT_CODE_DATA_KEY);
        if (clickedProductCode) {
            const clickedProduct = this._model?.ShippingOffer?.SliderModel.Products.find(
                (product) => product.ProductCode === clickedProductCode.toString()
            );
            if (clickedProduct) {
                if (
                    clickedProduct.Points >
                    this._offerSummary.getRemainingPoints(
                        this._model?.ShippingOffer?.AvailablePoints
                    )
                ) {
                    this._logger.info("Not enough points");
                    return;
                }
            }
            const quantityPicker = kendo.getNumericTextBox(
                `${this.QUANTITY_PICKER_SELECTOR}[${this.PRODUCT_CODE_DATA_ATTRIBUTE}="${clickedProductCode}"]`
            );
            if (quantityPicker) {
                quantityPicker.value(1);
                Utils.addClass(plusButton, this.CLASS_HIDDEN);
                Utils.removeClass(quantityPicker.wrapper, this.CLASS_HIDDEN);
            }
            this._changeProductQuantity(plusButton, 1);
        }
    }
}
