import { Inject } from "typescript-ioc";
import { IBreakpoint } from "./IBreakpoint";
import { Utils } from "Helpers/Scripts/Utils";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { IBreakpointDto } from "./IBreakpointDto";
import { ISliderDto } from "./ISliderDto";
import { IList } from "Helpers/Scripts/IList";

/**
 * RWD breakpoint interface.
 */
export class Breakpoint implements IBreakpoint {
    private readonly _logger: ILogger;

    public get key(): string {
        return "Breakpoint";
    }

    constructor(@Inject loggerFactory: LoggerFactory) {
        this._logger = loggerFactory.getLogger(this.key);
    }

    public init(data: IBreakpointDto): this {
        if (!data || !data.mq) {
            this._logger.warning("Incomplete breakpoint data.");

            return this;
        }
        this.index = data.index;
        this.mq = data.mq;
        this.sliders = data.sliders;

        return this;
    }

    /**
     * Breakpoint index. Valid in continuous range of same type breakpoints,
     * like size breakpoints for example.
     */
    public index: number;

    /**
     * Media query text definition.
     * @example "only screen and (max-width: 479px)"
     */
    public mq: string;

    /**
     * @deprecated Whole RWD configuration for sliders thing should be refactored.
     * List of slider configurations defined per breakpoint.
     */
    public sliders?: IList<ISliderDto>;

    /**
     * The actual MQL created runtime for matching media queries.
     */
    private _mql?: MediaQueryList;

    /**
     * True when current media query matches.
     */
    public get isActive(): boolean {
        return Boolean(this._mql && this._mql.matches);
    }

    /**
     * Connects media query events for this breakpoint.
     */
    public bindEvents(handler: (originalObject?: any) => void): void {
        if (!this.mq) {
            this._logger.warning("Can't bind media query events - incomplete data.");

            return;
        }
        if (!this._mql) {
            this._mql = window.matchMedia(this.mq);
        }
        if (!this._mql) {
            this._logger.warning(
                `Couldn't set mql object for breakpoint with index ${this.index}.
                    There will probably be problems with media query based funcionality in page '${
                        document.location.href
                    }'.
                    This may happen for example in hidden iframes in Firefox etc. where the MediaQueryList objects are not set.
                    Body visible : ${Utils.is("body", ":visible")}`
            );

            return;
        }
        this._mql.addListener(handler);
        this._logger.info(
            `Applied media query events in page '${document.location.href}'.
                Breakpoint index : ${this.index},
                media : '${this._mql.media}'.`
        );
    }

    public hasMql(mql: MediaQueryList): boolean {
        if (!mql) {
            return false;
        }

        return this._mql === mql;
    }
}
