import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Anchor } from "Ui/Scripts/Anchor";
import { autobind } from "core-decorators";
import { Span } from "Ui/Scripts/Span";
import { Div } from "Ui/Scripts/Div";
import { Utils } from "Helpers/Scripts/Utils";
import { DocumentItemType } from "Documents/Scripts/DocumentItemType";

export class DocumentItemComponent extends UiComponent {
    public get key(): string {
        return "DocumentItemComponent";
    }

    public documentId: string;
    public itemId: string;
    public itemType: DocumentItemType;
    public isApproved: boolean;
    public onDeleteClick: (item: DocumentItemComponent) => void;
    public onImageClick: (item: DocumentItemComponent) => void;
    public get isEditable(): boolean {
        return Boolean(this._deleteLink);
    }

    private readonly SEL_PROGRESS_BAR: string = ".js-document-item-progress-bar";
    private readonly CLASS_ERROR: string = "document-item--error";

    private _imageLink: Anchor;
    private _deleteLink: Anchor;
    private _progresIndicator: Div;
    private _errorMessage: Span;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    protected init(): void {
        this.initComponents();
        this.initError();

        if (this._deleteLink) {
            this._deleteLink.bindClick(this.triggerDeleteClick, this.key);
        }
        if (this._imageLink) {
            this._imageLink.bindClick(this.triggerImageClick, this.key);
        }
    }

    public setProgress(progress: number): void {
        const progressBar = this._progresIndicator.findElement(this.SEL_PROGRESS_BAR);

        this._progresIndicator.removeClass(this.CLASS_HIDDEN);
        if (progressBar) {
            Utils.setCss(progressBar, "width", `${progress.toString()}%`);
        }
    }

    @autobind
    private triggerDeleteClick(event: JQuery.Event): void {
        event.preventDefault();

        if (this.onDeleteClick) {
            this.onDeleteClick(this);
        }
    }

    @autobind
    private triggerImageClick(event: JQuery.Event): void {
        event.preventDefault();

        if (this.onImageClick) {
            this.onImageClick(this);
        }
    }

    private initError(): void {
        if (this._errorMessage) {
            const message: string = this._errorMessage.getData("error-message");
            if (message) {
                kendo.applyTooltip(this._errorMessage.context, { content: message });
                this._errorMessage.toggle(true);
                this.addClass(this.CLASS_ERROR);
            }
        }
    }

    private initComponents(): void {
        const progressSelector: string = ".js-document-item-progress";
        if (Utils.find(this._context, progressSelector).length > 0) {
            this._progresIndicator = this.createComponent(Div, progressSelector);
        }
        const deleteSelector: string = ".js-document-item-delete";
        if (Utils.find(this._context, deleteSelector).length > 0) {
            this._deleteLink = this.createComponent(Anchor, deleteSelector);
        }
        const imageSelector: string = ".js-document-item-image";
        if (Utils.find(this._context, imageSelector).length > 0) {
            this._imageLink = this.createComponent(Anchor, imageSelector);
        }
        const errorSelector: string = ".js-document-error";
        if (Utils.find(this._context, errorSelector).length > 0) {
            this._errorMessage = this.createComponent(Span, errorSelector);
        }

        this.documentId = this.getData("document-id").toString();
        this.itemId = this.getData("item-id").toString();
        this.itemType = this.getData("item-type");
        this.isApproved = this.getData("is-approved");
    }
}
