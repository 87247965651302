import { IStorageService } from "./IStorageService";
import { Utils } from "Helpers/Scripts/Utils";

export class SessionStorageService implements IStorageService {
    private static _instance: IStorageService;

    /**
     * Returns default storage instance.
     */
    public static getInstance(): IStorageService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Returns requested data when found.
     * @param key The key under which the actual data is stored.
     */
    public getItem(key: string): any {
        return key && Utils.isSessionStorageAvailable ? sessionStorage.getItem(key) : undefined;
    }

    /**
     * Stores provided data.
     * @param key Key under which to store the provided data in storage.
     * @param value Data to store.
     */
    public setItem(key: string, value: any): this {
        if (key && Utils.isSessionStorageAvailable) {
            sessionStorage.setItem(key, typeof value === "string" ? value : JSON.stringify(value));
        }
        return this;
    }

    /**
     * Removes the item with given key from storage when found.
     * @param key Key of the item to be removed.
     */
    public removeItem(key: string): this {
        if (key && Utils.isSessionStorageAvailable) {
            sessionStorage.removeItem(key);
        }
        return this;
    }

    /**
     * Clear keys with provided prefix from storage.
     * @param prefix Prefix of the items to be removed.
     */
    public clearItems(prefix: string): void {
        if (prefix && Utils.isSessionStorageAvailable) {
            Object.keys(sessionStorage)
                .filter((keyName) => keyName.indexOf(prefix, 0) === 0)
                .forEach((key) => sessionStorage.removeItem(key));
        }
    }
}
