import { Inject } from "typescript-ioc";
import { UiComponent } from "./UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";

export class Section extends UiComponent {
    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    /**
     * UI component's context element in the DOM.
     */
    public get context(): HTMLElement {
        return this.getContextAs(HTMLElement, "section");
    }
}
