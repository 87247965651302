import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import $ from "jquery";

/**
 * Provides information about when a particular functionality was loaded
 * into page and/or is ready to use. This is based on promises (or jQuery
 * in case of DOM ready, which behaves similar way), so it is
 * possible to attach callbacks to the promises when they are resolved.
 * Please remember, that the promise is resolved only once and its state
 * will remain resolved from this point. All callbacks attached previously
 * to the promise are executed when the promise is resolved. Callbacks after
 *
 * TODO: once all calls even from legacy JS are refactored to use ReadyHelper
 * instead of jQuery DOM ready, we can rewrite the DOM ready functionality in
 * this class to DOMContentLoaded based functionality
 * TODO: other loading notifications (fonts loaded, images loaded, etc.)
 * TODO: should we trigger also custom events when promises are resolved?
 */
@OnlyInstantiableByContainer
@Singleton
export class ReadyHelper {
    private _initCodeBlocksReadyPromise: Promise<any>;
    private _initCodeBlocksReadyPromiseResolve: () => any;
    private _loadedPromise: Promise<any>;

    public get key(): string {
        return "ReadyHelper";
    }

    private readonly _logger: ILogger;

    public constructor(@Inject loggerFactory: LoggerFactory) {
        this._logger = loggerFactory.getLogger(this.key);
        this.init();
    }

    /**
     * Checks settings and starts waiting for the ready state.
     */
    public init(): void {
        this.setupDomReadyPromise();
        this.setupInitCodeBlocksReadyPromise();
        this.setupLoadedPromise();
    }

    /**
     * Initializes the DOM ready promise for later resolving.
     * TODO: jQuery used, refactor later to DOMContentLoaded
     * and internal promise based functionality
     */
    private setupDomReadyPromise(): void {
        $(() => {
            this._logger.info("DOM ready promise resolved.");
        });
    }

    /**
     * Binds a callback to the DOM ready "promise". The callback is executed
     * immediatelly when the "promise" was already resolved, otherwise waits
     * until the DOM is ready.
     * @param callback A function to be executed when the DOM is ready.
     */
    public bindDomReady(callback: () => any): this {
        // TODO: Promise caused another delay here as callback is called asynchronously,
        // so the proper ordering was broken in combination with legacy code.
        // So for now we are not using the promise
        // for DOM ready, rather direct call to jQuery instead
        // this._domReadyPromise.then(callback);
        jQuery(() => callback());

        return this;
    }

    /**
     * Initializes the init code blocks ready promise for later resolving.
     */
    private setupInitCodeBlocksReadyPromise(): void {
        this._initCodeBlocksReadyPromise = new Promise<void>((resolve) => {
            // just stores the resolve function pointer for later
            // TODO: simpler solution?
            this._initCodeBlocksReadyPromiseResolve = resolve;
        });
    }

    /**
     * Resolves the initCodeBlocksReady promise. This is now called from a dedicated
     * helper view after supposedly all legacy code init code blocks were already run.
     */
    public initCodeBlocksReady(): void {
        this._logger.info("Init code blocks are ready.");
        this._initCodeBlocksReadyPromiseResolve();
    }

    /**
     * Binds a callback to the init-code-blocks-ready promise. The callback is executed
     * immediatelly when the promise was already resolved, otherwise waits
     * until all of the legacy JS init code blocks were supposedly executed.
     * Useful for cases when TS code should wait for legacy JS initializations
     * done in InitCodeBlock()'s or similar, like for example Kendo component
     * initializations.
     * @param callback Code to execute when ready.
     */
    public bindInitCodeBlocksReady(callback: () => any): void {
        this._initCodeBlocksReadyPromise.then(callback);
    }

    /**
     * Initializes the window loaded promise for later resolving.
     * Useful for cases when some code should be executed after the page
     * is completely loaded.
     * TODO: jQuery used, refactor later to native script
     */
    private setupLoadedPromise(): void {
        this._loadedPromise = new Promise<void>((resolve) => {
            $(window).bindLoad(
                () => {
                    this._logger.info("Page is loaded.");
                    window.setTimeout(() => {
                        this._logger.info("Page load promise resolved.");
                        resolve();
                    });
                },
                undefined,
                true
            );
        });
    }

    /**
     * Binds a callback to the window loaded promise. The callback is executed
     * immediatelly when the promise was already resolved, otherwise waits
     * until the window load event is triggered.
     * @param callback A function to be executed when the page was completely loaded.
     */
    public bindLoaded(callback: () => any): void {
        this._loadedPromise.then(callback);
    }
}
