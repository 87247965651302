import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { IList } from "Helpers/Scripts/IList";
import { Utils } from "Helpers/Scripts/Utils";
import { FetchServiceResponseError } from "./FetchServiceResponseError";

/**
 * AJAX wrapper based on window.fetch() functionality.
 */
@OnlyInstantiableByContainer
@Singleton
class FetchService {
    public static readonly ABORT_ERROR: string = "AbortError";
    public static readonly ACCEPT: string = "accept";
    public static readonly ACCEPT_JSON: string = "application/json, text/javascript, */*; q=0.01";
    public static readonly CONTENT_TYPE: string = "content-type";
    public static readonly CONTENT_TYPE_JSON: string = "application/json; charset=utf-8";
    public static readonly METHOD_GET: string = "GET";
    public static readonly METHOD_POST: string = "POST";
    public static readonly METHOD_PUT: string = "PUT";
    public static readonly METHOD_DELETE: string = "DELETE";
    public static readonly DEFAULT_TIMEOUT: number = 300000; // Time in miliseconds after which will be request aborted (5 minutes)
    public static readonly DEFAULT_REQUEST_OPTIONS: RequestInit = {
        credentials: "same-origin",
        headers: {
            "X-Fetch-Api": "1",
            "X-Requested-With": "XMLHttpRequest",
        },
        method: FetchService.METHOD_GET,
    };
    public static readonly NO_CACHE_REQUEST_OPTIONS: RequestInit = {
        // new way, probably still not supported everywhere
        cache: "no-store",
        // good old way, hopefully works in combination with above
        headers: {
            "cache-control": "no-cache",
            pragma: "no-cache",
        },
    };

    public get key(): string {
        return "FetchService";
    }

    protected readonly _logger: ILogger;

    constructor(@Inject loggerFactory: LoggerFactory) {
        this._logger = loggerFactory.getLogger(this.key);
    }

    /**
     * Generic wrapper method around the fetch API.
     * @param url URL to be loaded.
     * @param params Additional URL query parameters.
     * @param requestOptions Initialization options for fetch request.
     * @param noDefaultOptions Do not add default fetch options to the request header.
     */
    public async fetch<T>(
        url: string,
        params?: any,
        requestOptions: RequestInit = {},
        noDefaultOptions?: boolean
    ): Promise<T> {
        let options: RequestInit = {};
        if (noDefaultOptions) {
            options = Utils.extend(true, options, requestOptions);
        } else {
            options = Utils.extend(
                true,
                options,
                FetchService.DEFAULT_REQUEST_OPTIONS,
                requestOptions
            );
        }
        const currentUrl = this.prepareUrl(url, params);
        this._logger.info(`URL to be loaded: ${currentUrl}`);
        const request = new Request(currentUrl, options);

        return new Promise<T>((resolve, reject) => {
            this._logger.info(
                `sending request:
                url: ${request.url}
                headers: ${JSON.stringify(this.listAvailableHeaders(request.headers))}
                cache: ${request.cache}
                credentials: ${request.credentials}
                destination: ${request.destination}
                integrity: ${request.integrity}
                keepalive: ${request.keepalive}
                method: ${request.method}
                mode: ${request.mode}
                redirect: ${request.redirect}
                referrer: ${request.referrer}
                referrerPolicy: ${request.referrerPolicy}
            `
            );
            this._abortableFetch(request)
                .then(async (response: Response) => {
                    // TODO: this is just the JSON response, what other types?
                    this._logger.info(
                        `response received:
                        url: ${currentUrl}
                        ok: ${response.ok}
                        status: ${response.status}
                        statusText: ${response.statusText}
                        headers: ${JSON.stringify(this.listAvailableHeaders(response.headers))}
                    `
                    );
                    if (!response.ok) {
                        // there was a response but it was not ok so we reject it with FetchServiceResponseError that contain response
                        const error = new FetchServiceResponseError(response);
                        reject(error);
                    } else {
                        // TODO: parseResponse returns T|string, but here we cast to T anyway - ugly?
                        resolve((await this.parseResponse<T>(response)) as T);
                    }
                })
                .catch((error: Error) => {
                    if (error.name && error.name === FetchService.ABORT_ERROR) {
                        this._logger.info("Fetch request aborted.");
                    }
                    reject(error);
                });
        });
    }

    /**
     * Emergency fallback method for GET requests using jQuery.
     * Intended to use only for cross-domain requests where CORS does not work because of missing Access-Control-Allow-Origin header.
     * Callbacks can then be chained as in regular promise, using fluent syntax.
     * @param url URL to be loaded.
     */
    public getJQxhrJSON(url: string): JQueryXHR {
        return $.getJSON(url);
    }

    /**
     * Generic wrapper method for GET requests.
     * @param url URL to be loaded.
     * @param params Additional URL params.
     * @param requestOptions Custom request options can be provided.
     * @param noDefaultOptions Do not add default fetch options to the request header.
     */
    public async get<T>(
        url: string,
        params?: any,
        requestOptions?: RequestInit,
        noDefaultOptions?: boolean
    ): Promise<T> {
        const options = requestOptions || {};
        options.method = FetchService.METHOD_GET;

        return this.fetch<T>(url, params, options, noDefaultOptions);
    }

    /**
     * Generic method for loading JSON object data via GET request.
     * The compiler supposes here the correctly typed data object to be returned
     * and no additional runtime checks for data integrity are made by default.
     * The data is just deserialized to JSON object by a default parsing routine.
     * @param url URL to be loaded.
     * @param params Additional URL params.
     */
    public async getJSON<T>(url: string, params?: any): Promise<T> {
        const requestOptions: RequestInit = {
            headers: new Headers({ [FetchService.ACCEPT]: FetchService.ACCEPT_JSON }),
        };

        return this.get<T>(url, params, requestOptions);
    }

    /**
     * Generic wrapper method for PUT requests.
     * @param url URL to be loaded.
     * @param params Additional URL params.
     * @param requestOptions Custom request options can be provided.
     * @param noDefaultOptions Do not add default fetch options to the request header.
     */
    public async put<T>(
        url: string,
        params?: any,
        requestOptions?: RequestInit,
        noDefaultOptions?: boolean
    ): Promise<T> {
        const options = requestOptions || {};
        options.method = FetchService.METHOD_PUT;

        return this.fetch<T>(url, params, options, noDefaultOptions);
    }

    /**
     * Generic wrapper method for DELETE requests.
     * @param url URL to be loaded.
     * @param params Additional URL params.
     * @param requestOptions Custom request options can be provided.
     * @param noDefaultOptions Do not add default fetch options to the request header.
     */
    public async delete<T>(
        url: string,
        params?: any,
        requestOptions?: RequestInit,
        noDefaultOptions?: boolean
    ): Promise<T> {
        const options = requestOptions || {};
        options.method = FetchService.METHOD_DELETE;

        return this.fetch<T>(url, params, options, noDefaultOptions);
    }

    /**
     * Generic wrapper method for POST requests.
     * @param url URL to be loaded.
     * @param data Form data to be sent with this post request.
     * @param params Additional URL params.
     * @param requestOptions Custom request options can be provided.
     */
    public async post<T>(
        url: string,
        data?: FormData,
        params?: any,
        requestOptions?: RequestInit
    ): Promise<T> {
        const options = requestOptions || {};
        options.method = FetchService.METHOD_POST;
        if (data) {
            options.body = data;
        }

        return this.fetch<T>(url, params, options);
    }

    /**
     * Simple method for posting form data.
     * @param formSelector Form element or string selector to target given form.
     * @param url Custom URL can be provided, or the original form's action will be called.
     * @param params Additional URL params.
     * @param requestOptions Custom request options can be provided.
     */
    public async postForm<T>(
        formOrSelector: HTMLFormElement | string,
        postUrl?: string,
        params?: any,
        requestOptions?: RequestInit
    ): Promise<T> {
        const form =
            typeof formOrSelector === "string"
                ? (document.querySelector(formOrSelector) as HTMLFormElement)
                : formOrSelector;

        if (!form) {
            return new Promise<T>((_: Function, reject: Function) => {
                reject("Form element not found.");
            });
        }
        const data = new FormData(form);
        const url = postUrl || form.action;

        return this.post<T>(url, data, params, requestOptions);
    }

    /**
     * Method for appending a value of arbitrary type to form data.
     * It appends objects and arrays to unlimited depth by recursively going through them.
     * @param formData FormData object to append the value to.
     * @param name Name (key) in the newly appended value within FormData.
     * @param value Value to be appended.
     */
    public appendValue(formData: FormData, name: string, value: any): void {
        if (typeof value !== "object" || value === null) {
            // primitive value
            formData.append(name, value);
        } else if (value && value.constructor === Array) {
            // array
            value.forEach((val: any, index: number) => {
                this.appendValue(formData, `${name}[${index}]`, val);
            });
        } else {
            // object
            Object.keys(value).forEach((key) => {
                this.appendValue(formData, name === "" ? key : `${name}[${key}]`, value[key]);
            });
        }
    }

    private async _abortableFetch(request: Request): Promise<Response> {
        const controller = new AbortController();
        const signal = controller.signal;
        const outerSignal = request.signal;
        const abortError = new Error("Aborted");
        abortError.name = FetchService.ABORT_ERROR;

        if (outerSignal) {
            // Return early if already aborted, thus avoiding making an HTTP request
            if (outerSignal.aborted) {
                return Promise.reject(abortError);
            }

            outerSignal.addEventListener("abort", () => controller.abort(), { once: true });
        }

        // Turn an event into a promise, reject it once "abort" is dispatched
        const abortWithSignal = new Promise<Response>((_, reject) => {
            signal.addEventListener("abort", () => reject(abortError), { once: true });
        });

        // Abort request after timeout elapses
        window.setTimeout(() => controller.abort(), FetchService.DEFAULT_TIMEOUT);

        // Return the fastest promise (don't need to wait for request to finish)
        return Promise.race([fetch(request), abortWithSignal]);
    }

    private prepareUrl(url: string, params?: any): string {
        const query = this.encodeSearchParams(params);

        return url + (query ? `?${query}` : "");
    }

    private listAvailableHeaders(headers: Headers): IList<string> {
        const result: IList<string> = {};
        if (headers) {
            headers.forEach((value: string, key: string) => (result[key] = value));
        }

        return result;
    }

    /**
     * Tries to get proper response data according to the response headers.
     * Returns promise which should resolve to a required type or simple string output.
     * We are cloning the response to keep the original response body un-touched
     * just for the case we would need to work with it in a different way elsewhere
     * (response.bodyUsed will remain set to false).
     * TODO: How to limit this better in a typed way? Runtime?
     * TODO: other types of content like image blobs etc.?
     * response.arrayBuffer();
     * response.blob();
     * @param response Response for a particular fetch request.
     */
    public async parseResponse<T>(response: Response): Promise<T | string> {
        const contentType = response.headers.get(FetchService.CONTENT_TYPE);
        let responseClone;

        // IE11 does not like calling clone() on response object, so we try to do clone
        // and if it fails, we will use the original response
        try {
            responseClone = response.clone();
        } catch (error) {
            this._logger.warning(`Couldn't clone fetch response: ${error}`);
            responseClone = response;
        }

        switch (contentType) {
            case FetchService.CONTENT_TYPE_JSON:
                return responseClone.json();
            default:
                return responseClone.text();
        }
    }

    private encodeSearchParams(params?: any): string {
        if (params) {
            const esc = encodeURIComponent;

            return Object.keys(params)
                .map((k) => `${esc(k)}=${esc(params[k])}`)
                .join("&");
        }

        return "";
    }
}

export { FetchService, FetchServiceResponseError };
