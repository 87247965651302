import { OnlyInstantiableByContainer, Singleton } from "typescript-ioc";


@OnlyInstantiableByContainer
@Singleton
export class MobileOfficeSection {
    public get key(): string {
        return "MobileOfficeSection";
    }
    private readonly SECTION_CLASS: string = "mobile-office-section";
    private readonly SECTION_OUTLINED_CLASS: string = "mobile-office-section-outlined";
    private readonly SECTION_SELECTOR: string = ".mobile-office-section, .mobile-office-section-outlined";
    private readonly MOBILE_OFFICE_OLD_SECTION_CLASS: string = "js-mobile-office-old-section";
    private readonly SECTION_CONTENT_CLASS: string = "mobile-office-section__content";
    private readonly SECTION_CONTENT_SELECTOR: string = ".mobile-office-section__content";
    private readonly MOBILE_OFFICE_OLD_CONTENT_CLASS: string =
        "js-mobile-office-old-section__content";
    private readonly SECTION_ROW_CLASS: string = "mobile-office-section-grid";
    private readonly SECTION_ROW_SELECTOR: string = ".mobile-office-section-grid";

    public init(): void {
        this._hideEmptySections();
    }

    private _hideEmptySections(): void {
        document.querySelectorAll(this.SECTION_SELECTOR).forEach((item) => {
            const sectionRow = item.querySelector(this.SECTION_ROW_SELECTOR) as HTMLElement;
            if (this._isBlockEmpty(sectionRow, this.SECTION_ROW_CLASS)) {
                sectionRow.remove();
            }

            const content = item.querySelector(this.SECTION_CONTENT_SELECTOR) as HTMLElement;
            if (this._isBlockEmpty(content, this.SECTION_CONTENT_CLASS)) {
                item.remove();
            }
        });
    }

    public removeSectionModule(moduleSelector: string): void {
        const sectionModule = document.querySelector(moduleSelector) as HTMLElement;
        if (!sectionModule) {
            return;
        }
        let sectionContentElement = sectionModule.parentElement as HTMLElement;

        // remove selected module on mp2 and mp3
        sectionModule.remove();

        // on mp3 and mp2 - check if row wraprer is empty
        const sectionRowElement = sectionContentElement;
        if (this._isBlockEmpty(sectionRowElement, this.SECTION_ROW_CLASS)) {
            sectionContentElement = sectionRowElement.parentElement as HTMLElement;
            sectionRowElement.remove();
        }
        // on mp3
        this._tryToRemoveSection(
            this.SECTION_CLASS,
            this.SECTION_CONTENT_CLASS,
            sectionContentElement
        );
        // on mp2
        this._tryToRemoveSection(
            this.MOBILE_OFFICE_OLD_SECTION_CLASS,
            this.MOBILE_OFFICE_OLD_CONTENT_CLASS,
            sectionContentElement
        );
        // outlined section
        this._tryToRemoveSection(
            this.SECTION_OUTLINED_CLASS,
            this.SECTION_CONTENT_CLASS,
            sectionContentElement
        );
    }

    private _tryToRemoveSection(
        sectionClass: string,
        sectionContentClass: string,
        sectionContentElement: HTMLElement
    ): void {
        if (this._isBlockEmpty(sectionContentElement, sectionContentClass)) {
            const section = sectionContentElement.parentElement;
            if (section && section.classList.contains(sectionClass)) {
                section.remove();
            }
        }
    }

    private _isBlockEmpty(blockElement: HTMLElement, blockContentClass: string): boolean {
        if (
            blockElement &&
            blockElement.classList.contains(blockContentClass) &&
            blockElement.firstElementChild === null
        ) {
            return true;
        }
        return false;
    }
}
