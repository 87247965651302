/* ORDER TRACKING */

import { autobind } from "core-decorators";
import { ILogger } from "Logging/Scripts/ILogger";
import { UiComponent } from "Ui/Scripts/UiComponent";
import * as OrderPlacedEvent from "@ori-events/order-placed";
import { isReady as isAnalyticsReady } from "@ori/analytics-provider";

// Utils
import { Utils } from "Helpers/Scripts/Utils";

export class OrderTracking {
    private readonly _parentComponent: UiComponent;
    protected readonly _logger: ILogger;

    private readonly TOOLTIP_TEMPLATE_ID: string = "created-by-tooltip-template";
    private readonly C_PRIMARY_INVERT_BUTTON: string = "k-button primary-invert";
    private readonly C_OPEN_ORDER_TRACKING: string = "js-open-order-tracking";
    private readonly C_WITH_BUTTON: string = "with-button";
    private readonly S_BUTTON: string = ".k-button";
    private readonly S_DELIVERY_SUMMARY: string = ".js-delivery-summary-module";
    private readonly S_PAYMENT_SUMMARY: string = ".js-payment-summary-module";
    private readonly S_DELIVERY_SUMMARY_CONTENT: string = `${this.S_DELIVERY_SUMMARY} > .content`;
    private readonly S_CREATED_BY_ICON: string = ".js-icon-created-by";
    private readonly S_ORDER_TRACKING_POPUP: string = "#order-tracking-popup";
    private readonly DATA_TRACKING_BUTTON: string = "trackingButton";
    private readonly DATA_TRACKING_INFO: string = "trackingInfo";
    private readonly TRACKING_POPUP_WIDTH: number = 640;
    private _orderTrackingBtn: Element | null = null;
    private _orderTrackingPopupElm: Element | null = null;
    private _orderTrackingPopup: kendo.ui.Window;

    constructor(parentComponent: UiComponent) {
        this._parentComponent = parentComponent;
    }

    /**
     * Inits tooltips in order tracking popup
     */
    public init(): void {
        this._orderTrackingPopupElm = this._parentComponent.findElement(
            this.S_ORDER_TRACKING_POPUP
        );

        // check if order tracking popup exists and create kendo window, init tooltips, render button
        if (this._orderTrackingPopupElm) {
            this._orderTrackingPopup = kendo.createWindow(this._orderTrackingPopupElm, {
                maxWidth: this.TRACKING_POPUP_WIDTH,
                modal: true,
                resizable: false,
                title: false,
                visible: false,
            });
            this._initTooltips();
            this._renderOrderTrackingBtn();
        }

        this._dispatchOrderPlacedEvent();
        this._bindEvents();
    }

    /**
     * Inits tooltips in order tracking popup
     */
    private _initTooltips(): void {
        const tooltipTemplate = document.getElementById(this.TOOLTIP_TEMPLATE_ID);
        if (tooltipTemplate && this._orderTrackingPopupElm) {
            const tooltipElems = Utils.find(this._orderTrackingPopupElm, this.S_CREATED_BY_ICON);

            tooltipElems.forEach((tooltipElm) => {
                kendo.createTooltip(tooltipElm, {
                    content: kendo.template(tooltipTemplate.innerHTML),
                    offset: 12,
                    position: "top",
                });
            });
        }
    }

    private _dispatchOrderPlacedEvent(): void {
        const trackingInfo = this._parentComponent.getData(this.DATA_TRACKING_INFO);

        if (!trackingInfo) return;
       
        const detail: OrderPlacedEvent.TrackingInfoData = {
            firstPurchase: trackingInfo.firstPurchase,
            salesTime: trackingInfo.salesTime,
            transactionId: trackingInfo.transactionId,
            transactionConsultantId: trackingInfo.transactionConsultantId,
            transactionCustomerTypeId: trackingInfo.transactionCustomerTypeId,
            transactionCurrency: trackingInfo.transactionCurrency,
            transactionPaymentMethod: trackingInfo.transactionPaymentType,
            transactionDeliveryMethod: trackingInfo.transactionShippingMethod,
            transactionTotal: trackingInfo.transactionTotal,
            transactionVoucherPromotion: trackingInfo.transactionVoucherPromotion,
            transactionVoucherCode: trackingInfo.transactionVoucherCode,
            transactionOrderType: trackingInfo.transactionOrderType,
            transactionProducts: trackingInfo.transactionProducts,
        };

        const orderPlacedEvent = new CustomEvent(OrderPlacedEvent.Name, { detail });
        try {
            isAnalyticsReady().then(() => window.dispatchEvent(orderPlacedEvent));
        }
        catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Renders order tracking button if RTOT is disabled
     */
    private _renderOrderTrackingBtn(): void {
        const btnData = this._parentComponent.getData(this.DATA_TRACKING_BUTTON);

        if (!btnData || !btnData.showButton) {
            return;
        }

        this._orderTrackingBtn = document.createElement("button");
        Utils.addClass(
            this._orderTrackingBtn,
            `${this.C_PRIMARY_INVERT_BUTTON} ${this.C_OPEN_ORDER_TRACKING}`
        );
        Utils.setHtml(this._orderTrackingBtn, btnData.buttonLabel);
        Utils.append(this.S_DELIVERY_SUMMARY_CONTENT, this._orderTrackingBtn);
        this._alignButtons();
    }

    /**
     * Aligns horizontaly buttons in delivery and payment summary
     */
    private _alignButtons(): void {
        const deliverySummaryElm = this._parentComponent.findElement(this.S_DELIVERY_SUMMARY);
        const paymentSummaryElm = this._parentComponent.findElement(this.S_PAYMENT_SUMMARY);

        if (!deliverySummaryElm || !paymentSummaryElm) {
            return;
        }

        const paymentButton = paymentSummaryElm.querySelector(this.S_BUTTON);

        if (paymentButton) {
            deliverySummaryElm.classList.add(this.C_WITH_BUTTON);
            paymentSummaryElm.classList.add(this.C_WITH_BUTTON);
        }
    }

    private _bindEvents(): void {
        if (this._orderTrackingBtn) {
            this._parentComponent.bindDelegatedClick(
                `.${this.C_OPEN_ORDER_TRACKING}`,
                this._openOrderTrackingPopup
            );
        }
    }

    /**
     * Opens order tracking popup
     */
    @autobind
    private _openOrderTrackingPopup(event: JQuery.Event): void {
        event.preventDefault();
        this._orderTrackingPopup.open();
    }
}
