import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { FetchService } from "Async/Scripts/FetchService";
import { Utils } from "Helpers/Scripts/Utils";
import { autobind } from "core-decorators";

import { EditorInputFactory } from "./EditorInputFactory";
import { EditorInput } from "./EditorInput";
import { EditorInputAutoComplete } from "./EditorInputAutoComplete";
import { EditorInputHidden } from "./EditorInputHidden";
import { IFilterRequestData } from "./IFilterRequestData";
import { IAddressModel } from "./IAddressModel";
import { AddressCountrySelect } from "./AddressCountrySelect";

import "../Styles/address-editor.css";

export class AddressEditor extends UiComponent {
    private readonly CLASS_ADDRESS_AUTOCOMPLETE: string = "js-address-autocomplete";
    private readonly SEL_INPUT_AUTOCOMPLETE: string = "input." + this.CLASS_ADDRESS_AUTOCOMPLETE;
    // TODO remove "input" from the selector after kendo's unwanted propagation of js-* classes is fixed
    private readonly SEL_INPUT_HIDDEN: string = "input.js-address-hidden"; // TODO the same

    private readonly DATA_UPDATE_ADDRESS_URL: string = "update-address-url";
    private readonly DATA_AREA_TYPE: string = "area-type";
    private readonly DATA_STRICT_MODE: string = "strict-mode";

    private readonly LOWEST_ZIP_NODE_ID_CLASS: string = "js-lowest-zip-node-id";

    private readonly _editorInputFactory: EditorInputFactory;
    private readonly _fetchService: FetchService;

    private _countrySelect: AddressCountrySelect;
    private _autoCompleteInputs: EditorInputAutoComplete[];
    private _hiddenInputs: EditorInputHidden[];
    private _updateAddressModelUrl: string;
    private _lastFocusedAutoCompleteComponent: string;
    private _lastVerifiedAddressModel: IAddressModel;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject editorInputFactory: EditorInputFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject fetchService: FetchService
    ) {
        super(componentFactory, binder, loggerFactory);

        this._fetchService = fetchService;
        this._editorInputFactory = editorInputFactory;
    }

    public init(): void {
        try {
            this._autoCompleteInputs = this._editorInputFactory.createArray(
                this.context,
                EditorInputAutoComplete,
                this.SEL_INPUT_AUTOCOMPLETE
            );
        } catch (error) {
            this._autoCompleteInputs = [];
            this._logger.error(`Couldn't write data to inputs: ${error}`);
        }

        try {
            this._hiddenInputs = this._editorInputFactory.createArray(
                this.context,
                EditorInputHidden,
                this.SEL_INPUT_HIDDEN
            );
        } catch (error) {
            this._hiddenInputs = [];
            this._logger.error(`Couldn't hide data to inputs: ${error}`);
        }

        const countrySelectClass: string = ".js-address-country-select";
        if (Utils.find(this._context, countrySelectClass).length > 0) {
            this._countrySelect = this.createComponent(AddressCountrySelect, countrySelectClass);

            this._countrySelect.onChange = this.onCountryChange;
        }

        this._updateAddressModelUrl = this.getData(this.DATA_UPDATE_ADDRESS_URL);

        this._autoCompleteInputs.forEach((input) => {
            input.component.element.bindFocus(this._onAutoCompleteFocus);
            input.component.bindChange(this._onAutoCompleteChange);
            input.component.bindOpen(this._onAutoCompleteOpen);
            input.preventPageScroll();

            const ds = input.component.dataSource;
            if (ds.options && ds.options.transport && ds.options.transport.read) {
                (ds.options.transport.read as kendo.data.DataSourceTransportRead).data = () =>
                    this._onAdditionalData(input);
            }

            input.component.element.attr("autocomplete", "no-fill");
        });
    }

    @autobind
    private onCountryChange(): void {
        const model: IAddressModel = {};

        this._setValuesFromModel(this._autoCompleteInputs, model);
        this._setValuesFromModel(this._hiddenInputs, model);
        this.removeLowestZipNodeId();
    }

    @autobind
    private _onAdditionalData(e: EditorInputAutoComplete): IFilterRequestData<IAddressModel> {
        const areaType = e.component.element.data(this.DATA_AREA_TYPE);
        const requestData: IFilterRequestData<IAddressModel> = {
            filterModel: this._getFilterModel(),
            text: e.getValue(),
        };
        if (typeof areaType !== "undefined") {
            requestData.areaType = areaType;
        }

        return requestData;
    }

    private _getFilterModel(): IAddressModel {
        const values: IAddressModel = {};

        if (this._countrySelect) {
            values.Country = this._countrySelect.getValue();
        }
        this._autoCompleteInputs.forEach((input) => {
            values[input.getPropertyName() as keyof IAddressModel] = input.getValue();
        });

        return values;
    }

    @autobind
    private _onAutoCompleteFocus(e: JQuery.TriggeredEvent | undefined): void {
        if (typeof e === "undefined") {
            return;
        }
        const input = this._editorInputFactory.getComponentReference<EditorInputAutoComplete>(
            e.currentTarget
        );
        if (typeof input === "undefined") {
            return;
        }
        // Open the autocomplete even when no character is inserted (workaround for minLenght 0)
        if (input.getValue() === "") {
            input.component.search("");
        }

        if (input.component.element.hasClass(this.CLASS_ADDRESS_AUTOCOMPLETE)) {
            this._lastFocusedAutoCompleteComponent = input.component.element.data(
                this.DATA_AREA_TYPE
            );
        }
    }

    @autobind
    private _onAutoCompleteOpen(e: kendo.ui.AutoCompleteOpenEvent | undefined): void {
        if (typeof e === "undefined") {
            return;
        }

        const componentAreaType = e.sender.element.data(this.DATA_AREA_TYPE);
        if (
            this._lastFocusedAutoCompleteComponent !== "" &&
            componentAreaType !== this._lastFocusedAutoCompleteComponent
        ) {
            e.preventDefault();
        }
    }

    private _setValuesFromModel<T extends EditorInput>(inputs: T[], model: IAddressModel): void {
        inputs.forEach((input) => {
            const newValue = model[input.getPropertyName() as keyof IAddressModel];
            input.setValue(newValue || "");
        });
    }

    @autobind
    private _onAutoCompleteChange(e: kendo.ui.AutoCompleteChangeEvent | undefined): void {
        if (typeof e === "undefined") {
            return;
        }
        const element = e.sender.element;
        const autoComplete = kendo.getAutoComplete(element);
        if (typeof autoComplete === "undefined") {
            return;
        }
        const selectedItem = autoComplete.dataItem();

        // Prevent custom input in autocomplete control
        if (typeof selectedItem === "undefined") {
            const inputElement = element.get(0);
            if (inputElement) {
                const strictModeEnabled: boolean = Boolean(
                    Utils.getData(inputElement, this.DATA_STRICT_MODE)
                );

                if (this.wasVerifiedValueChanged(inputElement)) {
                    this.removeLowestZipNodeId();
                }
                if (strictModeEnabled) {
                    autoComplete.value("");
                }
            }
            return;
        }

        const formData = new FormData();
        formData.append("selectedNodeId", selectedItem.Value);
        this._fetchService.appendValue(formData, "addressModel", this._getFilterModel());

        this._loadFormData(formData);
    }

    private _loadFormData = async (formData: FormData): Promise<any> => {
        await this._fetchService.post(this._updateAddressModelUrl, formData).then(
            (result: IAddressModel) => {
                this._lastVerifiedAddressModel = result;
                this._setValuesFromModel(this._autoCompleteInputs, result);
                this._setValuesFromModel(this._hiddenInputs, result);
            },
            () => {
                this._logger.error("updateAddressModel call failed...");
            }
        );
    };

    private wasVerifiedValueChanged(inputElement: HTMLElement): boolean {
        if (!this._lastVerifiedAddressModel) {
            return false;
        }
        const inputName: string = (Utils.getAttr(inputElement, "name") ?? "").replace(/^.+\./, "");
        const lastVerifiedValue = this._lastVerifiedAddressModel[inputName as keyof IAddressModel];
        return typeof lastVerifiedValue !== "undefined" && lastVerifiedValue.toString().length > 0;
    }

    private removeLowestZipNodeId(): void {
        const lowestZipNodeIdInput = this._hiddenInputs.find((x) =>
            Utils.hasClass(x.component, this.LOWEST_ZIP_NODE_ID_CLASS)
        );
        if (typeof lowestZipNodeIdInput !== "undefined") {
            lowestZipNodeIdInput.setValue("");
        }
    }
}
