export enum OrderStatus {
    All = 0,
    Paid = 1,
    Unpaid = 2,
    CreditNote = 3,
    PreOrder = 4,
    PrePreOrder = 5,
    Pending = 6,
    Canceled = 7,
    Blocked = 8,
    Waiting = 9,
}
