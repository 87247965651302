/**
 * Logging output type.
 */
export enum DebugOutputType {
    None,
    Console,
    Alerts,
}

export const DEFAULT_OUTPUT_TYPE = DebugOutputType.Console;
export const DEFAULT_OUTPUT_TYPES = [DEFAULT_OUTPUT_TYPE];
