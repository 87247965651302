import { autobind } from "core-decorators";

import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { FetchService } from "Async/Scripts/FetchService";
import { PickupPoint } from "./PickupPoint";
import { ShippingOffer } from "./ShippingOffer/ShippingOffer";

import { Utils } from "Helpers/Scripts/Utils";
import { Spinner } from "Spinner/Scripts/Spinner";
import * as DeliveryChannelsEventType from "./DeliveryChannelsEventType";

import { IPickupPointMapFetch } from "./IPickupPointMapFetch";
import { IPickupPointDetail } from "./IPickupPointDetail";
import { IPickupPointPins } from "./IPickupPointPins";

@OnlyInstantiableByContainer
@Singleton
export class PickupPointWithMapYandex extends PickupPoint {
    private readonly MAP_HEIGHT: number = 250;
    private readonly MAP: string = "#js-delivery-map";
    private _isMapInited: boolean;
    private readonly DELIVERY_METHOD_KEY: string = "deliveryMethodKey";

    public get key(): string {
        return "PickupPointWithMapYandex";
    }

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject fetchService: FetchService,
        @Inject shippingOfferComponent: ShippingOffer
    ) {
        super(componentFactory, binder, loggerFactory, fetchService, shippingOfferComponent);
    }

    public init(): void {
        this._isMapInited = false;
        this._setUrlLinks();
        this._setPreselectedCity();

        this._setFilters();
        this._updateSelectedFilterType();
        super._initSelectedPickupPoint();
        this._bindSubmitAction();
    }

    private _setFilters(): void {
        this._setFilterSearch(this._onFilterSearchChange);
        this._setFilterType(this._model.DeliveryMethods, this._handleFilterTypeChange);
    }

    @autobind
    private _updateSelectedFilterType(): void {
        if (this._model.mapModule) {
            const filterTypeWidget = kendo.getDropDownList(this._filters.type.selector);
            const filterTypeValue = this._model.mapModule.DeliveryMethodFilter;
            if (filterTypeWidget && filterTypeValue !== null) {
                filterTypeWidget.value(filterTypeValue);
            }
        }
    }

    @autobind
    private _bindSubmitAction(): void {
        this._binder.bindKeyDown(
            (e) => this._submitAction(e as JQuery.TriggeredEvent),
            this._filters.search.selector
        );
    }

    @autobind
    private _handleFilterTypeChange(event: kendo.ui.DropDownListChangeEvent): void {
        this._updateFiltersCurrentData(event.sender.value(), this.FILTER_DELIVERY_METHOD);
        if (ori.maps) {
            ori.maps.hideDetailPinInfobox();
        }
        this._storeSelectedFilterType();
        this._forceCheckoutDisable();
        if (ori.maps) {
            ori.maps.initRemoteObjectManager();
        }
    }

    private _onFilterSearchChange(event: kendo.ui.AutoCompleteEvent): void {
        this._handleFilterSearchChange(
            event,
            () => {
                if (ori.maps) {
                    ori.maps.clearMap();
                }
                this.loadAndShowPickupPoints(true);
            },
            () => {
                this._channelState = null;
                if (ori.maps) {
                    ori.maps.hideDetailPinInfobox();
                }
                this.loadAndShowPickupPoints();
                if (ori.maps) {
                    ori.maps.initRemoteObjectManager();
                }
            }
        );
    }

    private _storeSelectedFilterType(): void {
        const dataForm = new FormData();
        dataForm.append(this.DELIVERY_METHOD_KEY, this._filtersCurrentData.deliveryMethodKey);

        this._fetchService.post(this._updateDeliveryMethodFilter, dataForm).then((data) => {
            if (data && ori.maps) {
                ori.maps.initRemoteObjectManager();
            }
        });
    }
    // TODO: remove un-D.R.Y. approach (move it in PickupPoint.ts)
    private _submitAction(event: JQuery.TriggeredEvent): void {
        if (
            event.keyCode === this.ENTER_KEY_CODE &&
            event.target.id === this._filters.search.selector.substr(1) &&
            this._filtersCurrentData.searchTerm !== "" &&
            !this._isOptionHighlighted()
        ) {
            this._setFilterCity();
            this._filtersCurrentData.requestId += 1;
            this._filtersCurrentData.page = 0;
        }
    }

    private _setFilterCity(): void {
        if (ori.maps) {
            ori.maps.moveToAddress(this._filtersCurrentData.searchTerm);
        }
    }

    /* eslint-disable @typescript-eslint/promise-function-async */
    private _loadMapPickupPoints(): Promise<IPickupPointMapFetch> {
        if (!ori.maps || !ori.maps.isMapCreated()) {
            throw new Error("Couldn't find IOriMap instance.");
        }

        const mapData = ori.maps.getBounds();
        const zoom = ori.maps.getZoomForServer();

        const NORTH_KEY = 0;
        const EAST_KEY = 1;
        const SOUTH_KEY = 2;
        const WEST_KEY = 3;
        const NUMBER_FORMAT = "n16";

        this._filtersCurrentData.requestId += 1;

        // TODO: instead of kendo.toString() consider alternative approach with unchanged precision:
        // mapData.bounds[...].toString().replace(".", kendo.getCulture().numberFormat["."])

        // TODO: here should be specified the type of object
        const rowData = {
            deliveryChannelId: this._filtersCurrentData.deliveryChannelId,
            deliveryMethodKey: this._filtersCurrentData.deliveryMethodKey,
            east: kendo.toString(mapData.bounds[EAST_KEY], NUMBER_FORMAT),
            north: kendo.toString(mapData.bounds[NORTH_KEY], NUMBER_FORMAT),
            requestId: this._filtersCurrentData.requestId,
            south: kendo.toString(mapData.bounds[SOUTH_KEY], NUMBER_FORMAT),
            west: kendo.toString(mapData.bounds[WEST_KEY], NUMBER_FORMAT),
            zoom,
        };

        const data = new FormData();
        for (const key of Object.keys(rowData)) {
            data.append(key, (rowData as any)[key]);
        }

        return this._fetchService.post<IPickupPointMapFetch>(this._getPickupPointsByBounds, data);
    }
    /* eslint-enable @typescript-eslint/promise-function-async */

    private _setPreselectedCity(): void {
        if (this._model.allAddresses) {
            this._model.allAddresses.forEach((address) => {
                // preselected city by user data
                if (address.Id === this._model.SelectedHomeDeliveryAddress) {
                    this._filtersCurrentData.searchTerm = address.City;
                }
            });
        }
    }

    public initMap(): void {
        if (this._isMapInited) {
            return;
        }

        if (!ori.maps) {
            const errorMessage = "Couldn't find IOriMap instance.";
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        if (!this._model.mapModule) {
            const errorMessage = "MapModule does not exist in current state.";
            this._logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        const mapWrapper = this.findElement(this.MAP_WRAPPER);
        if (mapWrapper && !ori.maps.isMapCreated()) {
            Spinner.applyOverlayTo(mapWrapper);
        }
        const startingAddress = this._getDefaultDeliveryAddress();
        const mapModule = this._model.mapModule;

        // TODO: do not call jQuery object constructors in TypeScript; here only because of ori.maps legacy code demands
        const $mapPlaceholder = $(this.MAP);
        const $mapWrapper = $(this.MAP_WRAPPER);

        ori.maps.init({
            $mapPlaceholder,
            $mapWrapper,
            account: mapModule.Account,
            defaultLocationPushpin: mapModule.DefaultLocationPushpinIcon,
            detailInfoboxRelativePosition: mapModule.DetailInfoboxRelativePosition,
            fallbackStartingLocation: mapModule.FallbackStartingLocation,
            height: this.MAP_HEIGHT,
            loadErrorCallback: this._mapLoadFailed,
            minZoom: mapModule.MinZoom,
            nameInfoboxRelativePosition: mapModule.NameInfoboxRelativePosition,
            outOfViewClass: "audible",
            showDefaultLocationPushpin: mapModule.ShowDefaultLocationPushpinIcon,
            startingAddress,
            userLocationPushpin: mapModule.UserLocationPushpinIcon,
            viewChangeEndEventHandler: this._handleMapChange,
            width: "100%",
            zoom: mapModule.InitialZoom,
            zoomAfterSelection: mapModule.ZoomAfterSelection,
        });

        ori.maps.on(ori.maps.INFO_CLOSED, () => {
            this._hideAndRemoveResults();
            this._channelState = null;
        });

        ori.maps.one(ori.maps.MAP_CREATED_EVENT, () => {
            this._handleMapChange();
            if (mapWrapper) {
                Spinner.removeOverlayFrom(mapWrapper);
            }
        });

        this._isMapInited = true;
    }

    @autobind
    private _mapLoadFailed(): void {
        this._logger.warning("Map failed to load. Turn to fallback option.");
        Utils.addClass(this.MAP_WRAPPER, this.CLASS_HIDDEN);
        // Destroy all filters. They will be recreated and handled within the fallback option
        this._destroyAllFilters();

        // Trigger custom event to inform the PickupPointDeliveryChannel to fallback to the option without map
        this._binder.trigger(DeliveryChannelsEventType.MAP_LOAD_FAILER);
    }

    @autobind
    private _handleMapChange(): void {
        this._loadAndShowPickupPointsYandex();
    }

    private _loadAndShowPickupPointsYandex(): void {
        if (!ori.maps) {
            return;
        }
        const mapData = ori.maps.getBounds();
        const zoom = ori.maps.getZoomForServer();

        const NORTH_KEY = 0;
        const EAST_KEY = 1;
        const SOUTH_KEY = 2;
        const WEST_KEY = 3;
        const NUMBER_FORMAT = "n16";

        // TODO: here should be specified the type of object
        const bounds = [
            kendo.toString(mapData.bounds[SOUTH_KEY], NUMBER_FORMAT),
            kendo.toString(mapData.bounds[WEST_KEY], NUMBER_FORMAT),
            kendo.toString(mapData.bounds[NORTH_KEY], NUMBER_FORMAT),
            kendo.toString(mapData.bounds[EAST_KEY], NUMBER_FORMAT),
        ];

        ori.maps.getPushpins(bounds, zoom);
    }

    public loadAndShowPickupPoints(selectPickupPoint?: boolean): void {
        this._loadMapPickupPoints().then((data: IPickupPointMapFetch) => {
            if (this._filtersCurrentData.requestId !== data.RequestId) {
                this._logger.warning("Mismatch in the request IDs.");
                return;
            }

            if (!this._filters.search.widget) {
                this._logger.warning("Search filter widget not found.");
                return;
            }

            const searchElement = this._filters.search.widget.element[0];

            Utils.setData(
                searchElement,
                this.PICKUP_POINT_SEARCH_FILTER_VALIDATION_MESSAGE,
                data.ErrorMessage
            );
            const validator = kendo.getValidator(searchElement);

            if (validator) {
                validator.validate();
            }

            if (data.PickupPoints.length > 0 && this._filtersCurrentData.searchTerm !== "") {
                this._addMapPins(data.PickupPoints);

                const deliveryChannelId = data.PickupPoints[0].DeliveryChannelId;
                if (selectPickupPoint && data.PickupPoints.length === 1 && deliveryChannelId) {
                    this._displayChannel(deliveryChannelId);
                }
            }
        }, this._displayError);
    }

    private _getDefaultDeliveryAddress(): IAddressSearch | undefined {
        return this._model.startingAddress || undefined;
    }

    private _addMapPins(pins: IPickupPointPins[]): void {
        if (!ori.maps) {
            return;
        }

        if (pins.length === 0) {
            ori.maps.clearMap();
            this._logger.warning("No pickup points found.");
            return;
        }

        const PARSE_INT_RADIX = 10;
        const data = pins.map((point: IPickupPointPins) => {
            let pinImageSrc = "";
            let pushpinAnchorX = 0;
            let pushpinAnchorY = 0;
            let latitude = 0;
            let longitude = 0;

            let isClusterred = false;
            let text: string | null = null;
            if (typeof point.PointsCount === "number") {
                text = `${point.PointsCount}`;
                isClusterred = true;
            }

            if (!isClusterred && this._model.DeliveryMethods) {
                const result = this._model.DeliveryMethods.filter(
                    (item) =>
                        parseInt(item.DeliveryMethodId, PARSE_INT_RADIX) === point.DeliveryMethodId
                );

                if (result.length > 0) {
                    pinImageSrc = result[0].PinImageSrc;
                    pushpinAnchorX = result[0].PushpinAnchorX;
                    pushpinAnchorY = result[0].PushpinAnchorY;
                }
            }

            if (point.AddressCoordinates) {
                latitude = point.AddressCoordinates.Latitude;
                longitude = point.AddressCoordinates.Longitude;
            }

            return {
                image: {
                    anchorX: pushpinAnchorX,
                    anchorY: pushpinAnchorY,
                    src: pinImageSrc,
                },
                latitude,
                longitude,
                metadata: {
                    id: point.DeliveryChannelId,
                    isClusterred,
                },
                text,
                title: point.Description,
            };
        });

        ori.maps.addPushpins(data, (channelId: number) => this._displayChannel(channelId));
    }

    private _displayChannel(deliveryChannelKey: number): void {
        const props = { deliveryChannelKey, requestId: this._filtersCurrentData.requestId };
        this._fetchService
            .get(this._getPickupPointDetails, props)
            .then((data: IPickupPointDetail) => {
                if (
                    this._channelState &&
                    !this._shouldComponentUpdate(this._channelState, [data.DeliveryChannel])
                ) {
                    this._logger.warning("Click on the same channel.");
                    return;
                }
                this._channelState = [data.DeliveryChannel];
                this._swapShownChannels();
            }, this._displayError);
    }

    public update(): void {
        if (!this._channelState) {
            return;
        }

        const props = {
            deliveryChannelKey: this._channelState[0].DeliveryChannelId,
            requestId: this._filtersCurrentData.requestId,
        };

        this._fetchService
            .get(this._getPickupPointDetails, props)
            .then(this._handlePickupPointDetailsFetch, this._displayError);
    }

    @autobind
    private _handlePickupPointDetailsFetch(data: IPickupPointDetail): void {
        if (!this._channelState) {
            return;
        }

        const pickupPoint = this._channelState[0];
        this._updateFromDeliveryModel(pickupPoint, data.DeliveryChannel, true);

        const isOutOfStockChanged = this._wasOutOfStockItemsChanged(
            pickupPoint.OutOfStockItems,
            data.DeliveryChannel.OutOfStockItems
        );
        const isDeliveryDateChanged = this._isChanged(
            pickupPoint.DeliveryDate,
            data.DeliveryChannel.DeliveryDate
        );
        const isEndCustomerAddressChanged =
            this._isEndCustomerFirstCheckout() &&
            this._isChanged(pickupPoint.Address, data.DeliveryChannel.Address);

        if (isOutOfStockChanged || isDeliveryDateChanged || isEndCustomerAddressChanged) {
            this._updateAddressInfo(data.DeliveryChannel);
        }
    }

    public loadAndSelectPickupPoints(id: number): void {
        if (!id) {
            return;
        }

        const props = {
            deliveryChannelKey: id,
            requestId: this._filtersCurrentData.requestId,
        };

        this._fetchService
            .get(this._getPickupPointDetails, props)
            .then(this._handlePickupPointDetailsFetch, this._displayError);

        this._fetchService
            .get(this._getPickupPointDetails, props)
            .then((data: IPickupPointDetail) => {
                if (
                    this._channelState &&
                    !this._shouldComponentUpdate(this._channelState, [data.DeliveryChannel])
                ) {
                    this._logger.warning("Click on the same channel.");
                    return;
                }
                this._channelState = [data.DeliveryChannel];
                this._swapShownChannels();
            }, this._displayError);
    }
}
