import { Inject } from "typescript-ioc";
import { autobind } from "core-decorators";

import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";

export class AddressCountrySelect extends UiComponent {
    private readonly COUNTRY_RADIO_INPUTS_SELECTOR: string = "input.js-country";
    private readonly COUNTRY_RADIO_HIDDEN_SELECTOR: string = "input.js-country-hidden";
    public onChange: () => void;
    private _countryRadioInputs: HTMLInputElement[];
    private _countryHiddenInput: HTMLInputElement;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    public init(): void {
        this._countryRadioInputs = this.find(
            this.COUNTRY_RADIO_INPUTS_SELECTOR
        ) as HTMLInputElement[];
        this._countryHiddenInput = this.findElement(
            this.COUNTRY_RADIO_HIDDEN_SELECTOR
        ) as HTMLInputElement;
        this._countryRadioInputs.forEach((element) => {
            element.onchange = () => {
                this._countryHiddenInput.value = this.getValue();
                if (this.onChange) {
                    this.onChange();
                }
            };
        });
    }

    @autobind
    public getValue(): string {
        const selectedCountry = this._countryRadioInputs.find((e) => e.checked);
        if (selectedCountry) return selectedCountry.value;
        throw new Error("There is no selected country");
    }
}
