import { autobind } from "core-decorators";
import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { EventBinder } from "Events/Scripts/EventBinder";
import { IDialogOptions } from "./IDialogOptions";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Utils } from "Helpers/Scripts/Utils";

@OnlyInstantiableByContainer
@Singleton
export class DialogUtils {
    private readonly CANCEL_ACTION: string = "cancel";
    private readonly CHANGES_LOST_MESSAGE: string = "All unsaved changes will be lost.";
    private readonly CLOSE_ACTION: string = "close";
    private readonly CONFIRM_DIALOG_ID: string = "confirmDialog";
    private readonly CONTINUE_TITLE: string = "Continue?";
    private readonly DELETE_ITEM_MESSAGE: string = "Are you sure to delete the selected item?";
    private readonly DIALOG_ELEMENT_TYPE: string = "div";
    private readonly ERROR_DIALOG_ID: string = "errorDialog";
    private readonly ERROR_TITLE: string = "Error";
    private readonly ID_ATTRIBUTE: string = "id";
    private readonly NORMAL_BUTTON_LAYOUT: string = "normal";
    private readonly OK_ACTION: string = "OK";

    private readonly _eventBinder: EventBinder;
    private readonly _logger: ILogger;

    constructor(@Inject eventBinder: EventBinder, @Inject loggerFactory: LoggerFactory) {
        this._eventBinder = eventBinder;
        this._eventBinder.init(document.documentElement);
        this._logger = loggerFactory.getLogger(this.key);
        this._logger.info("BackOffice dialog utils instance created.");
    }

    public get key(): string {
        return "DialogUtils";
    }

    /**
     * Dialog for deletion confirmation.
     */
    @autobind
    public confirmDeleteDialog(options: IDialogOptions): kendo.ui.Dialog {
        const opt: IDialogOptions = Utils.extend({}, options);
        if (!opt.message) {
            opt.message = this.DELETE_ITEM_MESSAGE;
        }

        return this.confirmDialog(opt);
    }

    /**
     * General Dialog for confirmation.
     */
    @autobind
    public confirmDialog(options: IDialogOptions): kendo.ui.Dialog {
        const element: Element = this._prepareDialogElement(this.CONFIRM_DIALOG_ID);
        let actionOk: Function = () => false;
        let actionCancel: Function = () => true;
        if (typeof options.onOkCallback === "function") {
            actionOk = options.onOkCallback;
        }
        if (typeof options.onCancelCallback === "function") {
            actionCancel = options.onCancelCallback;
        }
        const title: string = options.title || this.CONTINUE_TITLE;
        const okButtonText: string = options.okButtonText || this.OK_ACTION;
        const cancelButtonText: string = options.cancelButtonText || this.CANCEL_ACTION;
        const buttonLayout: string = options.buttonLayout || this.NORMAL_BUTTON_LAYOUT;

        return kendo.createDialog(element, {
            actions: [
                {
                    action: actionOk,
                    primary: true,
                    text: okButtonText,
                },
                {
                    action: actionCancel,
                    text: cancelButtonText,
                },
            ],
            buttonLayout,
            closable: false,
            content: options.message,
            maxWidth: 400,
            title,
            visible: true,
        });
    }

    /**
     * Dialog for unsaved changes disposal confirmation.
     */
    @autobind
    public confirmUnsavedChangesDialog(options: IDialogOptions): kendo.ui.Dialog {
        const opt: IDialogOptions = Utils.extend({}, options);
        if (!opt.message) {
            opt.message = this.CHANGES_LOST_MESSAGE;
        }

        return this.confirmDialog(opt);
    }

    /**
     * Display an error dialog with specified message and title.
     */
    @autobind
    public errorDialog(
        content: string,
        dialogTitle?: string,
        closeButtonText?: string,
        onClose?: Function
    ): kendo.ui.Dialog {
        let title = this.ERROR_TITLE;
        if (dialogTitle) {
            title = dialogTitle;
        }

        const closeAction: Function = typeof onClose === "function" ? onClose : () => true;
        const element: Element = this._prepareDialogElement(this.ERROR_DIALOG_ID);

        return kendo.createDialog(element, {
            actions: [
                {
                    action: closeAction,
                    text: closeButtonText || this.CLOSE_ACTION,
                },
            ],
            buttonLayout: this.NORMAL_BUTTON_LAYOUT,
            closable: false,
            content,
            maxWidth: 400,
            title,
            visible: true,
        });
    }

    /**
     * Gets a kendo window by id.
     * TODO: Maybe redundant & direct calls to kendo.getWindow() could be enough?
     */
    @autobind
    public getWindowById(id: string): kendo.ui.Window {
        const window: kendo.ui.Window | undefined = kendo.getWindow(`#${id}`);
        if (!window) {
            throw new Error(`Kendo window object wasn't found for element with id "${id}".`);
        }

        return window;
    }

    private _prepareDialogElement(dialogId: string): Element {
        let helperElement: Element | null = document.getElementById(dialogId);
        if (!helperElement) {
            helperElement = document.createElement(this.DIALOG_ELEMENT_TYPE);
            helperElement.setAttribute(this.ID_ATTRIBUTE, dialogId);
            document.body.appendChild(helperElement);
            this._logger.info(`Appended dialog helper element with ID ${dialogId} to DOM.`);
        }

        return helperElement;
    }
}
