export class DocumentItemRemovedEvent {
    public DocumentTypeId: number;

    public ContainsNewItems: boolean;

    constructor(documentTypeId: number, containsNewItems: boolean) {
        this.DocumentTypeId = documentTypeId;
        this.ContainsNewItems = containsNewItems;
    }
}
