import { Inject } from "typescript-ioc";
import { Input } from "./Input";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";

export class FocusableInput extends Input {
    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    /**
     * Binds focus event handler. Multiple calls will bind multiple event handlers
     * which will be executed in the order they were added.
     * @param handler Event handler method reference.
     * @param namespace Optional event namespace used when binding this particular handler via jQuery.
     * @param one When true, the handler will be disconnected after first execution.
     */
    public bindFocus(
        handler: (event?: JQuery.Event) => any,
        namespace?: string,
        one?: boolean
    ): this {
        this._binder.bindFocus(handler, namespace, one);
        return this;
    }

    /**
     * Unbinds focus event handler(s). When the handler reference is provided, this will
     * disconnect only the particular handler. When the namespace filter is provided,
     * only handlers previously connected with this namespace will be disconnected.
     * @param namespace Event namespace filter.
     * @param handler Event handler method reference.
     */
    public unbindFocus(namespace?: string, handler?: (event?: JQuery.Event) => any): this {
        this._binder.unbindFocus(namespace, handler);
        return this;
    }

    /* (un)bindKeyDown(), once needed, belongs here as well */
}
