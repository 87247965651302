/* VIP ACCESS CONSULTANT MODULE */

import { autobind } from "core-decorators";
import { Inject } from "typescript-ioc";
import { dispatch as dispatchUserAction } from "@ori-events/user-action";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";

// Utils
import { Utils } from "Helpers/Scripts/Utils";
import { FetchService } from "Async/Scripts/FetchService";
import { Spinner } from "Spinner/Scripts/Spinner";

// Modules
import { IVipAccessConsultantViewModel } from "./IVipAccessConsultantViewModel";

// Models
import { IVipAccessConsultant } from "./IVipAccessConsultant";
import { IVipAccessImpersonation } from "./IVipAccessImpersonation";
import { IDialogWarning } from "./IDialogWarning";
import { IRedirectButtonResponse } from "./IRedirectButtonResponse";

export class VipAccessConsultantView extends UiComponent {
    private _vipAccessConsultantViewModel: IVipAccessConsultantViewModel;
    private _vipAccessConsultant: IVipAccessConsultant;
    private _buttonCalendarCampaign: kendo.ui.Button;
    private _buttonGoToConsultant: kendo.ui.Button;
    private _buttonNewOrder: kendo.ui.Button;
    private _warningDialog: kendo.ui.Dialog;
    private _availableCreditTooltip: kendo.ui.Tooltip | undefined;
    private _dialogWarningContent: IDialogWarning;
    private _section: Element;

    private readonly _fetchService: FetchService;

    private readonly MODEL: string = "model";
    private readonly WIDTH_WARNING_DIALOG: number = 393;
    private readonly WIDTH_TOOLTIP: number = 330;
    private readonly SECTION_CONSULTANT_INFORMATION: string = ".js-consultant-info";
    private readonly BUTTON_GO_TO_CONSULTANT: string = ".js-button-go-to-consultant";
    private readonly BUTTON_NEW_ORDER: string = ".js-button-new-order";
    private readonly BUTTON_CALENDAR_CAMPAIGN: string = ".js-button-calendar-campaign";
    private readonly DIALOG_WARNING: string = ".js-dialog-warning";
    private readonly CONSULTANT_INFO_ICON: string = ".js-consultant-info-icon";
    private readonly CLASS_DIALOG_WARNING_WRAPPER: string = "dialog-warning-wrapper";
    private readonly CLASS_TOOLTIP_AVAILABLE_CREDIT_WRAPPER: string =
        "tooltip-available-credit-wrapper";
    private readonly DATA_DIALOG_WARNING_CONTENT: string = "dialog-warning-content";
    private readonly CONSULTANT_INFORMATION_TEMPLATE: string = "consultant-information-template";
    private readonly CLOSE_BUTTON: string = ".k-i-close";
    private readonly DOWNLINE_ORDERS: string = "Downline orders";
    private readonly ACCESS_MEMBER: string = "Access member";
    private readonly GO_TO_MEMBER: string = "Go to member";
    private readonly CREATE_NEW_ORDER: string = "Create new order";
    private readonly SPO: string = "SPO";
    private readonly GUARANTEE: string = "Guarantee";

    public get key(): string {
        return "VipAccessConsultantView";
    }

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject fetchService: FetchService
    ) {
        super(componentFactory, binder, loggerFactory);

        this._fetchService = fetchService;
    }

    public init(): void {
        const section = this.findElement(this.SECTION_CONSULTANT_INFORMATION);
        if (!section) {
            throw new Error(`Cannot find the element: "${this.SECTION_CONSULTANT_INFORMATION}"`);
        }
        this._section = section;
        this._vipAccessConsultantViewModel = this.model as IVipAccessConsultantViewModel;
        this._dialogWarningContent = Utils.getData(
            this.DIALOG_WARNING,
            this.DATA_DIALOG_WARNING_CONTENT
        ) as IDialogWarning;
        this._createComponents();
    }

    public show(consultantNumber: number, dispatchOriEventsUserAction = false): void {
        this._getConsultantInfo(consultantNumber, dispatchOriEventsUserAction);
    }

    public hide(): void {
        this._context.classList.add(this.CLASS_HIDDEN);
    }

    @autobind
    private _goToConsultant(): void {
        this._loginForAnotherConsultant(this._vipAccessConsultantViewModel.GoToConsultantUrl);
    }

    @autobind
    private _redirectToCalendarCampaign(): void {
        if (this._isOrderingBlocked()) {
            return;
        }

        const composedCalendarCampaignUrl = `${this._vipAccessConsultantViewModel.CalendarCampaignUrl}?customerId=${this._vipAccessConsultant.CustomerId}`;
        window.location.href = composedCalendarCampaignUrl;
    }

    @autobind
    private _createNewOrder(): void {
        if (this._vipAccessConsultant.IsConsultantBlockedForOrdering) {
            // show popup
            this._logger.info("Consultant is blocked for ordering!");
            this._warningDialog.content(this._vipAccessConsultant.ErrorMessage);
            this._warningDialog.open();
            return;
        }
        this._loginForAnotherConsultant(this._vipAccessConsultantViewModel.NewOrderUrl);
    }

    private _isOrderingBlocked(): boolean {
        if (this._vipAccessConsultant.HasBlockedGuaranteeOrdering) {
            // show popup
            this._logger.info("Consultant has blocked guarantee ordering!");
            this._warningDialog.content(this._vipAccessConsultant.ErrorMessage);
            this._warningDialog.open();
            return true;
        }

        if (this._vipAccessConsultant.IsConsultantBlockedForOrdering) {
            // show popup
            this._logger.info("Consultant is blocked for ordering!");
            this._warningDialog.content(this._vipAccessConsultant.ErrorMessage);
            this._warningDialog.open();
            return true;
        }

        return false;
    }

    private _loginForAnotherConsultant(url: string): void {
        if (this._vipAccessConsultant.HasBlockedGuaranteeOrdering) {
            // show popup
            this._logger.info("Consultant has blocked guarantee ordering!");
            this._warningDialog.content(this._vipAccessConsultant.ErrorMessage);
            this._warningDialog.open();
            return;
        }

        Spinner.runWith<IRedirectButtonResponse>(
            (async () => {
                const formData = new FormData();
                // won't send unnecessary data
                const payload: IVipAccessImpersonation = {
                    ConsultantNumber: this._vipAccessConsultant.ConsultantNumber,
                };
                this._fetchService.appendValue(formData, this.MODEL, payload);
                const promise = this._fetchService.post<IRedirectButtonResponse>(url, formData);
                promise.then(
                    (response) => {
                        window.location.href = response.RedirectUrl;
                    },
                    (error) => {
                        this._logger.info(error);
                    }
                );

                return promise;
            })()
        );
    }

    private _getConsultantInfo(
        consultantNumber: number,
        dispatchOriEventsUserAction = false
    ): void {
        Spinner.runWith<IVipAccessConsultant>(
            (async () => {
                const params = {
                    consultantNumber,
                };
                const promise = this._fetchService.get<IVipAccessConsultant>(
                    this._vipAccessConsultantViewModel.AccessConsultantUrl,
                    params
                );
                promise.then(
                    (response) => {
                        this._vipAccessConsultant = response;

                        if (this._vipAccessConsultant.IsAccessGranted) {
                            if (dispatchOriEventsUserAction) {
                                dispatchUserAction([
                                    this.DOWNLINE_ORDERS,
                                    this.ACCESS_MEMBER,
                                    this._vipAccessConsultant.IsSpoOwner
                                        ? this.SPO
                                        : this.GUARANTEE,
                                    this._vipAccessConsultant.CustomerTypeId.toString(),
                                ]);
                            }
                            this._showSection();
                        } else {
                            // show popup
                            this._logger.info("Access to the consultant is denied!");
                            this._clearSection();
                            this._warningDialog.content(this._vipAccessConsultant.ErrorMessage);
                            this._warningDialog.open();
                        }
                    },
                    (error) => {
                        this._logger.error("Api call failure - %s", error);
                    }
                );

                return promise;
            })()
        );
    }

    private _clearSection(): void {
        this._section.innerHTML = "";
        this._context.classList.add(this.CLASS_HIDDEN);
    }

    private _showSection(): void {
        this._clearSection();
        const consultantInformationTemplate = Utils.getTemplate(
            this.CONSULTANT_INFORMATION_TEMPLATE
        );
        const consultantInformation = consultantInformationTemplate(this._vipAccessConsultant);
        Utils.append(this._section, consultantInformation);
        this._context.classList.remove(this.CLASS_HIDDEN);
        this._createAvailableCreditTooltip();
        const isNewOrderBlocked =
            this._vipAccessConsultant.IsConsultantBlockedForOrdering ||
            this._vipAccessConsultant.HasBlockedGuaranteeOrdering;

        this._buttonNewOrder.element.toggleClass("k-state-disabled", isNewOrderBlocked);

        if (this._buttonGoToConsultant) {
            this._buttonGoToConsultant.element.toggleClass(
                "k-state-disabled",
                this._vipAccessConsultant.HasBlockedGuaranteeOrdering
            );
        }
    }

    private _createAvailableCreditTooltip(): void {
        const consultantInfoIcon = this.findElement(this.CONSULTANT_INFO_ICON);
        if (!consultantInfoIcon) {
            return;
        }

        const toolTipOptions = {
            content: this._vipAccessConsultantViewModel.AvailableCreditTooltipText,
            offset: 15,
            position: "bottom",
            showAfter: 100,
            width: this.WIDTH_TOOLTIP,
        } as kendo.ui.TooltipOptions;

        this._availableCreditTooltip = kendo.createTooltip(
            this.CONSULTANT_INFO_ICON,
            toolTipOptions
        );
        this._availableCreditTooltip.show(this._availableCreditTooltip.element);
        this._availableCreditTooltip.hide();
        if (this._availableCreditTooltip.popup && this._availableCreditTooltip.popup.element) {
            this._availableCreditTooltip.popup.element.addClass(
                this.CLASS_TOOLTIP_AVAILABLE_CREDIT_WRAPPER
            );
        }
    }

    private _createComponents(): void {
        if (
            !this._vipAccessConsultantViewModel.IsGoToConsultantButtonHidden &&
            !this._buttonGoToConsultant
        ) {
            this._buttonGoToConsultant = kendo.createButton(this.BUTTON_GO_TO_CONSULTANT);
            this._buttonGoToConsultant.bindClick(() => {
                dispatchUserAction([
                    this.DOWNLINE_ORDERS,
                    this.GO_TO_MEMBER,
                    this._vipAccessConsultant.IsSpoOwner ? this.SPO : this.GUARANTEE,
                ]);
                this._goToConsultant();
            });
        } else {
            Utils.addClass(this.BUTTON_GO_TO_CONSULTANT, this.CLASS_HIDDEN);
        }
        if (!this._buttonNewOrder) {
            this._buttonNewOrder = kendo.createButton(this.BUTTON_NEW_ORDER);
            this._buttonNewOrder.bindClick(() => {
                if (
                    !this._vipAccessConsultant.IsConsultantBlockedForOrdering &&
                    !this._vipAccessConsultant.HasBlockedGuaranteeOrdering
                ) {
                    dispatchUserAction([
                        this.DOWNLINE_ORDERS,
                        this.CREATE_NEW_ORDER,
                        this._vipAccessConsultant.IsSpoOwner ? this.SPO : this.GUARANTEE,
                    ]);
                }
                this._createNewOrder();
            });
        }
        if (
            this._vipAccessConsultantViewModel.IsCalendarCampaignEnabled &&
            !this._buttonCalendarCampaign
        ) {
            this._buttonCalendarCampaign = kendo.createButton(this.BUTTON_CALENDAR_CAMPAIGN);
            this._buttonCalendarCampaign.bindClick(this._redirectToCalendarCampaign);
        } else {
            Utils.addClass(this.BUTTON_CALENDAR_CAMPAIGN, this.CLASS_HIDDEN);
        }
        if (!this._warningDialog) {
            this._warningDialog = kendo.createDialog(this.DIALOG_WARNING, {
                actions: [
                    {
                        action: this._closeWarningDialog,
                        primary: true,
                        text: this._dialogWarningContent.ButtonClose,
                    },
                ],
                content: this._dialogWarningContent.Message,
                title: this._dialogWarningContent.Title,
                visible: false,
                width: this.WIDTH_WARNING_DIALOG,
            });
            this._warningDialog.wrapper.addClass(this.CLASS_DIALOG_WARNING_WRAPPER);
            this._warningDialog.wrapper
                .find(this.CLOSE_BUTTON)
                .bind("click", this._closeWarningDialog);
        }
    }

    @autobind
    private _closeWarningDialog(): void {
        this._warningDialog.close();
    }
}
