import { Inject } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { OneTrustGroup } from "Integrations/OneTrust/Scripts/OneTrustGroup";

export class OneTrust {
    private readonly _logger: ILogger;

    public get key(): string {
        return "OneTrust";
    }

    constructor(@Inject loggerFactory: LoggerFactory) {
        this._logger = loggerFactory.getLogger(this.key);
    }

    public isCookieAllowed(name: string): boolean {
        if (!this.isOneTrustEnabled) {
            return true;
        }

        const groupId = this.getGroupForCookie(name);

        if (groupId === "") {
            this._logger.warning(`This cookie is not registered in the OneTrust domain ${name}.`);
            return false;
        }

        return this.groupHasConsent(groupId);
    }

    public isGroupAllowed(group: OneTrustGroup): boolean {
        if (!this.isOneTrustEnabled) {
            return true;
        }

        return this.groupHasConsent(group);
    }

    public removeCookiesWithoutConsent(): void {
        const activeGroups = this.groupsFromOneTrust.filter(
            (group) => group.ShowInPopup && !this.groupHasConsent(this.groupIdForConsent(group))
        );
        activeGroups.forEach((group) =>
            group.Cookies.forEach((cookie: IOneTrustCookie) => this.eraseCookie(cookie.Name))
        );
    }

    private get isOneTrustEnabled(): boolean {
        return Boolean(window.OneTrust);
    }

    private get activeGroups(): string[] {
        if (this.isOneTrustEnabled && window.OptanonActiveGroups) {
            return window.OptanonActiveGroups.split(",").filter(Boolean);
        }

        return [];
    }

    private get groupsFromOneTrust(): IOneTrustGroup[] {
        return window.OneTrust ? window.OneTrust.GetDomainData().Groups : [];
    }

    private getGroupForCookie(name: string): string {
        const group = this.groupsFromOneTrust.find((oneTrustGroup) =>
            this.groupHasCookie(oneTrustGroup, name)
        );
        return this.groupIdForConsent(group);
    }

    private groupIdForConsent(group: IOneTrustGroup | undefined): string {
        if (group !== undefined) {
            return group.Parent ? group.Parent.OptanonGroupId : group.OptanonGroupId;
        }

        return "";
    }

    private groupHasConsent(groupId: string): boolean {
        return this.activeGroups.includes(groupId);
    }

    private groupHasCookie(group: IOneTrustGroup, name: string): boolean {
        if (group.Cookies) {
            return group.Cookies.some((cookie) => cookie.Name === name);
        }

        return false;
    }

    /*
        Try to remove cookie with all possible parameters
        Browser api allowing js identify cookie by name only, no way how to detect cookie domain, or path
        this solution will generate multiple remove queries and hopefully one of them will be true and cookie is removed.
     */
    private eraseCookie(name: string): void {
        const sameSiteValues = ["Lax", "None", "Strict", ""];
        const pathValues = ["", "/"];
        let domain = location.hostname.split(".");

        while (domain.length > 1) {
            for (const sameSite of sameSiteValues) {
                for (const path of pathValues) {
                    const expires = "Thu, 01 Jan 1970 00:00:00 UTC";

                    let cookieStr = `
                        ${name}=";expires=${expires};domain=${domain.join(".")}`;

                    if (path) {
                        cookieStr += ";path=" + path;
                    }
                    if (location.protocol === "https:") {
                        cookieStr += ";secure";
                    }
                    if (sameSite) {
                        cookieStr += ";sameSite=" + sameSite;
                    }

                    // Set the cookie string to the document cookie
                    document.cookie = cookieStr;
                }
            }
            domain = domain.slice(1); // remove left-most subdomain
        }
    }
}
