import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { OrderStatusDictionary } from "ECommerce/Orders/OrderListing/Scripts/OrderStatusDictionary";
import { OrderStatusInfo } from "ECommerce/Orders/OrderListing/Scripts/OrderStatusInfo";

@OnlyInstantiableByContainer
@Singleton
export class StatusUpdateHelper {
    public get key(): string {
        return "StatusUpdateHelper";
    }

    protected readonly _logger: ILogger;
    private _orderStatusDictionary: OrderStatusDictionary;

    constructor(@Inject loggerFactory: LoggerFactory) {
        this._logger = loggerFactory.getLogger(this.key);
        this._orderStatusDictionary = new OrderStatusDictionary();
    }

    public getUpdatedModel<T extends OrderStatusInfo>(list: T[]): T[] {
        list.forEach((item: T) => {
            const orderStatusInfo = this._orderStatusDictionary[item.CurrentStatus];

            if (orderStatusInfo) {
                item.OrderStatusClass = orderStatusInfo.OrderStatusClass;
                item.OrderStatusIcon = orderStatusInfo.OrderStatusIcon;
            }
        });

        return list;
    }
}
