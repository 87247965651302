// update the channel and address if provided
export const CHANNEL_UPDATE = "channelupdate";

// update the pickup point channel
// used to comunication changes between PickupPointDeliveryChannel and PickupPoint classes
export const PICKUP_POINT_CHANNEL_UPDATE = "pickuppointchannelupdate";

// Map failed to load event
export const MAP_LOAD_FAILER = "maploadfailer";

// End consumer address was changed event
// Propagates change of address from EndConsumerSelectorComponent to its wrapper
export const END_CONSUMER_ADDRESS_CHANGE = "endconsumeraddresschange";

// End consumer selector component refresh event
// Propagates change of address from EndConsumerSelectorComponent to its wrapper
export const END_CONSUMER_REFRESH = "endconsumerrefresh";

// End consumer selector component change event
// Propagates change of address from EndConsumerSelectorComponent to its wrapper
export const END_CONSUMER_COMPONENT_CHANGE = "endconsumercomponentchange";
