import { autobind } from "core-decorators";
import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { EventBinder } from "Events/Scripts/EventBinder";
import { IDataSourceCancelChangesErrorEvent } from "./IDataSourceCancelChangesErrorEvent";
import { IDistilledGridErrors } from "./IDistilledGridErrors";
import { IErrorMessages } from "./IErrorMessages";
import { IGridDataItem } from "./IGridDataItem";
import { IGridErrors } from "./IGridErrors";
import { IGridRowErrors } from "./IGridRowErrors";
import { IGridState } from "./IGridState";
import { ILogger } from "Logging/Scripts/ILogger";
import { IStorageService } from "Storages/Scripts/IStorageService";
import { LocalStorageService } from "Storages/Scripts/LocalStorageService";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Utils } from "Helpers/Scripts/Utils";
import { DialogUtils } from "Shared/DialogUtils/Scripts/DialogUtils";
import { IList } from "Helpers/Scripts/IList";

@OnlyInstantiableByContainer
@Singleton
export class GridUtils {
    private readonly ATTR_TITLE: string = "title";
    private readonly CLASS_HIDDEN: string = "hidden";
    private readonly CLASS_SELECTED: string = "k-state-selected";
    private readonly CLASS_WIDGET: string = "k-widget";
    private readonly COLOR_RED: string = "grid-color-red";
    private readonly DATA_GRID_ID: string = "gridId";
    private readonly DELETE_STATE_BUTTON: string = ".js-button-delete-grid-state";
    private readonly EQUAL_OPERATOR: string = "eq";
    private readonly FIELD_ID: string = "Id";
    private readonly FIELD_IS_DELETED: string = "IsDeleted";
    private readonly FIELD_IS_NEW: string = "IsNew";
    private readonly FIELD_IS_NEW_RECORD: string = "IsNewRecord";
    private readonly GRID_DATA_ERRORLIST: string = "errorList";
    private readonly GRID_DATA_ERRORS: string = "errors";
    private readonly GRID_DATAOP_CREATE: string = "create";
    private readonly GRID_DATAOP_DELETE: string = "delete";
    private readonly GRID_DATAOP_UPDATE: string = "update";
    private readonly GRID_VALIDATION_MODE_ERR: string = "err";
    private readonly GRID_VALIDATION_MODE_ID: string = "id";
    private readonly GRID_VALIDATION_MODE_OP: string = "op";
    private readonly GRID_VALIDATION_MODE_SUM: string = "sum";
    private readonly LINE_BREAK: string = "<br />";
    private readonly SEL_FILTER_DROPDOWN: string = ".k-filter-menu .k-dropdown";
    private readonly SEL_FILTER_MENU: string = ".k-filter-menu";
    private readonly SEL_FILTER_POPUP: string = ".k-animation-container";
    private readonly SEL_GRID: string = ".k-grid";
    private readonly SEL_SAVE_STATE_BUTTON: string = ".js-button-save-grid-state";
    private readonly STORAGE_KEY_PREFIX: string = "backoffice_";
    private readonly VALIDATION_ISSUE: string = "Validation issue";

    private readonly _dialogUtils: DialogUtils;
    private readonly _eventBinder: EventBinder;
    private readonly _logger: ILogger;
    private readonly _storage: IStorageService;

    constructor(
        @Inject dialogUtils: DialogUtils,
        @Inject eventBinder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject storage: LocalStorageService
    ) {
        this._dialogUtils = dialogUtils;
        this._eventBinder = eventBinder;
        this._eventBinder.init(document.documentElement);
        this._logger = loggerFactory.getLogger(this.key);
        this._storage = storage;
        this._bindEvents();
        this._logger.info("BackOffice grid utils instance created.");
    }

    public get key(): string {
        return "GridUtils";
    }

    private _bindEvents(): this {
        this._eventBinder
            .bindDelegatedClick(this.SEL_SAVE_STATE_BUTTON, this._onSaveStateButtonClick, this.key)
            .bindDelegatedClick(this.DELETE_STATE_BUTTON, this._onDeleteStateButtonClick, this.key)
            .bindDelegatedChange(this.SEL_FILTER_DROPDOWN, this._onFilterChange, this.key);

        return this;
    }

    @autobind
    private _onDeleteStateButtonClick(event: JQuery.TriggeredEvent): void {
        this.deleteGridState(this._getGridId(event));
    }

    @autobind
    private _onSaveStateButtonClick(event: JQuery.TriggeredEvent): void {
        this.saveGridState(this._getGridId(event));
    }

    /**
     * Fix of grid filter popups.
     * Move left when dropdownlist value is too long and causes overflow out of screen.
     * TODO: Rewise this later. Quite ugly.
     */
    @autobind
    private _onFilterChange(event: JQuery.TriggeredEvent): void {
        const target: Element | undefined = event ? event.target : undefined;
        if (!target) {
            return;
        }

        const filterMenu: Element | undefined = Utils.closest(target, this.SEL_FILTER_MENU);
        if (!filterMenu) {
            return;
        }

        const popup: kendo.ui.Popup | undefined = kendo.getPopup(filterMenu);
        const popupElement: Element | undefined = Utils.closest(filterMenu, this.SEL_FILTER_POPUP);
        if (!popup || !popupElement) {
            return;
        }

        const anchor: Element = (popup.options.anchor as JQuery)[0];
        const gridElement: Element | undefined = Utils.closest(anchor, this.SEL_GRID);
        if (!gridElement) {
            return;
        }

        const menuWidth: number = Utils.getWidth(filterMenu, true);
        const menuLeft: number = Utils.getOffset(filterMenu)?.left ?? 0;
        const gridWidth: number = Utils.getWidth(gridElement, true);
        const gridLeft: number = Utils.getOffset(gridElement)?.left ?? 0;
        // popup overflows 2 pixels out of grid
        const delta: number = 2;
        if (menuWidth + menuLeft > gridWidth) {
            Utils.setCss(popupElement, "left", Math.min(gridLeft + gridWidth - menuWidth + delta));
        }
    }

    private _getGridId(event: JQuery.TriggeredEvent): string {
        return (
            Utils.getData(event.target, this.DATA_GRID_ID) ||
            Utils.getData(event.currentTarget, this.DATA_GRID_ID) ||
            ""
        );
    }

    /**
     * Loads some basic grid instance settings from local storage and applies
     * it on a grid identified by provided ID.
     * @param gridId ID of the grid which state should be loaded.
     */
    @autobind
    public loadGridState(gridId: string): this {
        if (!gridId) {
            this._logger.error("Wrong grid ID specified, can't load grid state.");
            return this;
        }

        const grid: kendo.ui.Grid | undefined = kendo.getGrid(`#${gridId}`);
        if (!grid) {
            this._logger.error(
                `Couldn't find grid instance on element with ID "${gridId}". Can't load grid state.`
            );
            return this;
        }

        const stateSerialized: string | undefined = this._storage.getItem(
            this.STORAGE_KEY_PREFIX + gridId
        );
        const state: IGridState | undefined = stateSerialized
            ? JSON.parse(stateSerialized)
            : undefined;
        if (state) {
            grid.dataSource.query(state);
            this.deleteGridState(gridId);
            this._logger.info(`Loaded grid state for ${gridId}.`);
        }

        return this;
    }

    /**
     * Stores some basic grid instance settings into local storage.
     * @param gridId ID of the grid which state should be saved.
     */
    @autobind
    public saveGridState(gridId: string): this {
        if (!gridId) {
            this._logger.error("Wrong grid ID specified, can't save grid state.");
            return this;
        }

        const grid: kendo.ui.Grid | undefined = kendo.getGrid(`#${gridId}`);
        if (!grid) {
            this._logger.error(
                `Couldn't find grid instance on element with ID "${gridId}". Can't save grid state.`
            );
            return this;
        }

        const dataSource: kendo.data.DataSource = grid.dataSource;
        if (!dataSource) {
            return this.deleteGridState(gridId);
        }

        const state: IGridState = {
            filter: dataSource.filter(),
            group: dataSource.group(),
            page: dataSource.page(),
            pageSize: dataSource.pageSize(),
            sort: dataSource.sort(),
        };
        this._storage.setItem(this.STORAGE_KEY_PREFIX + gridId, JSON.stringify(state));
        this._logger.info(`Saved grid state for ${gridId}.`);

        return this;
    }

    @autobind
    public deleteGridState(gridId: string): this {
        if (gridId) {
            this._storage.removeItem(this.STORAGE_KEY_PREFIX + gridId);
            this._logger.info(`Deleted grid state for ${gridId}.`);
        } else {
            this._logger.error("Wrong grid ID specified, can't delete grid state.");
        }

        return this;
    }

    /**
     * Gets a kendo grid by id.
     * If kendo grid is an argument instead of string id, then it's returned.
     */
    @autobind
    public getGridById(gridOrId: kendo.ui.Grid | string): kendo.ui.Grid | undefined {
        if (!gridOrId) {
            return undefined;
        }

        if (typeof gridOrId === "object") {
            return gridOrId;
        }

        return kendo.getGrid(`#${gridOrId}`);
    }

    /**
     * Checks if data in a kendo grid are dirty or if there are items marked for delete.
     * @returns true if the grid was found and its data was changed.
     */
    @autobind
    public isGridChanged(gridOrGridId: kendo.ui.Grid | string): boolean {
        const grid: kendo.ui.Grid | undefined = this.getGridById(gridOrGridId);
        return grid ? grid.hasChanges() : false;
    }

    /**
     * Reloads a child grid by selected row.
     */
    @autobind
    public reloadChildGridBySelection(
        parentGridOrId: kendo.ui.Grid | string,
        childGridOrId: kendo.ui.Grid | string,
        filterField: string
    ): this {
        const parentGrid: kendo.ui.Grid | undefined = this.getGridById(parentGridOrId);
        if (!parentGrid) {
            return this;
        }

        const selectedRows = parentGrid.select();
        if (!selectedRows || !selectedRows.length) {
            return this._clearGrid(childGridOrId);
        }

        const selectedDataItem: IGridDataItem = parentGrid.dataItem(
            selectedRows[0]
        ) as IGridDataItem;
        const selectedId: any = selectedDataItem.get(this.FIELD_ID);

        return this._setFilterReloadGrid(childGridOrId, filterField, selectedId);
    }

    /**
     * Clears data from grid.
     */
    private _clearGrid(gridOrId: kendo.ui.Grid | string): this {
        const grid: kendo.ui.Grid | undefined = this.getGridById(gridOrId);
        if (grid) {
            grid.dataSource.data([]);
            this._logger.info(`Cleared data from grid ${grid}`);
        }

        return this;
    }

    /**
     * Reloads grid datasource by filter value.
     */
    private _setFilterReloadGrid(
        gridOrId: kendo.ui.Grid | string,
        field: string,
        value: any
    ): this {
        const grid: kendo.ui.Grid | undefined = this.getGridById(gridOrId);
        if (grid) {
            this._applyGridFilter(grid, field, value);
        }

        return this;
    }

    /**
     * Applies filter on given field of given grid's data source.
     * Filtering with equal operator and the provided value.
     */
    private _applyGridFilter(grid: kendo.ui.Grid, field: string, value: any): void {
        const filter: kendo.data.DataSourceFilterItem = {
            field,
            value,
            operator: this.EQUAL_OPERATOR,
        };
        grid.dataSource.filter(filter);
    }

    /**
     * Groups the grid datasource by specified field and reloads grid data.
     */
    @autobind
    public groupGridByField(gridOrId: kendo.ui.Grid | string, field: string): this {
        const grid: kendo.ui.Grid | undefined = this.getGridById(gridOrId);
        if (grid) {
            grid.dataSource.group(field ? { field } : []);
        }

        return this;
    }

    /**
     * Gets a dataItem (viewModel) of a row in a kendo grid by dataItem id.
     */
    private _getRowById(grid: kendo.ui.Grid, rowId: any): IGridDataItem | undefined {
        if (typeof rowId === "undefined") {
            throw new Error("An undefined rowId!");
        }

        const data: kendo.data.ObservableArray = grid.dataSource.data();
        return data.find((item: IGridDataItem) => item.get(this.FIELD_ID) === rowId);
    }

    private _findHtmlRowsByUid(grid: kendo.ui.Grid, uid: any): Element[] {
        const selector: string = `tr[data-uid="${uid}"]`;
        return Utils.find(grid.tbody[0], selector);
    }

    private _toggleGridRowElements(elements: Element[], visible: boolean): void {
        elements.forEach((element) => {
            element.classList.toggle(this.CLASS_HIDDEN, !visible);
            if (!visible) {
                element.classList.remove(this.CLASS_SELECTED);
            }
        });
    }

    @autobind
    public toggleRowsInGrid(
        gridOrId: kendo.ui.Grid | string,
        rows: IGridDataItem[],
        visible: boolean
    ): this {
        const grid: kendo.ui.Grid | undefined = this.getGridById(gridOrId);
        if (!grid) {
            return this;
        }

        rows.forEach((row: IGridDataItem) => {
            const item: IGridDataItem | undefined = this._getRowById(grid, row.get(this.FIELD_ID));
            if (item) {
                const elements: Element[] = this._findHtmlRowsByUid(grid, item.uid);
                this._toggleGridRowElements(elements, visible);
            }
        });

        return this;
    }

    /**
     * Hides rows in a grid.
     * The list of rows must be list of dataItems (viewModels).
     */
    @autobind
    public hideRowsInGrid(gridOrId: kendo.ui.Grid | string, rows: IGridDataItem[]): this {
        return this.toggleRowsInGrid(gridOrId, rows, false);
    }

    /**
     * Unhides hidden rows in a grid.
     * The list of rows must be list of dataItems (viewModels).
     */
    @autobind
    public showRowsInGrid(gridOrId: kendo.ui.Grid | string, rows: IGridDataItem[]): this {
        return this.toggleRowsInGrid(gridOrId, rows, true);
    }

    /**
     * Add rows to a grid.
     * The list of rows must be list of dataItems (viewModels).
     */
    @autobind
    public addRowsInGrid(gridOrId: kendo.ui.Grid | string, rows: IGridDataItem[]): this {
        const grid: kendo.ui.Grid | undefined = this.getGridById(gridOrId);
        if (!grid) {
            return this;
        }

        rows.forEach((row: IGridDataItem) => {
            const item: IGridDataItem | undefined = this._getRowById(grid, row.get(this.FIELD_ID));
            if (!item) {
                grid.dataSource.add(row);
            }
        });

        return this;
    }

    /**
     * Deletes selected rows in a grid.
     */
    @autobind
    public deleteSelectedRowsInGrid(gridOrId: kendo.ui.Grid | string): this {
        const grid: kendo.ui.Grid | undefined = this.getGridById(gridOrId);
        if (grid) {
            grid.select().each((_index: number, element: Element) => grid.removeRow(element));
        }

        return this;
    }

    /**
     * Gets a list of dataItems (viewModels of the rows) of selected rows in a kendo grid.
     */
    @autobind
    public getSelectedDataItems(gridOrId: kendo.ui.Grid | string): IGridDataItem[] {
        const grid: kendo.ui.Grid | undefined = this.getGridById(gridOrId);
        const result: IGridDataItem[] = [];

        if (grid) {
            grid.select().each((_index: number, element: Element) => {
                const item: IGridDataItem = grid.dataItem(element) as IGridDataItem;
                if (item) {
                    result.push(item);
                }
            });
        }

        return result;
    }

    /**
     * Gets a list of dataItem ids selected rows in a kendo grid.
     * If it returns empty collection your model probably does not
     * have property "Id"!
     */
    @autobind
    public getSelectedDataItemIds(gridOrId: kendo.ui.Grid | string): any[] {
        const grid: kendo.ui.Grid | undefined = this.getGridById(gridOrId);
        const result: any[] = [];

        if (grid) {
            grid.select().each((_index: number, element: Element) => {
                const item: IGridDataItem = grid.dataItem(element) as IGridDataItem;
                if (item) {
                    result.push(item.get(this.FIELD_ID));
                }
            });
        }

        return result;
    }

    /**
     * Gets a list of all dataItem ids in a kendo grid.
     */
    @autobind
    public getDataItemIds(gridOrId: kendo.ui.Grid | string): any[] {
        const grid: kendo.ui.Grid | undefined = this.getGridById(gridOrId);
        let result: any[] = [];

        if (grid) {
            const data: kendo.data.ObservableArray = grid.dataSource.data();
            result = data.map((item: IGridDataItem) => item.get(this.FIELD_ID));
        }

        return result;
    }

    /**
     * Marks all data in in a kendo grid are nondirty.
     */
    @autobind
    public setGridNonDirty(gridOrId: kendo.ui.Grid | string): this {
        const grid: kendo.ui.Grid | undefined = this.getGridById(gridOrId);

        if (grid) {
            const data: kendo.data.ObservableArray = grid.dataSource.data();
            data.forEach((item: IGridDataItem) => (item.dirty = false));
        }

        return this;
    }

    /**
     * Get field value from grid datasource's filter.
     */
    @autobind
    public getGridFilterValue(
        filter:
            | kendo.data.DataSourceFilters
            | kendo.data.DataSourceFilterItem
            | kendo.data.DataSourceFilterItem[],
        field: string
    ): any {
        const item = filter as kendo.data.DataSourceFilterItem;
        if (item.field) {
            return item.field === field ? item.value : null;
        }

        const filters = filter as kendo.data.DataSourceFilters;
        if (filters.filters) {
            return this.getGridFilterValue(filters.filters, field);
        }

        if (Array.isArray(filter)) {
            for (const filterItem of filter) {
                const value = this.getGridFilterValue(filterItem, field);
                if (value !== null && value !== undefined) {
                    return value;
                }
            }
        }

        return null;
    }

    private _getGridFromRequestEvent(event: kendo.data.DataSourceEvent): kendo.ui.Grid | undefined {
        const elements: Element[] = Utils.find(document.documentElement, this.SEL_GRID);
        let result: kendo.ui.Grid | undefined;
        elements.forEach((element) => {
            const grid: kendo.ui.Grid | undefined = kendo.getGrid(element);
            if (grid && grid.dataSource && grid.dataSource === event.sender) {
                result = grid;
            }
        });

        return result;
    }

    private _fillDataOpErrors(objectToFill: any, dataOperation: string): void {
        if (!objectToFill) {
            return;
        }

        if (dataOperation) {
            objectToFill[dataOperation] = [];
        } else {
            objectToFill[this.GRID_DATAOP_CREATE] = [];
            objectToFill[this.GRID_DATAOP_UPDATE] = [];
            objectToFill[this.GRID_DATAOP_DELETE] = [];
        }
    }

    /**
     * Handles datagrid datasource REQUESTSTART event, generated before each POST.
     * This is the proper point where we should clear all previous error data in the grid
     */
    @autobind
    public onGridRequestStart(event: kendo.data.DataSourceRequestStartEvent): void {
        const grid: kendo.ui.Grid | undefined = this._getGridFromRequestEvent(event);
        if (!grid) {
            return;
        }

        const gridElement: Element = grid.element[0];
        let gridErrors: IGridErrors = Utils.getData(gridElement, this.GRID_DATA_ERRORS);

        // create & fill the structure just once for the whole grid lifetime
        if (!gridErrors) {
            const options: kendo.data.DataSourceOptions = grid.dataSource.options || {};
            const batch: boolean = Boolean(options.batch) || false;
            let id: string = "";
            if (options.schema && options.schema.model && options.schema.model.id) {
                id = options.schema.model.id;
            }
            // failsafe mode if there is no better way: popup with error summary
            // SUM mode if in non-batch mode without ID field
            let mode: string = this.GRID_VALIDATION_MODE_SUM;
            // if we know which one is the ID field, we can leverage from it even in non-batch mode
            if (id) {
                // ID mode if we have id field. In non-batch mode we are able to gradually
                // compose the error & id arrays from partial request responses.
                mode = this.GRID_VALIDATION_MODE_ID;
            } else if (batch) {
                // Without ID field, in batch mode, we can still find the proper row according to dirty attribute.
                // OP mode if in batch mode without ID field
                // Find the row based on dirty, IsNew or IsDeleted attributes.
                mode = this.GRID_VALIDATION_MODE_OP;
            }
            gridErrors = {
                batch,
                cellColoring: false,
                changesCancelled: false,
                errors: {},
                id,
                ids: {},
                mode,
                sumShown: false,
            };
        }

        // now, delete any previous errors stored
        if (gridErrors.mode === this.GRID_VALIDATION_MODE_SUM) {
            gridErrors.sum = [];
        } else {
            // TODO: Update @types/ori-kendo-extensions with originalType?: string on TypedEvent
            let dataOperation: string = (event as any).originalType || "";
            if (
                dataOperation !== this.GRID_DATAOP_CREATE &&
                dataOperation !== this.GRID_DATAOP_DELETE &&
                dataOperation !== this.GRID_DATAOP_UPDATE
            ) {
                dataOperation = "";
            }

            this._fillDataOpErrors(gridErrors.errors, dataOperation);
            if (gridErrors.mode === this.GRID_VALIDATION_MODE_ID) {
                this._fillDataOpErrors(gridErrors.ids, dataOperation);
            }
        }

        Utils.removeData(gridElement, this.GRID_DATA_ERRORLIST);
        Utils.setData(gridElement, this.GRID_DATA_ERRORS, gridErrors);
    }

    /**
     * Handles datagrid datasource REQUESTEND event, generated after each return from POST.
     * This is the only point where we can distinguish between errors returned from CREATE and from UPDATE calls
     * and also the only point where we still see the returned data themselves, not just errors,
     * and thus we can pair the errors with data rows according to ID field, if it is known.
     */
    @autobind
    public onGridRequestEnd(event: kendo.data.DataSourceRequestEndEvent): void {
        const dataOperation: string = (event as any).originalType || "";
        if (!event.response || !event.response.Errors || !dataOperation) {
            return;
        }
        if (
            dataOperation !== this.GRID_DATAOP_CREATE &&
            dataOperation !== this.GRID_DATAOP_UPDATE &&
            dataOperation !== this.GRID_DATAOP_DELETE
        ) {
            return;
        }
        const grid: kendo.ui.Grid | undefined = this._getGridFromRequestEvent(event);
        if (!grid) {
            return;
        }

        const gridElement: Element = grid.element[0];
        const gridErrors: IGridErrors = Utils.getData(gridElement, this.GRID_DATA_ERRORS);

        if (gridErrors.mode === this.GRID_VALIDATION_MODE_SUM) {
            // no batch, without id field
            if (!gridErrors.sum) {
                gridErrors.sum = [];
            }
            gridErrors.sum.push(this._gridErrorSum(event.response.Errors));
        } else if (!gridErrors.batch) {
            // no batch, with id field
            (gridErrors.errors as any)[dataOperation].push(event.response.Errors);
            if (event.response.Data && event.response.Data[0]) {
                (gridErrors.ids as any)[dataOperation].push(event.response.Data[0][gridErrors.id]);
            }
        } else {
            // batch, with or without id field
            (gridErrors.errors as any)[dataOperation] = this._gridErrorTransform(
                event.response.Errors
            );
            if (gridErrors.id && event.response.Data) {
                (gridErrors.ids as any)[dataOperation] = this._gridDataIdList(
                    event.response.Data,
                    gridErrors.id
                );
            }
        }

        Utils.setData(gridElement, this.GRID_DATA_ERRORS, gridErrors);
    }

    private _gridErrorSum(errors: IGridRowErrors): string {
        let result: string[] = [];
        // TODO: Object.values() didn't want to work, probably have to adjust TS config & polyfills
        Object.keys(errors)
            .map((key) => errors[key])
            .forEach((item) => (result = result.concat(item.errors)));
        return result.join(this.LINE_BREAK);
    }

    /**
     * Splits the original errors object into array item where each item contains
     * errors for exactly one grid row. In original input format all grid errors
     * are mixed in one IGridRowErrors object.
     * @param errors Input grid row errors in Kendo format. More row errors in 1 object,
     * where particular rows are identified by prefix 'Model[row_index].'.
     */
    private _gridErrorTransform(errors: IGridRowErrors): IGridRowErrors[] {
        const result: IGridRowErrors[] = [];

        Object.keys(errors).map((key: string) => {
            const columnKeys: string[] = key.split(".");
            const column: string = columnKeys.length > 1 ? columnKeys[1] : key;
            const rowIndex: RegExpMatchArray | number[] | null =
                columnKeys.length > 1 ? columnKeys[0].match(/\d/g) : ["0"];
            if (!rowIndex) {
                return;
            }

            const index: number = parseInt(rowIndex[0], 10);
            if (!result[index]) {
                result[index] = {};
            }
            result[index][column] = errors[key];
        });

        return result;
    }

    /**
     * @param data Data source's data (array).
     * @param idField Name of ID column in the data source.
     * @returns Values for ID column from whole data source transformed to an array.
     */
    private _gridDataIdList(data: kendo.data.ObservableArray, idField: string): any[] {
        return data.map((item: any) => item[idField] || (item[idField] === 0 ? 0 : ""));
    }

    /**
     * Colors the background of the grid cells which contain an invalid value.
     * Errors received from the server have been processed and stored in the data-errors attribute.
     * Here, they are either displayed in a popup or assigned to individual cells as an error tooltip.
     */
    @autobind
    private _handleGridErrorCellColoring(event: kendo.ui.GridEvent): void {
        const grid: kendo.ui.Grid = event.sender;
        const gridElement: Element = grid.element[0];
        const errorList: IDistilledGridErrors[] =
            Utils.getData(gridElement, this.GRID_DATA_ERRORLIST) || [];

        if (errorList.length) {
            // we already have nicely preprocessed errors stored
            // this way, we also avoid showing error popup more than once
            return this._highlightErrors(grid, errorList);
        }

        const gridErrors: IGridErrors = Utils.getData(gridElement, this.GRID_DATA_ERRORS);
        if (
            gridErrors.mode === this.GRID_VALIDATION_MODE_ERR ||
            gridErrors.mode === this.GRID_VALIDATION_MODE_SUM
        ) {
            // only show popup with errors
            return this._showErrorsPopup(gridElement, gridErrors);
        }

        const ops = [this.GRID_DATAOP_CREATE, this.GRID_DATAOP_UPDATE, this.GRID_DATAOP_DELETE];
        if (gridErrors.changesCancelled) {
            // if changes are cancelled, there is no way to pair errors with data, so just show popup
            // - in OP mode, it applies to all errors, in ID mode it applies only to those sets of errors,
            // for which we have no data (and thus no IDs) returned
            if (!this._showCancelledChangesErrorsPopup(gridElement, gridErrors, ops)) {
                return;
            }
            // In ID mode, if we have any row IDs returned, we continue to display those errors in tooltips.
        }

        // 1st call after a POST request - we must preprocess errors and store them in grid
        // for easier pairing with rows later on (see the beginning of the function)
        // note that we only get here in ID and OP modes
        const itemIndex: IList<number> = {};
        const idsMissing: IList<boolean> = {};
        let employCounters: boolean = false;
        if (!gridErrors.changesCancelled) {
            // this pre-check is only meaningful when changes are not cancelled beforehand
            // (i.e. we can rely on dirty flag)
            employCounters = this._preprocessIndexesAndMissingIds(
                itemIndex,
                idsMissing,
                gridErrors,
                ops
            );
        }

        // now, mark the errors which can be anyhow paired with data rows
        this._pairAndHighlightErrors(
            grid,
            errorList,
            gridErrors,
            ops,
            itemIndex,
            idsMissing,
            employCounters
        );

        // In non-batch mode, we do not clear errors in onRequestStart, so we must do it now
        // because if changes have been cancelled, any stored errors would not get cleared.
        if (!gridErrors.batch && gridErrors.changesCancelled) {
            this._clearErrors(gridElement, gridErrors, ops);
        }
        Utils.setData(gridElement, this.GRID_DATA_ERRORLIST, errorList);
    }

    private _highlightErrors(grid: kendo.ui.Grid, errorList: IDistilledGridErrors[]): void {
        errorList.forEach((item) => {
            const row = this._findHtmlRowsByUid(grid, item.rowId)[0];
            if (!row) {
                return;
            }

            const cell = row.children.item(item.column);
            if (!cell) {
                return;
            }

            if (!cell.classList.contains(this.COLOR_RED)) {
                cell.classList.add(this.COLOR_RED);
                cell.setAttribute(this.ATTR_TITLE, item.message);
                this._setValidationError(cell, item.message);
            }
        });
    }

    private _showErrorsPopup(gridElement: Element, gridErrors: IGridErrors): void {
        if (gridErrors.sumShown || !gridErrors.sum || !gridErrors.sum.length) {
            // avoid showing popup more than once or when nothing to show
            return;
        }

        this._dialogUtils.errorDialog(gridErrors.sum.join(this.LINE_BREAK), this.VALIDATION_ISSUE);
        gridErrors.sumShown = true;
        Utils.setData(gridElement, this.GRID_DATA_ERRORS, gridErrors);
    }

    private _showCancelledChangesErrorsPopup(
        gridElement: Element,
        gridErrors: IGridErrors,
        ops: string[]
    ): boolean {
        let hasIds: boolean = false;
        if (gridErrors.sumShown) {
            return hasIds;
        }

        // If changes are cancelled, the newly CREATEd data rows do not exist.
        (gridErrors.ids as any)[this.GRID_DATAOP_CREATE] = [];
        // so we can only show those errors in a popup
        const messages: string[] = [];
        ops.forEach((operation: string) => {
            let errIds: any[] = (gridErrors.ids as any)[operation];
            if (errIds && errIds.length) {
                hasIds = true;
                return;
            }
            let errors: IGridRowErrors[] | undefined = (gridErrors.errors as any)[operation];
            if (errors) {
                errors.forEach((rowErrors: IGridRowErrors) =>
                    messages.push(this._gridErrorSum(rowErrors))
                );
            }
            // In non-batch mode, we do not clear errors in onRequestStart, so we must do it now
            // because all changes have been cancelled and any stored errors would not get cleared.
            errors = [];
            errIds = [];
        });
        if (messages.length) {
            this._dialogUtils.errorDialog(messages.join(this.LINE_BREAK), this.VALIDATION_ISSUE);
        }
        gridErrors.sumShown = true;
        Utils.setData(gridElement, this.GRID_DATA_ERRORS, gridErrors);

        return hasIds;
    }

    private _preprocessIndexesAndMissingIds(
        itemIndex: IList<number>,
        idsMissing: IList<boolean>,
        gridErrors: IGridErrors,
        ops: string[]
    ): boolean {
        let employCounters: boolean = false;
        ops.forEach((operation: string) => {
            itemIndex[operation] = 0;
            const errors: IGridRowErrors[] | undefined = (gridErrors.errors as any)[operation];
            const errIds: any[] = (gridErrors.ids as any)[operation];
            if (errors && errors.length && (!errIds || errIds.length !== errors.length)) {
                idsMissing[operation] = true;
                employCounters = true;
            } else {
                idsMissing[operation] = false;
            }
        });

        return employCounters;
    }

    private _pairAndHighlightErrors(
        grid: kendo.ui.Grid,
        errorList: IDistilledGridErrors[],
        gridErrors: IGridErrors,
        ops: string[],
        itemIndex: IList<number>,
        idsMissing: IList<boolean>,
        employCounters: boolean
    ): void {
        const data: kendo.data.ObservableArray = grid.dataSource.data();
        data.forEach((item: IGridDataItem) => {
            if (!gridErrors.changesCancelled && !item.dirty && !item.isNew()) {
                return;
            }

            let cntOp: string = "";
            let op: string = "";
            let idx: number = -1;
            // employCounters can only be true if changesCancelled === false (see above) which means
            // that dataItem.dirty IS true if we get inside this below is grossly unreliable,
            // because IsNew, IsDeleted and similar are model-defined properties,
            // not kendo's; they may or may not be there and may or may not indicate the reality
            // but there is no other way to distinguish whether a data item was updated, created or deleted
            if (employCounters) {
                if (item.get(this.FIELD_IS_NEW) || item.get(this.FIELD_IS_NEW_RECORD)) {
                    cntOp = this.GRID_DATAOP_CREATE;
                } else if (item.get(this.FIELD_IS_DELETED)) {
                    cntOp = this.GRID_DATAOP_DELETE;
                } else {
                    cntOp = this.GRID_DATAOP_UPDATE;
                }
            }

            if (gridErrors.mode === this.GRID_VALIDATION_MODE_ID) {
                let errIds: any[] = [];
                for (const o of ops) {
                    op = o;
                    errIds = (gridErrors.ids as any)[o];
                    idx = errIds.indexOf(item.id);
                    if (idx !== -1) {
                        break;
                    }
                }
                if (idx !== -1 && op === this.GRID_DATAOP_CREATE) {
                    // in Create, all ids of error rows are the same, so we must use "counter"
                    // let's "mark" the already processed item so we find the following one next time
                    // indexOf compares types so null (which cannot be there) is not equal to 0 or ""
                    errIds[idx] = null;
                }
            }

            // Got here because of one of possible reasons:
            // - Didn't find corresponding ID for datasource item in error items.
            // - IDs are not set up at all (not in GRID_VALIDATION_MODE_ID).
            // So we switch to "manual" index counters here (employCounters === true).
            if (idx === -1) {
                if (employCounters && idsMissing[cntOp]) {
                    op = cntOp;
                    idx = itemIndex[op];
                    itemIndex[op]++;
                } else {
                    return;
                }
            }

            const errors: IGridRowErrors[] | undefined = (gridErrors.errors as any)[op];
            if (!errors) {
                return;
            }

            // now we have the index of the error message in idx
            const rowErrors: IGridRowErrors | undefined = errors[idx];
            if (!rowErrors) {
                return;
            }

            Object.keys(rowErrors).forEach((columnName) => {
                const errorItem: IErrorMessages = rowErrors[columnName];
                const row: Element | undefined = this._findHtmlRowsByUid(grid, item.uid)[0];
                if (!row) {
                    return;
                }

                const columnIndex: number = this._getColumnIndexFromName(grid, columnName);
                const cell = row.children.item(columnIndex);
                if (!cell) {
                    return;
                }

                const errorMessages: string = errorItem.errors.join(this.LINE_BREAK);
                cell.classList.add(this.COLOR_RED);
                cell.setAttribute(this.ATTR_TITLE, errorMessages);
                this._setValidationError(cell, errorMessages);

                // store the error in the preprocessed format for easier access
                errorList.push({ rowId: item.uid, column: columnIndex, message: errorMessages });
            });
        });
    }

    private _clearErrors(gridElement: Element, gridErrors: IGridErrors, ops: string[]): void {
        ops.forEach((operation: string) => {
            (gridErrors.ids as any)[operation] = [];
            (gridErrors.errors as any)[operation] = [];
        });
        Utils.setData(gridElement, this.GRID_DATA_ERRORS, gridErrors);
    }

    private _setValidationError(element: Element, errorMsg: string): void {
        // TODO: adding/removing the widget class should be part of kendo extensions
        element.classList.add(this.CLASS_WIDGET);
        kendo.ui.errorMessage(element, errorMsg);
        element.classList.remove(this.CLASS_WIDGET);
    }

    /**
     * Handles datagrid datasource ERROR event, generated whenever a datasource returns any errors.
     */
    @autobind
    public handleGridError(event: IDataSourceCancelChangesErrorEvent): void {
        if (!event || !event.errors) {
            return;
        }

        const grid: kendo.ui.Grid | undefined = this._getGridFromRequestEvent(event);
        if (!grid) {
            return;
        }

        const gridElement: Element = grid.element[0];
        let gridErrors: IGridErrors = Utils.getData(gridElement, this.GRID_DATA_ERRORS);
        if (!gridErrors) {
            // Create & fill the structure just once for the whole grid lifetime.
            // If it is still undefined here, we do not have REQUESTSTART & REQUESTEND events bound,
            // in which case, the only way to go is with SUM mode because we don't know which
            // operation (create, update, delete) the errors were returned from
            // and thus cannot reliably pair them with datagrid rows.
            gridErrors = {
                cellColoring: false,
                changesCancelled: false,
                errors: {},
                id: "",
                ids: {},
                // equivalent to SUM, used to distinguish where errors are actually handled
                mode: this.GRID_VALIDATION_MODE_ERR,
                sum: [],
                sumShown: false,
            };
        }

        if (gridErrors.mode === this.GRID_VALIDATION_MODE_ERR) {
            // popup already shown, so this is a new request and errors start from the beginning
            if (!gridErrors.sum || gridErrors.sumShown) {
                gridErrors.sum = [];
            }
            gridErrors.sum.push(this._gridErrorSum(event.errors));
            Utils.removeData(gridElement, this.GRID_DATA_ERRORLIST);
        }
        if (!gridErrors.cellColoring) {
            // bind coloring only once
            grid.bindDataBound(this._handleGridErrorCellColoring);
            gridErrors.cellColoring = true;
        }
        gridErrors.sumShown = false;
        gridErrors.changesCancelled = Boolean(event.changesCancelled);
        Utils.setData(gridElement, this.GRID_DATA_ERRORS, gridErrors);
    }

    /**
     * Handles datagrid datasource ERROR event and immediately reverts back any changes which caused the error.
     * should it be called from any other function handling the event (not being the eventhandler itself),
     * use ori.backoffice.handleGridErrorWithCancel.apply(this, [event]); as it needs 'this' to be properly set
     */
    @autobind
    public handleGridErrorWithCancel(event: IDataSourceCancelChangesErrorEvent): void {
        event.changesCancelled = true;
        this.handleGridError(event);
        if (event.sender) {
            event.sender.cancelChanges();
        }
    }

    /**
     * Gets column index by its name
     */
    private _getColumnIndexFromName(grid: kendo.ui.Grid, name: string): number {
        let result: number = -1;
        const columns = grid.options.columns;
        if (columns) {
            columns.forEach((column: kendo.ui.GridColumn, index: number) => {
                // Sometimes the column is bound to SosType.Title, but the name from the model is only SosType.
                // column.title -- You can also use title property here but for this you have to assign title for all columns.
                const field: string | undefined = column.field;
                if (!field) {
                    return;
                }

                const fieldName = field.split(".");
                if (fieldName[0] === name) {
                    result = index;
                }
            });
        }

        return result;
    }
}
