export enum FormSubmitMode {
    /**
     * Form will be submitted in the normal way via reloading the page.
     */
    Normal,

    /**
     * Form data will be submitted via XHR like jQuery.ajax() or so.
     * TODO: not implemented yet
     */
    Ajax,

    /**
     * This should become "our standard" during time.
     * Form data will be submitted via fetch() API.
     * In IE a polyfill is used for this to work.
     */
    FetchApi,
}
