import { OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { Utils } from "Helpers/Scripts/Utils";

@OnlyInstantiableByContainer
@Singleton
export class GridHelper {
    public removeLinksFromHeader(grid: Element): void {
        if (!grid) {
            return;
        }

        const gridHeaderLinks = Utils.find(grid, "thead a");
        gridHeaderLinks.forEach((link: Element) => {
            Utils.setAttr(link, "href", "#");
        });
    }
}
