import { Inject } from "typescript-ioc";
import { ICookieService } from "./ICookieService";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { OneTrust } from "Integrations/OneTrust/Scripts/OneTrust";
import $ from "jquery";
import "jquery.cookie";

export class CookieService implements ICookieService {
    public static readonly DEFAULT_PATH: string = "/";
    private readonly _logger: ILogger;
    private readonly _oneTrust: OneTrust;

    public get key(): string {
        return "CookieService";
    }

    constructor(@Inject loggerFactory: LoggerFactory, @Inject oneTrust: OneTrust) {
        this._logger = loggerFactory.getLogger(this.key);
        this._oneTrust = oneTrust;
    }

    /**
     * Sets $.cookie.raw config
     */
    public setRaw(value: boolean): void {
        if ($.cookie) {
            $.cookie.raw = value;
        }
    }

    /**
     * @returns Value of specified cookie.
     */
    public getCookie(name: string): string {
        if (!$.cookie) {
            return "";
        }

        const value = $.cookie(name);
        if (!value) {
            return "";
        }

        return `${value}`;
    }

    /**
     * Sets specified cookie.
     * @param name Name of created cookie.
     * @param value Cookie value, will beconverted to string.
     * @param expires Count of days to expire.
     * @param path Cookie path.
     * @param domain Domain where the cookie is valid.
     * @param secure When secure, the cookie has to be transferred via HTTPS.
     */
    public setCookie(
        name: string,
        value: any,
        expires?: number,
        path: string = CookieService.DEFAULT_PATH,
        domain?: string,
        secure?: boolean
    ): this {
        if (!this._oneTrust.isCookieAllowed(name)) {
            return this;
        }

        if ($.cookie) {
            const options = { path, expires, domain, secure };
            const val = value.toString() || "";
            this._logger.info(
                "Saving cookie '%s', value: '%s', options: %o ...",
                name,
                val,
                options
            );
            $.cookie(name, val, options);
        }

        return this;
    }

    /**
     * Removes specified cookie. Path, domain & secure options
     * must be same as the original ones when the given cookie
     * was created, otherwise it won't be deleted.
     * @param name Name of cookie to be deleted.
     * @param path Cookie path.
     * @param domain Domain where the cookie is valid.
     * @param secure When secure, the cookie has to be transferred via HTTPS.
     * @returns True when the removal was successful.
     */
    public removeCookie(
        name: string,
        path: string = CookieService.DEFAULT_PATH,
        domain?: string,
        secure?: boolean
    ): boolean {
        const options = {
            domain,
            path,
            secure,
        };

        const result = $.removeCookie(name, options);
        if (result) {
            this._logger.info("Removed cookie '%s'.", name);
        } else {
            this._logger.warning(
                "Couldn't remove cookie '%s'. Options were %o. " +
                    "Did they match with the options with which the cookie was created?",
                name,
                options
            );
        }

        return result;
    }
}
